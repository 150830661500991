import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"
import { show, hide, buttonAsSubmitting } from "../../utils"

// Connects to data-controller="workflow-criteria--rules-controller"
export default class extends Controller {
  static targets = [
    "addRuleButton",
    "categoryInput",
    "workflowInput",
    "editableRulesContainer",
    "rulesForm",
    "mainErrors",
    "submitButton",
  ]
  addRuleButtonTarget: HTMLButtonElement
  editableRulesContainerTarget: HTMLDivElement
  categoryInputTargets: Array<any>
  workflowInputTargets: Array<any>
  rulesFormTarget: HTMLFormElement
  mainErrorsTarget: HTMLDivElement
  submitButtonTarget: HTMLButtonElement

  static values = {
    categoryOptions: Array,
    availableCategories: Array,
    ruleWorkflowOptionsUrl: String,
    requestType: String,
  }
  categoryOptionsValue: Array<string>
  availableCategoriesValue: Array<string>
  ruleWorkflowOptionsUrlValue: string
  requestTypeValue: string

  connect() {
    this.updateAvailableCategories()
  }

  submitForm() {
    if (this.rulesFormTarget && this.isFormValid()) {
      buttonAsSubmitting(this.submitButtonTarget)
      this.rulesFormTarget.requestSubmit()
    }
  }

  isFormValid() {
    let isValid = true

    this.clearErrors()

    this.visibleConditions().forEach((element) => {
      element.querySelectorAll("select").forEach((select_element) => {
        if (!select_element.value) {
          isValid = false
          show(element.querySelector(".errors"))
          return
        }
      })
    })

    return isValid
  }

  clearErrors() {
    hide(this.mainErrorsTarget)

    this.editableRulesContainerTarget.querySelectorAll(".editable-rule-container").forEach((element) => {
      hide(element.querySelector(".errors"))
    })
  }

  setInputCategories(input: HTMLSelectElement) {
    const opts = [...input.options]

    opts.forEach((opt) => {
      if (opt.selected || this.availableCategoriesValue.includes(opt.value)) {
        if (input.tomselect) {
          if (input.tomselect) {
            input.tomselect.updateOption(opt.value, { text: opt.value, value: opt.value, disabled: false })
          }
        }
      } else {
        if (input.tomselect) {
          input.tomselect.updateOption(opt.value, { text: opt.value, value: opt.value, disabled: true })
        } else {
          opt.disabled = true
        }
      }
    })
  }

  updateAvailableCategories() {
    let selectedCategories = this.categoryInputTargets
      .map((input) => {
        return [...input.selectedOptions].map((option) => option.value)
      })
      .flat()

    this.availableCategoriesValue = this.categoryOptionsValue.filter((opt) => !selectedCategories.includes(opt)).sort()

    this.categoryInputTargets.map((input) => {
      this.setInputCategories(input)
    })
    this.updateAddRuleButton()
  }

  updateRuleWorkflowOptions(event) {
    let params = new URLSearchParams()

    Array.from(event.target.selectedOptions).forEach((option) => {
      params.append("selected_request_category_names[]", option.value)
    })
    params.append("index", event.target.dataset.index)
    params.append("request_type", this.requestTypeValue)

    get(`${this.ruleWorkflowOptionsUrlValue}?${params}`, {
      responseKind: "turbo-stream",
    })
  }

  updateAddRuleButton() {
    if (this.availableCategoriesValue.length == 0 || this.reachedMaxPotentialRules()) {
      hide(this.addRuleButtonTarget)
    } else {
      show(this.addRuleButtonTarget)
    }
  }

  reachedMaxPotentialRules() {
    let visibleRulesWithoutCategories = 0
    this.visibleConditions().forEach((element) => {
      const inputControl = element.querySelector(".category-input").tomselect
      if (inputControl === undefined) {
        return
      }
      if (inputControl.getValue() == 0) {
        visibleRulesWithoutCategories++
      }
    })

    return visibleRulesWithoutCategories >= this.availableCategoriesValue.length
  }

  addRule() {
    let ruleToShow = this.editableRulesContainerTarget.querySelector(".editable-rule-container.hidden")
    show(ruleToShow)

    this.updateAddRuleButton()
  }

  removeRule(event) {
    const visibleConditions = this.visibleConditions()
    const indexToRemove = event.params.index
    const indexToHide = visibleConditions.length - 1
    let conditionToHide = visibleConditions[indexToHide]

    if (indexToRemove == indexToHide) {
      this.categoryInputTargets[indexToHide].tomselect.clear()
      this.updateAvailableCategories()
    } else {
      // Since the order of our conditions is important to the backend, this loops through the both condition we want to
      // remove and its following conditions, moving their input values up the chain so we can simply hide the last one
      let index = indexToRemove
      while (index < indexToHide) {
        const currentConditionCategoryInput = this.categoryInputTargets[index]
        const nextConditionCategoryInput = this.categoryInputTargets[index + 1]
        const currentConditionWorkflowInput = this.workflowInputTargets[index]
        const nextConditionWorkflowInput = this.workflowInputTargets[index + 1]

        let categories = [nextConditionCategoryInput.tomselect.getValue()]

        if (categories.length > 0) {
          nextConditionCategoryInput.tomselect.clear(true)
          this.updateAvailableCategories()

          currentConditionCategoryInput.tomselect.clear(true)
          categories.forEach((category) => {
            currentConditionCategoryInput.tomselect.addItem(category, true)
          })
          this.updateAvailableCategories()
        }

        currentConditionWorkflowInput.tomselect.clear()
        currentConditionWorkflowInput.tomselect.clearOptions()
        currentConditionWorkflowInput.tomselect.addOptions(nextConditionWorkflowInput.tomselect.options)

        const workflow = nextConditionWorkflowInput.tomselect.getValue()
        if (workflow) {
          nextConditionWorkflowInput.tomselect.clear()
          currentConditionWorkflowInput.tomselect.addItem(workflow)
        }

        index++
      }
    }

    hide(conditionToHide)
  }

  visibleConditions() {
    return this.editableRulesContainerTarget.querySelectorAll(".editable-rule-container:not(.hidden)")
  }
}
