import { getVisualBuilderCardDimensions } from "../../../../utils"
import { defaultEdge } from "./utils"

const EDGE_SEP = 20
const NODE_SEP = 40
const RANK_SEP = 60
const ROOT_IDS = ["request_submitted", "root"]
const POSITION_MULTIPLIER = 8
const JUMP_TO_VIEWPORT_MARGIN = 50

const position = ({ x, y }) => {
  return { x: (x || 1) * POSITION_MULTIPLIER, y: (y || 1) * POSITION_MULTIPLIER }
}

export const getFirstTaskOfStageCoordinates = (stageName: string, stageNodes: any[], taskNodes: any[]) => {
  const targetStageNode = stageNodes.find((stage) => stage.data.name === stageName)
  if (!targetStageNode || !targetStageNode.position) {
    return
  }

  const stageTasks = taskNodes.filter((task) => task.data.requestStage === stageName || task.requestStage === stageName)
  const targetTaskNode = stageTasks.length
    ? stageTasks.reduce((acc, task) => (task.position.y < acc.position.y ? task : acc), stageTasks[0])
    : null

  const x = targetStageNode.position.x
  const y = targetTaskNode ? targetTaskNode.position.y : targetStageNode.position.y

  return {
    x: -x + JUMP_TO_VIEWPORT_MARGIN,
    y: -y + JUMP_TO_VIEWPORT_MARGIN,
    targetTaskNode,
  }
}

const stageNode = (stage, stages) => ({
  id: stage.id,
  type: "stage",
  data: {
    name: stage.id,
    order: stages.indexOf(stage.id),
  },
  position: { x: stage.x, y: stage.y },
  sourcePosition: "right",
  targetPosition: "left",
  draggable: false,
  connectable: false,
  selectable: false,
  hidden: true,
})

const rootNodeType = (task, requestView) => {
  if (requestView) {
    if (task.id === "root") {
      return "inquirySubmittedTaskCard"
    } else {
      return "requestRequestSubmittedTaskCard"
    }
  }

  return "workflowRootTaskCard"
}

const rootNode = (task, requestView) => ({
  id: task.id,
  position: position({ x: 0, y: 0 }),
  data: task.data,
  sourcePosition: "right",
  targetPosition: "left",
  zIndex: 2,
  draggable: false,
  connectable: false,
  selectable: true,
  type: rootNodeType(task, requestView),
})

const taskNode = (task, requestView) => {
  const { data } = task

  if (ROOT_IDS.includes(task.id)) {
    return rootNode(task, requestView)
  }

  return {
    id: task.id,
    sourcePosition: "right",
    targetPosition: "left",
    data,
    position: position({ x: 0, y: 0 }),
    zIndex: 2,
    draggable: false,
    connectable: false,
    selectable: true,
    type: requestView ? "requestTaskCard" : "workflowTaskCard",
  }
}

const generateEdges = (tasks) => {
  const taskEdges = tasks.flatMap((task) => {
    const triggers = task.data.triggers || []

    return triggers.map((trigger) => ({
      id: `${trigger.id}-${task.id}`,
      source: trigger.id,
      target: task.id,
      type: "simplebezier",
      data: {
        requestStage: task.requestStage,
        taskId: task.id,
      },
      pathOptions: {
        offset: 0,
        borderRadius: 20,
      },
      style: defaultEdge({}),
    }))
  })

  return taskEdges
}

export const generateStageNodes = (taskNodes, stages) => {
  const stageNodes = stages.map((stage) => {
    const tasks = taskNodes.filter((task) => task.data.requestStage === stage)
    const leftMostTask = tasks.reduce((acc, task) => (task.position.x < acc.position.x ? task : acc), tasks[0])

    const x = leftMostTask.position.x
    const y = -100

    return stageNode({ id: stage, x, y }, stages)
  })

  return stageNodes
}

const arrangeNodes = (nodes, stages) => {
  stages.slice(1).forEach((stage) => {
    const previousStage = stages[stages.indexOf(stage) - 1]
    const previousStageMostRightNode = nodes.reduce((acc, node) => {
      if (node.data.requestStage === previousStage && node.position.x > acc.position.x) {
        return node
      }
      return acc
    })

    const previousRightMostX = previousStageMostRightNode.position.x
    const firstNodeOfStage = nodes.find((node) => node.data.requestStage === stage)
    const firstNodeOfStageX = firstNodeOfStage.position.x
    const XStep = previousRightMostX - firstNodeOfStageX

    const { width: cardWidth } = getVisualBuilderCardDimensions(true)

    const stageNodes = nodes.filter((node) => node.data.requestStage === stage)
    stageNodes.forEach((node) => {
      node.position.x = node.position.x + XStep + cardWidth + EDGE_SEP + NODE_SEP + RANK_SEP
    })
  })

  return nodes
}

export const generateGraph = (tasks, stages = [], requestView = false) => {
  const edges = generateEdges(tasks)
  const taskNodes = tasks.map((task) => taskNode(task, requestView))

  const includeStages = stages.length > 0
  if (includeStages) {
    arrangeNodes(taskNodes, stages)
    const stageNodes = generateStageNodes(taskNodes, stages)
    return { nodes: [...taskNodes, ...stageNodes], edges }
  }
  return { nodes: taskNodes, edges }
}
