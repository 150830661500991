import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="edit-application-match"
export default class extends Controller {
  static targets = ["matchStatusHiddenInput"]

  matchStatusHiddenInputTarget: HTMLButtonElement

  connect() {
    document.addEventListener("turbo:submit-end", this.handleSubmit)
  }

  disconnect() {
    document.removeEventListener("turbo:submit-end", this.handleSubmit)
  }

  handleSubmit = (e: CustomEvent) => {
    const url = e.detail.fetchResponse.response.url

    // Adding a new supplier from the supplier search will cause the modal
    // to close earlier so this handles submitting the form and closing the
    // modal only when the actual form is submitted.
    if (e.detail.success && url.includes("update_application_match")) {
      this.dispatch("handleSubmit")
    }
  }

  handleUnmatch = () => {
    this.matchStatusHiddenInputTarget.setAttribute("value", "0")
  }

  handleMatch = () => {
    this.matchStatusHiddenInputTarget.setAttribute("value", "1")
  }

  handleIgnore = () => {
    this.matchStatusHiddenInputTarget.setAttribute("value", "2")
  }
}
