import { Controller } from "@hotwired/stimulus"

export default class RelatedCheckboxController extends Controller {
  static targets = ["itemType", "subType"]

  itemTypeTarget!: HTMLInputElement
  subTypeTargets!: HTMLInputElement[]

  toggleItemType() {
    if (this.itemTypeTarget.checked) {
      this.subTypeTargets.forEach((subType) => {
        subType.checked = true
      })
    } else {
      this.subTypeTargets.forEach((subType) => {
        subType.checked = false
      })
    }
  }

  toggleSubType() {
    const subTypeChecked = this.subTypeTargets.filter((subType) => subType.checked)
    const subTypeIsChecked = subTypeChecked.length === this.subTypeTargets.length

    if (subTypeIsChecked) {
      this.itemTypeTarget.checked = true
    } else {
      this.itemTypeTarget.checked = false
    }
  }
}
