import { Controller } from "@hotwired/stimulus"
import { optionsForElement } from "dropzone"

// Connects to data-controller="organization-preferences--product-usage-controller"
export default class extends Controller {
  static targets = ["hideable", "option", "ssoUsageNo", "ssoSupplier"]
  supplierSearchTarget: HTMLDivElement
  ssoOnlyTarget: HTMLDivElement
  ssoSupplierTarget: HTMLInputElement
  optionTargets: HTMLInputElement[]
  hideableTargets: any

  requiredInputsMap: { [key: string]: Boolean } = {}
  hiddenCssClass = "hidden"

  ssoUsageNoTargetConnected(t) {
    if (t.checked) {
      this.hideElements()
    }
  }

  hideElements(): void {
    this.hideableTargets.forEach((el) => {
      el.classList.add(this.hiddenCssClass)
    })

    this.optionTargets.forEach((optionTarget) => {
      // remove any values already selected.
      optionTarget.checked = false

      const isRequired = this.requiredInputsMap[optionTarget.id]
      if (isRequired) {
        optionTarget.required = false
      }
    })
    this.ssoSupplierTarget.value = ""
  }

  showElements(): void {
    this.hideableTargets.forEach((el) => {
      el.classList.remove(this.hiddenCssClass)
    })

    this.optionTargets.forEach((optionTarget) => {
      const isRequired = this.requiredInputsMap[optionTarget.id]
      if (isRequired) {
        optionTarget.required = true
      }
    })
  }

  connect(): void {
    this.buildrequiredInputsMap()
  }

  buildrequiredInputsMap(): void {
    this.optionTargets.forEach((optionTarget) => {
      const isRequired = optionTarget.required
      if (isRequired) {
        this.requiredInputsMap[`${optionTarget.id}`] = true
      }
    })
  }
}
