import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="dynamic-link"
export default class extends Controller {
  static targets = ["link"]
  linkTarget: HTMLAnchorElement

  // Can be used with any element that has a data-dynamic-link-href attribute
  // Example:
  // <button data-action="click->dynamic-link#changeLinkHref" data-dynamic-link-href="https://tropicapp.io">Tropic</button>
  changeLinkHref(e: Event): void {
    if (e.target instanceof HTMLAnchorElement && e.target.dataset.dynamicLinkHref) {
      this.linkTarget.href = e.target.dataset.dynamicLinkHref
    }
  }
}
