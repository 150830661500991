import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="business-spend-approval-group-form"
export default class extends Controller {
  static targets = ["overlapSettingOpenStateField"]
  overlapSettingOpenStateFieldTarget: HTMLInputElement
  hasOverlapSettingOpenStateFieldTarget: boolean

  toggleOverlapSettingOpenState() {
    if (this.hasOverlapSettingOpenStateFieldTarget) {
      const newValue = this.overlapSettingOpenStateFieldTarget.value === "true" ? "false" : "true"
      this.overlapSettingOpenStateFieldTarget.value = newValue
    }
  }
}
