import { Controller } from "@hotwired/stimulus"
import { hide, show } from "../utils"

// Connects to data-controller="overridable-date"
export default class extends Controller {
  static targets = [
    "originalLabel",
    "overrideWrapper",
    "originalLink",
    "overrideLink",
    "originalInput",
    "overrideInput",
  ]
  overrideWrapperTarget: HTMLDivElement

  originalLabelTarget: HTMLLabelElement
  originalLinkTarget: HTMLAnchorElement
  originalInputTarget: HTMLInputElement

  overrideLinkTarget: HTMLAnchorElement
  overrideInputTarget: HTMLInputElement

  preservedOriginalValue: string
  preservedOverrideValue: string

  static values = {
    originalLabel: String,
    inactiveOriginalLabel: String,
    clearedState: Boolean,
  }
  originalLabelValue: string
  inactiveOriginalLabelValue: string
  clearedStateValue: boolean

  onOverrideLinkClickHandler = this.onOverrideLinkClick.bind(this)
  onOriginalLinkClickHandler = this.onOriginalLinkClick.bind(this)

  connect() {
    this.overrideLinkTarget.addEventListener("click", this.onOverrideLinkClickHandler)
    this.originalLinkTarget.addEventListener("click", this.onOriginalLinkClickHandler)
  }

  disconnect() {
    this.overrideLinkTarget.removeEventListener("click", this.onOverrideLinkClickHandler)
    this.originalLinkTarget.removeEventListener("click", this.onOriginalLinkClickHandler)
  }

  onOverrideLinkClick(el) {
    el.preventDefault()
    this.showOverride()
  }

  onOriginalLinkClick(el) {
    el.preventDefault()
    this.hideOverride()
  }

  maybeShowOverride() {
    if (this.overrideInputTarget.value != "") {
      this.showOverride()
    }
  }

  showOverride() {
    show(this.overrideWrapperTarget)
    hide(this.overrideLinkTarget)
    if (this.inactiveOriginalLabelValue) {
      this.originalLabelTarget.innerText = this.inactiveOriginalLabelValue
    }
  }

  hideOverride() {
    hide(this.overrideWrapperTarget)
    this.overrideInputTarget.value = ""
    this.originalLabelTarget.innerText = this.originalLabelValue
    show(this.overrideLinkTarget)
  }

  clearedStateValueChanged() {
    if (this.clearedStateValue) {
      this.preservedOriginalValue = this.originalInputTarget.value
      this.preservedOverrideValue = this.overrideInputTarget.value
      this.originalInputTarget.value = ""
      this.hideOverride()
      hide(this.overrideLinkTarget)
    } else {
      if (this.preservedOriginalValue) {
        this.originalInputTarget.value = this.preservedOriginalValue
      }
      if (this.preservedOverrideValue) {
        this.overrideInputTarget.value = this.preservedOverrideValue
      }
      show(this.overrideLinkTarget)
      this.maybeShowOverride()
    }
  }

  toggleClearedState(el) {
    this.clearedStateValue = el.target.checked
  }
}
