import { Controller } from "@hotwired/stimulus"
import { show, hide } from "../utils"

// Connects to data-controller="ask-question"
export default class extends Controller {
  static targets = ["radioInput", "deselectButton", "supportLink", "formLower"]
  radioInputTargets: HTMLInputElement[]
  deselectButtonTargets: HTMLButtonElement[]
  supportLinkTarget: HTMLAnchorElement
  formLowerTarget: HTMLElement

  updateSelection() {
    this.hideUncheckedRadioInputs()
    this.showDeselectButtons()
    hide(this.supportLinkTarget)
    show(this.formLowerTarget)
  }

  resetSelection() {
    this.hideDeselectButtons()
    this.radioInputTargets.forEach((radioInput) => {
      this.removeHighlightClasses(radioInput)
      radioInput.checked = false
    })
    this.radioInputTargets.forEach((radioInput) => {
      show(radioInput.parentElement)
    })
    show(this.supportLinkTarget)
    hide(this.formLowerTarget)
  }

  showDeselectButtons() {
    this.deselectButtonTargets.forEach((button) => {
      show(button)
    })
  }

  hideDeselectButtons() {
    this.deselectButtonTargets.forEach((button) => {
      hide(button)
    })
  }

  hideUncheckedRadioInputs() {
    this.radioInputTargets.forEach((radioInput) => {
      if (radioInput.checked) {
        this.addHighlightClasses(radioInput)
      } else {
        hide(radioInput.parentElement)
      }
    })
  }

  addHighlightClasses(radioInput) {
    radioInput.parentElement.classList.add("border-purple-200", "shadow-md", "shadow-gray-100")
  }

  removeHighlightClasses(radioInput) {
    radioInput.parentElement.classList.remove("border-purple-200", "shadow-md", "shadow-gray-100")
  }
}
