import { Controller } from "@hotwired/stimulus"
import { post } from "@rails/request.js"
import { buildUrl } from "../../utils/urls"
import TabsController from "../tabs_controller"

// Connects to data-controller="purchase-orders--select-builder"
export default class SelectBuilderController extends Controller {
  static targets = ["subsidiarySelect", "supplierInput", "vendorSelect"]

  subsidiarySelectTarget!: HTMLSelectElement
  supplierInputTarget!: HTMLInputElement
  vendorSelectTarget!: HTMLSelectElement

  static outlets = ["tabs"]
  tabsOutlet!: TabsController

  static values = {
    classificationPath: String,
    vendorPath: String,
    subsidiaryPath: String,
    requestId: String,
  }

  classificationPathValue!: string
  vendorPathValue!: string
  subsidiaryPathValue!: string
  requestIdValue!: string

  tomSelectIdsThatReRender = ["accounting_location_id", "accounting_department_id", "accounting_classification_id"]

  connect() {
    this.supplierInputTarget.addEventListener("input", this.changeVendor)
  }

  disconnect() {
    this.supplierInputTarget.removeEventListener("input", this.changeVendor)
  }

  async changeClassification(event?: Event) {
    let tomSelect = null

    const targetElement = event?.target as HTMLElement
    const dispatchedByTomSelect = targetElement && this.tomSelectIdsThatReRender.includes(targetElement.id)

    if (dispatchedByTomSelect) tomSelect = event.target

    try {
      const result = await this.makeRequest(
        this.classificationPathValue,
        this.subsidiarySelectTarget.selectedOptions[0].value,
        tomSelect,
      )
    } catch (err) {}
  }

  changeVendor = () => {
    const supplierID = this.supplierInputTarget.value

    if (!supplierID) {
      this.clearSubsidiaryAndClassificationSelects()
    }

    // if vendor and subsidiary are not selected, don't make a request
    // this is an edge case when re-rendering the form after a failed submission
    // we want to persist the selected values
    const vendorID = this.vendorSelectTarget.selectedOptions[0].value
    const subsidiaryID = this.subsidiarySelectTarget.selectedOptions[0].value
    const fixedSubsidiary = this.subsidiarySelectTarget.dataset["fixedSubsidiary"] == "true"
    const shouldUpdateVendor = !vendorID && (!subsidiaryID || fixedSubsidiary)

    if (shouldUpdateVendor) {
      this.makeRequest(this.vendorPathValue, supplierID)
    }
  }

  changeSubsidiary() {
    this.subsidiarySelectTarget.selectedOptions[0].value = ""
    const selectedValue = this.vendorSelectTarget.selectedOptions[0].value

    this.makeRequest(this.subsidiaryPathValue, selectedValue)
  }

  makeRequest(path: string, selectedValue: string, tomSelect: HTMLSelectElement | null = null) {
    const formData = new FormData(document.forms[0])
    const queryParams = {
      model_id: selectedValue,
      selected_line_items_tab: this.tabsOutlet.currentTabValue,
      request_id: this.requestIdValue,
    }
    const url = buildUrl(path, queryParams)

    // destroy tom select instance before the frame re-renders, otherwise the instance becomes stale
    // when the tom select initializes again, it renders with options dropdown opened
    if (tomSelect) {
      const destroyTomSelectEvent = new CustomEvent("StyledSelect:destroy", { detail: { target: tomSelect } })
      window.dispatchEvent(destroyTomSelectEvent)
    }

    return post(url, {
      body: formData,
      responseKind: "turbo-stream",
    })
  }

  clearSubsidiaryAndClassificationSelects() {
    // clear out vendor and update the select options
    this.vendorSelectTarget.selectedOptions[0].value = ""
    this.changeClassification()

    // clear out vendor and update the select options only if multi-subsidiary
    const multiSubsidiary = this.subsidiarySelectTarget.dataset["fixedSubsidiary"] !== "true"
    if (multiSubsidiary) {
      this.subsidiarySelectTarget.selectedOptions[0].value = ""
      this.changeSubsidiary()
    }
  }
}
