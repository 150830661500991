import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="clear-session-storage"
export default class extends Controller {
  // values
  static values = {
    keys: Array<String>,
  }
  keysValue: string[]

  connect() {
    const shouldClearSessionStorage = this.keysValue.length && sessionStorage.length

    if (shouldClearSessionStorage) {
      this.keysValue.forEach((key) => {
        sessionStorage.removeItem(key)
      })
    }
  }
}
