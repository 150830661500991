import { Controller } from "@hotwired/stimulus"
import { show, hide } from "../utils"

// Connects to data-controller="period-section"
export default class extends Controller {
  static targets = ["content"]

  contentTarget: HTMLDivElement
  hasContentTarget: boolean

  connect() {
    this.toggleSection()
  }

  toggleSection() {
    if (this.hasContent()) {
      show(this.context.element)
    } else {
      hide(this.context.element)
    }
  }

  hasContent() {
    if (this.hasContentTarget) {
      const content = this.contentTarget.textContent
      return !!content.trim()
    }
    return false
  }
}
