import { Controller } from "@hotwired/stimulus"
import { hide } from "../utils"

// Connects to data-controller="edit-sourcing-vendors"
export default class extends Controller {
  static targets = ["confirmationSection", "saveChangesButton"]

  static values = {
    originalSupplierId: String,
  }

  confirmationSectionTarget: HTMLDivElement
  saveChangesButtonTarget: HTMLButtonElement

  originalSupplierIdValue: string

  connect() {
    document.addEventListener("select", this.supplierChanged)
  }

  disconnect() {
    document.removeEventListener("select", this.supplierChanged)
  }

  unconfirm() {
    this.element.dispatchEvent(new CustomEvent("enableSupplierChanges", { bubbles: true }))
    hide(this.confirmationSectionTarget)
  }

  supplierChanged = (event) => {
    if (event.detail.value === "" || event.detail.value === this.originalSupplierIdValue) {
      this.saveChangesButtonTarget.disabled = true
      this.saveChangesButtonTarget.classList.add("disabled")
    } else {
      this.saveChangesButtonTarget.disabled = false
      this.saveChangesButtonTarget.classList.remove("disabled")
    }
  }
}
