import React, { useCallback } from "react"
import { Panel, useReactFlow } from "reactflow"
import Dropdown from "../../dropdown/dropdown"
import { IStageNode, Stage } from "../../../../utils/types"
import { useWorkflow } from "./workflowContext"
import { RequestStageIcon } from "./requestStageIcon"
import { getFirstTaskOfStageCoordinates } from "./graphHelper"

interface StageNavigationProps {
  stageNodes: IStageNode[]
  taskNodes: any[]
}

const StageNavigation = (props: StageNavigationProps) => {
  const { stageNodes, taskNodes } = props
  const { setViewport, getViewport } = useReactFlow()
  const { setHoveredTaskId } = useWorkflow()

  const jumpToStage = useCallback(
    (name) => {
      const coordinates = getFirstTaskOfStageCoordinates(name, stageNodes, taskNodes)
      if (coordinates.targetTaskNode?.id) {
        setHoveredTaskId(coordinates.targetTaskNode.id)
      }
      setViewport({ ...coordinates, zoom: 1 })
    },
    [setViewport, stageNodes, taskNodes],
  )

  const sortedStageNodes = stageNodes.sort((a, b) => a.data.order - b.data.order)

  return (
    <Panel position="bottom-left" className="jump-to-stage-nav !bottom-[40px]" id="stage-navigation">
      <div className="flex flex-col gap-2 p-4 bg-white border border-base rounded-lg shadow">
        <div className="small-heading mb-2">Go to Stage</div>
        {sortedStageNodes.map((node) => (
          <button
            className="text-base text-gray-600 flex gap-1 items-center"
            key={node.data.name}
            onClick={() => jumpToStage(node.data.name)}
          >
            <RequestStageIcon stage={node.data.name} />
            <span className="underline cursor-pointer">{node.data.name}</span>
          </button>
        ))}
      </div>
    </Panel>
  )
}

export default StageNavigation
