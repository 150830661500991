import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="purchase-order-line-item-form"
export default class extends Controller {
  static targets = ["quantity", "unitPrice", "total"]

  hasQuantityTarget: boolean
  hasUnitPriceTarget: boolean
  hasTotalTarget: boolean

  quantityTarget: HTMLInputElement
  unitPriceTarget: HTMLInputElement
  totalTarget: HTMLInputElement

  connect() {
    this.quantityTarget.addEventListener("change", this.calculateTotal)
    this.unitPriceTarget.addEventListener("change", this.calculateTotal)
    this.unitPriceTarget.addEventListener("change", this.roundUnitPrice)
    this.calculateTotal()
  }

  disconnect() {
    this.quantityTarget.removeEventListener("change", this.calculateTotal)
    this.unitPriceTarget.removeEventListener("change", this.calculateTotal)
    this.unitPriceTarget.removeEventListener("change", this.roundUnitPrice)
  }

  // The existing NumberFieldComponent allows a user to enter a price with any number of
  // decimal places. This method rounds the unit price to two decimal places, which is what
  // the data model currently supports.
  //
  // Once Multi-Currency is enabled for these line items, this will no longer be necessary
  // since the MC input will ensure only 2 decimal places are set.
  roundUnitPrice = () => {
    if (this.hasUnitPriceTarget) {
      this.unitPriceTarget.value = parseFloat(this.unitPriceTarget.value).toFixed(2)
      this.calculateTotal()
    }
  }

  calculateTotal = () => {
    const quantity = this.hasQuantityTarget ? parseFloat(this.quantityTarget.value) : 0
    const unitPrice = this.hasUnitPriceTarget ? parseFloat(this.unitPriceTarget.value) : 0

    const total = quantity * unitPrice
    if (this.hasTotalTarget && total) {
      this.totalTarget.value = total.toFixed(2)
    } else {
      this.totalTarget.value = ""
    }
    this.totalTarget.dispatchEvent(new Event("change"))
  }
}
