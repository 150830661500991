import { Controller } from "@hotwired/stimulus"
import { debounce } from "lodash"
import { isTextBasedInput, isTimeBasedInput } from "../utils/forms"

/*
  Connects to data-controller="form-rerender"

  Essentially, this controller submits a form whenever inputs on that form change so it can be
  re-rendered by the server in some way. Submissions are debounced to prevent them from firing on
  _every_ change if someone is typing quickly. The intent is to handle that button press with a
  server-side re-render of parts of the page (using turbo stream directives) to give a dynamic
  experience using server-side rendering. This can be used to update elements on a form based on
  other values in the form -- to calculate values or replace drop-downs contents.

  A hidden button can be used to submit the form — the "btn" target for the controller. If no "btn"
  target is found, the form will be submitted directly. Note that this will appear in the controller
  as a submit with no "commit" param. Using a "btn" target on a button (hidden or otherwise) gives
  some more control over how the form submits via formaction or formmethod attributs.

  If any elements are declared as "trigger" targets for the controller, only change events that target
  those elements will be considered relevant.

  The behavior can be triggered in two ways:
  * the form declared as the "form" target for the controller that will automatically have
    triggerRerender to the "input" event on that form
  * by directly invoking the triggerRerender function, by, say by setting the data-action on
    an input to include "focusout->form-rerender#triggerRerender"

  This controller supports forms that use morphing in their turbo stream responses. The element that is
  currently in focus when the trigger is fired will be marked as "data-turbo-permanent" for as long as it
  maintains focus. To prevent this behavior, such as when a given field might require adjustments, like being
  disabled / enabled, after interacting with them, add the "form-rerender-allow-morph" data attribute to the
  input.

 */

export default class extends Controller {
  // targets
  static targets = ["btn", "trigger", "form"]

  btnTarget: HTMLButtonElement
  hasBtnTarget: boolean
  triggerTargets: Array<HTMLElement>
  hasTriggerTarget: boolean
  formTarget: HTMLFormElement
  hasFormTarget: boolean

  static values = {
    debounceMs: { type: Number, default: 333 },
  }
  debounceMsValue: number

  _form: HTMLFormElement
  queue: RerenderQueue

  connect() {
    this.queue = new RerenderQueue(this.submitForm.bind(this), { debounceMs: this.debounceMsValue })

    this.form.addEventListener("change", this.changeHandler)
    this.form.addEventListener("keyup", this.keyupHandler)
    this.form.addEventListener("focusin", this.focusInHandler)
    this.form.addEventListener("focusout", this.focusOutHandler)
    this.form.addEventListener("turbo:submit-end", this.submitEndHandler)
  }

  disconnect() {
    this.form.removeEventListener("change", this.changeHandler)
    this.form.removeEventListener("keyup", this.keyupHandler)
    this.form.removeEventListener("focusin", this.focusInHandler)
    this.form.removeEventListener("focusout", this.focusOutHandler)
    this.form.removeEventListener("turbo:submit-end", this.submitEndHandler)
  }

  get form() {
    if (this._form === undefined) {
      if (this.hasFormTarget) this._form = this.formTarget
      else if (this.hasBtnTarget) this._form = this.btnTarget.form
      else throw new Error("No form found for form-rerender controller")
    }
    return this._form
  }

  triggerRerender(e: Event, complete: boolean = false) {
    this.togglePendingFlag(true)
    this.queue.add(e)
    if (complete) this.queue.finalize()
  }

  submitForm(payload: string) {
    if ((this.hasBtnTarget && this.btnTarget.disabled) || this.form.getAttribute("aria-busy")) return
    this.togglePendingFlag(true)
    if (this.hasBtnTarget) {
      this.btnTarget.value = payload
      this.form.requestSubmit(this.btnTarget)
    } else {
      this.form.requestSubmit()
    }
  }

  toggleUnmorphableFlag(value: boolean, e: Event) {
    const element = <HTMLElement>e.target
    element.toggleAttribute("data-turbo-permanent", value)
  }

  // this creates a test-visible indication that a rerender is still pending
  togglePendingFlag(value: boolean) {
    this.form.toggleAttribute("data-form-rerender-pending", value)
  }

  irrelevantEvent(e: Event) {
    return this.hasTriggerTarget && !this.triggerTargets.includes(<HTMLElement>e.target)
  }

  /***** EVENT HANDLERS *****/

  onChange(e: Event) {
    // time-based elements have an annoying tendency to send WAY more change events than we'd like
    // so, we ignore them and instead rely on a focusout event to finalize
    if (this.irrelevantEvent(e)) return
    this.triggerRerender(e, !isTimeBasedInput(<HTMLElement>e.target))
  }

  onKeyup(e: Event) {
    if (this.irrelevantEvent(e) || !isTextBasedInput(<HTMLElement>e.target)) return
    this.triggerRerender(e)
  }

  onFocusIn(e: Event) {
    if (this.irrelevantEvent(e)) return
    if ((<HTMLElement>e.target).dataset.formRerenderAllowMorph) return
    this.toggleUnmorphableFlag(true, e)
  }

  onFocusout(e: Event) {
    if (this.irrelevantEvent(e)) return
    this.toggleUnmorphableFlag(false, e)
    // all text-based inputs, including dates, should trigger a focusout event and mark the interaction as complete
    if (isTextBasedInput(<HTMLElement>e.target)) this.triggerRerender(e, true)
  }

  onSubmitEnd(e: Event) {
    e.stopPropagation() // prevent the event from bubbling up to anything like, say, a modal handler
    this.queue.flush()
    this.togglePendingFlag(false)
  }

  // pre-generate bound versions, since bind() can't be used in removeEventListener calls as it returns a new func
  changeHandler = this.onChange.bind(this)
  keyupHandler = this.onKeyup.bind(this)
  focusOutHandler = this.onFocusout.bind(this)
  focusInHandler = this.onFocusIn.bind(this)
  submitEndHandler = this.onSubmitEnd.bind(this)
}

/*
  This class manages the capture of events for submission as a batch after a debounce period. Two separate
  debounced functions are used to allow for two different submit timings. The debounceMs value governs the
  speed of the "slow" timer while the "fast" timer has a fixed 100ms debounce delay. The "slow" timer is used
  by default but once the add function is passed an immediate value of true, the fast timer will be used.

  This allows for a faster submission when the user takes an action that should be perceived as happening
  instantaneously, like validations upon exiting a changed field or switching a dropdown. The slow timer is
  used to space out submission in response to repeated events.

  Originally, immediate transmission was handled without the fast debouce, but this was causing an excessive
  number of fast succession submissions during the execution of specs. Even outside of specs, a user can trigger
  two events within 100ms of each other quite easily by clicking a checkbox while in a text field — the focusout
  event from the textbox is immediately followed by the change event from the checkbox.

  Once the timeout is hit, the function passed to the constructor will be executed with a payload containing the
  captured events. Duplicate events captured will be ignored. Top-level values will represent the most recent
  non-duplicative event, while other events will be returned in the `other_events` array. If _any_ event qualified
  as a user-complete event, the top-level user_complete value will be true.
 */
class RerenderQueue {
  events: Array<{ input: string; event: string }>
  userComplete: boolean = false
  form: HTMLFormElement
  rerenderButton: HTMLButtonElement
  _slowTimer: () => void
  _fastTimer: () => void
  startTimer: () => void
  onTimeout: (payload: string) => void

  constructor(onTimeout: (payload: string) => void, { debounceMs }: { debounceMs: number }) {
    this._slowTimer = debounce(this.triggerCallback, debounceMs)
    this._fastTimer = debounce(this.triggerCallback, 100)
    this.onTimeout = onTimeout
    this.reset()
  }

  add(e: Event) {
    this.capture(e)
    this.startTimer()
  }

  finalize() {
    this._slowTimer.cancel()
    this.startTimer = this._fastTimer
    this.userComplete = true
    this.startTimer()
  }

  flush() {
    this.startTimer.flush()
    this.reset()
  }

  triggerCallback() {
    if (this.events.length == 0) return
    this.onTimeout(this.buildPayload())
    this.reset()
  }

  reset() {
    this.startTimer = this._slowTimer
    this.events = []
    this.userComplete = false
  }

  capture(e: Event) {
    const entry = {
      input: (<HTMLInputElement>e.target).name,
      event: e.type,
    }
    if (!this.events.some((el) => el.input == entry.input && el.event == entry.event)) {
      this.events.push(entry)
    }
  }

  buildPayload() {
    return JSON.stringify({
      ...this.events.pop(),
      user_complete: this.userComplete,
      other_events: this.events,
    })
  }
}
