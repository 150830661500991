import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="price-checks-v2--line-item--price-driver-filters"
export default class extends Controller {
  static targets = ["priceDriverFilter"]
  priceDriverFilterTarget: HTMLTableRowElement

  static values = { submitButton: String }
  submitButtonValue: string

  connect() {}

  removePriceDriverFilterRow() {
    this.priceDriverFilterTarget.remove()
    this.submitForm()
  }

  submitForm() {
    const button = document.getElementById(this.submitButtonValue) as HTMLButtonElement
    button.click()
  }
}
