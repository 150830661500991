import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="text-truncate"
export default class extends Controller {
  static targets = ["lessContent", "moreContent", "lessButton", "moreButton"]

  moreContentTarget: HTMLDivElement
  lessContentTarget: HTMLDivElement
  moreButtonTarget: HTMLButtonElement
  lessButtonTarget: HTMLButtonElement

  showMore() {
    this.moreContentTarget.classList.remove("hidden")
    this.lessContentTarget.classList.add("hidden")
    this.moreButtonTarget.classList.add("hidden")
    this.lessButtonTarget.classList.remove("hidden")
  }

  showLess() {
    this.moreContentTarget.classList.add("hidden")
    this.lessContentTarget.classList.remove("hidden")
    this.moreButtonTarget.classList.remove("hidden")
    this.lessButtonTarget.classList.add("hidden")
  }
}
