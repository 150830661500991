import React from "react"
import { Handle, Position, useReactFlow } from "reactflow"
import { useWorkflow } from "./workflowContext"

interface AddTaskButtonProps {
  actionPath: string
  subworkflow: boolean
}

export const AddFirstTaskButton = ({ actionPath, subworkflow }: AddTaskButtonProps) => {
  const { getViewport } = useReactFlow()
  const handleClick = (e) => {
    e.stopPropagation()
    const url = `${actionPath}&viewport_position=${JSON.stringify(getViewport())}`
    location = url
  }

  const handleStyles = {
    minWidth: "2px",
    minHeight: "2px",
    width: "103px",
    height: "1px",
    background: "#6A778A",
    border: "none",
    borderRadius: "0px",
    transform: "none",
    right: "-90px",
    zIndex: -1,
  }

  return (
    <>
      <Handle type="source" position={Position.Right} style={handleStyles} />
      <button onClick={handleClick}>
        <div
          id={`add-first-task`}
          className="absolute pointer-events-auto z-10 rounded-md text-center shadow space-y-1 bg-purple-500 py-2 px-3 right-[-205px] top-[calc(50%-18px)]"
        >
          <div className="font-semibold text-base text-white flex items-center justify-center">
            <i className="fas fa-plus px-1"></i>
            <div>Add a Task</div>
          </div>
        </div>
      </button>
    </>
  )
}
