import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="netsuite-drawer"
export default class extends Controller {
  static targets = ["expenseCheckbox", "hrisCheckbox", "poCheckbox", "alertContent"]

  expenseCheckboxTarget: HTMLInputElement
  hrisCheckboxTarget: HTMLInputElement
  poCheckboxTarget: HTMLInputElement
  alertContentTarget: HTMLDivElement

  connect(): void {
    this.updateRole()
  }

  updateRole(): void {
    const selectedRoles = this.getSelectedRoles()
    const formattedRoles = this.formatRoles(selectedRoles)

    this.alertContentTarget.innerHTML = `Tropic ${formattedRoles} Integration`
  }

  getSelectedRoles(): string[] {
    const roles = []

    if (this.expenseCheckboxTarget.checked) roles.push("Spend Mgmt")
    if (this.hrisCheckboxTarget.checked && !this.poCheckboxTarget.checked) roles.push("HRIS")
    // If PO is checked you get both HRIS and PO
    if (this.poCheckboxTarget.checked) roles.push("HRIS", "PO")

    return roles
  }

  formatRoles(roles: string[]): string {
    switch (roles.length) {
      case 0:
        return ""
      case 1:
        return roles[0]
      default:
        return `${roles.slice(0, -1).join(", ")} & ${roles.slice(-1)}`
    }
  }
}
