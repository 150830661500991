/* Resources used:
  https://github.com/ParamagicDev/exploring-trix/blob/part01-changing-the-default-toolbar/main.js
 https://dev.to/paramagicdev/modifying-the-default-toolbar-in-trix-411b
 */

import Trix from "trix"
import "trix/dist/trix.css"

window.Trix = Trix // Don't need to bind to the window, but useful for debugging.
Trix.config.toolbar.getDefaultHTML = toolbarDefaultHTML

// trix-before-initialize runs too early.
// We only need to do this once. Everything after initialize will get the
// defaultHTML() call automatically.
document.addEventListener("trix-initialize", updateToolbars, { once: true })

function updateToolbars(event) {
  const toolbars = document.querySelectorAll("trix-toolbar")
  const html = Trix.config.toolbar.getDefaultHTML()
  toolbars.forEach((toolbar) => (toolbar.innerHTML = html))
}

/**
 * This is the default Trix toolbar. Feel free to change / manipulate it how you would like.
 * @see https://github.com/basecamp/trix/blob/main/src/trix/config/toolbar.coffee
 */
function toolbarDefaultHTML() {
  const { lang } = Trix.config
  return `
  <div class="trix-button-row">
    <span class="trix-button-group trix-button-group--text-tools" data-trix-button-group="text-tools">
      <button type="button" class="trix-button trix-button-custom--icon" data-trix-attribute="bold" data-trix-key="b" title="${lang.bold}" tabindex="-1"><i class="fa-solid fa-bold"></i></button>
      <button type="button" class="trix-button trix-button-custom--icon trix-button--icon-italic" data-trix-attribute="italic" data-trix-key="i" title="${lang.italic}" tabindex="-1"><i class="fa-solid fa-italic"></i></button>
      <button type="button" class="trix-button trix-button-custom--icon trix-button--icon-strike" data-trix-attribute="strike" title="${lang.strike}" tabindex="-1"><i class="fa-solid fa-strikethrough"></i></button>
    </span>
    <span class="trix-button-group trix-button-group--block-tools" data-trix-button-group="block-tools">
      <button type="button" class="trix-button trix-button-custom--icon trix-button--icon-quote" data-trix-attribute="quote" title="${lang.quote}" tabindex="-1"><i class="fa-solid fa-block-quote"></i></button>
      <button type="button" class="trix-button trix-button-custom--icon trix-button--icon-bullet-list" data-trix-attribute="bullet" title="${lang.bullets}" tabindex="-1"><i class="fa-solid fa-list"></i></button>
      <button type="button" class="trix-button trix-button-custom--icon trix-button--icon-number-list" data-trix-attribute="number" title="${lang.numbers}" tabindex="-1"><i class="fa-solid fa-list-ol"></i></button>
      <button type="button" class="trix-button trix-button-custom--icon trix-button--icon-link" data-trix-attribute="href" data-trix-action="link" data-trix-key="k" title="${lang.link}" tabindex="-1"><i class="fa-solid fa-link"></i></button>
    </span>
    <!--Temporarily hides the file upload button https://app.shortcut.com/tropic/story/14843/external-images-permitted-in-comments-->
    <!--<span class="trix-button-group trix-button-group--file-tools" data-trix-button-group="file-tools">-->   
    <!--<button type="button" class="trix-button trix-button-custom--icon trix-button--icon-attach" data-trix-action="attachFiles" title="${lang.attachFiles}" tabindex="-1"><i class="fa-solid fa-upload"></i></button>--> 
    <!--</span>--> 
    <span class="trix-button-group-spacer"></span>
  </div>
  <div class="trix-dialogs" data-trix-dialogs>
    <div class="trix-dialog trix-dialog--link" data-trix-dialog="href" data-trix-dialog-attribute="href">
      <div class="trix-dialog__link-fields">
        <span class="left-input-addon">
          https://
        </span>
        <input type="text" data-comment-form-target="hrefInput" data-action="keydown->comment-form#onLinkCreation" name="href" class="trix-input text-input rounded-l-none rounded-r-md" placeholder="${lang.urlPlaceholder}" aria-label="${lang.url}" data-trix-input >
        <div class="trix-button-group">
          <span class="space-x-2">
            <button type="button" data-action="click->comment-form#onLinkCreation" id="link-button" class="trix-button trix-button--dialog button-secondary-sm !w-[30px] !h-[30px] flex justify-center items-center" data-trix-method="setAttribute">
              <i class="fas fa-check"></i>
            </button>
            <button type="button" class="trix-button trix-button--dialog button-secondary-sm !w-[30px] !h-[30px] flex justify-center items-center" data-trix-method="removeAttribute">
              <i class="fas fa-times"></i>
            </button>
          </span>
        </div>
      </div>
    </div>
  </div>
`
}

// Temporarily disables file upload action https://app.shortcut.com/tropic/story/14843/external-images-permitted-in-comments
document.addEventListener("trix-file-accept", function (event) {
  event.preventDefault()
})
