import { Controller } from "@hotwired/stimulus"

/* Connects to data-controller="canopy--button-component"

  This controller is used to disable the canopy button when a form is submitted. The button
  will be disabled and a loader will be shown.

  If you need to avoid this behaviour, the `data-block-disable-on-submit` attribute can be
  added to the button element, which will ensure that the listeners are not addded. This may
  be helpful in cases where the form is being morphed and you don't want re-rendering to
  always trigger the button state to change.
 */
export default class extends Controller {
  static targets = ["button", "loader", "label"]
  buttonTarget: HTMLButtonElement
  loaderTarget: HTMLSpanElement
  labelTarget: HTMLSpanElement

  connect() {
    if (this.shouldDisableOnSubmit()) {
      document.addEventListener("turbo:submit-start", this.disableButton)
      document.addEventListener("turbo:submit-end", this.enableButton)
    }
  }

  disconnect() {
    if (this.shouldDisableOnSubmit()) {
      document.removeEventListener("turbo:submit-start", this.disableButton)
      document.removeEventListener("turbo:submit-end", this.enableButton)
    }
  }

  disableButton = () => {
    this.buttonTarget.disabled = true
    this.buttonTarget.ariaBusy = "true"
    this.toggleOpacity()
  }

  enableButton = () => {
    this.buttonTarget.disabled = false
    this.buttonTarget.ariaBusy = "false"
    this.toggleOpacity()
  }

  toggleOpacity = () => {
    this.loaderTarget.classList.toggle("opacity-0")
    this.labelTarget.classList.toggle("opacity-0")
  }

  shouldDisableOnSubmit = () => {
    return !this.buttonTarget.hasAttribute("data-block-disable-on-submit", "")
  }
}
