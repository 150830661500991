import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="links-new-tab"
export default class extends Controller {
  connect() {
    this.element.querySelectorAll("a").forEach((anchor) => {
      anchor.target = "_blank"
    })
  }
}
