export const insertOverlayIntoDOM = (elementId: string, overlayClasses: string = ""): void => {
  const createOverlay = (): HTMLDivElement => {
    let classList = ["fixed", "inset-0", "bg-gray-500", "bg-opacity-75", "hidden"]

    if (overlayClasses) {
      if (!overlayClasses.includes("z-")) {
        overlayClasses.concat(" z-fullscreen")
      }
      classList = [...classList, ...overlayClasses.split(" ")]
    } else {
      classList.push("z-fullscreen")
    }

    const overlay = document.createElement("div")
    overlay.id = elementId
    overlay.classList.add(...classList)

    return overlay
  }

  const exisitingOverlay = document.getElementById(elementId)

  if (exisitingOverlay) {
    return
  }

  const overlay = createOverlay()
  const wrapper = document.getElementById("overlay-wrapper")
  wrapper?.appendChild(overlay)
}

export const removeOverlayFromDOM = (elementId: string): void => {
  const overlay = document.getElementById(elementId)
  overlay?.remove()
}
