import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="input-character-counter"
export default class extends Controller {
  counterTarget: HTMLElement
  inputTarget: HTMLInputElement

  static targets = ["input", "counter"]

  initialize(): void {
    this.update = this.update.bind(this)
  }

  connect(): void {
    this.update()
    this.inputTarget.addEventListener("input", this.update)
  }

  disconnect(): void {
    this.inputTarget.removeEventListener("input", this.update)
  }

  update(): void {
    if (this.maxLength >= 0 && this.minLength >= 0) {
      this.counterTarget.innerHTML = `${this.count.toString()} Characters`
    } else if (this.maxLength >= 0) {
      this.counterTarget.innerHTML = `${this.count.toString()}/${this.maxLength} Characters`
    } else if (this.minLength >= 0) {
      this.counterTarget.innerHTML = `${this.count.toString()} Characters`
    }
  }

  get count(): number {
    let value: number = this.inputTarget.value.length

    if (this.maxLength < 0 && this.minLength < 0) {
      console.error(
        `[input-character-counter] You need to add a minlength or a maxlength attribute on the input to use input-character-counter. Currently minlength is ${this.minLength} and maxlength is ${this.maxLength}.`,
      )
    }

    return value
  }

  get maxLength(): number {
    return this.inputTarget.maxLength
  }

  get minLength(): number {
    return this.inputTarget.minLength
  }
}
