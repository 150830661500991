const PROTOCOL_REGEX = /(.+:\/\/)/

const ALL_URL_INCLUDING_FULL_EMAIL_REGEX =
  /(<a.*?<\/a>)|(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,7}\b([-a-zA-Z0-9@:%_\+.~#?!,&//=]*)/gm

/*
The above regex matches URLS, Emails and anchor tags:
  tropicapp.io
  http://tropicapp.io
  www.tropicapp.io
  pawel@tropicapp.io
  <a href="pawel@tropicapp.io">Website URL</a>
  <a href="mailto:pawel@tropicapp.io"><strong>pawel@tropicapp.io</strong></a>

It also allows the ".com" or ".io" part of the domain to have a max of 7 characters
and a min of 2. So domains such as the following will work:
  .example
  .io
  .com
  .info
  .bix
But domains, such as these, will not work:
  .comexample
  .examples
  .m
*/

export class CommentLinksFinalizer {
  private readonly content: string

  static call = (content: string): string => new CommentLinksFinalizer(content).call()

  private call = (): string => this.contentWithLinks()

  private addProtocol = (url: string): string => {
    return !!url.match(PROTOCOL_REGEX)?.length ? url : `https://${url}`
  }

  private contentWithLinks = (): string => this.content.replace(ALL_URL_INCLUDING_FULL_EMAIL_REGEX, this.createLink)

  private exclusion_conditions = ["</a>", "@"]

  private createLink = (url: string) => {
    // if the element is already anchor tagged, or includes @ (is an email) do not create a link for it and return as is.
    if (this.exclusion_conditions.some((exclusion) => url.includes(exclusion))) {
      return url
    }

    return `<a href="${this.addProtocol(url)}">${url}</a>`
  }

  private constructor(content: string) {
    this.content = content
  }
}
