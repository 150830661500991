import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="collapse"
export default class extends Controller {
  static targets = ["content", "openElement", "closedElement", "border", "iconElement"]
  static values = {
    groupName: { type: String, default: "none" },
    isOpen: { type: Boolean, default: false },
    customClass: { type: String, default: "hidden" },
    openedBorderClass: { type: String, default: "" },
    closedBorderClass: { type: String, default: "" },
  }

  connect() {
    this.isOpen = this.isOpenValue

    if (this.isOpen) {
      this.open()
    } else {
      this.close()
    }
  }

  reconnect() {
    // Set the isOpenValue to the current value of isOpen
    this.isOpenValue = this.isOpen
    this.connect()
  }

  isOpenStatus() {
    return this.isOpen
  }

  clickable(e) {
    e.stopPropagation()
  }

  toggle(e) {
    e.preventDefault()
    this.updateCollapseState()
    this.updateIconState()

    if (this.element.dataset["mainMultiCollapseTarget"] == "groupItem") {
      const mainMultiCollapseController = this.application.getControllerForElementAndIdentifier(
        this.element.closest("[data-controller*='main-multi-collapse']"),
        "main-multi-collapse",
      )
      mainMultiCollapseController.checkGroupItemStatus()
    }

    if (this.element.dataset["subMultiCollapseTarget"] == "groupItem") {
      const subMultiCollapseController = this.application.getControllerForElementAndIdentifier(
        this.element.closest("[data-controller*='sub-multi-collapse']"),
        "sub-multi-collapse",
      )
      subMultiCollapseController.checkGroupItemStatus()
    }
  }

  updateIconState() {
    if (this.hasIconElementTarget) {
      if (this.isOpen) {
        this.iconElementTarget.classList.remove("border-light", "bg-gray-50", "text-gray-500")
        this.iconElementTarget.classList.add("border-purple-100", "bg-purple-50", "text-purple-500")
      } else {
        this.iconElementTarget.classList.remove("border-purple-100", "bg-purple-50", "text-purple-500")
        this.iconElementTarget.classList.add("border-light", "bg-gray-50", "text-gray-500")
      }
    }
  }

  updateCollapseState() {
    if (this.isOpen) {
      this.close()
    } else {
      this.open()
    }
  }

  open() {
    this.contentTarget.classList.remove(this.customClassValue)
    this.openElementTarget.classList.remove("hidden")
    this.closedElementTarget.classList.add("hidden")

    this.isOpen = true
    this.#updateBorder(this.openedBorderClass, this.closedBorderClass)
  }

  close() {
    this.contentTarget.classList.add(this.customClassValue)
    this.openElementTarget.classList.add("hidden")
    this.closedElementTarget.classList.remove("hidden")

    this.isOpen = false
    this.#updateBorder(this.closedBorderClass, this.openedBorderClass)
  }

  #updateBorder(addClass, removeClass) {
    if (this.hasBorderTarget) {
      if (addClass) this.BorderTarget.classList.add(addClass)
      if (removeClass) this.BorderTarget.classList.remove(removeClass)
    }
  }
}
