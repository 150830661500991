import { Controller } from "@hotwired/stimulus"
import { hide, show } from "../utils"

// Connects to data-controller="import-review"
export default class extends Controller {
  static values = {
    mode: String,
  }
  modeValue: string

  static targets = ["excludeAllBtn", "formBtns", "submitBtn"]
  excludeAllBtnTarget: HTMLElement
  formBtnsTarget: HTMLElement
  submitBtnTarget: HTMLElement

  modeValueChanged(value) {
    if (value === "edit") {
      this.enableEditButtons()
      this.disableBulkEdit()
      this.disableScopes()
      this.disablePagination()
    } else {
      this.disableEditButtons()
      this.enableBulkEdit()
      this.enableScopes()
      this.enablePagination()
    }
  }

  changeDetected(ev) {
    this.modeValue = ev.detail.total > 0 ? "edit" : "view"
  }

  submitForm(ev) {
    ev.preventDefault()
    this.submitBtnTarget.click()
  }

  hideExcludeAll() {
    hide(this.excludeAllBtnTarget)
  }

  showExcludeAll() {
    show(this.excludeAllBtnTarget)
  }

  disableLink(linkElement) {
    linkElement.classList.add("pointer-events-none", "disabled")
    linkElement.parentElement.classList.add("cursor-not-allowed")
    linkElement.tabIndex = -1
  }

  enableLink(linkElement) {
    linkElement.classList.remove("pointer-events-none", "disabled")
    linkElement.parentElement.classList.remove("cursor-not-allowed")
    // TODO: reset tabIndex somehow?
  }

  disableBulkEdit() {
    this.element.querySelectorAll("input[name='select_contract']").forEach((el: HTMLInputElement) => {
      if (el.checked) {
        el.click()
      }
      el.disabled = true
      el.classList.add("disabled", "cursor-not-allowed")
    })

    this.element.querySelectorAll("input[name='select_all']").forEach((el: HTMLInputElement) => {
      el.disabled = true
      el.classList.add("disabled", "cursor-not-allowed")
    })
  }

  enableBulkEdit() {
    this.element.querySelectorAll("input[name='select_contract']").forEach((el: HTMLInputElement) => {
      el.disabled = false
      el.classList.remove("disabled", "cursor-not-allowed")
    })

    this.element.querySelectorAll("input[name='select_all']").forEach((el: HTMLInputElement) => {
      el.disabled = false
      el.classList.remove("disabled", "cursor-not-allowed")
    })
  }

  disablePagination() {
    this.element.querySelectorAll("#pagination a").forEach(this.disableLink)
  }

  enablePagination() {
    this.element.querySelectorAll("#pagination a").forEach(this.enableLink)
  }

  disableScopes() {
    this.element.querySelectorAll("#table-content_scopes a").forEach(this.disableLink)
  }

  enableScopes() {
    this.element.querySelectorAll("#table-content_scopes a").forEach(this.enableLink)
  }

  enableEditButtons() {
    hide(this.excludeAllBtnTarget)
    show(this.formBtnsTarget)
  }

  disableEditButtons() {
    show(this.excludeAllBtnTarget)
    hide(this.formBtnsTarget)
  }
}
