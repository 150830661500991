import { Controller } from "@hotwired/stimulus"
import { post } from "@rails/request.js"
import { debounce } from "lodash"

export default class extends Controller {
  static targets = [
    "availableField",
    "availableFieldsContainer",
    "selectedField",
    "selectedFieldRow",
    "search",
    "clearSearch",
    "selectedFieldsCounter",
    "availableFieldsCounter",
    "selectedFieldsEmptyMessage",
    "searchEmptyMessage",
  ]
  static classes = ["hidden"]

  availableFieldTargets: HTMLDivElement[]
  clearSearchTarget: HTMLButtonElement
  searchTarget: HTMLInputElement
  selectedFieldsCounterTarget: HTMLDivElement
  selectedFieldRowTargets: HTMLDivElement[]
  selectedFieldTargets: HTMLDivElement[]
  availableFieldsCounter: HTMLDivElement
  selectedFieldsEmptyMessageTarget: HTMLDivElement
  searchEmptyMessageTarget: HTMLDivElement
  availableFieldsContainerTarget: HTMLDivElement

  hiddenClass: string

  initialize() {
    this.search = debounce(this.search, 250)
  }

  connect() {
    this.clearSearchTarget.classList.add(this.hiddenClass)
    if (!this.hasSelectedFieldRowTarget) {
      this.selectedFieldsCounterTarget.innerHTML = this.selectedFieldTargets.length.toString()
      this.updateAvailableFieldsCounter()
      this.selectedFieldsEmptyMessageTarget.classList.toggle(this.hiddenClass)
    }
  }

  selectedFieldRowTargetConnected(target: Element) {
    const element = target as HTMLDivElement

    this.availableFieldTargets.forEach((field) => {
      if (field.dataset.remoteId == element.dataset.remoteId) {
        const checkmark: HTMLElement = field.querySelector(".fa-check")
        field.classList.add("bg-purple-50")
        checkmark.classList.remove(this.hiddenClass)
        field.setAttribute("data-selected", "true")
      }
    })

    this.selectedFieldsCounterTarget.innerHTML = this.selectedFieldTargets.length.toString()
    this.updateAvailableFieldsCounter()
  }

  selectedFieldRowTargetDisconnected(target: Element) {
    if (!this.hasSelectedFieldRowTarget) {
      this.showEmptyState()
    }
  }

  updateAvailableFieldsCounter() {
    this.availableFieldsCounterTarget.innerHTML = this.availableFieldTargets
      .map((field) => {
        if (field.querySelector(".fa-check").classList.contains(this.hiddenClass)) {
          return 1
        } else {
          return 0
        }
      })
      .reduce((accumulator, currentValue) => accumulator + currentValue, 0)
  }

  async selectOrUnselectField(event: Event) {
    const selectedField = event.target as HTMLDivElement
    const checkmark: HTMLElement = selectedField.querySelector(".fa-check")
    const isSelected = selectedField.toggleAttribute("data-selected")
    checkmark.classList.toggle(this.hiddenClass)
    selectedField.classList.toggle("bg-purple-50")

    const remoteId = selectedField.dataset.remoteId
    const action = isSelected ? "select" : "unselect"

    await post(`custom_fields/${action}`, {
      responseKind: "turbo-stream",
      body: JSON.stringify({ remote_id: remoteId }),
    })

    this.hideEmptyState()

    setTimeout(() => {
      this.selectedFieldsCounterTarget.innerHTML = this.selectedFieldTargets.length.toString()
      this.updateAvailableFieldsCounter()
    }, 50)
  }

  async deleteField(event: Event) {
    const deletedField = event.currentTarget as HTMLDivElement
    const remoteId = deletedField.dataset.remoteId

    const fieldListing = document.querySelector(`#available_custom_field_${remoteId}`)

    if (fieldListing) {
      const checkmark: HTMLElement = fieldListing.querySelector(".fa-check")
      checkmark.classList.toggle(this.hiddenClass)
      fieldListing.classList.toggle("bg-purple-50")
    }

    await post("custom_fields/unselect", {
      responseKind: "turbo-stream",
      body: JSON.stringify({ remote_id: remoteId }),
    })

    setTimeout(() => {
      this.selectedFieldsCounterTarget.innerHTML = this.selectedFieldTargets.length.toString()
      this.updateAvailableFieldsCounter()
    }, 50)
  }

  search() {
    const query = this.searchTarget.value.toLowerCase().trim()
    this.clearSearchTarget.classList.toggle(this.hiddenClass, query === "")

    this.availableFieldTargets.forEach((field) => {
      const text = field.textContent.toLowerCase()
      const availableFieldMainContainer = field.parentElement.parentElement
      if (text.toLowerCase().includes(query)) {
        availableFieldMainContainer.classList.remove(this.hiddenClass)
      } else {
        availableFieldMainContainer.classList.add(this.hiddenClass)
      }
    })

    const shouldShowEmptyState = this.availableFieldTargets.every((field) =>
      field.parentElement.parentElement.classList.contains(this.hiddenClass),
    )
    if (shouldShowEmptyState) {
      this.searchShowEmptyState()
    } else {
      this.searchHideEmptyState()
    }
  }

  clearSearch() {
    this.searchTarget.value = ""
    this.search()
  }

  showEmptyState() {
    this.selectedFieldsEmptyMessageTarget.classList.toggle(this.hiddenClass)
  }

  hideEmptyState() {
    this.selectedFieldsEmptyMessageTarget.classList.add(this.hiddenClass)
  }

  searchShowEmptyState() {
    this.searchEmptyMessageTarget.classList.remove(this.hiddenClass)
    this.availableFieldsContainerTarget.classList.add(this.hiddenClass)
  }

  searchHideEmptyState() {
    this.searchEmptyMessageTarget.classList.add(this.hiddenClass)
    this.availableFieldsContainerTarget.classList.remove(this.hiddenClass)
  }
}
