{
  "Onboard Supplier": {
    "content": "<p>Tropic onboards new Suppliers via Coupa. To onboard a new supplier, the supplier needs to go through multiple approval steps. You can start by filling in the Coupa Onboarding form.</p><br><p>Link: <a href='SUPPLIER_ONBOARDING_URL'>SUPPLIER_ONBOARDING_URL</a></p>"
  },
  "Supplier Request Status": {
    "content": "<p>A supplier onboarding request has been created in Coupa for this supplier. You can view the request by using the following link. This task will close automatically when the request has been approved.</p>"
  },
  "Supplier Onboarding Status": {
    "content": "<p>Onboarding has started in Coupa for this supplier. You can view the supplier in Coupa by using the following link. This task will close automatically when the supplier has been onboarded.</p>"
  },
  "Create Purchase Requisition": {
    "content": "<p>This Requisition will be pushed to Coupa upon submission. If you already have an active requisition for this request in Coupa, you can link it to this task.</p>"
  },
  "Purchase Requisition Status": {
    "content": "<p>This task opens when your Requisition status changes to 'Pending Approval' in Coupa. You can view the Requisition and Approvers in Coupa by clicking the link below. This task will close automatically when the status changes to 'Ordered'.</p>"
  },
  "Purchase Order(s) Status": {
    "content": "<p>A Purchase Order has been created in Coupa for this Request. You can view the Purchase Order by clicking the link below. This task will close automatically when all items have been Received.</p>"
  },
  "Submit Contract Request": {
    "content": "<p>This request requires you to submit a contract request to Coupa. Select a contract form and complete it. If there is only one available form, complete the form.</p>"
  },
  "Contract Request Status": {
    "content": "<p>A contract request has been created in Coupa. You can view the request by using the following link. This task will close automatically when the request has been approved and applied.</p>"
  },
  "Contract Creation Status": {
    "content": "<p>A contract is being created in Coupa. You can view the contract by using the following link. This task will close automatically when the contract has been published.</p>"
  }
}
