// /app/javascript/controllers/showhide_controller.js
import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="show-hide-input"
export default class extends Controller {
  static targets = ["input", "output"]
  static values = {
    showIf: String,
    showUnless: String,
    handleRequired: Boolean,
    handleDisabled: Boolean,
    separator: String,
  }

  inputTarget: HTMLInputElement // element to read to determine visibility of output target
  outputTarget: any // element to hide or show based on the inputTarget's value
  showIfValue: string // one or more values that will cause the output to be displayed (if has priority over unless)
  showUnlessValue: string // one or more values that will cause the output to NOT be displayed
  handleRequiredValue: boolean // toggle the required status of the hidden input
  handleDisabledValue: boolean // toggle the disabled status of the hidden input
  separatorValue: string // separator if multiple values are provided for showIf or showUnless

  hasShowIfValue: boolean
  hasShowUnlessValue: boolean
  hasSeparatorValue: boolean

  connect() {
    this.toggleVisibility()
  }

  toggleVisibility() {
    let showOutputTarget = this.checkInputValue()
    this.outputTarget.hidden = !showOutputTarget

    if (this.handleDisabledValue) {
      this.handleDisabled(!showOutputTarget)
    }
    if (this.handleRequiredValue) {
      this.handleRequired(showOutputTarget)
    }
  }

  checkInputValue() {
    if (this.inputTarget.type == "checkbox") {
      if (this.hasShowIfValue) {
        return this.inputTarget.checked == !!this.showIfValue
      } else if (this.hasShowUnlessValue) {
        return this.inputTarget.checked != !!this.showUnlessValue
      }
    } else {
      if (this.hasShowIfValue) {
        let inclusionList = this.hasSeparatorValue ? this.showIfValue.split(this.separatorValue) : [this.showIfValue]
        return inclusionList.includes(this.inputTarget.value)
      } else if (this.hasShowUnlessValue) {
        let exclusionList = this.hasSeparatorValue
          ? this.showUnlessValue.split(this.separatorValue)
          : [this.showUnlessValue]
        return !exclusionList.includes(this.inputTarget.value)
      }
    }
  }

  // If hidden, remove required attribute
  handleRequired(isRequired: boolean) {
    if (this.elementIsInput(this.outputTarget)) {
      this.outputTarget.required = isRequired
    } else {
      this.outputTarget.querySelectorAll(["input", "textarea", "select"]).forEach((inputElement) => {
        inputElement.required = isRequired
      })
    }
  }

  // If hidden, dont submit as part of the form
  handleDisabled(shouldBeDisabled: boolean) {
    if (this.elementIsInput(this.outputTarget)) {
      this.outputTarget.disabled = shouldBeDisabled
    } else {
      this.outputTarget.querySelectorAll(["input", "textarea", "select"]).forEach((inputElement) => {
        inputElement.disabled = shouldBeDisabled
      })
    }
  }

  elementIsInput(element): boolean {
    return element.tagName.toLowerCase() === "input"
  }
}
