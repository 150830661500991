import Dropzone from "dropzone"

export const FILE_TYPE_TO_ICON = Object.freeze({
  pdf: "fa-file-pdf",
  docx: "fa-file-word",
  doc: "fa-file-word",
  txt: "fa-file-alt",
  xls: "fa-file-excel",
  csv: "fa-file-csv",
  jpg: "fa-file-image",
  png: "fa-file-image",
  jpeg: "fa-file-image",
  gif: "fa-file-image",
  ppt: "fa-file-powerpoint",
  pptx: "fa-file-powerpoint",
  mov: "fa-file-video",
  mp4: "fa-file-video",
  avi: "fa-file-video",
  mpg: "fa-file-video",
  mpeg: "fa-file-video",
  mkv: "fa-file-video",
  mp3: "fa-file-audio",
  undefined: "fa-file",
})

export enum ErrorType {
  FILE_TOO_LARGE,
  UNSUPPORTED_FILE_TYPE,
  SERVER_ERROR,
  UNKNOWN_ERROR,
  EMPTY_FILE,
}

export const RETRYABLE_ERRORS = new Set([ErrorType.SERVER_ERROR])

export const RETRYABLE_FILE_STYLES = Object.freeze({
  icon: "fa-file-exclamation",
  color: "text-gold-600",
})

export const NON_RETRYABLE_FILE_STYLES = Object.freeze({
  icon: "fa-file-times",
  color: "text-red-500",
})

export const CURRENCY_SYMBOLS_MAP = Object.freeze({
  USD: "$",
  EUR: "€",
  GBP: "£",
  PLN: "zł",
})

export enum FileStatus {
  LOADING,
  SUCCESS,
  ERROR,
}

export const MAX_INPUT_GROUP_PARENT_LEVELS = 3

export interface IFormFieldOption {
  description: string
  hidden?: boolean
  hideable?: boolean
  id: string
  title: string
}

export interface IFormFieldPropertiesWithOptions {
  options: IFormFieldOption[]
}

export type FormFieldKey = "request_type" | string
export type FormFieldKind = "RadioButton"

export interface IFormField<T = {}> {
  id: string
  form_id: string
  form_page_id: string
  title: string
  key: FormFieldKey
  kind: FormFieldKind
  properties: object & T
  editable: boolean
  archived_at: string | null
  created_at: string | null
  updated_at: string | null
}

export interface ICustomDropzoneOptions extends Dropzone.DropzoneOptions {
  disablePreviews?: boolean
  maxFilesPerDrop?: number
}
