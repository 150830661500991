import { IFormField, IFormFieldPropertiesWithOptions, IFormFieldOption } from "../../utils/constants"

export const availableOption = (field: IFormField): IFormFieldOption => {
  const { properties } = field as IFormField<IFormFieldPropertiesWithOptions>
  return properties.options.find((o) => !o.hidden)
}

export const onlyOneOptionIsVisible = (field: IFormField): boolean => {
  const { properties } = field as IFormField<IFormFieldPropertiesWithOptions>
  const visibleOptions = properties.options.filter((o) => !o.hidden)

  return visibleOptions.length === 1
}
