import { Controller } from "@hotwired/stimulus"
import { hide, show } from "../utils"

// Connects to data-controller="single-select-component"
export default class extends Controller {
  static targets = ["itemCaption", "itemName"]

  hasItemCaptionTarget: boolean

  itemCaptionTargets: Array<HTMLSelectElement>
  itemNameTargets: Array<HTMLSelectElement>

  addNewItemCaption({ detail: { isUserAdded, origin } }) {
    const eventForThisSelect = this.itemNameTargets.find((element) => element.id == origin.id)
    const eventIndex = this.itemNameTargets.findIndex((element) => element.id == origin.id)
    if (!eventForThisSelect || !this.hasItemCaptionTarget) return

    const errorEl = this.itemNameTargets[eventIndex].nextElementSibling.querySelector(
      `#${this.itemNameTargets[eventIndex].id}_error`,
    )
    if (errorEl) {
      this.itemCaptionTargets[eventIndex].classList.remove("mt-2")
      this.itemCaptionTargets[eventIndex].classList.add("mt-5")
    } else {
      this.itemCaptionTargets[eventIndex].classList.remove("mt-5")
      this.itemCaptionTargets[eventIndex].classList.add("mt-2")
    }

    if (isUserAdded) {
      show(this.itemCaptionTargets[eventIndex])
    } else {
      hide(this.itemCaptionTargets[eventIndex])
    }
  }
}
