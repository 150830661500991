import { Controller } from "@hotwired/stimulus"
import { IFormField } from "../../utils/constants"
import { RequestFormAutoSubmitConditionChecker } from "../../utils/request_form/request_form_auto_submit_condition_checker"
import { RequestFormFieldAutoSelector } from "../../utils/request_form/request_form_field_auto_selector"

export interface PageAutoSubmitter {
  conditionallyAutoSubmitPage: (visibleFields: IFormField[]) => void
}

// Connects to data-controller="request-form--page-auto-submit"
export default class extends Controller {
  fields: IFormField[]

  static targets = ["form", "loader", "nextButton", "previousButton"]
  formTarget: HTMLFormElement
  loaderTarget: HTMLInputElement
  nextButtonTarget: HTMLInputElement
  previousButtonTarget: HTMLInputElement

  static values = {
    fieldsJson: String,
    flagEnabled: Boolean,
    navigationDirection: String,
  }
  fieldsJsonValue: string
  flagEnabledValue: boolean
  navigationDirectionValue: "backward" | "forward"

  connect = (): void => {
    this.fields = JSON.parse(this.fieldsJsonValue)
  }

  conditionallyAutoSubmitPage = (visibleFields: IFormField[]): void => {
    if (!this.flagEnabledValue) return
    if (visibleFields.length === 0) {
      this.goToNextOrPreviousPage()
      return
    }

    if (this.navigationDirectionValue === "backward") return

    RequestFormFieldAutoSelector.call(this.formTarget, visibleFields)
    if (!RequestFormAutoSubmitConditionChecker.canSubmit(this.fields)) return

    this.goToNextOrPreviousPage()
  }

  goToNextOrPreviousPage = () => {
    this.loaderTarget.classList.remove("hidden")

    if (this.navigationDirectionValue === "backward") {
      this.previousButtonTarget.click()
      return
    }

    this.nextButtonTarget.click()
  }
}
