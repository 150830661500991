import { Controller } from "@hotwired/stimulus"
import { hideShallowModeModal, showShallowModeModal } from "../utils"

// Connects to data-controller="period-form"
export default class extends Controller {
  static values = {
    startDate: String,
    endDate: String,
    subscriptions: {
      default: [],
      type: Array,
    },
  }

  static targets = [
    "formWrapper",
    "destroyPeriodField",
    "spendBreakDownSelection",
    "partialAmountField",
    "noneReasonFields",
    "breakdownReasonSelection",
    "breakdownReasonOtherField",
    "schemeSelection",
    "userBasedPerSeatFields",
    "userBasedFixedFields",
    "platformFixedFields",
    "platformUsageBasedFields",
    "otherFixedFields",
    "otherUsagedBasedFields",
    "overageSelection",
    "overageDescriptionField",
    "commonSchemeFields",
    "fixedSeatsSelect",
    "specifiedNumberOfSeatsField",
    "contractValueField",
    "periodStartDateField",
    "periodEndDateField",
    "subscriptionField",
    "modalWrapper",
  ]

  hasModalWrapperTarget: boolean

  modalWrapperTarget: HTMLDivElement
  formWrapperTarget: HTMLDivElement
  destroyPeriodFieldTarget: HTMLInputElement
  spendBreakDownSelectionTarget: HTMLSelectElement
  partialAmountFieldTarget: HTMLDivElement
  noneReasonFieldsTarget: HTMLDivElement
  breakdownReasonSelectionTarget: HTMLSelectElement
  breakdownReasonOtherFieldTarget: HTMLDivElement
  schemeSelectionTarget: HTMLSelectElement
  userBasedPerSeatFieldsTarget: HTMLDivElement
  userBasedFixedFieldsTarget: HTMLDivElement
  platformFixedFieldsTarget: HTMLDivElement
  platformUsageBasedFieldsTarget: HTMLDivElement
  otherFixedFieldsTarget: HTMLDivElement
  otherUsagedBasedFieldsTarget: HTMLDivElement
  overageSelectionTarget: HTMLSelectElement
  overageDescriptionFieldTarget: HTMLDivElement
  commonSchemeFieldsTarget: HTMLDivElement
  fixedSeatsSelectTarget: HTMLSelectElement
  specifiedNumberOfSeatsFieldTarget: HTMLDivElement
  contractValueFieldTarget: HTMLInputElement
  subscriptionFieldTarget: HTMLSelectElement
  periodStartDateFieldTarget: HTMLInputElement
  periodEndDateFieldTarget: HTMLInputElement

  subscriptionsValue: object[]
  previousSubscriptionId = ""

  connect() {
    this.setFormElementIdOnDeleteButton()
    this.spendBreakDownSelectionTarget.addEventListener("change", (e) => {
      this.toggleSpendBreakDownFields((<HTMLSelectElement>e.target).value)
    })
    this.breakdownReasonSelectionTarget.addEventListener("change", (e) =>
      this.toggleBreakdownReasonOther((<HTMLSelectElement>e.target).value),
    )
    this.schemeSelectionTarget.addEventListener("change", (e) =>
      this.toggleSchemeFields((<HTMLSelectElement>e.target).value),
    )
    this.overageSelectionTarget.addEventListener("change", (e) =>
      this.toggleOverageDescription((<HTMLSelectElement>e.target).value),
    )
    this.fixedSeatsSelectTarget.addEventListener("change", (e) =>
      this.toggleSpecifiedNumberOfSeats((<HTMLSelectElement>e.target).value),
    )
    this.toggleSpendBreakDownFields(this.spendBreakDownSelectionTarget.value)
    this.toggleBreakdownReasonOther(this.breakdownReasonSelectionTarget.value)
    this.toggleSchemeFields(this.schemeSelectionTarget.value)
    this.toggleOverageDescription(this.overageSelectionTarget.value)
    this.toggleSpecifiedNumberOfSeats(this.fixedSeatsSelectTarget.value)
  }

  hide(el) {
    el.classList.add("hidden")
  }

  show(el) {
    el.classList.remove("hidden")
  }

  removeForm({ detail: { formId } }) {
    const parentWrapper = this.formWrapperTarget.parentElement

    if (parentWrapper.id !== formId) {
      return
    }

    this.contractValueFieldTarget.setAttribute("data-ignore", "true")
    parentWrapper.classList.remove("active_period_form")

    this.dispatch("removeForm")

    if (this.formWrapperTarget.dataset.newRecord === "true") {
      parentWrapper.remove()
    } else {
      parentWrapper.style.display = "none"
      this.destroyPeriodFieldTarget.value = "1"
    }
  }

  setFormElementIdOnDeleteButton() {
    const formElementId = this.formWrapperTarget.parentElement.id
    const deleteButton = this.formWrapperTarget.querySelector("a#dropdown-item-delete-period")

    if (deleteButton !== null) {
      const buttonHref = deleteButton.href
      deleteButton.href = `${buttonHref}?period_form_id=${formElementId}`
    }
  }

  toggleSpendBreakDownFields(breakdown) {
    this.hide(this.partialAmountFieldTarget)
    this.hide(this.noneReasonFieldsTarget)
    this.hide(this.breakdownReasonOtherFieldTarget)

    if (breakdown === "partial") {
      this.show(this.partialAmountFieldTarget)
    } else if (breakdown == "none") {
      this.show(this.noneReasonFieldsTarget)
    }
  }

  toggleBreakdownReasonOther(reason) {
    if (reason === "other") {
      this.show(this.breakdownReasonOtherFieldTarget)
    } else {
      this.hide(this.breakdownReasonOtherFieldTarget)
    }
  }

  toggleSchemeFields(schemeType) {
    this.hide(this.commonSchemeFieldsTarget)
    this.hide(this.userBasedPerSeatFieldsTarget)
    this.hide(this.userBasedFixedFieldsTarget)
    this.hide(this.platformFixedFieldsTarget)
    this.hide(this.platformUsageBasedFieldsTarget)
    this.hide(this.otherFixedFieldsTarget)
    this.hide(this.otherUsagedBasedFieldsTarget)

    switch (schemeType) {
      case "user_based_per_seat":
        this.show(this.userBasedPerSeatFieldsTarget)
        this.show(this.commonSchemeFieldsTarget)
        break
      case "user_based_fixed":
        this.show(this.userBasedFixedFieldsTarget)
        this.show(this.commonSchemeFieldsTarget)
        break
      case "platform_fixed":
        this.show(this.platformFixedFieldsTarget)
        this.show(this.commonSchemeFieldsTarget)
        break
      case "platform_usage_based":
        this.show(this.platformUsageBasedFieldsTarget)
        break
      case "other_fixed":
        this.show(this.otherFixedFieldsTarget)
        break
      case "other_usage_based":
        this.show(this.otherUsagedBasedFieldsTarget)
        break
      default:
    }
  }

  toggleOverageDescription(hasOverage) {
    this.hide(this.overageDescriptionFieldTarget)
    if (hasOverage === "yes") {
      this.show(this.overageDescriptionFieldTarget)
    } else {
      this.hide(this.overageDescriptionFieldTarget)
    }
  }

  toggleSpecifiedNumberOfSeats(seatsSpecified) {
    this.hide(this.specifiedNumberOfSeatsFieldTarget)
    if (seatsSpecified === "specified") {
      this.show(this.specifiedNumberOfSeatsFieldTarget)
    } else {
      this.hide(this.specifiedNumberOfSeatsFieldTarget)
    }
  }

  doConfirm(e: Event) {
    e.preventDefault()
    this.previousSubscriptionId = this.subscriptionFieldTarget.value
    hideShallowModeModal(this.modalWrapperTarget, this.application)
  }

  doCancel(e: Event) {
    e.preventDefault()
    this.subscriptionFieldTarget.value = this.previousSubscriptionId
    hideShallowModeModal(this.modalWrapperTarget, this.application)
  }

  savePreviousSubscription(e: Event) {
    this.previousSubscriptionId = (<HTMLSelectElement>e.target).value || ""
  }

  selectSubscriptionConfirmation(e: Event) {
    const subscriptionId = (<HTMLSelectElement>e.target).value
    if (!subscriptionId || !this.periodStartDateFieldTarget.value) {
      this.previousSubscriptionId = ""
      return
    }

    const subscription = this.subscriptionsValue.find((sub) => sub.id === subscriptionId)
    if (!subscription) {
      return
    }

    const periodStartDate = this.convertStringToDate(this.periodStartDateFieldTarget.value)
    const subscriptionValidFrom = this.convertStringToDate(subscription.valid_from)
    const subscriptionValidUntil = this.convertStringToDate(subscription.valid_until)

    if (!this.isDateBetween(periodStartDate, subscriptionValidFrom, subscriptionValidUntil)) {
      showShallowModeModal(this.modalWrapperTarget, this.application)
    } else {
      this.previousSubscriptionId = this.subscriptionFieldTarget.value
    }
  }

  private isDateBetween(dateToCheck, startDate, endDate) {
    if (!this.isValidDate(dateToCheck) || !this.isValidDate(startDate) || !this.isValidDate(endDate)) {
      return true
    }
    return dateToCheck > startDate && dateToCheck < endDate
  }

  private isValidDate(date) {
    return date instanceof Date && !isNaN(date.getTime())
  }

  private convertStringToDate(dateString: string): Date {
    return dateString ? new Date(dateString) : null
  }
}
