import { Controller } from "@hotwired/stimulus"
import { manuallyClearRequiredErrors } from "../utils/validations"
import { CURRENCY_SYMBOLS_MAP } from "../utils/constants"

// Connects to data-controller="amount-range-input"
export default class extends Controller {
  static targets = [
    "operator",
    "textSeperator",
    "fromInput",
    "fromInputParent",
    "fromLeftAddOn",
    "toInput",
    "toLeftAddOn",
  ]
  operatorTarget: HTMLInputElement
  textSeperatorTarget: HTMLParagraphElement
  fromInputTarget: HTMLInputElement
  fromInputParentTarget: HTMLDivElement
  fromLeftAddOnTarget: HTMLDivElement
  toInputTarget: HTMLInputElement
  toLeftAddOnTarget: HTMLDivElement

  static values = {
    required: Boolean,
    leftAddOnVisible: Boolean,
  }
  requiredValue: Boolean
  leftAddOnVisibleValue: Boolean

  hiddenCssClass = "hidden"
  flexSelfStartClass = "self-start"

  showElements(): void {
    this.textSeperatorTarget.classList.remove(this.hiddenCssClass)
    this.fromInputParentTarget.classList.remove(this.hiddenCssClass)
    this.fromInputParentTarget.classList.add(this.flexSelfStartClass)
  }

  hideElements(): void {
    this.textSeperatorTarget.classList.add(this.hiddenCssClass)
    this.fromInputParentTarget.classList.add(this.hiddenCssClass)
    this.fromInputParentTarget.classList.remove(this.flexSelfStartClass)

    manuallyClearRequiredErrors(this.fromInputTarget, this.fromInputParentTarget.parentElement)
  }

  toggleRequiredInput(): void {
    const isRequired = this.requiredValue

    if (isRequired) {
      const isHidden = this.fromInputParentTarget.classList.contains(this.hiddenCssClass)
      this.fromInputTarget.required = !isHidden
      this.fromInputTarget.value = ""
    }
  }

  setAddOnCurrency(currency: string): void {
    if (this.fromLeftAddOnTarget) {
      this.fromLeftAddOnTarget.innerText = CURRENCY_SYMBOLS_MAP[currency]
    }
    if (this.toLeftAddOnTarget) {
      this.toLeftAddOnTarget.innerText = CURRENCY_SYMBOLS_MAP[currency]
    }
  }

  onRangeChange(e): void {
    const opereator = e.target.value

    if (opereator !== "Between") {
      this.hideElements()
    } else {
      this.showElements()
    }

    this.toggleRequiredInput()
  }

  onCurrencyChange(e): void {
    const currency = e.target.value
    this.setAddOnCurrency(currency)
  }

  connect() {
    if (this.operatorTarget.value !== "Between") {
      this.hideElements()
      this.toggleRequiredInput()
    } else {
      this.showElements()
    }

    if (this.leftAddOnVisibleValue) {
      this.setAddOnCurrency("USD")
    }
  }
}
