import { Controller } from "@hotwired/stimulus"
import { FrameElement, TurboRenderEvent, TurboClickEvent } from "@hotwired/turbo"
import { show, hide } from "../utils"

export default class extends Controller {
  static targets = ["discountFormFrame", "editFormFrame"]
  discountFormFrameTarget: FrameElement
  editFormFrameTarget: FrameElement

  hasDiscountFormFrameTarget: boolean
  hasEditFormFrameTarget: boolean

  discountFormFrameTargetConnected() {
    if (!this.hasDiscountFormFrameTarget) {
      return
    }
    this.discountFormFrameTarget.addEventListener("turbo:frame-render", this.handleFrameRender.bind(this))
    this.discountFormFrameTarget.addEventListener("turbo:click", this.handleButtonClick.bind(this))
    hide(this.discountFormFrameTarget)
  }

  discountFormFrameTargetDisconnected() {
    if (!this.hasDiscountFormFrameTarget) {
      return
    }
    this.discountFormFrameTarget.removeEventListener("turbo:frame-render", this.handleFrameRender.bind(this))
    this.discountFormFrameTarget.removeEventListener("turbo:click", this.handleButtonClick.bind(this))
  }

  editFormFrameTargetConnected() {
    if (!this.hasEditFormFrameTarget) {
      return
    }
    this.editFormFrameTarget.addEventListener("turbo:frame-render", this.handleFrameRender.bind(this))
    this.editFormFrameTarget.addEventListener("turbo:click", this.handleButtonClick.bind(this))
    hide(this.editFormFrameTarget)
  }

  editFormFrameTargetDisconnected() {
    if (!this.hasEditFormFrameTarget) {
      return
    }
    this.editFormFrameTarget.removeEventListener("turbo:frame-render", this.handleFrameRender.bind(this))
    this.editFormFrameTarget.removeEventListener("turbo:click", this.handleButtonClick.bind(this))
  }

  handleFrameRender(ev: TurboRenderEvent) {
    show(ev.target)
  }

  handleButtonClick(ev: TurboClickEvent) {
    if ((<HTMLAnchorElement>ev.target).text == "Cancel") {
      ev.detail.url = ""
      hide(ev.currentTarget)
    }
  }
}
