import { Application, defaultSchema } from "@hotwired/stimulus"
import { addCustomRequiredErrorListener } from "../utils/validations"

const customSchema = {
  ...defaultSchema,
  keyMappings: { ...defaultSchema.keyMappings, slash: "/" },
}

const application = Application.start(document.documentElement, customSchema)

// Configure Stimulus development experience
application.debug = false // process.env.NODE_ENV === 'development'

window.Stimulus = application

/*
 * npm installed stimulus controllers should be loaded below
 * otherwise they get removed from the `controllers/index.js` file
 * when the stimulus generator command gets invoked `rails generate stimulus`
 * Example:
 *
 * import { Dropdown } from "tailwindcss-stimulus-components"
 * application.register("dropdown", Dropdown)
 */

export { application }

addCustomRequiredErrorListener()
