import React, { useEffect, useState } from "react"
import { Handle, Position, useReactFlow } from "reactflow"
import { AddTaskButton } from "./addTaskButton"
import { EditTaskButton } from "./editTaskButton"
import { getVisualBuilderHandleStyles, taskTriggersText } from "../../../../utils"
import { useWorkflow } from "./workflowContext"
import { Trigger } from "../../../../utils/types"
import { taskState } from "./cardSelectedService"
import { ClickToEditTaskButton } from "./clickToEditTaskButton"
import { RequestStageIcon } from "./requestStageIcon"

interface SubworkflowTaskCardNodeProps {
  taskId: string
  editPath: string
  taskNameAndTooltip: string | null
  assigneeName: string | null
  assignmentType: string
  triggerCondition: string
  triggers: Trigger[]
  targets: string[]
  ruleBadge: string
  addTaskPathBottom?: string
  addTaskPathRight?: string
  groupLabelName: string | null
  requestStage: string
  subworkflowPlaceholder: boolean | null
  subworkflowToApply: string | null
  creditType: string | null
}

export const SubworkflowTaskCardNode = ({ data }: SubworkflowTaskCardNodeProps) => {
  const {
    cardDimensions,
    editable,
    selectedTaskId,
    drawerOpened,
    hoveredTaskId,
    setHoveredTaskId,
    taskHoverDelay,
    setTaskHoverDelay,
    redirectToTask,
  } = useWorkflow()
  const containerStyles = {
    width: `${cardDimensions.width - 4}px`,
    height: `${cardDimensions.height - 4}px`,
  }
  const iconStyles = {
    color: "white",
  }
  const leftHandleStyles = getVisualBuilderHandleStyles("left")
  const rightHandleStyles = getVisualBuilderHandleStyles("right")

  const isSelected = selectedTaskId === data.taskId
  const isHovered = hoveredTaskId === data.taskId

  const { isEditing, rightButtonSelected, bottomButtonSelected } = taskState({ data })
  const { getViewport } = useReactFlow()

  const selectedClasses = "selected bg-purple-50 shadow-xl"
  const hoverOverClasses = "hover:border hover:border-purple-500"
  const borderClasses = isSelected ? "border border-purple-500" : "border border-base"

  const handleMouseLeave = () => {
    const timeout = setTimeout(() => {
      setHoveredTaskId(null)
    }, 300)
    setTaskHoverDelay(timeout)
  }

  const handleMouseEnter = (taskId: string) => {
    setHoveredTaskId(taskId)
    clearTimeout(taskHoverDelay)
  }

  const viewTasks = (e) => {
    e.stopPropagation()
    fetch(data.subworkflowVisualizationUrl, {
      headers: {
        Accept: "text/vnd.turbo-stream.html",
      },
    })
      .then((r) => r.text())
      .then((html) => Turbo.renderStreamMessage(html))
  }

  const handleOnClick = () => {
    if (!data.editPath || !data.taskId || isSelected) {
      return
    }

    redirectToTask(data.editPath, JSON.stringify(getViewport()), data.taskId, selectedTaskId)
  }

  const showAddTaskButtons = (path: string) => {
    return editable && path && (isSelected || isHovered)
  }

  const renderEditTaskButton = function (data) {
    if (!data.editPath) {
      return null
    }

    if (isSelected || isHovered) {
      return (
        <div>
          <ClickToEditTaskButton taskId={data.taskId} isEditing={isSelected} />
        </div>
      )
    }
  }

  return (
    <div
      id={`workflow-task-card-${data.taskId}`}
      className={`subworkflow-task-card hover:shadow-xl mt-4 relative rounded-md shadow-md bg-white ${borderClasses} p-4 ${
        isSelected ? selectedClasses : ""
      }`}
      style={containerStyles}
    >
      <div
        className={`absolute -top-2 -left-2 rounded-md shadow-md bg-white ${borderClasses} p-4 ${
          isSelected ? selectedClasses : ""
        } ${hoverOverClasses} ${isHovered ? "hovered" : ""}`}
        style={containerStyles}
      >
        <div
          className={`absolute -top-2 -left-2 rounded-md shadow-md bg-white ${borderClasses} p-4 ${
            isSelected ? selectedClasses : ""
          } ${hoverOverClasses}`}
          style={containerStyles}
          onMouseEnter={() => handleMouseEnter(data.taskId)}
          onMouseLeave={handleMouseLeave}
          onClick={handleOnClick}
        >
          {renderEditTaskButton(data)}
          <Handle type="target" position={Position.Left} style={leftHandleStyles} />
          <div className="flex flex-col">
            <div className="space-y-4">
              <div className="flex flex-row items-center">
                <RequestStageIcon stage={data.requestStage} />
                <div className="small-heading truncate" dangerouslySetInnerHTML={{ __html: data.taskNameAndTooltip }} />
              </div>
              <>
                <div className="flex flex-row items-center space-x-2">
                  <div>
                    <div className="flex items-center justify-center w-6 h-6 bg-gray-400 rounded-full">
                      <i className="fa-solid fa-play fa-2xs" style={iconStyles}></i>
                    </div>
                  </div>
                  <div className="text-sm font-normal text-gray-600 truncate">{taskTriggersText(data.triggers)}</div>
                </div>
              </>
            </div>
          </div>
          <div className="flex flex-row items-center text-purple-500 mt-4">
            <i className="fa-solid fa-diagram-subtask fa-sm"></i>
            <div className="pl-1 link-text">
              <a href="#" onClick={viewTasks}>
                View Tasks
              </a>
            </div>
          </div>
          {showAddTaskButtons(data.addTaskPathRight) && (
            <AddTaskButton
              position="right"
              selected={rightButtonSelected}
              actionPath={data.addTaskPathRight}
              taskId={data.taskId}
            />
          )}
          {showAddTaskButtons(data.addTaskPathBottom) && (
            <AddTaskButton
              selected={bottomButtonSelected}
              position="bottom"
              actionPath={data.addTaskPathBottom}
              taskId={data.taskId}
            />
          )}
          <Handle type="source" position={Position.Right} style={rightHandleStyles} />
        </div>
      </div>
    </div>
  )
}
