import { Controller } from "@hotwired/stimulus"
import { show, hide } from "../utils"

// Connects to data-controller="tabs"
export default class extends Controller {
  static targets = ["link", "content"]
  static values = {
    currentTab: String,
    variant: String,
    visibilityClasses: [String],
  }

  linkTargets: HTMLAnchorElement[]
  contentTargets: HTMLElement[]
  currentTabValue: string
  variantValue: string
  visibilityClassesValue: string[]

  connect() {
    this.setTabs()
  }

  select(event) {
    this.currentTabValue = event.currentTarget.id
    this.setTabs()
  }

  setTabs() {
    this.linkTargets.map((link) => {
      link.classList.remove(...this.activeStyles())
      link.classList.add(...this.inactiveStyles())
    })
    this.contentTargets.map((content) => content.classList.add(...this.visibilityClassesValue))

    try {
      let selectedLink = this.linkTargets.find((element) => element.id === this.currentTabValue)
      let selectedContent = this.contentTargets.find((element) => element.id === this.currentTabValue)

      selectedLink.classList.remove(...this.inactiveStyles())
      selectedLink.classList.add(...this.activeStyles())
      show(selectedContent)
      selectedContent.classList.remove(...this.visibilityClassesValue)

      window.dispatchEvent(new CustomEvent("TabsComponent:tabChanged", { detail: { value: selectedLink.id } }))
    } catch {}
  }

  activeStyles() {
    switch (this.variantValue) {
      case "pill":
        return ["scope-pill-on", "text-purple-700", "bg-purple-100"]
      default: // bar
        return ["scope-bar-on"]
    }
  }

  inactiveStyles() {
    switch (this.variantValue) {
      case "pill":
        return ["scope-pill-off", "text-gray-500"]
      default: // bar
        return ["scope-bar-off"]
    }
  }
}
