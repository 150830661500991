import { Controller } from "@hotwired/stimulus"
import Sortable from "sortablejs"
import { post } from "@rails/request.js"
import { Turbo } from "@hotwired/turbo-rails"

// Connects to data-controller="request-form-editor--page-sortable"
export default class extends Controller {
  private sortable: Sortable

  static targets = ["sortableContainer", "staticContainer"]
  sortableContainerTarget: HTMLUListElement
  staticContainerTarget: HTMLUListElement

  static values = { updateUrl: String }
  updateUrlValue: String

  connect() {
    this.sortable = Sortable.create(this.sortableContainerTarget, {
      ghostClass: "invisible",
      forceFallback: true,
      animation: 250,
      onStart: (e) => {
        e.target.classList.add("cursor-grabbing")
      },
      onEnd: (e) => {
        e.target.classList.remove("cursor-grabbing")
        if (e.oldIndex !== e.newIndex) this.moveFormPage(e)
      },
    })
  }

  disconnect() {
    this.sortable = undefined
  }

  getLastStaticPage = () => Array.from(this.staticContainerTarget.children).at(-1)?.dataset?.key

  getPageOrder = () => Array.from(this.sortableContainerTarget.children).map((el) => el.dataset.key)

  getPlaceAfter = (formPage: string) => {
    const pageOrder = this.getPageOrder()
    const idx = pageOrder.indexOf(formPage) - 1

    // The form page has been moved to the top
    if (idx === -1) return this.getLastStaticPage()

    return pageOrder.at(idx)
  }

  moveFormPage = (event) => {
    const formPage = event.item.dataset.key
    const placeAfter = this.getPlaceAfter(formPage)

    post(this.updateUrlValue, {
      body: { form_page: formPage, place_after: placeAfter },
      responseKind: "turbo-stream",
    }).then(() => Turbo.cache.clear())
  }
}
