import { Controller } from "@hotwired/stimulus"
import { showShallowModeModal } from "../utils"

// Connects to data-controller="contracted-spend-period-form"
export default class extends Controller {
  static targets = ["confirmDeleteModalWrapper", "markForDestructionField", "spendPeriodField", "endDate"]
  confirmDeleteModalWrapperTarget: HTMLDivElement
  markForDestructionFieldTarget: HTMLInputElement
  spendPeriodFieldTarget: HTMLInputElement
  endDateTarget: HTMLInputElement

  hasConfirmDeleteModalWrapperTarget: boolean

  connect() {}

  deleteSpendPeriod = (event: Event) => {
    event.preventDefault()
    if (this.hasConfirmDeleteModalWrapperTarget) {
      showShallowModeModal(this.confirmDeleteModalWrapperTarget, this.application)
    }
  }

  confirmDeleteSpendPeriod = () => {
    this.markForDestructionFieldTarget.value = 1
    this.markForDestructionFieldTarget.dispatchEvent(new Event("change", { bubbles: true }))
  }

  periodHasEndDate = () => {
    this.setSpendPeriod("full")
  }

  periodNoEndDate = () => {
    this.endDateTarget.value = ""
    this.setSpendPeriod("monthly")
  }

  setSpendPeriod = (value: string) => {
    this.spendPeriodFieldTarget.value = value
    this.spendPeriodFieldTarget.dispatchEvent(new Event("change", { bubbles: true }))
  }
}
