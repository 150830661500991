import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="clear-form"
export default class extends Controller {
  clear(event) {
    // We can't just call this.form().reset() on the form element due to dynamic forms
    event.preventDefault()

    this.form()
      .querySelectorAll("input, select")
      .forEach((element) => {
        element.value = ""
      })

    this.clearAllTomSelects()
  }

  form(): HTMLFormElement {
    return this.element.closest("form")
  }

  clearAllTomSelects(): void {
    this.form()
      .querySelectorAll('[data-controller="styled-select"]')
      .forEach((element) => {
        const controller = this.application.getControllerForElementAndIdentifier(element, "styled-select")
        controller.tomSelect.clear()
      })
  }
}
