import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="proposals--form"
export default class extends Controller {
  static targets = [
    "form",
    "statusField",
    "contactCard",
    "contactsField",
    "paymentTermsContainer",
    "paymentTerms",
    "paymentTermsOther",
  ]
  formTarget: HTMLFormElement
  statusFieldTarget: HTMLInputElement
  contactsFieldTarget: HTMLInputElement
  contactCardTarget: HTMLElement
  paymentTermsContainerTarget: HTMLElement
  paymentTermsTarget: HTMLElement
  paymentTermsOtherTarget: HTMLElement

  connect() {
    this.updateForm()
  }

  contactCardTargetConnected(element) {
    const contactIds = []
    const contactsCards = document.getElementsByClassName("contact-card")
    Array.prototype.forEach.call(contactsCards, (contactCard) => {
      contactIds.push(contactCard.id)
    })

    this.contactsFieldTarget.value = contactIds
  }

  updateForm() {
    let paymentTerms = this.paymentTermsTarget.value
    if (paymentTerms === "other") {
      let paymentTermsOther = this.paymentTermsOtherTarget.content.cloneNode(true)
      this.paymentTermsContainerTarget.appendChild(paymentTermsOther)
    } else {
      let element = document.getElementById("payment_terms_other_container")
      if (element) this.paymentTermsContainerTarget.removeChild(element)
    }
  }

  onFormSubmit(event) {
    event.preventDefault()
    this.statusFieldTarget.value = event.params.status
    if (this.formTarget.checkValidity()) this.formTarget.submit()
  }
}
