import { Controller } from "@hotwired/stimulus"
import PurchaseOrders__SumLineController from "../purchase_orders/sum_line_controller"
// Connects to data-controller="components--purchase-order-form"
export default class extends Controller {
  static targets = ["newExpenseLineItem", "templateExpenseLineItem", "newItemLineItem", "templateItemLineItem"]
  static outlets = ["purchase-orders--sum-line"]
  purchaseOrdersSumLineOutlet: PurchaseOrders__SumLineController

  newExpenseLineItemTarget: HTMLElement
  templateExpenseLineItemTarget: HTMLElement
  newItemLineItemTarget: HTMLElement
  templateItemLineItemTarget: HTMLElement

  expenseTomSelectIds = [
    "select-expense_line_item-accounting_expense_category_id",
    "select-expense_line_item-accounting_account_id",
    "select-expense_line_item-accounting_department_id",
    "select-expense_line_item-accounting_location_id",
    "select-expense_line_item-accounting_classification_id",
  ]

  itemTomSelectIds = [
    "select-item_line_item-accounting_item_id",
    "select-item_line_item-accounting_department_id",
    "select-item_line_item-accounting_location_id",
    "select-item_line_item-accounting_classification_id",
  ]

  expenseInputIds = ["purchase_order_expense_line_items__amount", "purchase_order_expense_line_items__memo"]

  itemInputIds = [
    "purchase_order_item_line_items__quantity",
    "purchase_order_item_line_items__rate",
    "purchase_order_item_line_items__memo",
  ]

  addExpenseLineItem() {
    this.addLineItem("ExpenseLineItem")
  }

  addItemLineItem() {
    this.addLineItem("ItemLineItem")
  }

  addLineItem(itemType: "ExpenseLineItem" | "ItemLineItem") {
    const template = this[`template${itemType}Target`].innerHTML
    const container = this[`new${itemType}Target`]
    const timestamp = new Date().getTime().toString()
    const newTemplate = template.replace(/replace_item_id/g, timestamp)
    container.insertAdjacentHTML("beforeend", newTemplate)
  }

  removeLineItem(event: Event) {
    event.preventDefault()
    const target = event.target as HTMLElement
    target.closest(".table-row").remove()
    this.purchaseOrdersSumLineOutlet.calculateSums()
    this.purchaseOrdersSumLineOutlet.updateTotal()
  }

  async insertLineItem(container: HTMLElement, clone: string, newId: string): Promise<void> {
    return new Promise((resolve) => {
      container.insertAdjacentHTML("afterend", clone)
      const observer = new MutationObserver((mutations, observerInstance) => {
        mutations.forEach((mutation) => {
          if (mutation.addedNodes.length) {
            const element = document.querySelector(`#${newId}`)
            if (element) {
              resolve()
              observerInstance.disconnect()
              return
            }
          }
        })
      })

      observer.observe(container.parentElement, {
        childList: true, // Observe direct children
        subtree: true, // and lower descendants
      })
    })
  }

  setTomSelectValue(originalElement: HTMLElement, newRow: HTMLElement, elementId: string) {
    const originalElementValue = originalElement.querySelector(`#${elementId}`).selectedOptions[0].value
    const newElement = newRow.querySelector(`#${elementId}`).tomselect
    newElement.setValue(originalElementValue, false)
  }

  setInputValue(originalElement: HTMLElement, newRow: HTMLElement, elementId: string) {
    const originalElementValue = originalElement.querySelector(`#${elementId}`).value
    newRow.querySelector(`#${elementId}`).value = originalElementValue
  }

  async duplicateExpenseLineItem(event: Event) {
    event.preventDefault()

    const template = this.templateExpenseLineItemTarget.innerHTML
    const rowToDuplicate = event.target.closest(".table-row")
    const newId = `line-expense_line_item-${new Date().getTime()}`
    const newTemplate = template.replace(/replace_item_id/g, newId)

    await this.insertLineItem(rowToDuplicate, newTemplate, newId)
    const newRow = document.getElementById(newId)

    this.expenseInputIds.forEach((elementId) => {
      this.setInputValue(rowToDuplicate, newRow, elementId)
    })

    this.expenseTomSelectIds.forEach((elementId) => {
      //first check if element exists
      if (rowToDuplicate.querySelector(`#${elementId}`)) {
        this.setTomSelectValue(rowToDuplicate, newRow, elementId)
      }
    })

    this.purchaseOrdersSumLineOutlet.calculateExpenseSum()
  }

  async duplicateItemLineItem(event: Event) {
    event.preventDefault()

    const template = this.templateItemLineItemTarget.innerHTML
    const rowToDuplicate = event.target.closest(".table-row")
    const newId = `line-item_line_item-${new Date().getTime()}`
    const newTemplate = template.replace(/replace_item_id/g, newId)

    await this.insertLineItem(rowToDuplicate, newTemplate, newId)
    const newRow = document.getElementById(newId)

    this.itemInputIds.forEach((elementId) => {
      this.setInputValue(rowToDuplicate, newRow, elementId)
    })

    this.itemTomSelectIds.forEach((elementId) => {
      if (rowToDuplicate.querySelector(`#${elementId}`)) {
        this.setTomSelectValue(rowToDuplicate, newRow, elementId)
      }
    })

    this.purchaseOrdersSumLineOutlet.calculateItemSum()
  }
}
