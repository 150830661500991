import { Dispatch, SetStateAction } from "react"
import { getConnectedEdges, getIncomers, getOutgoers, isEdge, isNode, Node, Edge } from "reactflow"

const getAllIncomers = (node: Node, nodes: Node[], edges: Edge[], prevIncomers: Node[] = []): Node[] => {
  const incomers = getIncomers(node, nodes, edges)
  const result = incomers.reduce((memo, incomer) => {
    memo.push(incomer)

    if (prevIncomers.findIndex((n) => n.id == incomer.id) == -1) {
      prevIncomers.push(incomer)

      getAllIncomers(incomer, nodes, edges, prevIncomers).forEach((foundNode) => {
        memo.push(foundNode)

        if (prevIncomers.findIndex((n) => n.id == foundNode.id) == -1) {
          prevIncomers.push(incomer)
        }
      })
    }
    return memo
  }, [])
  return result
}

export const ignoredNode = (node: Node): boolean => {
  return (
    node.id.includes("connector") ||
    node.id.includes("dummy") ||
    node.id.includes("request_submitted") ||
    node.type.includes("stage") ||
    (node.className || "").includes("subworkflow")
  )
}

const stageNode = (node: Node): boolean => {
  return node.type.includes("stage")
}

const placeholderNode = (node: Node): boolean => {
  return node.data.subworkflowPlaceholder
}

const defaultNode = (style: React.CSSProperties): React.CSSProperties => {
  return {
    ...style,
    border: "1px solid rgb(203 213 224)",
    opacity: 1,
    borderRadius: "0.5rem",
    transition: "border 0.25s ease, border-radius 0.25s ease, opacity 0.25s ease",
  }
}

const mutedNode = (style: React.CSSProperties): React.CSSProperties => {
  return {
    ...style,
    border: "1px solid rgb(203 213 224)",
    opacity: 0.5,
    transition: "opacity 0.25s ease border 0.25s ease",
  }
}

const highlightedNode = (style: React.CSSProperties): React.CSSProperties => {
  return {
    ...style,
    opacity: 1,
    border: "1px solid #6b63e8",
    borderRadius: "0.5rem",
    transition: "border 0.25s ease, border-radius 0.25s ease",
    zIndex: 3,
  }
}

const selectedNode = (style: React.CSSProperties): React.CSSProperties => {
  return {
    ...style,
    opacity: 1,
    border: "1px solid #6B63E8",
    borderRadius: "0.5rem",
  }
}

const mutedEdge = (style: React.CSSProperties): React.CSSProperties => {
  return {
    ...style,
    stroke: "rgb(229 231 235)",
    opacity: 0.5,
    strokeWidth: 2,
  }
}
const defaultEdge = (style: React.CSSProperties): React.CSSProperties => {
  return {
    ...style,
    stroke: "rgb(229 231 235)",
    opacity: 1,
    strokeWidth: 1,
  }
}
const highlightedEdge = (style: React.CSSProperties): React.CSSProperties => {
  return {
    ...style,
    stroke: "#6B63E8",
    opacity: 1,
    strokeWidth: "1px",
    transition: "stroke 0.25s ease, opacity 0.25s ease, stroke-width 0.25s ease",
  }
}

const highlightCurrentEdge = (
  edge: Edge,
  setEdges: Dispatch<SetStateAction<Edge[]>>,
  setNodes: Dispatch<SetStateAction<Node[]>>,
): void => {
  setNodes((prevNodes) => {
    return prevNodes?.map((elem) => {
      if (ignoredNode(elem) || stageNode(elem)) return elem

      const shouldHighlight = elem.id === edge.source || elem.id === edge.target
      if (shouldHighlight) {
        elem.style = highlightedNode(elem.style)
      } else {
        elem.style = mutedNode(elem.style)
      }

      return elem
    })
  })

  setEdges((prevEdges) => {
    return prevEdges?.map((elem) => {
      const isCurrent = elem.id === edge.id

      if (isCurrent) {
        elem.style = highlightedEdge(elem.style)
      } else {
        elem.style = mutedEdge(elem.style)
      }
      return elem
    })
  })
}

const unhighlightCurrentEdge = (
  setEdges: Dispatch<SetStateAction<Edge[]>>,
  setNodes: Dispatch<SetStateAction<Node[]>>,
): void => {
  setNodes((prevNodes) => {
    return prevNodes?.map((elem) => {
      if (ignoredNode(elem) || stageNode(elem)) return elem

      elem.style = defaultNode(elem.style)
      return elem
    })
  })

  setEdges((prevEdges) => {
    return prevEdges?.map((elem) => {
      elem.style = defaultEdge(elem.style)
      return elem
    })
  })
}

const highlightCurrentNode = (
  node: Node,
  nodes: Node[],
  edges: Edge[],
  setNodes: Dispatch<SetStateAction<Node[]>>,
  setEdges: Dispatch<SetStateAction<Edge[]>>,
): void => {
  setEdges((prevEdges) => {
    const incomingEdges = edges.filter((edge) => edge.target === node.id)
    const outgoingEdges = edges.filter((edge) => edge.source === node.id)
    const edgesToHighlight = [...incomingEdges, ...outgoingEdges]

    return prevEdges?.map((elem) => {
      const shouldHighlight = edgesToHighlight.find((i) => i.id === elem.id)
      if (shouldHighlight) {
        elem.style = highlightedEdge(elem.style)
      } else {
        elem.style = mutedEdge(elem.style)
      }
      return elem
    })
  })

  setNodes((prevNodes) => {
    const incomers = getIncomers(node, nodes, edges)
    const outgoers = getOutgoers(node, nodes, edges)
    const nodesToHighlight = [...incomers, ...outgoers, node]

    return prevNodes?.map((elem) => {
      if (ignoredNode(elem) || stageNode(elem)) return elem
      const shouldHighlight = nodesToHighlight.find((i) => i.id === elem.id)
      if (shouldHighlight) {
        elem.style = highlightedNode(elem.style)
      } else {
        elem.style = mutedNode(elem.style)
      }
      return elem
    })
  })
}

const unhighlightCurrentNode = (
  setNodes: Dispatch<SetStateAction<Node[]>>,
  setEdges: Dispatch<SetStateAction<Edge[]>>,
): void => {
  setEdges((prevEdges) => {
    return prevEdges?.map((elem) => {
      elem.style = defaultEdge(elem.style)
      return elem
    })
  })

  setNodes((prevNodes) => {
    return prevNodes?.map((elem) => {
      if (ignoredNode(elem) || stageNode(elem)) return elem
      elem.style = defaultNode(elem.style)
      return elem
    })
  })
}

const highlightPath = (
  node: Node,
  nodes: Node[],
  edges: Edge[],
  setEdges: Dispatch<SetStateAction<Edge[]>>,
  setNodes: Dispatch<SetStateAction<Node[]>>,
): void => {
  if (node && [...nodes, ...edges]) {
    const allIncomers = getAllIncomers(node, nodes, edges)
    const incomerNodeIds = allIncomers.map((i) => i.id)
    const allConnectedEdges = getConnectedEdges(allIncomers, edges).filter((edge) => {
      return edge.target == node.id || incomerNodeIds.includes(edge.target)
    })
    const incomerEdgeIds = allConnectedEdges.map((i) => i.id)

    if (allIncomers.length > 0) {
      setNodes((prevNodes) => {
        return prevNodes?.map((elem) => {
          if (ignoredNode(elem) || stageNode(elem)) return elem
          const isIncomer = incomerNodeIds.includes(elem.id)
          const isSelectedNode = elem.id === node.id

          if (isSelectedNode && !placeholderNode(elem)) {
            elem.style = selectedNode(elem.style)
          } else if (isIncomer) {
            elem.style = highlightedNode(elem.style)
          } else {
            elem.style = mutedNode(elem.style)
          }

          return elem
        })
      })

      setEdges((prevEdges) => {
        return prevEdges?.map((elem) => {
          const highlighted = incomerEdgeIds.includes(elem.id)

          if (highlighted) {
            elem.style = highlightedEdge(elem.style)
            elem.zIndex = 1
          } else {
            elem.style = mutedEdge(elem.style)
          }

          return elem
        })
      })
    }
  }
}

const resetNodeStyles = (
  setEdges: Dispatch<SetStateAction<Edge[]>>,
  setNodes: Dispatch<SetStateAction<Node[]>>,
): void => {
  setNodes((prevNodes) => {
    return prevNodes?.map((elem) => {
      if (ignoredNode(elem) || stageNode(elem)) return elem
      elem.style = defaultNode(elem.style)

      return elem
    })
  })

  setEdges((prevEdges) => {
    return prevEdges?.map((elem) => {
      elem.style = defaultEdge(elem.style)
      elem.zIndex = 0

      return elem
    })
  })
}

const sameTargets = (targets, targetTaskIds) => JSON.stringify(targets.sort()) === JSON.stringify(targetTaskIds.sort())
const sameNode = (taskId, sourceTaskIds) =>
  taskId === sourceTaskIds[0] || (sourceTaskIds[0] === "request_submitted" && taskId === "request-submitted-node")

const sameOrigin = (triggers, sourceTaskIds) => triggers && triggers[0].id === sourceTaskIds[0]

const isParallel = (taskId, sourceTaskIds) => !sameNode(taskId, sourceTaskIds)
const isFirstParallel = (taskId, sourceTaskIds, triggers, targetTaskIds) =>
  triggers &&
  triggers.length > 0 &&
  isParallel(taskId, sourceTaskIds) &&
  sourceTaskIds[0] === "request_submitted" &&
  triggers[0].name === "Request Submitted" &&
  targetTaskIds.length === 0

const isChildlessParallel = ({ taskId, targets, triggers, sourceTaskIds, targetTaskIds }) =>
  isParallel(taskId, sourceTaskIds) &&
  sameOrigin(triggers, sourceTaskIds) &&
  targetTaskIds.length === 0 &&
  !targets.includes(sourceTaskIds[0])

const isBottomButtonSelected = ({ taskId, targets, triggers, sourceTaskIds, targetTaskIds, path }) =>
  !!path &&
  (isFirstParallel(taskId, sourceTaskIds, triggers, targetTaskIds) ||
    isChildlessParallel({ taskId, targets, triggers, sourceTaskIds, targetTaskIds }))

const isRightButtonSelected = ({ taskId, targets, sourceTaskIds, targetTaskIds, path }) =>
  !!path && sameNode(taskId, sourceTaskIds) && sameTargets(targets, targetTaskIds)

const isLeftButtonSelected = ({ taskId, targetTaskIds }) => targetTaskIds.length === 1 && targetTaskIds[0] === taskId

export {
  highlightPath,
  resetNodeStyles,
  isRightButtonSelected,
  isBottomButtonSelected,
  isLeftButtonSelected,
  highlightCurrentEdge,
  unhighlightCurrentEdge,
  highlightCurrentNode,
  unhighlightCurrentNode,
  defaultEdge,
}
