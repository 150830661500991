export function pluralize(word: string, count: Number): string {
  // Add exceptions in a big 'ol case here when needed
  if (count === 1) {
    return `${word}`
  } else {
    return `${word}s`
  }
}

export function numberWithDelimiter(num: Number): string {
  return num.toLocaleString() // add "(en-US)" param if we want to enforce commas
}

export function pluralizeWithDelimiter(word: string, count: number): string {
  return `${numberWithDelimiter(count)} ${pluralize(word, count)}`
}
