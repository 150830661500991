import { Controller } from "@hotwired/stimulus"
import { disable, enable, hide, show } from "../utils"
// Connects to data-controller="organization-supplier-detail-form"
export default class extends Controller {
  static targets = ["certificationContainer", "certificationsWrapper"]
  certificationsWrapperTarget: HTMLInputElement
  certificationContainerTargets: HTMLInputElement[]

  getCertificationElement(value: string): HTMLInputElement {
    return this.certificationContainerTargets.find(
      (target: HTMLInputElement) => target.dataset.certificationValue === value,
    )
  }

  handleDiversityChange(e): void {
    const selectedDiversityValues: string[] = e.currentTarget.tomselect.items
    const toEnable = selectedDiversityValues.map((value) => this.getCertificationElement(value))

    const toDisable = this.certificationContainerTargets.filter((checkbox) => !toEnable.includes(checkbox))

    if (selectedDiversityValues.length === 0) {
      hide(this.certificationsWrapperTarget)
    } else {
      show(this.certificationsWrapperTarget)
    }

    toEnable.forEach((checkboxContainer) => {
      enable(checkboxContainer.querySelector("input"))
      show(checkboxContainer)
    })
    toDisable.forEach((checkboxContainer) => {
      disable(checkboxContainer.querySelector("input"))
      hide(checkboxContainer)
    })
  }
}
