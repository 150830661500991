import { Controller } from "@hotwired/stimulus"

// data-controller="purchase-orders--edit-sidebar"
export default class extends Controller {
  static targets = ["sectionLink", "sectionLine", "sectionElement"]

  sectionLinkTargets: HTMLAnchorElement[]
  sectionLineTargets: HTMLElement[]
  sectionElementTargets: HTMLElement[]

  observer: IntersectionObserver

  connect() {
    this.setupIntersectionObserver()
  }

  disconnect() {
    this.observer?.disconnect()
  }

  setupIntersectionObserver() {
    this.observer = new IntersectionObserver(this.handleIntersection.bind(this), {
      // Onbservation area to give more room for detection
      rootMargin: "-20% 0px -80% 0px",
      // Trigger as soon as 10% of the element appears (ie: headline)
      threshold: 0.1,
    })
    this.sectionElementTargets.forEach((section) => {
      this.observer!.observe(section)
    })
  }

  handleIntersection(entries: IntersectionObserverEntry[]) {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        const sectionIndex = this.sectionElementTargets.indexOf(entry.target as HTMLElement)
        if (sectionIndex !== -1) {
          this.activateLink(this.sectionLinkTargets[sectionIndex])
        }
      }
    })
  }

  smoothScroll(event: Event) {
    event.preventDefault()
    const link = event.currentTarget as HTMLAnchorElement
    const targetId = link.getAttribute("href")?.slice(1)

    if (!targetId) {
      return
    }

    const targetElement = this.sectionElementTargets.find((section) => section.id === targetId)

    if (targetElement) {
      targetElement.scrollIntoView({
        behavior: "smooth",
        block: "center",
      })

      this.activateLink(link)
    }
  }

  activateLink(activeLink: HTMLElement) {
    this.sectionLinkTargets.forEach((link, index) => {
      const isActive = link === activeLink
      this.sectionLineTargets[index].classList.toggle("bg-purple-500", isActive)
      this.sectionLineTargets[index].classList.toggle("bg-gray-200", !isActive)
    })
  }
}
