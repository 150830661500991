import { Controller } from "@hotwired/stimulus"
import { enable, disable } from "../utils"

// Connects to data-controller="dependant-submit"
export default class extends Controller {
  static targets = ["field", "btn"]

  static values = {
    disabled: {
      type: Boolean,
      default: false,
    },
  }

  hasBtnTarget: boolean

  fieldTargets: HTMLInputElement[]
  btnTarget: HTMLButtonElement

  disabledValue: boolean

  toggleSubmitHandler = this.toggleSubmit.bind(this)

  connect() {
    this.fieldTargets.forEach((input) => input.addEventListener("change", this.toggleSubmitHandler))
    this.toggleSubmit()
  }

  disconnect() {
    this.fieldTargets.forEach((input) => input.removeEventListener("change", this.toggleSubmitHandler))
  }

  toggleSubmit() {
    const values = this.fieldTargets.map((input) => input.value)
    const hasAllValues = values.every((value) => !!value)
    if (!this.disabledValue && hasAllValues) {
      this.enableSubmit()
    } else {
      this.disableSubmit()
    }
  }

  disableSubmit() {
    if (this.hasBtnTarget) {
      disable(this.btnTarget)
    }
  }

  enableSubmit() {
    if (this.hasBtnTarget) {
      enable(this.btnTarget)
    }
  }
}
