import { Dispatch, SetStateAction } from "react"
import { getConnectedEdges, getIncomers, isEdge, isNode, Node, Edge } from "reactflow"

const getAllIncomers = (node: Node, nodes: Node[], edges: Edge[], prevIncomers: Node[] = []): Node[] => {
  const incomers = getIncomers(node, nodes, edges)
  const result = incomers.reduce((memo, incomer) => {
    memo.push(incomer)

    if (prevIncomers.findIndex((n) => n.id == incomer.id) == -1) {
      prevIncomers.push(incomer)

      getAllIncomers(incomer, nodes, edges, prevIncomers).forEach((foundNode) => {
        memo.push(foundNode)

        if (prevIncomers.findIndex((n) => n.id == foundNode.id) == -1) {
          prevIncomers.push(incomer)
        }
      })
    }
    return memo
  }, [])
  return result
}

const ignoredNode = (node: Node): boolean => {
  return node.id.includes("connector") || node.id.includes("dummy") || node.id.includes("request_submitted")
}

const placeholderNode = (node: Node): boolean => {
  return node.data.subworkflowPlaceholder
}

const highlightPath = (
  node: Node,
  nodes: Node[],
  edges: Edge[],
  setEdges: Dispatch<SetStateAction<Edge[]>>,
  setNodes: Dispatch<SetStateAction<Node[]>>,
): void => {
  if (node && [...nodes, ...edges]) {
    const allIncomers = getAllIncomers(node, nodes, edges)
    const incomerNodeIds = allIncomers.map((i) => i.id)
    const allConnectedEdges = getConnectedEdges(allIncomers, edges).filter((edge) => {
      return edge.target == node.id || incomerNodeIds.includes(edge.target)
    })
    const incomerEdgeIds = allConnectedEdges.map((i) => i.id)

    if (allIncomers.length > 0) {
      setNodes((prevNodes) => {
        return prevNodes?.map((elem) => {
          const isIncomer = incomerNodeIds.includes(elem.id)
          const isSelectedNode = elem.id === node.id
          const highlight = isSelectedNode || isIncomer

          elem.style = {
            ...elem.style,
            opacity: highlight ? 1 : 0.5,
          }

          if (isSelectedNode && !placeholderNode(elem)) {
            elem.style = {
              ...elem.style,
              border: "2px solid #6b63e8",
              borderRadius: "0.5rem",
            }
          }

          if (isIncomer && !ignoredNode(elem)) {
            elem.style = {
              ...elem.style,
              border: "2px solid #8982ED",
              borderRadius: "0.5rem",
            }
          }

          return elem
        })
      })

      setEdges((prevEdges) => {
        return prevEdges?.map((elem) => {
          const animated = incomerEdgeIds.includes(elem.id)

          if (animated) {
            elem.style = {
              ...elem.style,
              stroke: "#8982ED",
              opacity: 1,
              strokeWidth: "4px",
            }
            elem.markerEnd = {
              ...elem.markerEnd,
              color: elem.target == node.id ? "#8982ED" : "transparent",
            }
            elem.zIndex = 1
          } else {
            elem.style = {
              ...elem.style,
              stroke: "#6A778A",
              opacity: 0.5,
              strokeWidth: 2,
            }
            elem.markerEnd = {
              ...elem.markerEnd,
              color: elem.target == node.id ? "#6A778A" : "transparent",
            }
          }

          return elem
        })
      })
    }
  }
}

const resetNodeStyles = (
  setEdges: Dispatch<SetStateAction<Edge[]>>,
  setNodes: Dispatch<SetStateAction<Node[]>>,
): void => {
  setNodes((prevNodes) => {
    return prevNodes?.map((elem) => {
      elem.style = {
        ...elem.style,
        opacity: 1,
        border: 0,
      }

      return elem
    })
  })

  setEdges((prevEdges) => {
    return prevEdges?.map((elem) => {
      elem.style = {
        ...elem.style,
        stroke: "#6A778A",
        opacity: 1,
        strokeWidth: 2,
      }
      elem.markerEnd = {
        ...elem.markerEnd,
        color: elem.markerEnd.color === "transparent" ? "transparent" : "#6A778A",
      }
      elem.zIndex = 0

      return elem
    })
  })
}

const sameTargets = (targets, targetTaskIds) => JSON.stringify(targets.sort()) === JSON.stringify(targetTaskIds.sort())
const sameNode = (taskId, sourceTaskIds) =>
  taskId === sourceTaskIds[0] || (sourceTaskIds[0] === "request_submitted" && taskId === "request-submitted-node")

const sameOrigin = (triggers, sourceTaskIds) => triggers && triggers[0].id === sourceTaskIds[0]

const isParallel = (taskId, sourceTaskIds) => !sameNode(taskId, sourceTaskIds)
const isFirstParallel = (taskId, sourceTaskIds, triggers, targetTaskIds) =>
  triggers &&
  triggers.length > 0 &&
  isParallel(taskId, sourceTaskIds) &&
  sourceTaskIds[0] === "request_submitted" &&
  triggers[0].name === "Request Submitted" &&
  targetTaskIds.length === 0

const isChildlessParallel = ({ taskId, targets, triggers, sourceTaskIds, targetTaskIds }) =>
  isParallel(taskId, sourceTaskIds) &&
  sameOrigin(triggers, sourceTaskIds) &&
  targetTaskIds.length === 0 &&
  !targets.includes(sourceTaskIds[0])

const isBottomButtonSelected = ({ taskId, targets, triggers, sourceTaskIds, targetTaskIds, path }) =>
  !!path &&
  (isFirstParallel(taskId, sourceTaskIds, triggers, targetTaskIds) ||
    isChildlessParallel({ taskId, targets, triggers, sourceTaskIds, targetTaskIds }))

const isRightButtonSelected = ({ taskId, targets, sourceTaskIds, targetTaskIds, path }) =>
  !!path && sameNode(taskId, sourceTaskIds) && sameTargets(targets, targetTaskIds)

const isLeftButtonSelected = ({ taskId, targetTaskIds }) => targetTaskIds.length === 1 && targetTaskIds[0] === taskId

export { highlightPath, resetNodeStyles, isRightButtonSelected, isBottomButtonSelected, isLeftButtonSelected }
