import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="settings--integrations--coupa--coupa-supplier-onboarding-setup"

export default class extends Controller {
  static targets = ["supplierErp", "supplierErpControl", "supplierMethod"]

  supplierErpTarget: HTMLTextAreaElement
  supplierErpControlTarget: HTMLElement
  supplierMethodTarget: HTMLSelectElement

  connect() {
    this.update()
  }

  update() {
    const erpTextClasses = this.supplierErpControlTarget.classList

    if (this.supplierMethodTarget.value === "onboarding_erp") {
      this.supplierErpTarget.required = true
      erpTextClasses.remove("hidden")
    } else {
      this.supplierErpTarget.required = false
      erpTextClasses.add("hidden")
    }
  }
}
