import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="radio-button-hidden-field"
export default class extends Controller {
  static targets = ["hiddenInputField"]

  hiddenInputFieldTarget: HTMLInputElement
  hasHiddenInputFieldTarget: boolean

  static values = { keyValueMap: Object }

  keyValueMapValue: object

  onChange(event: Event): void {
    if (!this.hasHiddenInputFieldTarget) {
      return
    }

    const value = this.keyValueMapValue[event.target.value]
    this.hiddenInputFieldTarget.value = value
  }
}
