import React from "react"
import { Handle, Position } from "reactflow"
import { useWorkflow } from "./workflowContext"

const DummyCardNode = ({ data }) => {
  const { cardDimensions } = useWorkflow()
  const handleStyles = {
    minWidth: "2px",
    minHeight: "2px",
    width: "2px",
    height: "2px",
    border: "none",
    background: "#6A778A",
    top: (cardDimensions.height - 2) / 2,
    left: 0,
    transform: "none",
  }
  const containerStyles = {
    width: "1px",
    height: "1px",
  }

  return (
    <div style={containerStyles}>
      <Handle type="target" position={Position.Left} style={handleStyles} />
      <Handle type="source" position={Position.Right} style={handleStyles} />
    </div>
  )
}

export default DummyCardNode
