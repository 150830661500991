import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="document-dependant-field"
export default class extends Controller {
  static targets = ["field"]

  hasFieldTarget: boolean
  fieldTarget: HTMLInputElement

  updateDocumentIdHandler = this.updateDocumentId.bind(this)
  connect() {
    document.addEventListener("DocumentViewer:onDocumentIdChange", this.updateDocumentIdHandler)
  }

  disconnect() {
    document.removeEventListener("DocumentViewer:onDocumentIdChange", this.updateDocumentIdHandler)
  }

  updateDocumentId(e: CustomEvent) {
    const { documentId } = e.detail
    const field = this.hasFieldTarget ? this.fieldTarget : (this.element as HTMLInputElement)

    field.value = documentId
  }
}
