import { Controller } from "@hotwired/stimulus"
import { NestedFormSyncer } from "../../utils/comment_form/NestedFormSyncer"

// Connects to data-controller="workflows--task-transition-form"
export default class extends Controller {
  static targets = ["taskTransitionForm", "commentContentHiddenField", "commentInternalHiddenField"]
  taskTransitionFormTarget: HTMLFormElement
  commentContentHiddenFieldTarget: HTMLInputElement
  commentInternalHiddenFieldTarget: HTMLInputElement

  connect() {}

  onFormSubmit(event) {
    // before submitting, we need to get the comment form values if any
    NestedFormSyncer.call(this.taskTransitionFormTarget)

    const commentContentField = document.getElementById("comment-content-field")
    if (commentContentField) {
      this.commentContentHiddenFieldTarget.value = commentContentField.value
    }

    const commentInternalField = document.getElementById("comment-internal-field")
    if (commentInternalField) {
      this.commentInternalHiddenFieldTarget.value = commentInternalField.value
    }

    this.dispatch("clearDraft")

    /*
    requestSubmit() sets format to :turbo_stream
    submit() sets the format to html
    Below ternary makes sure that request comes in as a turbo_stream when tasks are being completed from workflows
    */
    if (window.location.href.includes("/request_steps")) {
      this.taskTransitionFormTarget.requestSubmit()
    } else {
      window.location.href.includes("/portal") || window.location.href.includes("/supplier_portal")
        ? this.taskTransitionFormTarget.submit()
        : this.taskTransitionFormTarget.requestSubmit()
    }
  }
}
