import { Controller } from "@hotwired/stimulus"
import { enter, leave, toggle } from "el-transition"

// Connects to data-controller="sliding-sidebar"
export default class extends Controller {
  static targets = ["sidebar"]
  sidebarTarget: HTMLDivElement

  static values = { open: Boolean }
  openValue: boolean

  // bind() creates a new function with the given context, we need to store the
  // reference of that function so we can then remove the listener
  toggleHandler = this.toggle.bind(this)

  connect() {
    this.conditionallyToggleSidebar()
    document.addEventListener("SlidingSidebar:toggle", this.toggleHandler)
  }

  disconnect() {
    document.removeEventListener("SlidingSidebar:toggle", this.toggleHandler)
  }

  conditionallyToggleSidebar() {
    if (this.openValue === true) {
      this.openSidebar()
    }
  }

  openSidebar() {
    enter(this.sidebarTarget)
    document.dispatchEvent(new CustomEvent("SlidingSidebar:onToggle", { detail: { open: true } }))
  }

  toggle() {
    const isOpen = this.sidebarTarget.classList.contains("hidden")
    toggle(this.sidebarTarget)
    document.dispatchEvent(new CustomEvent("SlidingSidebar:onToggle", { detail: { open: isOpen } }))
  }
}
