import { Controller } from "@hotwired/stimulus"
import { post } from "@rails/request.js"
import { disable, enable, hide, show } from "../utils"

// Connects to data-controller="contract-price-driver-fields"
export default class extends Controller {
  static targets = ["addDiscountDescriptionWrapper", "discountDescriptionWrapper", "quantityField"]

  static values = {
    formChangeUrl: String,
    index: String,
    isFirst: Boolean,
    turboFrame: String,
    contractLineItem: Object,
  }

  formChangeUrlValue: string
  indexValue: string
  isFirstValue: boolean
  turboFrameValue: string
  contractLineItemValue: object

  addDiscountDescriptionWrapperTarget: HTMLDivElement
  discountDescriptionWrapperTarget: HTMLDivElement
  quantityFieldTarget: HTMLInputElement

  connect() {}

  onFormChange(e): void {
    const { hasDiscount, preventDefault } = e.params
    if (preventDefault) {
      e.preventDefault()
    }

    const formData = new FormData()

    this.prepareFormData(formData)

    if (hasDiscount !== undefined) {
      this.toggleDiscount(formData, hasDiscount, this.indexValue)
    }

    post(this.formChangeUrlValue, {
      responseKind: "turbo-stream",
      body: formData,
    })
  }

  toggleDiscountField(e: Event) {
    e.preventDefault()

    const isFieldShown = !this.getDiscountDescriptionWrapper().classList.contains("hidden")

    if (isFieldShown) {
      hide(this.getDiscountDescriptionWrapper())
      show(this.getAddDiscountDescriptionWrapper())
    } else {
      show(this.getDiscountDescriptionWrapper())
      hide(this.getAddDiscountDescriptionWrapper())
    }
  }

  private prepareFormData(formData: FormData) {
    this.serializeLineItemObject(formData)

    formData.set("is_first_price_driver", this.isFirstValue.toString())
    formData.set("index", this.indexValue)
    formData.set("turbo_frame", this.turboFrameValue)
    formData.set("is_price_driver", this.isLineItemThePriceDriver().toString())
    formData.set("total_price_drivers", this.totalPriceDrivers())

    this.serializePriceDriverFields(formData)
  }

  private serializeLineItemObject(formData: FormData) {
    for (const key in this.contractLineItemValue) {
      if (this.contractLineItemValue[key] != undefined || this.contractLineItemValue[key] != null) {
        formData.append(`contract_line_item[${key}]`, this.contractLineItemValue[key])
      }
    }
  }

  private serializePriceDriverFields(formData: FormData) {
    const inputs = this.element.querySelectorAll("input:not([disabled]), select, textarea")
    inputs.forEach((input: HTMLInputElement) => {
      if (input.type === "radio") {
        if (input.checked) {
          formData.append(this.getFieldName(input.name), input.value)
        }
      } else {
        formData.append(this.getFieldName(input.name), input.value)
      }
    })
  }

  private getFieldName(name: string) {
    if (this.indexValue) {
      return name.replace(`[${this.indexValue}]`, "[]")
    }

    return name
  }

  private toggleDiscount(formData: FormData, hasDiscount: boolean, id: string) {
    if (hasDiscount) {
      formData.set(`contract_line_item[contract_price_drivers_attributes][][discount_present]`, "true")
    } else {
      formData.set(`contract_line_item[contract_price_drivers_attributes][][discount_present]`, "false")
      formData.delete(`contract_line_item[contract_price_drivers_attributes][][discount_type]`)
      formData.delete(`contract_line_item[contract_price_drivers_attributes][][discount_description]`)
      formData.delete(`contract_line_item[contract_price_drivers_attributes][][line_item_total_list_price]`)
      formData.delete(`contract_line_item[contract_price_drivers_attributes][][discount_amount]`)
      formData.delete(`contract_line_item[contract_price_drivers_attributes][][discount_percentage]`)
      formData.delete(`contract_line_item[contract_price_drivers_attributes][][discount_description]`)
      formData.delete(`contract_line_item[contract_price_drivers_attributes][][list_unit_cost]`)
    }
  }

  // Since the fields of the form are shown/hidden dynamically, there is no guaranty
  // these elements are going to be present in the form on page load.
  // These methods will look for the elements in the most recent DOM if the stimulus targets do not exist
  private getAddDiscountDescriptionWrapper() {
    return (
      this.addDiscountDescriptionWrapperTarget ||
      this.element.querySelector('[data-contract-price-driver-fields-target="addDiscountDescriptionWrapper"]')
    )
  }

  private getDiscountDescriptionWrapper() {
    return (
      this.discountDescriptionWrapperTarget ||
      this.element.querySelector('[data-contract-price-driver-fields-target="discountDescriptionWrapper"]')
    )
  }

  private isLineItemThePriceDriver(): boolean {
    const toggle = document.getElementById("is_price_driver") as HTMLInputElement
    return toggle.checked
  }

  private totalPriceDrivers(): string {
    const price_driver_counter = document.getElementById("price-driver-count")
    return price_driver_counter.innerHTML.trim()
  }
}
