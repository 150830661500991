import { Controller } from "@hotwired/stimulus"
import { show, hide } from "../utils"

// Connects to data-controller="opt-out-fields"
export default class extends Controller {
  // targets
  static targets = ["optOutSourceField", "optOutSourceUrl", "optOutSourceDocument", "optOutSourceOther"]
  optOutSourceFieldTarget: HTMLSelectElement
  optOutSourceUrlTarget: HTMLDivElement
  optOutSourceDocumentTarget: HTMLDivElement
  optOutSourceOtherTarget: HTMLDivElement

  connect() {
    this.optOutSourceFieldTarget.addEventListener("change", (e) =>
      this.toggleOptOutSource((<HTMLSelectElement>e.target).value),
    )
    this.toggleOptOutSource(this.optOutSourceFieldTarget.value)
  }

  disconnect() {
    this.optOutSourceFieldTarget.removeEventListener("change", (e) =>
      this.toggleOptOutSource((<HTMLSelectElement>e.target).value),
    )
  }

  toggleOptOutSource(optOutSource) {
    hide(this.optOutSourceUrlTarget)
    hide(this.optOutSourceDocumentTarget)
    hide(this.optOutSourceOtherTarget)
    if (optOutSource === "online_terms_of_service") {
      show(this.optOutSourceUrlTarget)
    } else if (optOutSource === "document") {
      show(this.optOutSourceDocumentTarget)
    } else if (optOutSource === "other") {
      show(this.optOutSourceOtherTarget)
    }
  }
}
