import { Controller } from "@hotwired/stimulus"

const NON_INPUT_EVENT_TAGS = new Set(["checkbox"])

// Connects to data-controller="block-no-change-updates"
export default class extends Controller {
  static targets = ["submitButton", "watchable"]

  someInputHasChanged: boolean
  watchableTargets: HTMLInputElement[]
  submitButtonTarget: HTMLButtonElement

  submitButton: HTMLButtonElement | null

  inputsToWatch: HTMLInputElement[]
  hasWatchableTarget: boolean
  hasSubmitButtonTarget: boolean
  setInputHasChangedFunction: () => void
  blockSubmitIfNoChangesFunction: (event) => void

  connect() {
    this.setInputHasChangedFunction = this.setInputHasChanged.bind(this)
    this.blockSubmitIfNoChangesFunction = this.blockSubmitIfNoChanges.bind(this)
    this.listenForChanges = this.listenForChanges.bind(this)

    this.setInputsToWatch()
    this.setSubmitButtonTarget()
    this.disableSubmitButton()

    this.element.addEventListener("submit", this.blockSubmitIfNoChangesFunction)
    this.listenForChanges()
  }

  disconnect(): void {
    this.element.removeEventListener("submit", this.blockSubmitIfNoChangesFunction)
    this.inputsToWatch.forEach((input) => input.removeEventListener("change", this.setInputHasChangedFunction))
  }

  blockSubmitIfNoChanges(event) {
    if (!this.someInputHasChanged) {
      event.preventDefault()
    }
  }

  setInputHasChanged() {
    if (!this.someInputHasChanged) {
      this.someInputHasChanged = true
      this.enableSubmitButton()
    }
  }

  setInputsToWatch() {
    if (this.hasWatchableTarget) {
      this.inputsToWatch = this.watchableTargets
    } else {
      this.inputsToWatch = Array.from(this.element.querySelectorAll("input, select, checkbox, textarea"))
    }
  }

  setSubmitButtonTarget() {
    if (this.hasSubmitButtonTarget) {
      this.submitButton = this.submitButtonTarget
    } else {
      this.submitButton = this.element.querySelector("input[type=submit]")
    }
  }

  disableSubmitButton() {
    if (this.submitButton) {
      this.submitButton.disabled = true
      this.submitButton.classList.add("disabled")
    }
  }

  enableSubmitButton() {
    if (this.submitButton) {
      this.submitButton.disabled = false
      this.submitButton.classList.remove("disabled")
    }
  }

  listenForChanges() {
    this.inputsToWatch.forEach((input) => {
      if (input.type === "radio") {
        input.addEventListener("click", this.setInputHasChangedFunction)
      } else if (NON_INPUT_EVENT_TAGS.has(input.type)) {
        input.addEventListener("change", this.setInputHasChangedFunction)
      } else {
        input.addEventListener("input", this.setInputHasChangedFunction)
      }
    })
  }
}
