import React from "react"
import { Handle, Position } from "reactflow"
import { useWorkflow } from "./workflowContext"
import { AddTaskButton } from "./addTaskButton"
import { getVisualBuilderHandleStyles } from "../../../../utils"

interface cardProps {
  data: {
    taskId: string
    editPath: null
    taskNameAndTooltip: string | null
    assigneeName: string | null
    assignmentType: string
    triggerCondition: string
    triggers: string[]
    addTaskPathBottom?: string
    addTaskPathRight?: string
    groupLabelName: string | null
  }
}

export const approvalChainInitiatedTaskCardNode = ({ data }: cardProps) => {
  const { cardDimensions } = useWorkflow()
  const containerStyles = {
    boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.08)",
    width: `${cardDimensions.width}px`,
    height: `${cardDimensions.height}px`,
  }
  const handleStyles = getVisualBuilderHandleStyles("right")

  return (
    <div
      id={`workflow-task-card-${data.taskId}`}
      className="rounded-md bg-gray-50 border border-base p-4"
      style={containerStyles}
    >
      <>
        <div className="flex flex-col space-y-2">
          <div className="space-y-1">
            <div className="small-heading truncate">Approval Chain Initiated</div>
            <div className="text-sm text-gray-600 font-normal">
              Approval Chains will be initiated based on their placement in the parent Enhanced Workflow.
            </div>
            <div className="flex flex-row pt-6 items-center text-purple-500">
              <i className="fa-solid fa-book fa-sm"></i>
              <div className="pl-1 link-text">View Approval Chain Guide</div>
            </div>
          </div>
        </div>
      </>
      <Handle type="source" position={Position.Right} style={handleStyles} />
      {data.addTaskPathRight && (
        <AddTaskButton position="right" taskId={data.taskId} actionPath={data.addTaskPathRight} />
      )}
    </div>
  )
}

export default approvalChainInitiatedTaskCardNode
