import { Controller } from "@hotwired/stimulus"
import { patch } from "@rails/request.js"
import { show, hide } from "../utils"

// Connects to data-controller="request-submission-form"
export default class extends Controller {
  static values = {
    requestId: String,
    currentBudget: Number,
    hasBudgetRules: Boolean,
    onFormChangeUrl: String,
  }

  static targets = ["form"]

  requestIdValue: string
  currentBudgetValue: number
  hasBudgetRulesValue: boolean
  formTarget: HTMLFormElement
  onFormChangeUrlValue: string

  connect() {
    const onChange = this.onChange.bind(this)

    const form = this.formTarget

    // multi-currency should not trigger the onChange event
    const formElements = form.querySelectorAll("input:not([type='text']), textarea, select:not([id='currency'])")

    formElements.forEach(function (element) {
      element.addEventListener("change", onChange)
    })

    const budgetField = form.querySelector("#form_response_data_budget_budgeted_amount")
    budgetField?.addEventListener("change", this.onBudgetChange.bind(this))
  }

  onBudgetChange(event: Event) {
    const newBudget = Math.round(parseFloat(event.target.value.replace(/,/g, "")) * 100)
    const currentBudget = this.currentBudgetValue
    const budgetChanged = newBudget != currentBudget

    if (budgetChanged && this.hasBudgetRulesValue) {
      const updateBudgetAlert = document.getElementById("budgeted-amount-alert")
      show(updateBudgetAlert)
    } else {
      const updateBudgetAlert = document.getElementById("budgeted-amount-alert")
      hide(updateBudgetAlert)
    }
  }

  onChange(event: Event) {
    const form = this.formTarget
    const formData = new FormData(form)

    patch(this.onFormChangeUrlValue, {
      responseKind: "turbo-stream",
      body: formData,
    })
  }

  checkIfFormIsInvalid(event: Event) {
    if (!this.formTarget.checkValidity()) {
      event.preventDefault()
      event.stopImmediatePropagation()
    }
  }
}
