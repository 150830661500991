import { Controller } from "@hotwired/stimulus"
import { Tracking } from "../utils/tracking"

/*
  Stimulus controller to easily track simple user actions on HTML elements

  Usage:
      <a href="/something"
        data-controller="tracking"
        data-tracking-event-name-value="my_custom_event"
        data-tracking-listener-value="click"
        data-tracking-payload-value="{'hello': 'world'}">
        Click Me!
      </a>

  If applied to a form field, it will send its value along with the payload:
      <input type="text"
        data-controller="tracking"
        data-tracking-event-name-value="my_custom_event"
        data-tracking-listener-value="blur"
        data-tracking-payload-value="{'additional': 'data'}" />
 */

// Connects to data-controller="tracking"
export default class extends Controller {
  static targets = ["element"]

  static values = {
    // Required. Name of the event to track in MixPanel
    eventName: String,
    // Required. JS Event Type to attach to the target element
    listener: String,
    // Optional. Custom event payload
    payload: {
      type: Object,
      default: {},
    },
    // Optional. Sets a custom key name that holds the input value
    valueKey: {
      type: String,
      default: "value",
    },
    // Optional. Only tracks the event if the value of the input matches this value
    ifMatches: String,
  }

  hasElementTarget: boolean
  elementTarget: any

  eventNameValue: string
  payloadValue: object
  listenerValue: string
  valueKeyValue: string
  ifMatchesValue: string

  hasIfMatchesValue: boolean

  trackHandler = this.track.bind(this)

  connect() {
    if (this.canAttachListener()) {
      this.attachListener()
    }
  }

  disconnect() {
    if (this.canAttachListener()) {
      this.detachListener()
    }
  }

  private attachListener() {
    this.getTargetElement().addEventListener(this.listenerValue, this.trackHandler)
  }

  private detachListener() {
    this.getTargetElement().removeEventListener(this.listenerValue, this.trackHandler)
  }

  private canAttachListener() {
    return this.eventNameValue && this.listenerValue
  }

  private getTargetElement() {
    return this.hasElementTarget ? this.elementTarget : this.element
  }

  private isFormElement() {
    return this.getTargetElement().checked !== undefined || this.getTargetElement().value !== undefined
  }

  private isCheckboxElement() {
    return this.getTargetElement().getAttribute("type") == "checkbox"
  }

  private getElementValue() {
    if (this.isCheckboxElement()) {
      return this.getTargetElement().checked
    } else {
      return this.getTargetElement().value
    }
  }

  private parseIfMatchesValue() {
    if (this.ifMatchesValue == "true") {
      return true
    }

    if (this.ifMatchesValue == "false") {
      return false
    }

    return this.ifMatchesValue
  }

  private track() {
    let payload = this.payloadValue

    if (this.isFormElement()) {
      const elementValue = this.getElementValue()
      if (elementValue !== undefined && this.valueKeyValue) {
        payload = { ...payload, [this.valueKeyValue]: elementValue }
      }

      if (!this.hasIfMatchesValue) {
        Tracking.createEvent(this.eventNameValue, payload, this.getTargetElement(), this.listenerValue)
      } else {
        if (this.parseIfMatchesValue() == elementValue) {
          Tracking.createEvent(this.eventNameValue, payload, this.getTargetElement(), this.listenerValue)
        }
      }
    } else {
      Tracking.createEvent(this.eventNameValue, payload, this.getTargetElement(), this.listenerValue)
    }
  }
}
