import { createElement } from "react"
import { createRoot } from "react-dom/client"

const loader = (registry, event) => {
  registry.forEach(({ component, target }) => {
    const nodes = event.target.getElementsByClassName(target)
    Array.from(nodes).forEach((node) => {
      const props = JSON.parse(node.getAttribute("data-visualization-props-value"))
      const comp = createElement(component, props)
      const root = createRoot(node, { identifierPrefix: target })
      root.render(comp)
      function unmountOnLeave() {
        root.unmount()
      }
      event.target.addEventListener("turbo:load", unmountOnLeave, { once: true })
    })
  })
}

export default loader
