import { Controller } from "@hotwired/stimulus"
import { buildUrl } from "../utils/urls"
import { insertOverlayIntoDOM, removeOverlayFromDOM } from "../utils/overlay"
import { debounce } from "lodash"

const DISCOVER_SEARCH_BASE_URL = "/discover/search"

interface FrameElement extends HTMLElement {
  src: string
}

// Connects to data-controller="discover-search"
export default class extends Controller {
  /* Targets */
  static targets = ["resultsFrame", "input", "option", "selection", "icon", "searchGlassIcon", "clearInputIcon"]

  resultsFrameTarget: FrameElement
  inputTarget: HTMLInputElement
  selectionTarget: HTMLInputElement
  iconTargets: [HTMLDivElement]
  optionTargets: [HTMLLIElement]
  hasResultsFrameTarget: boolean
  hasSelectionTarget: boolean
  hasInputTarget: boolean
  searchGlassIconTarget: SVGAElement
  clearInputIconTarget: SVGAElement

  /* Values */
  static values = {
    output: String,
    orgId: String,
    portal: Boolean,
    frameId: String,
  }

  outputValue: string
  orgIdValue: string
  portalValue: boolean
  frameIdValue: string

  initialize() {
    if (this.hasSelectionTarget) {
      this.updateOutput(this.selectionTarget.value)
    }

    this.onSearch = debounce(this.onSearch.bind(this), 200)
  }

  connect() {
    document.addEventListener("click", this.onClickOutsideOfResults)

    if (this.isPortal()) {
      insertOverlayIntoDOM(this.overlayId(), "z-20 md:hidden")
      this.hideOverlay()
    }
  }

  disconnect() {
    document.removeEventListener("click", this.onClickOutsideOfResults)
    if (this.hasInputTarget) {
      this.inputTarget.value = ""
    }
    if (this.hasResultsFrameTarget) {
      this.resultsFrameTarget.src = null
      this.resultsFrameTarget.innerHTML = ""
    }

    if (this.isPortal()) {
      removeOverlayFromDOM(this.overlayId())
    }
  }

  selectionTargetConnected() {
    this.updateOutput(this.selectionTarget.value)
    this.onOptionSelect(this.selectionTarget.value)
  }

  selectionTargetDisconnected() {
    this.updateOutput("")
    this.onOptionSelect("")
  }

  onSearch(e) {
    if (e.code == "ArrowDown") {
      this.focusOption(0)
    } else if (e.code == "Enter") {
      this.focusOption(0, true)
    } else {
      const params = {
        q: this.inputTarget.value,
        org_id: this.orgIdValue,
        portal: this.portalValue,
        frame_id: this.frameIdValue,
      }

      this.resultsFrameTarget.src = buildUrl(DISCOVER_SEARCH_BASE_URL, params)
    }
  }

  onClickOutsideOfResults = (e) => {
    if (!this.hasResultsFrameTarget) {
      return false
    }

    const isClickInsideElement = this.element.contains(e.target)
    if (!isClickInsideElement) {
      this.resultsFrameTarget.src = null
      this.resultsFrameTarget.innerHTML = ""

      if (this.isPortal() && e.target !== this.inputTarget) {
        this.removeFocusStyle()
        this.hideOverlay()
      }

      this.inputTarget.value = ""
    }
  }

  focusOption(i, click = false) {
    const option = this.optionTargets[i]
    if (option) {
      option.focus()
      if (click) option.getElementsByTagName("a")[0].click()
    }
  }

  onOptionKeyUp(e) {
    if (e.code == "ArrowDown") {
      this.focusOption(e.params.id + 1)
    } else if (e.code == "ArrowUp") {
      this.focusOption(e.params.id - 1)
    } else if (e.code == "Enter") {
      e.target.getElementsByTagName("a")[0].click()
    }
  }

  updateOutput(val) {
    let outputValueElem = document.getElementById(this.outputValue)
    if (outputValueElem) {
      outputValueElem.setAttribute("value", val)
      const inputEvent = new Event("input")
      outputValueElem.dispatchEvent(inputEvent)
    }
  }

  onOptionSelect(value: string) {
    this.element.dispatchEvent(new CustomEvent("select", { detail: { value } }))
  }

  onFocus() {
    if (this.isPortal()) {
      this.addFocusStyle()
      this.showOverlay()
    }
  }

  addFocusStyle(): void {
    const inputContainer = this.inputTarget.parentElement
    const searchGlassIcon = this.searchGlassIconTarget
    const clearInputIcon = this.clearInputIconTarget

    inputContainer?.classList.add("bg-purple-100")
    searchGlassIcon?.classList.add("text-purple-500")
    clearInputIcon?.classList.add("text-purple-500")
  }

  removeFocusStyle(): void {
    const inputContainer = this.inputTarget.parentElement
    const searchGlassIcon = this.searchGlassIconTarget
    const clearInputIcon = this.clearInputIconTarget

    inputContainer?.classList.remove("bg-purple-100")
    searchGlassIcon?.classList.remove("text-purple-500")
    clearInputIcon?.classList.remove("text-purple-500")
  }

  overlayId(): string {
    const frameUuid = this.frameIdValue.split("__").at(-1)
    return `discover-search-overlay__${frameUuid}`
  }

  isPortal(): boolean {
    return this.portalValue
  }

  showOverlay(): void {
    const overlay = document.getElementById(this.overlayId())

    if (!overlay) {
      return
    }

    overlay.classList.remove("hidden")
  }

  hideOverlay(): void {
    const overlay = document.getElementById(this.overlayId())

    if (!overlay) {
      return
    }

    overlay.classList.add("hidden")
  }
}
