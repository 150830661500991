import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="wheel-scroll-disable"
export default class extends Controller {
  static targets = ["numberInput"]

  numberInputTarget: HTMLInputElement

  numberInputTargetConnected(input) {
    if (input.type === "number") {
      input.addEventListener("mousewheel", function (event) {
        this.blur()
      })
    }
  }
}
