import { Controller } from "@hotwired/stimulus"
import { show } from "../utils"

// Connects to data-controller="attachment-uploader"
export default class extends Controller {
  static targets = ["fileAttachmentField", "attachmentList"]

  fileAttachmentFieldTarget: HTMLElement
  attachmentListTarget: HTMLElement

  removeAttachment(event) {
    event.preventDefault()
    const attachment = event.target.closest(".attachment-wrapper")
    attachment.remove()
    show(this.fileAttachmentFieldTarget)
  }
}
