import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="budget-alert"
export default class extends Controller {
  static targets = ["budgetField", "updateBudgetAlert"]
  static values = {
    budget: Number,
    hasBudgetRules: Boolean,
  }

  budgetFieldTarget: HTMLInputElement
  updateBudgetAlertTarget: HTMLElement

  budgetValue: number
  hasBudgetRulesValue: boolean

  connect() {
    this.budgetFieldTarget.addEventListener("change", this.budgetFieldChanged)
  }

  private budgetFieldChanged = () => {
    if (!this.hasBudgetRulesValue) return

    const budget = this.budgetFieldTarget.value
    const budgetFormValue = parseFloat(budget) * 100

    if (budgetFormValue != this.budgetValue) {
      this.updateBudgetAlertTarget.classList.remove("hidden")
    } else {
      this.updateBudgetAlertTarget.classList.add("hidden")
    }
  }
}
