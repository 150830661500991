import { Controller } from "@hotwired/stimulus"
import { buildUrl } from "../../utils/urls"
import { debounce } from "lodash"

interface FrameElement extends HTMLElement {
  src: string
}

// Connects to data-controller="unisearch--suggestions"
export default class extends Controller {
  static targets = ["input", "resultsFrame", "option", "filterDisplay", "filterField"]
  inputTarget: HTMLInputElement
  hasInputTarget: boolean

  resultsFrameTarget: FrameElement
  hasResultsFrameTarget: boolean

  optionTargets: [HTMLLIElement]

  filterDisplayTarget: HTMLElement
  filterFieldTarget: HTMLInputElement

  static values = {
    url: String,
    possibleFilters: Array,
  }

  urlValue: string
  possibleFiltersValue: string[]

  previousQueryLength: number

  initialize(): void {
    this.suggest = debounce(this.suggest.bind(this), 200)
  }

  connect(): void {
    document.addEventListener("click", this.onClickOutsideOfResults)
    if (this.inputTarget.value) {
      // Submit the form if there is a value in the input
      this.inputTarget.form.requestSubmit()
    }

    this.previousQueryLength = 0
  }

  disconnect() {
    document.removeEventListener("click", this.onClickOutsideOfResults)
    this.clearSuggestions()
  }

  suggest(event: Event): void {
    let queryLength = this.inputTarget.value.length

    if (event.code == "ArrowDown") {
      this.focusOption(0)
    } else if (event.code == "Enter") {
      this.clearSuggestions()
    } else if (event.code == "Tab") {
      this.focusOption(0, true)
    } else if (event.code == "Semicolon") {
      this.attemptToConvertToFilter()
    } else if (event.code == "Backspace" && this.inputTarget.value == "") {
      this.removeFilter()
    } else if (queryLength == 0) {
      this.clearSuggestions()
    } else if (queryLength > 1 && queryLength != this.previousQueryLength) {
      this.previousQueryLength = queryLength
      const params = {
        query: event.target.value,
      }
      this.resultsFrameTarget.src = buildUrl(this.urlValue, params)
    }
  }

  // When a user clicks on hint, we want to add the hint to the search bar
  // This is because we want to be able to have users click on the hint to add it to their search or by typing ":"
  // This function is connected to a click event on the hint
  addFilterFromHint(e): void {
    const hintValue = (e.currentTarget as HTMLElement).dataset.hintValue || ""
    const input = this.inputTarget

    // Add the hint to the input
    input.value = `${hintValue}:`
    this.attemptToConvertToFilter()
    input.focus()
  }

  // After a user types a ":" we check to see if their input matches one of the possible filters
  // If it does, we convert their input into a filter and then reset their search starting anew
  attemptToConvertToFilter(): void {
    const query = this.inputTarget.value
    const filter = query.split(":")[0]

    // If filter is a prefix of one of the possibleFiltersValue
    let firstValidFilter = this.possibleFiltersValue.find((possibleFilter) => {
      return possibleFilter.toLowerCase().startsWith(filter.toLowerCase())
    })

    if (firstValidFilter) {
      this.inputTarget.value = query.replace(filter + ":", "")
      this.inputTarget.placeholder = "..."
      this.filterFieldTarget.value = firstValidFilter
      this.filterDisplayTarget.children[2].innerText = firstValidFilter
      this.filterDisplayTarget.classList.remove("hidden")

      // If we just added a filter, also reset the autocomplete
      this.clearSuggestions()
    }
  }

  removeFilter(): void {
    this.inputTarget.placeholder = "Search within your account"
    this.filterFieldTarget.value = ""
    this.filterDisplayTarget.classList.add("hidden")
  }

  focusOption(i, click = false): void {
    const option = this.optionTargets[i]
    if (option) {
      option.focus()
      if (click) {
        option.getElementsByTagName("a")[0].click()
        this.clearSuggestions()
      }
    }
  }

  onOptionKeyUp(e): void {
    if (e.code == "ArrowDown") {
      this.focusOption(e.params.id + 1)
    } else if (e.code == "ArrowUp") {
      this.focusOption(e.params.id - 1)
    } else if (e.code == "Enter") {
      e.target.getElementsByTagName("a")[0].click()
    }
  }

  onClickOutsideOfResults = (e) => {
    if (!this.hasResultsFrameTarget) {
      return false
    }
    const isClickInsideElement = this.element.contains(e.target)
    if (!isClickInsideElement) {
      this.clearSuggestions()

      if (!this.hasInputTarget) {
        return false
      }
    }
  }

  clearSuggestions(): void {
    if (this.hasResultsFrameTarget) {
      this.resultsFrameTarget.src = null
      this.resultsFrameTarget.innerHTML = ""
    }
  }
}
