import { Controller } from "@hotwired/stimulus"
import { Chart } from "chart.js"
import { ChartConfiguration } from "chart.js"

// Connects to data-controller="chart-dynamic"
export default class ChartDynamicController extends Controller {
  static values = {
    type: String,
    data: String,
    options: String,
  }
  typeValue: string
  dataValue: string
  optionsValue: string
  chart: Chart

  connect() {
    const data = JSON.parse(this.dataValue)
    const options = JSON.parse(this.optionsValue)
    const config = this.initializeConfig(data, options)
    if (data) {
      this.chart = new Chart(this.element as HTMLCanvasElement, config as ChartConfiguration)
    }
  }

  initializeConfig(data, options) {
    return {
      type: this.typeValue,
      data: data,
      options: options,
    }
  }
}
