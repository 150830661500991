import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"
import { debounce } from "lodash"
import { hide, isTurboPreview, show } from "../../utils"
import { useClickOutside } from "stimulus-use"
import { buildUrl } from "../../utils/urls"

// Connects to data-controller="form-editor--search"
export default class extends Controller {
  static targets = ["searchResults", "input"]
  searchResultsTarget: HTMLDivElement
  inputTarget: HTMLInputElement

  static values = { url: String }
  urlValue: string

  connect() {
    useClickOutside(this)
  }

  initialize() {
    this.onInputChange = debounce(this.onInputChange, 500)

    // Avoids content layout shift (CLS) when turbo loads a cache preview
    if (isTurboPreview()) {
      this.hideResults()
      this.inputTarget.value = ""
    }
  }

  onInputChange = (e: InputEvent) => {
    const url = buildUrl(this.urlValue, { q: e.target.value })

    get(url, { responseKind: "turbo-stream" }).then(this.showResults)
  }

  clickOutside = () => this.hideResults()

  showResults = () => show(this.searchResultsTarget)

  hideResults = () => hide(this.searchResultsTarget)
}
