import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="option-button"
export default class extends Controller {
  static targets = ["radio"]

  static values = {
    name: String,
    activeClass: {
      type: String,
      default: "",
    },
  }

  radioTarget: HTMLInputElement
  hasRadioTarget: boolean

  nameValue: string
  activeClassValue: string

  toggleActiveClassHandler = this.toggleActiveClass.bind(this)

  connect() {
    if (this.hasRadioTarget) {
      this.radioTarget.addEventListener("change", this.toggleActiveClassHandler)
    }
  }

  disconnect() {
    if (this.hasRadioTarget) {
      this.radioTarget.removeEventListener("change", this.toggleActiveClassHandler)
    }
  }

  toggleActiveClass() {
    // Removes active css class from all the radios from the same group
    document.querySelectorAll(`input[type="radio"][name="${this.nameValue}"]`).forEach((radio) => {
      radio.closest("label").classList.remove(...this.activeClassValue.split(" "))
    })
    if (this.radioTarget.checked) {
      this.activeClassValue && this.element.classList.add(...this.activeClassValue.split(" "))
    }
  }
}
