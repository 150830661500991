import { Controller } from "@hotwired/stimulus"
import { v4 as uuidv4 } from "uuid"

// Connects to data-controller="duplicate-template"
export default class extends Controller {
  static targets: string[] = ["template", "wrapper"]
  templateTarget: HTMLElement
  wrapperTarget: HTMLElement

  static values = {
    containsSupplierSelect: Boolean,
    exisitingTemplatesCount: Number,
  }
  containsSupplierSelectValue: boolean
  exisitingTemplatesCountValue: number

  index = 0

  connect(): void {
    this.index = this.exisitingTemplatesCountValue

    // render initial template
    if (this.exisitingTemplatesCountValue === 0) {
      this.add()
    }

    // initialize logic to hide/show delete button
    this.toggleDeleteButtonVisibility()
  }

  toggleDeleteButtonVisibility(): void {
    const deleteButtons = document.getElementsByName("delete-button")
    const rows = document.querySelectorAll(".supplier_row_wrapper")

    if (rows.length > 1) {
      deleteButtons.forEach((button) => button.classList.remove("hidden"))
    } else {
      deleteButtons.forEach((button) => button.classList.add("hidden"))
    }
  }

  add(e?: Event): void {
    e?.preventDefault()

    const template = this.replaceStockElementIds()
    this.wrapperTarget.innerHTML += template
    this.index++

    this.toggleDeleteButtonVisibility()
  }

  remove(e: Event): void {
    e.preventDefault()

    const supplierRowWrapper = (e.target as HTMLElement).closest(".supplier_row_wrapper")

    if (supplierRowWrapper) {
      supplierRowWrapper.remove()
    }
    this.toggleDeleteButtonVisibility()
  }

  replaceStockElementIds(): string {
    const clonedTemplate = this.templateTarget.cloneNode(true) as HTMLElement
    const templateWithID = clonedTemplate.innerHTML.replace(/INDEX/g, this.index.toString())

    if (this.containsSupplierSelectValue) {
      const uniqueFrameID = uuidv4()
      const templateWithFrameUUID = templateWithID.replace(/__TEMPLATE/g, uniqueFrameID)

      return templateWithFrameUUID
    }

    return templateWithID
  }
}
