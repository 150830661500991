import { Controller } from "@hotwired/stimulus"
import { show, hide } from "../utils"

// Connects to data-controller="nonlinear-task-rules-form"
export default class extends Controller {
  static outlets = ["nonlinear-task-rule-field"]

  static targets = ["rulesToggleConditionally", "rulesForm"]
  rulesToggleConditionallyTarget: HTMLInputElement
  rulesFormTarget: HTMLFormElement

  connect() {
    this.toggleRules()
  }

  toggleRules(): void {
    const rulesEnabled = this.rulesToggleConditionallyTarget.checked
    if (rulesEnabled) {
      show(this.rulesFormTarget)
    } else {
      hide(this.rulesFormTarget)
    }

    this.nonlinearTaskRuleFieldOutlets.forEach((outlet) => {
      outlet.toggleRules(rulesEnabled)
    })
  }
}
