import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="redirect"
export default class extends Controller {
  static targets = ["form"]
  formTarget: HTMLFormElement

  connect() {
    this.formTarget.submit()
  }
}
