export class NestedFormSyncer {
  private form: HTMLFormElement

  static call = (form: HTMLFormElement) => {
    new NestedFormSyncer(form).call()
  }

  private call = () => {
    window.dispatchEvent(new CustomEvent("INIT_COMMENT_FORM_SYNC"))
    this.syncNode("comment-mentioned-user-ids")
  }

  private commentFormFieldValue = (id: string): string => {
    return (document.getElementById(id) as HTMLInputElement)?.value ?? ""
  }

  private syncNode = (id: string): void => {
    const value = this.commentFormFieldValue(id)
    const newNode = document.createElement("input")

    newNode.setAttribute("type", "hidden")
    newNode.setAttribute("name", id.replace(/-/g, "_"))
    newNode.setAttribute("value", value)
    this.form.appendChild(newNode)
  }

  private constructor(form: HTMLFormElement) {
    this.form = form
  }
}
