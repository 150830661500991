import MultiCollapseController from "./main_multi_collapse_controller"

// Connects to data-controller="sub-multi-collapse"
export default class extends MultiCollapseController {
  static targets = ["groupHeader"]
  groupHeaderTarget: HTMLDivElement

  openAllSections(event, parentOrder = false) {
    this.callOnAllSections(event, "open", parentOrder)
  }

  closeAllSections(event, parentOrder = false) {
    this.callOnAllSections(event, "close", parentOrder)
  }

  callOnAllSections(event, functionName, parentOrder = false) {
    const collapseController = this.application.getControllerForElementAndIdentifier(this.groupHeaderTarget, "collapse")

    if (functionName == "open" || parentOrder) {
      collapseController[functionName]()
    } else if (functionName == "close" && !parentOrder) {
      collapseController.open()
    }

    if (!(functionName == "close" && parentOrder)) {
      super.callOnAllSections(event, functionName)
    }
  }
}
