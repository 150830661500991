import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"
import { show, hide } from "../../utils"
import { buildUrl } from "../../utils/urls"

// Connects to data-controller="request-form-editor--toolbar"
export default class extends Controller {
  static targets = [
    "checkbox",
    "toolbar",
    "toolbarContainer",
    "selectedFieldCount",
    "selectedFieldCountContainer",
    "editButton",
    "clearButton",
    "fieldContainer",
  ]
  checkboxTargets: HTMLInputElement[]
  toolbarTarget: HTMLTemplateElement
  toolbarContainerTargets: HTMLDivElement[]
  selectedFieldCountTarget: HTMLSpanElement
  selectedFieldCountContainerTarget: HTMLLIElement
  editButtonTarget: HTMLLIElement
  clearButtonTarget: HTMLLIElement
  fieldContainerTargets: HTMLDivElement[]

  static values = {
    deleteUrl: String,
    copyAndPasteUrl: String,
    cutAndPasteUrl: String,
  }
  deleteUrlValue: string
  copyAndPasteUrlValue: string
  cutAndPasteUrlValue: string

  selectedFieldIds: string[] = []

  // bind() creates a new function with the given context, we need to store the
  // reference of that function so we can then remove the listener
  urlHashChangeHandler = this.handleURLHashChange.bind(this)

  connect() {
    window.addEventListener("hashchange", this.urlHashChangeHandler)

    this.toggleSearchResultToolbar(window.location.hash.slice(1))
  }

  disconnect() {
    window.removeEventListener("hashchange", this.urlHashChangeHandler)
  }

  toggleSelectedFieldBackground(): void {
    this.checkboxTargets.forEach(({ value, checked }) => {
      const fieldContainer = this.fieldContainerTargets.find(({ id }) => id === `field-container_${value}`)

      if (checked) {
        fieldContainer?.classList.add("bg-purple-100")
      } else {
        fieldContainer?.classList.remove("bg-purple-100")
      }
    })
  }

  setSelectedFieldIds(): void {
    this.selectedFieldIds = this.checkboxTargets.filter(({ checked }) => checked).map(({ value }) => value)
  }

  handleURLHashChange(e: HashChangeEvent) {
    const url = new URL(e.newURL)

    this.toggleSearchResultToolbar(url.hash.slice(1))
  }

  // Opens the toolbar from the field related to a search result.
  // This can be triggered from a search result link or from a `#`
  // change in the URL (when the search result is on the current page).
  toggleSearchResultToolbar(formFieldId: string) {
    if (formFieldId.length == 0) return

    const checkbox = this.checkboxTargets.find((el) => el.value === formFieldId)
    if (!checkbox) return

    this.checkboxTargets.forEach((el) => (el.checked = false))
    checkbox.checked = true
    this.toggleToolbar()
  }

  toggleToolbar(): void {
    this.setSelectedFieldIds()
    this.toggleSelectedFieldBackground()

    const firstSelectFieldId = this.selectedFieldIds.at(0)
    const firstSelectField = this.toolbarContainerTargets.find(
      ({ id }) => id === `toolbar-container_${firstSelectFieldId}`,
    )

    // rm the toolbar from DOM before moving it another elm
    this.removeToolbar()

    if (firstSelectField) {
      const toolbar = this.toolbarTarget.content.cloneNode(true)
      firstSelectField.append(toolbar)

      this.setSelectedFieldCount()

      this.toggleTargetVisibility(this.editButtonTarget)
      this.toggleTargetVisibility(this.clearButtonTarget, true)
      this.toggleTargetVisibility(this.selectedFieldCountContainerTarget, true)
    }
  }

  removeToolbar(): void {
    const toolbar = document.getElementById("request-form-editor-toolbar")
    toolbar?.remove()
  }

  setSelectedFieldCount(): void {
    this.selectedFieldCountTarget.innerText = this.selectedFieldIds.length.toString()
  }

  toggleTargetVisibility(actionTarget: HTMLElement, bulkAction: boolean = false): void {
    const hasHiddenClass = actionTarget.classList.contains("hidden")
    const shouldShowTarget = bulkAction
      ? this.selectedFieldIds.length > 1 && hasHiddenClass
      : this.selectedFieldIds.length === 1 && hasHiddenClass

    if (shouldShowTarget) {
      show(actionTarget)
    } else {
      hide(actionTarget)
    }
  }

  getIdParams(): { id: string } {
    return { id: this.selectedFieldIds.join(",") }
  }

  // toolbar actions
  clearSelectedFormFields(): void {
    this.checkboxTargets.forEach((checkbox) => (checkbox.checked = false))
    this.toggleToolbar()
  }

  delete(): void {
    const deleteUrlWithParams = buildUrl(this.deleteUrlValue, this.getIdParams())

    get(deleteUrlWithParams, { responseKind: "turbo-stream" })
  }

  copyAndPaste(): void {
    const copyAndPasteUrlWithParams = buildUrl(this.copyAndPasteUrlValue, this.getIdParams())

    get(copyAndPasteUrlWithParams, { responseKind: "turbo-stream" })
  }

  cutAndPaste(): void {
    const cutAndPasteUrlWithParams = buildUrl(this.cutAndPasteUrlValue, this.getIdParams())

    get(cutAndPasteUrlWithParams, { responseKind: "turbo-stream" })
  }
}
