import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="countable-children"
export default class extends Controller {
  static targets = ["count"]

  countTarget: HTMLElement

  updateCount({ detail: customEvent }) {
    const childCount = customEvent.overflowChildren.length
    this.countTarget.innerText = childCount.toString()
  }
}
