import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="styled-select"
export default class extends Controller {
  static targets = [
    "dateTimeWarning",
    "targetSignatureDate",
    "unsnoozeAtField",
    "unsnoozeDateField",
    "unsnoozeHourField",
  ]

  dateTimeWarningTarget: HTMLDivElement
  targetSignatureDateTarget: HTMLInputElement
  unsnoozeAtFieldTarget: HTMLInputElement
  unsnoozeDateFieldTarget: HTMLInputElement
  unsnoozeHourFieldTarget: HTMLInputElement

  connect = () => {
    this.unsnoozeDateFieldTarget.addEventListener("change", this.syncUnsnoozeAtFieldValue)
    this.unsnoozeHourFieldTarget.addEventListener("change", this.syncUnsnoozeAtFieldValue)
  }

  disconnect = () => {
    this.unsnoozeDateFieldTarget.removeEventListener("change", this.syncUnsnoozeAtFieldValue)
    this.unsnoozeHourFieldTarget.removeEventListener("change", this.syncUnsnoozeAtFieldValue)
  }

  syncUnsnoozeAtFieldValue = () => {
    if (!this.unsnoozeDateFieldTarget.value || !this.unsnoozeHourFieldTarget.value) return

    const date = this.unsnoozeDateFieldTarget.value
    const hour = this.unsnoozeHourFieldTarget.value.toString().padStart(2, "0")
    const dateTime = new Date(`${date}T${hour}:00:00.000`)
    this.unsnoozeAtFieldTarget.value = dateTime.toISOString()

    this.validateUnsnoozeDateTime()
  }

  toggleUnsnoozeDateTimeWarning = (showWarning: boolean) => {
    showWarning
      ? this.dateTimeWarningTarget.classList.remove("hidden")
      : this.dateTimeWarningTarget?.classList.add("hidden")
  }

  validateUnsnoozeDateTime = () => {
    if (!this.targetSignatureDateTarget.value) return

    const snoozeDate = new Date(this.unsnoozeAtFieldTarget.value)
    const dueDate = new Date(this.targetSignatureDateTarget.value)

    this.toggleUnsnoozeDateTimeWarning(snoozeDate > dueDate)
  }
}
