import { Controller } from "@hotwired/stimulus"

TRANSITION_DURATION = 500

/* Connects to data-controller="canopy--panel-accordion-component"
 */
export default class extends Controller {
  element: HTMLDetailsElement
  heightIsSet: boolean = false

  connect = () => {
    this.element.addEventListener("toggle", this.adjustContentHeightToFillPanel)
    this.adjustContentHeightToFillPanel()
  }

  disconnect = () => {
    this.element.removeEventListener("toggle", this.adjustContentHeightToFillPanel)
  }

  adjustContentHeightToFillPanel = () => {
    if (!this.element.open || this.heightIsSet) return

    setTimeout(() => {
      const summary = this.element.querySelector("summary") as HTMLElement | undefined
      const content = this.element.querySelector(".panel_accordion_content") as HTMLDivElement

      const summaryHeight = summary?.offsetHeight ?? 0
      content.style.height = `calc(100% - ${summaryHeight}px)`

      this.heightIsSet = true
    }, TRANSITION_DURATION)
  }
}
