import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="workflows--legacy-new-workflow-form"
export default class extends Controller {
  static targets = ["workflowTemplateTypeSelect", "templateWorkflowSelectContainer"]
  workflowTemplateTypeSelectTarget: HTMLSelectElement
  templateWorkflowSelectContainerTarget: HTMLDivElement

  connect() {}

  onWorkflowTemplateTypeSelected(): void {
    if (this.getSelectCurrentValue(this.workflowTemplateTypeSelectTarget) === "existing") {
      this.templateWorkflowSelectContainerTarget.classList.remove("hidden")
    } else {
      this.templateWorkflowSelectContainerTarget.classList.add("hidden")
    }
  }

  getSelectCurrentValue(selectElement) {
    return selectElement.options[selectElement.selectedIndex].value
  }
}
