import { Controller } from "@hotwired/stimulus"
import { showShallowModeModal } from "../utils"

// Connects to data-controller="contracted-spend-fields"
export default class extends Controller {
  static targets = ["confirmTurnOffCustomSpendModalWrapper", "customSpendDistributionField"]
  confirmTurnOffCustomSpendModalWrapperTarget: HTMLDivElement
  customSpendDistributionFieldTarget: HTMLInputElement

  toggleCustomSpendDistribution(event: Event) {
    if (!event.target.checked) {
      // Leave checkbox in a checked state until user confirms
      event.target.checked = true

      if (this.hasConfirmTurnOffCustomSpendModalWrapperTarget) {
        showShallowModeModal(this.confirmTurnOffCustomSpendModalWrapperTarget, this.application)
      }
    } else {
      this.setCustomSpendDistribution("true")
    }
  }

  confirmTurnOffCustomSpendDistribution() {
    this.setCustomSpendDistribution("false")
  }

  setCustomSpendDistribution(newValue: string) {
    this.customSpendDistributionFieldTarget.value = newValue
    this.customSpendDistributionFieldTarget.dispatchEvent(new Event("change", { bubbles: true }))
  }
}
