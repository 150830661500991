import { Controller } from "@hotwired/stimulus"
import { useResize } from "stimulus-use"

// Connects to data-controller="collapse-more-menu"
export default class extends Controller {
  static targets = ["menuToggle", "visibleList", "overflowList"]
  static values = {
    breakpoints: {
      type: Array,
      default: [],
    },
  }

  menuToggleTarget: Element
  visibleListTarget: Element
  overflowListTarget: Element

  hasMenuToggleTarget: boolean
  hasVisibleListTarget: boolean
  hasOverflowListTarget: boolean

  breakpointsValue: number[]

  connect() {
    useResize(this)
  }

  resize({ width }) {
    this.toggleOverflowChildren({ width })
  }

  toggleOverflowChildren({ width: containerWidth }) {
    const { visibleListWidth, totalAvailableWidth } = this.calcWidths({ containerWidth })

    if (visibleListWidth > totalAvailableWidth) {
      // Move the last child to the overflow list
      this.showMenuTarget()

      this.setCalculatedBreakpointValue(containerWidth)

      const visibleChildren = this.visibleListTarget.children

      const breakingChild = visibleChildren[visibleChildren.length - 1]

      this.overflowListTarget.appendChild(breakingChild)

      this.dispatchOverflowEvent(breakingChild)
    } else {
      // Check if there is room to show more children
      if (totalAvailableWidth > this.breakpointsValue[this.breakpointsValue.length - 1]) {
        const overflowChildren = Array.from(this.overflowListTarget.children)
        const lastChildToBeBroken = overflowChildren[overflowChildren.length - 1]

        this.visibleListTarget.appendChild(lastChildToBeBroken)

        this.breakpointsValue = [...this.breakpointsValue].slice(0, -1)

        this.dispatchOverflowEvent(lastChildToBeBroken)
      }

      if (this.breakpointsValue.length === 0) {
        this.hideMenuTarget()
      }
    }

    const { visibleListWidth: newVisibleListWidth, totalAvailableWidth: newTotalWidth } = this.calcWidths({
      containerWidth,
    })

    if (newVisibleListWidth > newTotalWidth) {
      this.toggleOverflowChildren({ width: containerWidth })
    }
  }

  // Dispatch an event that the overflow list has been updated
  dispatchOverflowEvent(target: Element) {
    this.dispatch("overflowChild", {
      target: target,
      detail: { overflowChildren: Array.from(this.overflowListTarget.children) },
    })
  }

  calcWidths({ containerWidth }) {
    const menuButtonWidth = this.hasMenuToggleTarget ? this.menuToggleTarget.getBoundingClientRect().width : 0
    const visibleListWidth = this.visibleListTarget.getBoundingClientRect().width

    const totalAvailableWidth =
      this.isMenuToggleHidden || this.allChildrenVisible ? containerWidth : containerWidth - menuButtonWidth

    return {
      menuButtonWidth,
      visibleListWidth,
      totalAvailableWidth,
    }
  }

  get isMenuToggleHidden() {
    return this.hasMenuToggleTarget ? this.menuToggleTarget.classList.contains("invisible") : true
  }

  get allChildrenVisible() {
    return this.overflowListTarget.children.length === 0
  }

  setCalculatedBreakpointValue(containerWidth) {
    const { visibleListWidth, menuButtonWidth } = this.calcWidths({ containerWidth })
    if (this.breakpointsValue.length > 0) {
      this.breakpointsValue = [...this.breakpointsValue, Math.floor(visibleListWidth)]
    } else {
      const calculatedPoint = visibleListWidth - menuButtonWidth
      this.breakpointsValue = [Math.floor(calculatedPoint)]
    }
  }

  showMenuTarget() {
    if (this.hasMenuToggleTarget) {
      this.menuToggleTarget.classList.remove("invisible")
    }
    if (this.hasOverflowListTarget) {
      this.overflowListTarget.classList.remove("hidden")
    }
  }

  hideMenuTarget() {
    if (this.hasMenuToggleTarget) {
      this.menuToggleTarget.classList.add("invisible")
    }
    if (this.hasOverflowListTarget) {
      this.overflowListTarget.classList.add("hidden")
    }
  }
}
