import NestedForm from "stimulus-rails-nested-form"

// Connects to data-controller="nested-form"
// This controller extends the base NestedForm controller from the
// `stimulus-rails-nested-form` package and overrides the existing
// `add` function so that we can inject additional data.
export default class extends NestedForm {
  static targets = ["wrapper", "target", "template"]
  templateTarget: HTMLElement
  targetTarget: HTMLElement
  wrapperTarget: HTMLElement

  add(e?: Event) {
    e?.preventDefault()

    const id = new Date().getTime().toString()
    const contentWithId: string = this.templateTarget.innerHTML.replace(/NEW_FORM_WRAPPER/g, id)
    const content: string = contentWithId.replace(/NEW_RECORD/g, id)
    this.targetTarget.insertAdjacentHTML("beforebegin", content)
    this.dispatch("add")
  }

  remove(e?: Event) {
    super.remove(e)

    this.dispatch("remove")
  }

  addMultiple({ detail: { numPeriods } }): void {
    for (let i = 0; i < numPeriods; i++) {
      this.add()
    }
  }
}
