import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"
import { buildUrl } from "../utils/urls"

// Connects to data-controller="category-select"
export default class extends Controller {
  categorySelectTarget: HTMLSelectElement

  subCategorySelectTarget: HTMLSelectElement

  static targets: string[] = ["categorySelect", "subCategorySelect"]

  static values = {
    basePath: String,
  }
  basePathValue: string

  change() {
    let category_id = this.categorySelectTarget.selectedOptions[0].value
    let target = this.subCategorySelectTarget.id

    const params = {
      target: target,
      category_id: category_id,
    }

    let url = buildUrl(`${this.basePathValue}/subcategories`, params)

    get(url, {
      responseKind: "turbo-stream",
    })

    this.subCategorySelectTarget.disabled = false
  }
}
