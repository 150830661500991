import { Controller } from "@hotwired/stimulus"
import { hide, show } from "../utils"

// Connects to data-controller="import-column"
export default class extends Controller {
  static values = {
    state: { type: String, default: "display" },
    emptyColumn: Boolean,
  }
  stateValue: string

  static targets = ["displayWrapper", "inputWrapper", "previewWrapper", "input", "tooltipWrapper", "pseudoInput"]
  displayWrapperTarget: HTMLElement
  previewWrapperTarget: HTMLElement
  hasPreviewWrapperTarget: Boolean
  inputWrapperTarget: HTMLElement
  inputTarget: HTMLInputElement
  pseudoInputTarget: HTMLInputElement

  inputInitialValue: string
  emptyColumnValue: Boolean

  _popperController: Controller

  get popperController() {
    // this is lazily evaluated to account for unpredictable stratus controller wiring
    if (this._popperController === undefined) {
      this._popperController = this.application.getControllerForElementAndIdentifier(this.inputWrapperTarget, "popper")
    }
    return this._popperController
  }

  connect() {
    if (!this.hasPreviewWrapperTarget) {
      return
    }

    this.inputInitialValue = this.inputTarget?.value

    this.element.addEventListener("mouseenter", this.onMouseEnter)
    this.element.addEventListener("mouseleave", this.onMouseLeave)
    this.element.addEventListener("click", this.onClick)
    this.inputTarget.addEventListener("blur", this.onInputExit)
  }

  disconnect() {
    if (!this.hasPreviewWrapperTarget) {
      return
    }

    this.element.removeEventListener("mouseenter", this.onMouseEnter)
    this.element.removeEventListener("mouseleave", this.onMouseLeave)
    this.element.removeEventListener("click", this.onClick)
    this.inputTarget.removeEventListener("blur", this.onInputExit)
  }

  stateValueChanged(value) {
    if (!this.hasPreviewWrapperTarget) {
      return
    }

    if (value === "display") {
      show(this.displayWrapperTarget)
    } else {
      hide(this.displayWrapperTarget)
    }

    if (value === "preview") {
      show(this.previewWrapperTarget)
    } else {
      hide(this.previewWrapperTarget)
    }

    if (value === "input") {
      this.inputTarget.disabled = false
      show(this.inputWrapperTarget)
      this.popperController?.show()
      this.inputTarget.focus()
    } else {
      hide(this.inputWrapperTarget)
      this.inputTarget.disabled = true
    }
  }

  resumeSupplierSelection(ev) {
    ev.stopPropagation()
    ev.preventDefault()
    this.popperController.show()
  }

  cancelSupplierSelection(ev) {
    ev.stopPropagation()
    ev.preventDefault()
    this.popperController.hide()
    this.stateValue = "display"
    this.dispatch("hideInput", { target: this.inputTarget })
  }

  acceptSupplierSelection(ev) {
    ev.stopPropagation()
    ev.preventDefault()
    let resultDiv = this.inputWrapperTarget.querySelector("div.supplier-search")
    this.pseudoInputTarget.innerText = resultDiv ? resultDiv.innerText : "Not Set"
    this.popperController.hide()
  }

  anyToInput() {
    this.stateValue = "input"
    this.dispatch("showInput", { target: this.inputTarget })
  }
  displayToPreview() {
    if (this.stateValue === "display") this.stateValue = "preview"
  }
  previewToDisplay() {
    if (this.stateValue === "preview") this.stateValue = "display"
  }
  inputToDisplay() {
    if (this.stateValue === "input" && !this.isInputChanged()) {
      this.stateValue = "display"
      this.dispatch("hideInput", { target: this.inputTarget })
    }
  }

  isInputChanged() {
    let inputValue = this.inputTarget?.value
    let startedNonEmptyAndIsNowEmpty = !this.emptyColumnValue && (inputValue === undefined || inputValue === "")
    return startedNonEmptyAndIsNowEmpty || inputValue !== this.inputInitialValue
  }

  onMouseEnter = this.displayToPreview.bind(this)
  onMouseLeave = this.previewToDisplay.bind(this)
  onClick = this.anyToInput.bind(this)
  onInputExit = this.inputToDisplay.bind(this)
}
