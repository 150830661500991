import { Controller } from "@hotwired/stimulus"
import Sortable from "sortablejs"
import { put } from "@rails/request.js"

// Connects to data-controller="form-editor--question-sort"
export default class extends Controller {
  private sortable: Sortable

  static targets = ["sortableContainer"]
  sortableContainerTarget: HTMLDivElement

  static values = { updateUrl: String, displayOrder: Array }
  updateUrlValue: String

  connect() {
    this.sortable = Sortable.create(this.sortableContainerTarget, {
      handle: ".draggable",
      ghostClass: "invisible",
      forceFallback: true,
      animation: 250,
      onStart: (e) => {
        e.target.classList.add("cursor-grabbing")
      },
      onEnd: (e) => {
        e.target.classList.remove("cursor-grabbing")
        if (e.oldIndex !== e.newIndex) this.persistDisplayOrder()
      },
    })
  }

  disconnect() {
    this.sortable = undefined
  }

  getOrderedFormFieldKeys = () => Array.from(this.sortableContainerTarget.children).map((el) => el.dataset.key)

  persistDisplayOrder = () => {
    put(this.updateUrlValue, {
      body: { display_order: this.getOrderedFormFieldKeys() },
      responseKind: "turbo-stream",
    })
  }
}
