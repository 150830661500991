import { Controller } from "@hotwired/stimulus"
import { hide, disable, enable, show } from "../utils"

// Connects to data-controller="coupa-phone-widget"
export default class extends Controller {
  static targets = ["countryRegion", "defaultCountryCode", "phoneTypeOther", "areaCode", "number"]
  static values = { isOtherCountry: Boolean }

  isOtherCountryValue: boolean
  phoneTypeOtherTarget: HTMLInputElement
  countryRegionTarget: HTMLInputElement
  defaultCountryCodeTarget: HTMLInputElement
  areaCodeTarget: HTMLInputElement
  numberTarget: HTMLInputElement

  connect() {
    this.isOtherCountryValue = this.phoneTypeOtherTarget.checked
  }

  toggle() {
    this.isOtherCountryValue = this.phoneTypeOtherTarget.checked
  }

  isOtherCountryValueChanged() {
    const countryRegionInput = this.countryRegionTarget.querySelector("input")
    if (this.isOtherCountryValue) {
      show(this.countryRegionTarget)
      enable(this.countryRegionTarget)
      enable(countryRegionInput)
      disable(this.defaultCountryCodeTarget)
      this.defaultCountryCodeTarget.value = ""
    } else {
      hide(this.countryRegionTarget)
      disable(this.countryRegionTarget)
      disable(countryRegionInput)
      enable(this.defaultCountryCodeTarget)
      this.conditionallySetDefaultCountryCode()
    }
  }

  conditionallySetDefaultCountryCode() {
    if (this.isOtherCountryValue) {
      return
    }

    // When in US-Canada mode, set the value to '1' if phone number is present
    if (this.areaCodeTarget.value || this.numberTarget.value) {
      this.defaultCountryCodeTarget.value = "1"
    } else {
      this.defaultCountryCodeTarget.value = ""
    }
  }
}
