import { Controller } from "@hotwired/stimulus"
import { swapClass } from "../utils"

// Connects to data-controller="collapsible-sidebar"
export default class CollapsibleSidebarController extends Controller {
  static targets = ["wrapper", "spanHolder", "spanTop", "spanBottom"]

  static values = {
    // Controls either the sidebar is rendered on the left or right on the screen
    position: {
      type: String,
      default: "left",
    },
    // Allow/disallow to collapse
    allowCollapsing: {
      type: Boolean,
      default: true,
    },
    // If true, renders the sidebar closed by default
    renderCollapsed: {
      type: Boolean,
      default: false,
    },
    // Auto-closes the sidebar if window width is lesser than this value
    autoCollapseWidthThreshold: {
      type: Number,
      default: 1200,
    },
    // If a value is set, will save the sidebar state in the local storage
    localStorageKey: {
      type: String,
      default: "",
    },
    // Classes applied when the sidebar is expanded
    widthClass: {
      type: String,
      default: "min-w-[250px] w-[250px]",
    },
    // Classes applied when the sidebar is collapsed
    collapsedWidthClass: {
      type: String,
      default: "min-w-[72px] w-[72px]",
    },
  }

  renderCollapsedValue: boolean
  allowCollapsingValue: boolean
  autoCollapseWidthThresholdValue: number
  localStorageKeyValue: string
  widthClassValue: string
  collapsedWidthClassValue: string

  wrapperTarget: HTMLElement
  hasWrapperTarget: boolean

  resizeHandler = this.resize.bind(this)
  toggleHandler = this.toggle.bind(this)

  connect(): void {
    if (this.hasWrapperTarget) {
      this.initializeSidebarState()
      window.addEventListener("resize", this.resizeHandler)
      window.addEventListener("CollapsibleSidebar:toggle", this.toggleHandler)
    }
  }

  disconnect(): void {
    window.removeEventListener("resize", this.resizeHandler)
    window.removeEventListener("CollapsibleSidebar:toggle", this.toggleHandler)
  }

  // Toggles the sidebar
  toggle(): void {
    if (this.isCollapsed()) {
      this.expand()
    } else {
      this.collapse()
    }

    if (this.shouldPreserveState()) {
      this.saveSidebarState()
    }
  }

  // Return the current state of the sidebar
  isCollapsed(): boolean {
    return this.wrapperTarget.classList.contains("collapsed")
  }

  // Expands the sidebar
  expand(): void {
    this.wrapperTarget.classList.remove("collapsed")
    this.wrapperTarget.classList.add("expanded")
    swapClass(this.wrapperTarget, this.collapsedWidthClassValue, this.widthClassValue)
    window.dispatchEvent(
      new CustomEvent("collapsible-sidebar:expanded", {
        detail: { sidebar: this.element, isCollapsed: this.isCollapsed() },
        bubbles: true,
        cancelable: false,
      }),
    )
  }

  // Collapses the sidebar
  collapse(): void {
    if (!this.allowCollapsingValue) {
      return
    }

    this.wrapperTarget.classList.remove("expanded")
    this.wrapperTarget.classList.add("collapsed")
    swapClass(this.wrapperTarget, this.widthClassValue, this.collapsedWidthClassValue)
    window.dispatchEvent(
      new CustomEvent("collapsible-sidebar:collapsed", {
        detail: { sidebar: this.element, isCollapsed: this.isCollapsed() },
        bubbles: true,
        cancelable: false,
      }),
    )
  }

  private initializeSidebarState(): void {
    if (this.shouldAutoCollapseSidebar()) {
      if (!this.isCollapsed()) {
        this.collapse()
      }
    } else {
      if (this.shouldPreserveState()) {
        this.setSidebarStateBasedOnLocalStorage()
      } else {
        this.expand()
      }
    }
  }

  // Handles the resize event and collapses the sidebar if the window is too small
  private resize(): void {
    if (this.isWindowTooSmall()) {
      this.collapse()
    } else {
      this.expand()
    }
  }

  private shouldPreserveState(): boolean {
    return !!this.localStorageKeyValue
  }

  private shouldAutoCollapseSidebar(): boolean {
    return this.allowCollapsingValue && (this.renderCollapsedValue || this.isWindowTooSmall())
  }

  // Sets the sidebar state based on local storage
  private setSidebarStateBasedOnLocalStorage(): void {
    const storedState = localStorage.getItem(this.localStorageKeyValue)
    if (storedState === "collapsed") {
      this.collapse()
    } else {
      this.expand()
    }
  }

  // Saves the sidebar state to local storage
  private saveSidebarState(): void {
    const sidebarState = this.isCollapsed() ? "collapsed" : "expanded"
    localStorage.setItem(this.localStorageKeyValue, sidebarState)
  }

  private isWindowTooSmall(): boolean {
    if (!this.autoCollapseWidthThresholdValue) {
      return false
    }

    return window.innerWidth < this.autoCollapseWidthThresholdValue
  }
}
