import { Controller } from "@hotwired/stimulus"
import { show, hide } from "../utils"
const OTHER_OPTION = "Other"

export default class extends Controller {
  static targets = ["customPronounsField", "pronounsSelectField"]
  customPronounsFieldTarget: HTMLElement
  pronounsSelectFieldTarget: HTMLElement

  static values = { customPronounsField: String }
  customPronounsFieldValue: String

  connect() {
    if (this.customPronounsFieldValue) {
      show(this.customPronounsFieldTarget)
      this.customPronounsFieldTarget.querySelector("input")?.setAttribute("value", this.customPronounsFieldValue)
      this.pronounsSelectFieldTarget.querySelector(`option[value='${OTHER_OPTION}']`).selected = true
    }
  }

  onChange(e: Event): void {
    if (e.target.value === OTHER_OPTION) {
      show(this.customPronounsFieldTarget)
    } else {
      hide(this.customPronounsFieldTarget)
    }
  }
}
