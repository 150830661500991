import { Controller } from "@hotwired/stimulus"
import { patch } from "@rails/request.js"
import { showShallowModeModal } from "../utils"

// Connects to data-controller="exclude-document"
export default class extends Controller {
  static targets = ["confirmExcludeDocumentWrapper", "exclusionToggle", "autoSaveStatus"]

  static values = {
    alreadyExcluded: Boolean,
  }

  confirmExcludeDocumentWrapperTarget: HTMLDivElement
  exclusionToggleTarget: HTMLDivElement
  autoSaveStatusTarget: HTMLElement

  alreadyExcludedValue: boolean

  onExclusionChange(e) {
    if (e.target.checked) {
      showShallowModeModal(this.confirmExcludeDocumentWrapperTarget, this.application)
    } else if (this.alreadyExcludedValue) {
      this.saveProcessingStatus()
      this.alreadyExcludedValue = false
    }
  }

  saveProcessingStatus() {
    window.dispatchEvent(
      new CustomEvent("AutoSave:save", {
        detail: { param: "exclude", value: "false" },
      }),
    )
  }

  revertDocumentExclusion() {
    this.exclusionToggleTarget.click()
  }
}
