import { Controller } from "@hotwired/stimulus"

export type CommentWordChangeDetails = {
  internal?: boolean
  word: string
}

// Connects to data-controller="comment-form-mentions-box"
export default class extends Controller {
  MAX_VISIBLE_ELEMENTS = 6

  static targets = ["usersList", "usersListItem", "contentField"]
  usersListTarget: HTMLDivElement
  usersListItemTargets: HTMLLIElement[]
  contentFieldTarget: HTMLInputElement
  visibleElements: HTMLLIElement[]
  selectedElement: HTMLLIElement
  isUserListVisible: boolean

  connect = () => {
    this.usersListItemTargets.forEach((li) => {
      li.addEventListener("click", this.onUserSelected)
    })
    this.contentFieldTarget.addEventListener("keydown", this.onKeyPressed)
  }

  disconnect = () => {
    this.usersListItemTargets.forEach((li) => {
      li.removeEventListener("click", this.onUserSelected)
    })
    this.contentFieldTarget.removeEventListener("keydown", this.onKeyPressed)
  }

  filteredUsersList = (searchText: string, internal: boolean) => {
    const visibleElements: HTMLLIElement[] = []

    this.usersListItemTargets.forEach((li: HTMLLIElement) => {
      if (this.isAMatch(li, searchText, internal)) {
        li.classList.remove("hidden")
        this.limitListToMaxSix(visibleElements, li)
        return
      }

      li.classList.add("hidden")
    })

    this.visibleElements = visibleElements
  }

  getMention = (word: string): string | undefined => {
    const firstCharacter = word.charAt(0)
    const mentioningSomeone = firstCharacter === "@"

    return mentioningSomeone ? word.substring(1) : undefined
  }

  getUsersName = (li: HTMLLIElement): string => li.dataset.fullName.replace(" ", "")

  hideMentionsBox() {
    this.usersListTarget.classList.add("hidden")
    this.isUserListVisible = false
  }

  isAMatch = (element: HTMLLIElement, searchText: string, internal: boolean): boolean => {
    return (
      this.passesInternalFilter(element, internal) &&
      this.getUsersName(element).toLowerCase().includes(searchText.toLowerCase())
    )
  }

  limitListToMaxSix = (list: HTMLLIElement[], li: HTMLLIElement) => {
    if (list.length < this.MAX_VISIBLE_ELEMENTS) {
      list.push(li)
      return
    }

    li.classList.add("hidden")
  }

  onKeyPressed = (e: KeyboardEvent) => {
    if (!this.isUserListVisible) return
    const isNavigationKey = ["ArrowDown", "ArrowUp", "Tab"].includes(e.code)
    const isEnterKey = e.code === "Enter"

    if (isNavigationKey) {
      e.preventDefault()
      this.navigateThroughList(e.code)
    } else if (isEnterKey && this.selectedElement) {
      e.preventDefault()
      this.selectedElement.classList.remove("bg-gray-100")
      this.selectUser(this.selectedElement)
      this.selectedElement = null
    }
  }

  navigateThroughList = (key: string) => {
    const targetIndex = this.visibleElements.indexOf(this.selectedElement)
    const direction = key === "ArrowUp" ? -1 : 1
    let nextElIndex = (targetIndex + direction + this.visibleElements.length) % this.visibleElements.length
    this.selectElement(this.visibleElements[nextElIndex])
  }

  onUserSelected = (e: Event) => {
    this.selectUser(e.currentTarget as HTMLElement)
  }

  passesInternalFilter = (li: HTMLLIElement, internal: boolean): boolean => {
    if (!internal) return true

    return li.dataset.isStaffUser === "true"
  }

  processUserInput = ({ detail: { internal, word } }: CustomEvent<CommentWordChangeDetails>) => {
    const mention = this.getMention(word)
    this.toggleUserListVisibility(mention)
    if (this.isUserListVisible) this.processMention(mention!, !!internal)
  }

  processMention = (mention: string, internal: boolean) => {
    this.filteredUsersList(mention, internal)
    if (this.visibleElements.length === 0) this.hideMentionsBox()
    else this.selectElement(this.visibleElements[0])
  }

  selectUser = (element: HTMLElement) => {
    const { fullName, userId } = element.dataset

    if (element.tagName.toLowerCase() === "li" && fullName) {
      this.dispatch("userMentioned", { detail: { fullName, userId } })
      this.hideMentionsBox()
    }
  }

  showMentionsBox = () => {
    this.usersListTarget.classList.remove("hidden")
    this.isUserListVisible = true
  }

  toggleUserListVisibility = (mention: string | undefined) => {
    mention != null ? this.showMentionsBox() : this.hideMentionsBox()
  }

  selectElement = (element: HTMLLIElement) => {
    if (!element) return
    this.selectedElement?.classList.remove("bg-gray-100")
    element.classList.add("bg-gray-100")
    this.selectedElement = element
  }
}
