import { Controller } from "@hotwired/stimulus"
import { hide, disable, enable } from "../utils"

// Connects to data-controller="coupa-easy-form-selector"
export default class extends Controller {
  static targets = ["viewFormLink"]

  viewFormLinkTarget: HTMLSelectElement

  changeSelectedEasyForm(event) {
    this.viewFormLinkTarget.href = event.target.value
    if (event.target.value === "") {
      disable(this.viewFormLinkTarget)
    } else {
      enable(this.viewFormLinkTarget)
    }
  }

  hideTaskModal() {
    hide(document.querySelector("#modal-container"))
  }
}
