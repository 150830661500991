import ChartController from "../chart_controller"
import { Chart } from "chart.js"

// Connects to data-controller="employee-profiles--activity-chart"
export default class extends ChartController {
  static values = {
    type: String,
    datasets: String,
    granularity: String,
  }
  granularityValue: string
  predefinedColors = ["#564FBA"]

  chartOptions() {
    const timeFormat = {
      unit: "week",
      isoWeekday: true,
      displayFormats: {
        week: "dd MMM",
      },
    }
    return {
      maintainAspectRatio: false,
      parsing: {
        xAxisKey: "formattedTime",
        yAxisKey: "active_count",
      },
      scales: {
        y: {
          max: 7,
          min: 0,
          grid: {
            color: "#B9BDC6",
            drawBorder: false,
          },
          beginAtZero: true,
          ticks: {
            stepSize: 1,
            count: 6,
            maxTicksLimit: 10,
            color: "#B9BDC6",
            font: {
              size: 14,
            },
            callback: function (val, index) {
              return index % 2 === 0 ? this.getLabelForValue(val) : ""
            },
          },
        },
        x: {
          offset: true,
          grid: {
            display: false,
          },
          type: "time",
          time: timeFormat,
          ticks: {
            color: "#B9BDC6",
            font: {
              size: 14,
            },
          },
        },
      },
      plugins: {
        legend: {
          position: "bottom",
        },
        tooltip: { enabled: false },
        title: {
          font: { size: 16, weight: 500 },
          display: true,
          text: "Days Active per Week",
          color: "#8798AD",
          position: "top",
          align: "start",
          padding: {
            bottom: 20,
          },
        },
      },
    }
  }
}
