import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="workflows--documents-modal-controller"
export default class extends Controller {
  static targets = [
    "uploadDocumentsSection",
    "docUploaderSection",
    "noDocumentsSection",
    "documentsForm",
    "uploadDocumentsButton",
    "noDocumentsButton",
  ]
  uploadDocumentsSectionTarget: HTMLDivElement
  docUploaderSectionTarget: HTMLDivElement
  noDocumentsSectionTarget: HTMLDivElement
  documentsFormTarget: HTMLFormElement
  noDocumentsButtonTarget: HTMLDivElement
  uploadDocumentsButtonTarget: HTMLButtonElement

  static values = {}

  connect() {}

  onNoDocuments() {
    this.uploadDocumentsSectionTarget.classList.add("hidden")
    this.docUploaderSectionTarget.classList.add("hidden")
    this.noDocumentsSectionTarget.classList.remove("hidden")
  }

  onDisableDocUpload() {
    if (this.hasNoDocumentsButtonTarget) {
      this.noDocumentsButtonTarget.classList.remove("hidden")
    }
    this.uploadDocumentsButtonTarget.disabled = true
    this.uploadDocumentsButtonTarget.classList.add("disabled")
  }

  onEnableDocUpload() {
    if (this.hasNoDocumentsButtonTarget) {
      this.noDocumentsButtonTarget.classList.add("hidden")
    }
    this.uploadDocumentsButtonTarget.disabled = false
    this.uploadDocumentsButtonTarget.classList.remove("disabled")
  }

  submitForm(e: PointerEvent) {
    if (this.documentsFormTarget) {
      e.preventDefault()
      /*
      requestSubmit() sets format to :turbo_stream
      submit() sets the format to html
      Below ternary makes sure that request comes in as a turbo_stream when tasks are being completed from workflows
      */
      if (window.location.href.includes("/supplier_portal")) {
        this.documentsFormTarget.submit()
        return
      }

      this.documentsFormTarget.requestSubmit()
    }
  }
}
