import { Controller } from "@hotwired/stimulus"
import TomSelect from "tom-select"

// Connects to data-controller="styled-select-remover"
export default class extends Controller {
  static values = {
    domId: String,
    selectionId: String,
  }
  domIdValue: string
  selectionIdValue: string

  removeSelection(): void {
    this.tomSelectElement().removeOption(this.selectionIdValue)
  }

  tomSelectElement(): TomSelect {
    const selectElement = document.getElementById(this.domIdValue)
    const selectController = this.application.getControllerForElementAndIdentifier(selectElement, "styled-select")
    return selectController.tomSelect
  }
}
