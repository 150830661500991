import { Controller } from "@hotwired/stimulus"
import { swapClass } from "../utils"
import CollapsibleSidebarController from "./collapsible_sidebar_controller"

// Connects to data-controller="collapse-sidebar-button"
export default class extends Controller {
  static targets = ["spanHolder", "spanTop", "spanBottom"]
  static outlets = ["collapsible-sidebar"]

  static values = {
    position: {
      type: String,
      default: "left",
    },
    openTooltipText: {
      type: String,
      default: "Expand",
    },
    closeTooltipText: {
      type: String,
      default: "Collapse",
    },
    buttonClassesData: Object,
  }

  positionValue: string
  openTooltipTextValue: string
  closeTooltipTextValue: string
  buttonClassesDataValue: any

  spanHolderTarget: HTMLElement
  spanTopTarget: HTMLElement
  spanBottomTarget: HTMLElement

  hasCollapsibleSidebarOutlet: boolean
  collapsibleSidebarOutlet: CollapsibleSidebarController

  onSidebarChangeHandler = this.onSidebarChange.bind(this)

  connect() {
    if (this.hasCollapsibleSidebarOutlet) {
      const isSidebarCollapsed = this.collapsibleSidebarOutlet.isCollapsed()
      this.updateTooltipText(isSidebarCollapsed)
      this.updateDirection(isSidebarCollapsed)
    }

    window.addEventListener("collapsible-sidebar:expanded", this.onSidebarChangeHandler)
    window.addEventListener("collapsible-sidebar:collapsed", this.onSidebarChangeHandler)
  }

  disconnect() {
    window.removeEventListener("collapsible-sidebar:expanded", this.onSidebarChangeHandler)
    window.removeEventListener("collapsible-sidebar:collapsed", this.onSidebarChangeHandler)
  }

  onSidebarChange(e: CustomEvent) {
    const { sidebar, isCollapsed } = e.detail
    this.updateDirection(isCollapsed)
    this.updateTooltipText(isCollapsed)
  }

  updateDirection(isSidebarCollapsed: boolean) {
    if (this.positionValue === "left") {
      if (isSidebarCollapsed) {
        this.pointArrowToEast()
      } else {
        this.pointArrowToWest()
      }
    } else {
      if (isSidebarCollapsed) {
        this.pointArrowToWest()
      } else {
        this.pointArrowToEast()
      }
    }
  }

  private pointArrowToWest(): void {
    swapClass(
      this.spanHolderTarget,
      this.buttonClassesDataValue?.east?.arrows_holder,
      this.buttonClassesDataValue?.west?.arrows_holder,
    )
    swapClass(
      this.spanTopTarget,
      this.buttonClassesDataValue?.east?.top_arrow,
      this.buttonClassesDataValue?.west?.top_arrow,
    )
    swapClass(
      this.spanBottomTarget,
      this.buttonClassesDataValue?.east?.bottom_arrow,
      this.buttonClassesDataValue?.west?.bottom_arrow,
    )
  }

  // Updates the tooltip of the sidenav_collapse component based on the sidebar state
  private updateTooltipText(isSidebarCollapsed: boolean): void {
    // Targeting the div directly as the tooltip is not a direct child of the sidenav-collapse-button
    const tooltipElement = document.querySelector("#collapse-sidebar-button #tooltip")
    if (tooltipElement) {
      tooltipElement.textContent = isSidebarCollapsed ? this.openTooltipTextValue : this.closeTooltipTextValue
    }
  }

  private pointArrowToEast(): void {
    swapClass(
      this.spanHolderTarget,
      this.buttonClassesDataValue?.west?.arrows_holder,
      this.buttonClassesDataValue?.east?.arrows_holder,
    )
    swapClass(
      this.spanTopTarget,
      this.buttonClassesDataValue?.west?.top_arrow,
      this.buttonClassesDataValue?.east?.top_arrow,
    )
    swapClass(
      this.spanBottomTarget,
      this.buttonClassesDataValue?.west?.bottom_arrow,
      this.buttonClassesDataValue?.east?.bottom_arrow,
    )
  }
}
