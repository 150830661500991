import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="contract-description"
export default class extends Controller {
  static targets = ["supplierName", "contractDescription"]

  supplierNameTarget: HTMLDivElement
  contractDescriptionTarget: HTMLDivElement

  hasSupplierNameTarget: boolean
  hasContractDescriptionTarget: boolean

  connect() {}

  supplierNameTargetConnected() {
    this.disableTooltip()
  }

  contractDescriptionTargetConnected() {
    this.disableTooltip()
  }

  disableTooltip() {
    const tooltipElement = this.element.querySelector<HTMLDivElement>('[data-popper-target="element"]')
    if (tooltipElement && !this.isSupplierTruncated() && !this.isContractTruncated()) {
      tooltipElement.classList.add("pointer-events-none")
    }
  }

  isSupplierTruncated() {
    if (!this.hasSupplierNameTarget) {
      return false
    }
    return this.supplierNameTarget.offsetWidth < this.supplierNameTarget.scrollWidth
  }

  isContractTruncated() {
    if (!this.hasContractDescriptionTarget) {
      return false
    }
    return this.contractDescriptionTarget.offsetWidth < this.contractDescriptionTarget.scrollWidth
  }
}
