import { Controller } from "@hotwired/stimulus"
import { show, hide } from "../../utils"

// Connects to data-controller="requests--platform-edit-form"
export default class extends Controller {
  static targets = ["workflowSelect", "workflowChangeAlertContainer"]

  workflowSelectTarget: HTMLSelectElement
  workflowChangeAlertContainerTarget: HTMLDivElement

  static values = {
    currentWorkflowId: String,
  }

  currentWorkflowIdValue: string

  connect() {}

  selectedWorkflowChanged() {
    if (this.workflowSelectTarget.value === this.currentWorkflowIdValue) {
      hide(this.workflowChangeAlertContainerTarget)
    } else {
      show(this.workflowChangeAlertContainerTarget)
    }
  }
}
