export const timeBasedInputTypes = ["date", "datetime-local", "month", "week", "time", "color"]

export const textBasedInputTypes = [
  "text",
  "password",
  "email",
  "search",
  "tel",
  "url",
  "number",
  ...timeBasedInputTypes,
]

export const isTextBasedInput = (element: HTMLElement) => {
  return element.tagName === "TEXTAREA" || (element.tagName === "INPUT" && textBasedInputTypes.includes(element.type))
}

/*
  time-based input types have wonky event behaviors. They fire a change event whenever the entered value becomes
  valid, even if that happens repeatedly while the user is typing. This differs from the handling of text-based
  inputs where the change event is only fired when the input loses focus. This function allows us to check for
  these time-based inputs.
 */
export const isTimeBasedInput = (element: HTMLElement) => {
  return element.tagName === "INPUT" && timeBasedInputTypes.includes(element.type)
}
