import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="proposals--confirm"
export default class extends Controller {
  onConfirm(event) {
    const form = document.getElementById("proposal-form")
    const statusField = document.getElementById("proposal-status")
    statusField.value = event.params.status
    if (form.checkValidity()) form.submit()
    else {
      const cancelButton = document.getElementById("confirm-modal-cancel-button")
      cancelButton.click()
    }
  }
}
