import { Controller } from "@hotwired/stimulus"
import { show, hide, disable, enable, buttonAsSubmitting } from "../../utils"

// Connects to data-controller="workflows--workflow-rules-form"
export default class extends Controller {
  static targets = [
    "editButtonContainer",
    "formButtonsContainer",
    "tropicManagedNewPurchasesSelect",
    "tropicManagedRenewalsSelect",
    "tropicManagedUpgradesSelect",
    "tropicManagedDowngradesSelect",
    "tropicManagedReplacementsSelect",
    "orgManagedNewPurchasesSelect",
    "orgManagedRenewalsSelect",
    "orgManagedUpgradesSelect",
    "orgManagedDowngradesSelect",
    "orgManagedReplacementsSelect",
    "tropicManagedNewPurchasesError",
    "tropicManagedRenewalsError",
    "tropicManagedUpgradesError",
    "tropicManagedDowngradesError",
    "tropicManagedReplacementsError",
    "orgManagedNewPurchasesError",
    "orgManagedRenewalsError",
    "orgManagedUpgradesError",
    "orgManagedDowngradesError",
    "orgManagedReplacementsError",
    "workflowRulesForm",
    "submitButton",
  ]

  editButtonContainerTarget: HTMLDivElement
  formButtonsContainerTarget: HTMLDivElement
  tropicManagedNewPurchasesSelectTarget: HTMLSelectElement
  tropicManagedRenewalsSelectTarget: HTMLSelectElement
  tropicManagedUpgradesSelectTarget: HTMLSelectElement
  tropicManagedDowngradesSelectTarget: HTMLSelectElement
  tropicManagedReplacementsSelectTarget: HTMLSelectElement
  orgManagedNewPurchasesSelectTarget: HTMLSelectElement
  orgManagedRenewalsSelectTarget: HTMLSelectElement
  orgManagedUpgradesSelectTarget: HTMLSelectElement
  orgManagedDowngradesSelectTarget: HTMLSelectElement
  orgManagedReplacementsSelectTarget: HTMLSelectElement
  tropicManagedNewPurchasesErrorTarget: HTMLSelectElement
  tropicManagedRenewalsErrorTarget: HTMLDivElement
  tropicManagedUpgradesErrorTarget: HTMLDivElement
  tropicManagedDowngradesErrorTarget: HTMLDivElement
  tropicManagedReplacementsErrorTarget: HTMLDivElement
  orgManagedNewPurchasesErrorTarget: HTMLDivElement
  orgManagedRenewalsErrorTarget: HTMLDivElement
  orgManagedUpgradesErrorTarget: HTMLDivElement
  orgManagedDowngradesErrorTarget: HTMLDivElement
  orgManagedReplacementsErrorTarget: HTMLDivElement
  workflowRulesFormTarget: HTMLFormElement
  submitButtonTarget: HTMLButtonElement

  connect() {}

  toggleEditableView() {
    show(this.formButtonsContainerTarget)
    hide(this.editButtonContainerTarget)

    this.selectElements().forEach((element) => enable(element))
  }

  toggleNoneditableView() {
    this.clearErrors()

    hide(this.formButtonsContainerTarget)
    show(this.editButtonContainerTarget)

    this.selectElements().forEach((element) => disable(element))
  }

  submitForm() {
    if (this.workflowRulesFormTarget && this.isFormValid()) {
      buttonAsSubmitting(this.submitButtonTarget)
      this.workflowRulesFormTarget.submit()
    }
  }

  isFormValid() {
    let isValid = true
    this.clearErrors()

    this.errorAndSelectElements().forEach((elementPair) => {
      if (elementPair[1].selectedIndex === 0) {
        this.showError(elementPair[0], elementPair[1])
        isValid = false
      }
    })

    return isValid
  }

  clearErrors() {
    this.errorAndSelectElements().forEach((elementPair) => {
      this.hideError(elementPair[0], elementPair[1])
    })
  }

  showError(errorElement, formElement) {
    show(errorElement)
    formElement.classList.add("border-red-500")
  }

  hideError(errorElement, formElement) {
    hide(errorElement)
    formElement.classList.remove("border-red-500")
  }

  selectElements() {
    return [
      this.tropicManagedNewPurchasesSelectTarget,
      this.tropicManagedRenewalsSelectTarget,
      this.tropicManagedUpgradesSelectTarget,
      this.tropicManagedDowngradesSelectTarget,
      this.tropicManagedReplacementsSelectTarget,
      this.orgManagedNewPurchasesSelectTarget,
      this.orgManagedRenewalsSelectTarget,
      this.orgManagedUpgradesSelectTarget,
      this.orgManagedDowngradesSelectTarget,
      this.orgManagedReplacementsSelectTarget,
    ]
  }

  errorAndSelectElements() {
    return [
      [this.tropicManagedNewPurchasesErrorTarget, this.tropicManagedNewPurchasesSelectTarget],
      [this.tropicManagedRenewalsErrorTarget, this.tropicManagedRenewalsSelectTarget],
      [this.tropicManagedUpgradesErrorTarget, this.tropicManagedUpgradesSelectTarget],
      [this.tropicManagedDowngradesErrorTarget, this.tropicManagedDowngradesSelectTarget],
      [this.tropicManagedReplacementsErrorTarget, this.tropicManagedReplacementsSelectTarget],
      [this.orgManagedNewPurchasesErrorTarget, this.orgManagedNewPurchasesSelectTarget],
      [this.orgManagedRenewalsErrorTarget, this.orgManagedRenewalsSelectTarget],
      [this.orgManagedUpgradesErrorTarget, this.orgManagedUpgradesSelectTarget],
      [this.orgManagedDowngradesErrorTarget, this.orgManagedDowngradesSelectTarget],
      [this.orgManagedReplacementsErrorTarget, this.orgManagedReplacementsSelectTarget],
    ]
  }
}
