import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="workflows--edit-workflow-details"
export default class extends Controller {
  static targets = ["workflowRequestCategorySelect"]

  static values = {
    workflowIsAutoAssigned: String,
    workflowRequestCategoryId: String || null,
    workflowIsAutoAssignedAsOtherwise: String,
    workflowHasTaskWithRequestFormQuestionCondition: String,
  }
  workflowIsAutoAssignedValue: String
  workflowRequestCategoryIdValue: String | null
  workflowIsAutoAssignedAsOtherwiseValue: String
  workflowHasTaskWithRequestFormQuestionConditionValue: String

  onWorkflowRequestCategoryChange(event) {
    let inputField = this.workflowRequestCategoryInput()

    if (inputField) {
      if (this.requestCategoryStayedTheSame(event.target.value)) {
        this.hideErrors(inputField)
        this.hideWarnings()
      } else if (this.workflowStartedWithoutaRequestCategory()) {
        if (this.workflowIsAutoAssignedAsOtherwise()) {
          this.showErrors(inputField)
        } else {
          this.hideErrors(inputField)
        }
      } else if (this.workflowIsAutoAssigned()) {
        this.showErrors(inputField)
      } else if (this.workflowHasTaskWithRequestFormQuestionCondition()) {
        this.showWarnings()
      }
    }
  }

  private workflowHasTaskWithRequestFormQuestionCondition(): boolean {
    return this.workflowHasTaskWithRequestFormQuestionConditionValue === "true"
  }

  private workflowIsAutoAssigned(): boolean {
    return this.workflowIsAutoAssignedValue === "true"
  }

  private workflowIsAutoAssignedAsOtherwise(): boolean {
    return this.workflowIsAutoAssignedAsOtherwiseValue === "true"
  }

  private workflowStartedWithoutaRequestCategory(): boolean {
    return this.workflowRequestCategoryIdValue === ""
  }

  private requestCategoryStayedTheSame(selectedValue): boolean {
    return selectedValue === this.workflowRequestCategoryIdValue
  }

  private hideErrors(inputField) {
    inputField.classList.remove("border-red-500", "hover:border-red-500")

    this.errors().forEach((error) => {
      error.classList.add("hidden")
    })
  }

  private showErrors(inputField) {
    inputField.classList.add("border-red-500", "hover:border-red-500")

    this.errors().forEach((error) => {
      error.classList.remove("hidden")
    })
  }

  private hideWarnings() {
    this.warnings().forEach((warning) => {
      warning.classList.add("hidden")
    })
  }

  private showWarnings() {
    this.warnings().forEach((warning) => {
      warning.classList.remove("hidden")
    })
  }

  private warnings() {
    return Array.from(document.getElementsByClassName("warnings"))
  }

  private errors() {
    return Array.from(document.getElementsByClassName("errors"))
  }

  private workflowRequestCategoryInput() {
    return this.workflowRequestCategorySelectTarget?.parentElement?.querySelector(".ts-wrapper")
  }
}
