import ExampleComponent from "./components/ExampleComponent"
import LinearWorkflow from "./components/workflows/linear/linearWorkflow"
import NonlinearWorkflow from "./components/workflows/nonlinear/nonlinearWorkflow"
import LegacyNonlinearWorkflow from "./components/workflows/nonlinear/legacyNonlinearWorkflow"

/*
  Map the compnent to the class name of the target to be attached to:

  If you are using the "reactify" helper, the name of the class
  will be "react-" + the name of the component.

  i.e. <%= reactify "FooBar" %> will map to "react-foo-bar"

  and the registry entry will be:
    { component: FooBar, target: "react-foo-bar"}
*/

export default [
  { component: ExampleComponent, target: "react-example-component" },
  { component: LinearWorkflow, target: "react-linear-workflow" },
  { component: NonlinearWorkflow, target: "react-nonlinear-workflow" },
  { component: LegacyNonlinearWorkflow, target: "react-legacy-nonlinear-workflow" },
]
