import { Controller } from "@hotwired/stimulus"
import { show, hide, disable, enable, buttonAsSubmitting, removeRequired, setRequired } from "../../utils"

// Connects to data-controller="requests--management-details-form"
export default class extends Controller {
  static targets = [
    "allocatedCreditTypeSelect",
    "managedBySelect",
    "tropicWorkflowSelect",
    "tropicWorkflowSelectContainer",
    "tropicWorkflowSelectError",
    "orgWorkflowSelect",
    "orgWorkflowSelectContainer",
    "orgWorkflowSelectError",
    "managementDetailsForm",
    "submitButton",
    "addressableMismatchAlertContainer",
    "addressableRadioButton",
    "workflowSelectorsContainer",
    "autoassignWorkflowCheckbox",
    "autoassignWorkflowAlertContainer",
    "workflowChangeAlertContainer",
    "workflowCategoryChangeAlertContainer",
    "workflowOption",
  ]

  managedBySelectTarget: HTMLSelectElement
  hasManagedBySelectTarget: Boolean
  tropicWorkflowSelectTarget: HTMLSelectElement
  tropicWorkflowSelectContainerTarget: HTMLDivElement
  tropicWorkflowSelectErrorTarget: HTMLDivElement
  orgWorkflowSelectTarget: HTMLSelectElement
  orgWorkflowSelectContainerTarget: HTMLDivElement
  orgWorkflowSelectErrorTarget: HTMLDivElement
  managementDetailsFormTarget: HTMLFormElement
  submitButtonTarget: HTMLButtonElement
  addressableMismatchAlertContainerTarget: HTMLDivElement
  addressableRadioButtonTargets: [HTMLInputElement]
  workflowSelectorsContainerTarget: HTMLDivElement
  autoassignWorkflowCheckboxTarget: HTMLInputElement
  autoassignWorkflowAlertContainerTarget: HTMLDivElement
  workflowChangeAlertContainerTarget: HTMLDivElement
  allocatedCreditTypeSelectTarget: HTMLSelectElement
  hasAllocatedCreditTypeSelectTarget: Boolean
  workflowCategoryChangeAlertContainerTarget: HTMLDivElement
  workflowOptionTargets: [HTMLDivElement]

  static values = {
    currentWorkflowId: String,
    currentRequestCategoryId: String,
    orgWorkflowIdToCategoryIdObject: Object,
    tropicWorkflowIdToCategoryIdObject: Object,
  }

  currentWorkflowIdValue: string
  currentRequestCategoryIdValue: string
  orgWorkflowIdToCategoryIdObjectValue: object
  tropicWorkflowIdToCategoryIdObjectValue: object

  connect() {
    this.conditionallyToggleMismatchAlert()
  }

  onManagedByChange(event) {
    if (event.currentTarget.value === "tropic") {
      enable(this.tropicWorkflowSelectTarget)
      show(this.tropicWorkflowSelectContainerTarget)

      disable(this.orgWorkflowSelectTarget)
      hide(this.orgWorkflowSelectContainerTarget)
    } else if (event.currentTarget.value === "organization") {
      disable(this.tropicWorkflowSelectTarget)
      hide(this.tropicWorkflowSelectContainerTarget)

      enable(this.orgWorkflowSelectTarget)
      show(this.orgWorkflowSelectContainerTarget)
    }
    this.conditionallyToggleMismatchAlert()
    this.selectedWorkflowChanged()
    this.selectedWorkflowCategoryChanged()
  }

  onAddressableSelected() {
    this.conditionallyToggleMismatchAlert()
  }

  conditionallyToggleMismatchAlert() {
    if (this.addressableAndManagedByMatch()) {
      hide(this.addressableMismatchAlertContainerTarget)
    } else {
      show(this.addressableMismatchAlertContainerTarget)
    }
  }

  enableAllocatedCreditTypeSelect() {
    if (this.hasAllocatedCreditTypeSelectTarget) {
      enable(this.allocatedCreditTypeSelectTarget)
    }
  }

  disableAllocatedCreditTypeSelect() {
    if (this.hasAllocatedCreditTypeSelectTarget) {
      disable(this.allocatedCreditTypeSelectTarget)
    }
  }

  addressableAndManagedByMatch() {
    if (!this.hasManagedBySelectTarget) {
      return true
    }

    const checkedAddressableValue = Array.prototype.find.call(
      this.addressableRadioButtonTargets,
      (button) => button.checked,
    ).value
    const checkedAddressableBooleanValue = JSON.parse(checkedAddressableValue.toLowerCase())

    return (
      (checkedAddressableBooleanValue && this.managedBySelectTarget.value === "tropic") ||
      (!checkedAddressableBooleanValue && this.managedBySelectTarget.value === "organization")
    )
  }

  toggleAutoassignWorkflow() {
    if (this.autoassignWorkflowCheckboxTarget.checked) {
      hide(this.workflowSelectorsContainerTarget)
      removeRequired(this.orgWorkflowSelectTarget)
      show(this.autoassignWorkflowAlertContainerTarget)
    } else {
      setRequired(this.orgWorkflowSelectTarget)
      show(this.workflowSelectorsContainerTarget)
      hide(this.autoassignWorkflowAlertContainerTarget)
    }
  }

  selectedWorkflowChanged() {
    if (!this.selectedWorkflowId() || this.selectedWorkflowId() === this.currentWorkflowIdValue) {
      hide(this.workflowChangeAlertContainerTarget)
    } else {
      show(this.workflowChangeAlertContainerTarget)
    }
  }

  selectedWorkflowId() {
    if (this.hasManagedBySelectTarget && this.managedBySelectTarget.value === "tropic") {
      return this.tropicWorkflowSelectTarget.value
    } else {
      return this.orgWorkflowSelectTarget.value
    }
  }

  selectedWorkflowCategoryChanged() {
    if (!this.selectedWorkflowId()) {
      hide(this.workflowCategoryChangeAlertContainerTarget)
    } else if (this.currentRequestCategoryIdValue.length === 0) {
      hide(this.workflowCategoryChangeAlertContainerTarget)
    } else if (
      this.selectedWorkflowCategoryId() === this.currentRequestCategoryIdValue ||
      this.selectedWorkflowId() === this.currentWorkflowIdValue
    ) {
      hide(this.workflowCategoryChangeAlertContainerTarget)
    } else {
      show(this.workflowCategoryChangeAlertContainerTarget)
    }
  }

  selectedWorkflowCategoryId() {
    if (this.hasManagedBySelectTarget && this.managedBySelectTarget.value === "tropic") {
      return this.tropicWorkflowIdToCategoryIdObjectValue[this.selectedWorkflowId()]
    } else {
      return this.orgWorkflowIdToCategoryIdObjectValue[this.selectedWorkflowId()]
    }
  }

  submitForm() {
    if (this.managementDetailsFormTarget && this.isFormValid()) {
      buttonAsSubmitting(this.submitButtonTarget)
      this.managementDetailsFormTarget.requestSubmit()
    }
  }

  isFormValid() {
    let isValid = true
    this.clearErrors()

    if (!this.autoassignWorkflowCheckboxTarget.checked) {
      if (this.tropicWorkflowIsUnselected()) {
        this.showError(
          this.tropicWorkflowSelectErrorTarget,
          this.tropicWorkflowCategoryInput() || this.tropicWorkflowSelectTarget,
        )
        isValid = false
      } else if (this.orgWorkflowIsUnselected()) {
        this.showError(
          this.orgWorkflowSelectErrorTarget,
          this.orgWorkflowCategoryInput() || this.orgWorkflowSelectTarget,
        )
        isValid = false
      }
    }

    return isValid
  }

  tropicWorkflowCategoryInput() {
    return this.tropicWorkflowSelectTarget?.parentElement?.querySelector(".ts-wrapper")
  }

  orgWorkflowCategoryInput() {
    return this.orgWorkflowSelectTarget?.parentElement?.querySelector(".ts-wrapper")
  }

  tropicWorkflowIsUnselected() {
    let tropicWorkflowSelectHasNoSelection = this.tropicWorkflowSelectTarget.tomselect?.getValue() === ""

    return (
      this.hasManagedBySelectTarget &&
      this.managedBySelectTarget.value === "tropic" &&
      tropicWorkflowSelectHasNoSelection
    )
  }

  orgWorkflowIsUnselected() {
    let orgWorkflowSelectHasNoSelection = this.orgWorkflowSelectTarget.tomselect?.getValue() === ""

    return (
      this.hasManagedBySelectTarget &&
      this.managedBySelectTarget.value === "organization" &&
      orgWorkflowSelectHasNoSelection
    )
  }

  clearErrors() {
    this.hideError(this.tropicWorkflowSelectErrorTarget, this.tropicWorkflowSelectTarget)
    this.hideError(this.orgWorkflowSelectErrorTarget, this.orgWorkflowSelectTarget)
  }

  showError(errorElement, formElement) {
    show(errorElement)
    formElement.classList.add("border-red-500")
  }

  hideError(errorElement, formElement) {
    hide(errorElement)
    formElement.classList.remove("border-red-500")
  }
}
