import React from "react"
import { Handle, Position } from "reactflow"

interface EdgeConnectorNodeProps {
  data: {
    handleStyles: {
      background: string
    }
    nodeStyles: {
      background: string
      height: string
      width: string
      position: string
      left: number
      top: number
      borderRadius: number
    }
  }
}

const EdgeConnectorNode = ({ data }: EdgeConnectorNodeProps) => {
  const handleStyles = {
    minWidth: "2px",
    minHeight: "2px",
    width: "2px",
    height: "2px",
    background: "#6A778A",
    border: "none",
    borderRadius: 0,
    left: -5,
    transform: "none",
    ...data?.handleStyles,
  }

  const nodeStyles = {
    width: "2px",
    height: "2px",
    ...data?.nodeStyles,
  }

  return (
    <div>
      <Handle type="target" position={Position.Left} style={handleStyles} />
      <div style={nodeStyles}></div>
      <Handle type="source" position={Position.Right} style={handleStyles} />
    </div>
  )
}

export default EdgeConnectorNode
