import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="loader"
export default class extends Controller {
  static targets = ["loader"]

  loaderTargets: Array<HTMLElement>

  showLoaders(e): void {
    this.loaderTargets.forEach((loader) => loader.classList.remove("hidden"))
  }
}
