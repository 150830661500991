import { Controller } from "@hotwired/stimulus"
import { hide, show } from "../../utils"
import CollapsibleSidebarController from "../collapsible_sidebar_controller"

// Connects to data-controller="workflows--empty-sidebar"
export default class extends Controller {
  static outlets = ["collapsible-sidebar"]

  collapsibleSidebarOutlet: CollapsibleSidebarController
  hasCollapsibleSidebarOutlet: Boolean

  connect() {
    if (this.hasCollapsibleSidebarOutlet) {
      const isSidebarCollapsed = this.collapsibleSidebarOutlet.isCollapsed()
      if (isSidebarCollapsed) {
        this.hideContent()
      } else {
        this.showContent()
      }
    }
  }

  showContent(): void {
    show(this.element)
  }

  hideContent(): void {
    hide(this.element)
  }
}
