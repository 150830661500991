import { Controller } from "@hotwired/stimulus"
import { enter, leave } from "el-transition"

// Connects to data-controller="flash"
export default class extends Controller {
  static targets = ["wrapper"]
  wrapperTarget: Element

  connect() {
    setTimeout(() => this.close(), 6000)
    this.announcePresence()
  }

  close() {
    leave(this.wrapperTarget)
  }

  // We use this to announce Flash to Dialog Components
  // See app/javascript/controllers/canopy/dialog_component_controller.ts
  // Flash Handler Functionality
  announcePresence() {
    const event = new CustomEvent("flash:present", {
      detail: { flashElement: this.element },
      bubbles: true,
    })
    this.element.dispatchEvent(event)
  }
}
