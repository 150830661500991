import { Controller } from "@hotwired/stimulus"
import { hide, show } from "../utils"

// Connects to data-controller="contract-rate-schedule-fields"
export default class extends Controller {
  static targets = ["unitsRangeWrapper", "unitsMinField", "unitsMaxField"]

  unitsRangeWrapperTarget: HTMLDivElement
  unitsMinFieldTarget: HTMLInputElement
  unitsMaxFieldTarget: HTMLInputElement
  connect() {}

  toggleUnitsRangeFields(e) {
    const isVisible = e.target.checked

    if (isVisible) {
      show(this.unitsRangeWrapperTarget)
      this.unitsMinFieldTarget.value = 0
    } else {
      hide(this.unitsRangeWrapperTarget)
      this.unitsMinFieldTarget.value = null
      this.unitsMaxFieldTarget.value = null
    }
  }
}
