import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="unisearch--tabs"
export default class extends Controller {
  static targets = ["link", "content"]
  static values = { currentTab: String }

  connect(): void {
    this.set_tabs()
  }

  select(event) {
    // Find tab with same id as clicked btn
    this.currentTabValue = event.currentTarget.id
    this.set_tabs()
  }

  // private
  set_tabs() {
    // Hide all tabs
    this.linkTargets.map((x) => {
      x.classList.remove("scope-bar-on")
      x.classList.add("scope-bar-off")
    })
    this.contentTargets.map((x) => x.classList.add("hidden"))

    // Open current tab
    try {
      let selectedLink = this.linkTargets.find((element) => element.id === this.currentTabValue)
      let selectedContent = this.contentTargets.find((element) => element.id === this.currentTabValue)
      selectedLink.classList.remove("scope-bar-off")
      selectedLink.classList.add("scope-bar-on")
      selectedContent.classList.remove("hidden")
    } catch {}
  }
}
