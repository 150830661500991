import React, { useState } from "react"

export const Collapsible = (props) => {
  const [open, setOpen] = useState(false)

  const toggle = () => {
    setOpen(!open)
  }

  const openButton = () => {
    return (
      <button key="view-more" onClick={toggle} className="text-purple-500 body-text font-medium">
        <i className="fa-solid fa-chevron-down"></i> View More
      </button>
    )
  }

  const closeButton = () => {
    return (
      <button key="view-less" onClick={toggle} className="text-purple-500 body-text mt-3 font-medium">
        <i className="fa-solid fa-chevron-up"></i> View Less
      </button>
    )
  }

  return (
    <div>
      {open && props.children}
      {open ? closeButton() : openButton()}
    </div>
  )
}
