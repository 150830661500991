import { Controller } from "@hotwired/stimulus"
import { buildUrl } from "../utils/urls"
import { insertOverlayIntoDOM, removeOverlayFromDOM } from "../utils/overlay"
import { debounce } from "lodash"

const SUPPLIER_INTELLIGENCE_SEARCH_BASE_URL = "/supplier_intelligence/search"

interface FrameElement extends HTMLElement {
  src: string
}

// Connects to data-controller="supplier-intelligence-search"
export default class extends Controller {
  /* Targets */
  static targets = [
    "resultsFrame",
    "input",
    "option",
    "selection",
    "icon",
    "searchGlassIcon",
    "clearInputIcon",
    "submitButton",
  ]

  resultsFrameTarget: FrameElement
  inputTarget: HTMLInputElement
  selectionTarget: HTMLInputElement
  iconTargets: [HTMLDivElement]
  optionTargets: [HTMLLIElement]
  hasResultsFrameTarget: boolean
  hasSelectionTarget: boolean
  hasInputTarget: boolean
  searchGlassIconTarget: SVGAElement
  clearInputIconTarget: SVGAElement
  submitButtonTarget: HTMLDivElement

  /* Values */
  static values = {
    output: String,
    orgId: String,
    frameId: String,
  }

  outputValue: string
  orgIdValue: string
  frameIdValue: string

  initialize() {
    if (this.hasSelectionTarget) {
      this.updateOutput(this.selectionTarget.value)
    }

    this.onSearch = debounce(this.onSearch.bind(this), 200)
  }

  connect() {
    document.addEventListener("click", this.onClickOutsideOfResults)
    this.updateSubmitButton()
  }

  disconnect() {
    document.removeEventListener("click", this.onClickOutsideOfResults)
    if (this.hasInputTarget) {
      this.inputTarget.value = ""
    }
    if (this.hasResultsFrameTarget) {
      this.resultsFrameTarget.src = null
      this.resultsFrameTarget.innerHTML = ""
    }
  }

  selectionTargetConnected() {
    this.updateOutput(this.selectionTarget.value)
    this.onOptionSelect(this.selectionTarget.value)
  }

  selectionTargetDisconnected() {
    this.updateOutput("")
    this.onOptionSelect("")
  }

  onSearch(e) {
    this.updateSubmitButton()
    if (e.code == "ArrowDown") {
      this.focusOption(0)
    } else if (e.code == "Enter") {
      this.focusOption(0, true)
    } else {
      const params = {
        q: this.inputTarget.value,
        org_id: this.orgIdValue,
        frame_id: this.frameIdValue,
      }

      this.resultsFrameTarget.src = buildUrl(SUPPLIER_INTELLIGENCE_SEARCH_BASE_URL, params)
    }
  }

  updateSubmitButton() {
    if (this.hasSubmitButtonTarget) {
      const submitButton = this.submitButtonTarget

      if (this.inputTarget.value) {
        submitButton?.classList.add("bg-purple-500")
        submitButton?.classList.remove("bg-gray-300")
      } else {
        submitButton?.classList.remove("bg-purple-500")
        submitButton?.classList.add("bg-gray-300")
      }
    }
  }

  onClickOutsideOfResults = (e) => {
    if (!this.hasResultsFrameTarget) {
      return false
    }

    const isClickInsideElement = this.element.contains(e.target)
    if (!isClickInsideElement) {
      this.resultsFrameTarget.src = null
      this.resultsFrameTarget.innerHTML = ""

      this.inputTarget.value = ""
    }
  }

  focusOption(i, click = false) {
    const option = this.optionTargets[i]
    if (option) {
      option.focus()
      if (click) option.getElementsByTagName("a")[0].click()
    }
  }

  onOptionKeyUp(e) {
    if (e.code == "ArrowDown") {
      this.focusOption(e.params.id + 1)
    } else if (e.code == "ArrowUp") {
      this.focusOption(e.params.id - 1)
    } else if (e.code == "Enter") {
      e.target.getElementsByTagName("a")[0].click()
    }
  }

  updateOutput(val) {
    let outputValueElem = document.getElementById(this.outputValue)
    if (outputValueElem) {
      outputValueElem.setAttribute("value", val)
      const inputEvent = new Event("input")
      outputValueElem.dispatchEvent(inputEvent)
    }
  }

  onOptionSelect(value: string) {
    this.element.dispatchEvent(new CustomEvent("select", { detail: { value } }))
  }
}
