import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="zendesk-chat"
export default class extends Controller {
  static values = {
    opened: Boolean,
  }

  openedValue: boolean

  connect() {}

  openChat(e: Event) {
    e.preventDefault()

    if (typeof zE === "function") {
      if (!this.openedValue) {
        zE("messenger", "show")
        zE("messenger", "open")
        this.openedValue = true
      } else {
        zE("messenger", "close")
        this.openedValue = false
      }
    }
  }
}
