import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="default-open"
export default class extends Controller {
  static values = {
    type: {
      type: String,
      default: "element",
    },
    elementTarget: String,
    prefix: String,
    queryStringParam: String,
    turboFrame: String,
    turboFrameUrl: String,
  }
  typeValue: string
  queryStringParamValue: string
  prefixValue: string

  turboFrameValue: string
  turboFrameUrlValue: string
  hasTurboFrameValue: boolean
  hasTurboFrameUrlValue: boolean

  urlParams = new URLSearchParams(location.search)

  connect() {
    if (this.urlParams.has(this.queryStringParamValue)) {
      if (this.typeValue === "element") {
        this.openExistingElement()
      } else if (this.typeValue === "element-with-turbo-frame") {
        this.openExistingElement(this.observeElementPresence.bind(this))
      } else if (this.typeValue === "turbo-frame") {
        this.openTurboFrame()
      }
    }
  }

  openExistingElement(callback) {
    const objectId = this.urlParams.get(this.queryStringParamValue)
    const elem = document.getElementById(`${this.prefixValue}-${objectId}`)

    // If element exists then click else clear the urlParam
    if (elem) {
      elem.click()

      if (callback) callback()
    } else {
      this.urlParams.delete(this.queryStringParamValue)
      history.replaceState({}, null, `${window.location.pathname}?${this.urlParams.toString()}`)
    }
  }

  // Allows to open any turbo frame with a custom URL no matter if the triggering element exist in the current page or not
  openTurboFrame() {
    if (this.hasTurboFrameValue && this.hasTurboFrameUrlValue) {
      const turboFrameElement: any = document.querySelector(`turbo-frame[id="${this.turboFrameValue}"]`)
      if (turboFrameElement) {
        const url = this.turboFrameUrlValue.replace(
          this.queryStringParamValue,
          this.urlParams.get(this.queryStringParamValue),
        )
        turboFrameElement.src = url
        turboFrameElement.reload()
      }
    }
  }

  observeElementPresence() {
    if (this.hasElementTargetValue) {
      const objectId = this.urlParams.get(this.queryStringParamValue)
      const elem = document.getElementById(this.elementTargetValue)

      if (elem) {
        this.elementFound(elem)
      } else {
        const observer = new MutationObserver(() => {
          const observedElem = document.getElementById(this.elementTargetValue)
          if (observedElem) {
            this.elementFound(observedElem)
            observer.disconnect()
          }
        })

        observer.observe(document.body, { childList: true, subtree: true })
      }
    }
  }

  elementFound(element) {
    this.openTurboFrame()
  }
}
