import { Controller } from "@hotwired/stimulus"
import "@hotwired/turbo"

// Connects to data-controller="ios-check"
export default class extends Controller {
  disableIosTextFieldZoom(): void {
    if (/iPad|iPhone/.test(navigator.userAgent) && !window.MSStream) {
      const el = document.querySelector("meta[name=viewport]")

      if (el !== null) {
        let content = el.getAttribute("content")
        let options = /maximum\-scale=[0-9\.]+/g

        if (options.test(content)) {
          content = content.replace(options, "maximum-scale=1.0")
        } else {
          content = `${content},maximum-scale=1.0`
        }

        el.setAttribute("content", content)
      }
    }
  }
}
