import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="toggle-content"
export default class extends Controller {
  toggleUrlTarget: HTMLLinkElement
  keywordFieldLinkTarget: HTMLInputElement
  keywordFieldInputTarget: HTMLInputElement
  createTransactionRuleFieldTarget: HTMLInputElement

  static targets = ["toggleUrl", "keywordField", "keywordFieldLink", "keywordFieldInput", "createTransactionRuleField"]

  toggleContent() {
    this.toggleUrlTarget.click()
  }

  toggleKeyword(e: InputEvent) {
    e.preventDefault()

    if (e.target.id === "add-description-filter") {
      this.createTransactionRuleFieldTarget.value = "true"
    } else if (e.target.id === "remove-description-filter") {
      this.createTransactionRuleFieldTarget.value = "false"
    }

    this.keywordFieldLinkTarget.classList.toggle("hidden")
    this.keywordFieldInputTarget.classList.toggle("hidden")

    this.keywordFieldInputTarget
      .querySelectorAll("input, select")
      .forEach((input: HTMLInputElement | HTMLSelectElement) => {
        input.disabled = false
      })

    const addDescriptionFilter = this.keywordFieldLinkTarget.querySelector("#add-description-filter")
    addDescriptionFilter.classList.toggle("hidden")
  }
}
