import { Controller } from "@hotwired/stimulus"
import { show, hide } from "../utils"

// Connects to data-controller="collapsible"
export default class extends Controller {
  static targets = ["collapsible", "expandButton", "collapseButton"]

  collapsibleTarget: HTMLElement
  collapsibleTargets: Array<HTMLElement>

  expandButtonTarget: HTMLElement
  collapseButtonTarget: HTMLElement
  toggleButtonTarget: HTMLElement

  hasExpandButtonTarget: boolean
  hasCollapseButtonTarget: boolean
  hasToggleButtonTarget: boolean

  collapse(e) {
    const { preventDefault } = e.params
    if (preventDefault) {
      e.preventDefault()
    }

    this.collapsibleTargets.forEach((elem) => elem.classList.add("hidden"))

    if (this.hasExpandButtonTarget) {
      show(this.expandButtonTarget)
    }

    if (this.hasCollapseButtonTarget) {
      hide(this.collapseButtonTarget)
    }
  }

  expand(e) {
    const { preventDefault } = e.params
    if (preventDefault) {
      e.preventDefault()
    }

    this.collapsibleTargets.forEach((elem) => elem.classList.remove("hidden"))

    if (this.hasExpandButtonTarget) {
      hide(this.expandButtonTarget)
    }

    if (this.hasCollapseButtonTarget) {
      show(this.collapseButtonTarget)
    }
  }

  toggle(e) {
    const { preventDefault } = e.params
    if (preventDefault) {
      e.preventDefault()
    }

    this.collapsibleTargets.forEach((elem) => elem.classList.toggle("hidden"))
  }
}
