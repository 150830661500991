import { Controller } from "@hotwired/stimulus"
import { show, hide, isEmpty } from "../utils"
import { Task, Stage } from "../utils/types"
import { getStringLiterals } from "./mixins/use_typed_stimulus_ctrl"
import TaskStageController from "./task_stage_controller"
import TriggerFormsController from "./trigger_forms_controller"

type Task = {
  name: string
  id: string
}
type Stage = {
  order: Number
  name: string
  tasks: Array<Task>
}

// Connects to data-controller="trigger-form"
class TriggerFormController extends Controller {
  static outlets = ["task-stage", "trigger-forms"]
  taskStageOutlet: TaskStageController
  triggerFormsOutlet: TriggerFormsController

  static values = { requestSubmittedId: String, stageMap: Array }
  requestSubmittedIdValue: string
  stageMapValue: Array<Stage>

  static targets = getStringLiterals(["triggeredBy", "removeButton"])
  removeButtonTarget: HTMLButtonElement
  triggeredByTarget: HTMLSelectElement

  connect() {
    this.updateTriggeredByOptions()
    this.toggleRemoveButtons()
    this.toggleAddTriggerButton()
    this.triggerFormsOutlet.toggleFormDescription()
  }

  handleTriggeredByFieldChange() {
    this.taskStageOutlet.updateTriggers()
    this.toggleAddTriggerButton()
    this.triggerFormsOutlet.toggleFormDescription()
  }

  remove(e?: Event) {
    this.element.remove()

    this.toggleRemoveButtons()
  }

  update() {
    if (
      !isEmpty(this.triggeredByTarget.value) &&
      !this.validTasksForCurrentStage().includes(this.triggeredByTarget.value)
    ) {
      this.remove()
    } else {
      this.updateTriggeredByOptions()
    }
    this.updateTriggeredByOptions()
  }

  updateTriggeredByOptions() {
    Array.apply(null, this.triggeredByTarget.options).map((option) => {
      if (this.unselectedTriggeredByValues().includes(option.value)) {
        show(option)
      } else {
        hide(option)
      }
    })
  }

  private validTasksForCurrentStage() {
    const currentStageName = this.taskStageOutlet.stageSelectTarget.value
    const currentStageOrder = this.stageMapValue.filter((stage) => {
      return stage.name === currentStageName
    })[0].order

    const stageTasks = this.stageMapValue.map((stage) => {
      if (stage.order <= currentStageOrder) {
        return stage.tasks
      }
    })

    let updatedStageTasks = stageTasks
      .flat()
      .filter((task): task is Task => task !== undefined)
      .map((task) => task.id)

    if (this.allowRequestSubmitted()) {
      updatedStageTasks.unshift(this.requestSubmittedIdValue)
    }

    return updatedStageTasks
  }

  private allowRequestSubmitted(): boolean {
    return document.querySelectorAll("#workflow_task_triggers__triggered_by").length === 1
  }

  private selectedTriggeredByValues(): Array<string> {
    let currentTriggeredByDropdowns = this.currentTriggeredByDropdowns()
    return Array.from(currentTriggeredByDropdowns)
      .map((dropdown) => dropdown.value)
      .filter((e) => e)
  }

  private currentTriggeredByDropdowns(): NodeListOf<HTMLSelectElement> {
    return document.querySelectorAll("#workflow_task_triggers__triggered_by") as NodeListOf<HTMLSelectElement>
  }

  // Array of selectable task ids that are not already selected on the page
  private unselectedTriggeredByValues(): Array<string> {
    let unselectedValidTasksForCurrentStage = this.validTasksForCurrentStage().filter(
      (task) => !this.selectedTriggeredByValues().includes(task),
    )
    if (this.allowRequestSubmitted()) {
      unselectedValidTasksForCurrentStage.unshift(this.requestSubmittedIdValue)
    }
    let allOptionValuesInTriggeredByDropdown = Array.from(this.triggeredByTarget.options).map((option) => option.value)
    // Return the unselected tasks that are also available in the triggered by dropdown:
    return unselectedValidTasksForCurrentStage.filter((task) => allOptionValuesInTriggeredByDropdown.includes(task))
  }

  // Returns the "Add Trigger" button
  private addTriggerButton(): HTMLButtonElement | null {
    return document.querySelector('button[data-action="nested-form#add"]')
  }

  private toggleRemoveButtons() {
    const removeButtons = document.querySelectorAll("[data-trigger-form-target='removeButton']")

    if (removeButtons.length > 1) {
      removeButtons.forEach((triggerForm) => show(triggerForm))
    } else if (removeButtons.length === 1) {
      hide(removeButtons[0])
    }

    this.taskStageOutlet.updateAllTriggerOptions()
    this.toggleAddTriggerButton()
    this.triggerFormsOutlet.toggleFormDescription()
  }

  // Hide the "Add Trigger" button if all tasks have been selected as triggers
  // and all trigger types have been selected OR if the only selected trigger
  // is "Request Submitted"
  private toggleAddTriggerButton(): void {
    if (
      isEmpty(this.unselectedTriggeredByValues()) ||
      this.onlyTriggerIsRequestSubmitted() ||
      this.maxTriggersAdded()
    ) {
      hide(this.addTriggerButton())
    } else {
      show(this.addTriggerButton())
    }
  }

  private onlyTriggerIsRequestSubmitted(): boolean {
    const selectedTriggeredByValues = this.selectedTriggeredByValues()

    return selectedTriggeredByValues.length === 1 && selectedTriggeredByValues[0] === this.requestSubmittedIdValue
  }

  private maxTriggersAdded(): boolean {
    return this.currentTriggeredByDropdowns().length === 10
  }
}

export default TriggerFormController
