import { Controller } from "@hotwired/stimulus"
import { show } from "../../utils"

const RESPONSE_DELAYED_MS = 3000
// Connects to data-controller="ai-assistant--loader"
export default class extends Controller {
  static targets = ["loaderContainer", "delayedResponseMessage"]

  hasLoaderContainerTarget: boolean
  loaderContainerTarget: HTMLDivElement
  delayedResponseMessageTarget: HTMLSpanElement
  timeoutId: number

  connect(): void {
    if (this.hasLoaderContainerTarget) {
      this.timeoutId = setTimeout(() => {
        show(this.delayedResponseMessageTarget)
      }, RESPONSE_DELAYED_MS)
    }
  }

  disconnect(): void {
    clearTimeout(this.timeoutId)
  }
}
