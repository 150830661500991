import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="reload-frame"
export default class extends Controller {
  static targets = ["frameToReload"]

  frameToReloadTarget: HTMLDivElement

  connect() {}

  reload(event: Event) {
    if (event.target.type !== "select-one") {
      throw new Error("This controller is only currently implemented for select-one elements.")
    }

    const selectedIndex = event.currentTarget.selectedIndex
    const newFrameUrl = event.currentTarget.options[selectedIndex].dataset.frameUrl

    if (newFrameUrl) {
      this.frameToReloadTarget.src = newFrameUrl
    } else {
      console.debug(
        "Select options using this controller must have a data-frame-url attribute in order to reload the payment form.",
      )
    }
  }
}
