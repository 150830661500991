import { Controller } from "@hotwired/stimulus"
import { hide, show } from "../utils"

// Connects to data-controller="coupa-attachment-upload"
export default class extends Controller {
  static targets = [
    "textAttachmentTemplate",
    "urlAttachmentTemplate",
    "textAttachmentField",
    "fileAttachmentField",
    "urlAttachmentField",
    "attachmentList",
    "attachment",
  ]
  textAttachmentTemplateTarget: HTMLElement
  urlAttachmentTemplateTarget: HTMLElement
  textAttachmentFieldTarget: HTMLInputElement
  urlAttachmentFieldTarget: HTMLInputElement
  fileAttachmentFieldTarget: HTMLElement

  attachmentListTarget: HTMLElement

  connect() {
    hide(this.urlAttachmentFieldTarget.closest(".attachment-field-wrapper"))
    hide(this.textAttachmentFieldTarget.closest(".attachment-field-wrapper"))
    hide(this.fileAttachmentFieldTarget)
  }

  showUrlAttachmentField(event) {
    event.preventDefault()
    show(this.urlAttachmentFieldTarget.closest(".attachment-field-wrapper"))
    hide(this.textAttachmentFieldTarget.closest(".attachment-field-wrapper"))
    hide(this.fileAttachmentFieldTarget)
  }

  showTextAttachmentField(event) {
    event.preventDefault()
    hide(this.urlAttachmentFieldTarget.closest(".attachment-field-wrapper"))
    show(this.textAttachmentFieldTarget.closest(".attachment-field-wrapper"))
    hide(this.fileAttachmentFieldTarget)
  }

  showFileAttachmentField(event) {
    event.preventDefault()
    show(this.fileAttachmentFieldTarget)
    hide(this.urlAttachmentFieldTarget.closest(".attachment-field-wrapper"))
    hide(this.textAttachmentFieldTarget.closest(".attachment-field-wrapper"))
  }

  addUrlAttachment(event) {
    event.preventDefault()
    if (this.urlAttachmentFieldTarget.value.length) {
      const newUrlValue = this.urlAttachmentFieldTarget.value
      const content = this.urlAttachmentTemplateTarget.innerHTML.replaceAll("VALUE", newUrlValue)
      this.attachmentListTarget.insertAdjacentHTML("beforeend", content)
      this.clearAttachmentField(event)
    }
  }

  addTextAttachment(event) {
    event.preventDefault()
    if (this.textAttachmentFieldTarget.value.length) {
      const newTextValue = this.textAttachmentFieldTarget.value
      const content = this.textAttachmentTemplateTarget.innerHTML.replaceAll("VALUE", newTextValue)
      this.attachmentListTarget.insertAdjacentHTML("beforeend", content)
      this.clearAttachmentField(event)
    }
  }

  clearAttachmentField(event) {
    event.preventDefault()
    const attachmentWrapper = event.target.closest(".attachment-field-wrapper")
    attachmentWrapper.querySelector("input").value = ""
    const saveBtn = attachmentWrapper.querySelector(".save-btn")
    this.disableSaveButton(saveBtn)
  }

  removeAttachment(event) {
    event.preventDefault()
    const attachment = event.target.closest("[data-coupa-attachment-upload-target]")
    attachment.remove()
  }

  handleAttachmentFieldChange(event) {
    const saveBtn = event.target.closest(".attachment-field-wrapper").querySelector(".save-btn")
    if (event.target.value.length) {
      this.enableSaveButton(saveBtn)
    } else {
      this.disableSaveButton(saveBtn)
    }
  }

  disableSaveButton(saveBtn) {
    saveBtn.disabled = true
    saveBtn.classList.add("disabled")
  }

  enableSaveButton(saveBtn) {
    saveBtn.disabled = false
    saveBtn.classList.remove("disabled")
  }
}
