import { Controller } from "@hotwired/stimulus"
import { show, hide } from "../utils"

// Connects to data-controller="add-and-remove-item"
export default class extends Controller {
  static targets = ["template", "addItemButton", "item", "list"]
  static values = { minimum: Number, counter: Number }

  connect() {
    this.counterValue = 0
    this.renderItems()
  }

  renderItems() {
    if (this.itemTargets.length < this.minimumValue) {
      for (let i = this.itemTargets.length; i < this.minimumValue; i++) {
        this.counterValue++
        let content = this.templateTarget.innerHTML.replaceAll("UNIQUE_ID", this.counterValue)
        this.listTarget.insertAdjacentHTML("beforebegin", content)
      }

      this.conditionallyDisableRemoveButton()
    }
  }

  conditionallyDisableRemoveButton() {
    if (this.itemTargets.length === 1 && this.minimumValue >= 1) {
      let removeButton = this.itemTargets[0].querySelector("[data-remove-button]")
      removeButton.disabled = true
      removeButton.classList.add("disabled")
    }
  }

  conditionallyEnableRemoveButton() {
    if (this.itemTargets.length === 1) {
      let removeButton = this.itemTargets[0].querySelector("[data-remove-button]")
      removeButton.disabled = false
      removeButton.classList.remove("disabled")
    }
  }

  addItem(event) {
    event.preventDefault()
    this.counterValue++
    this.conditionallyEnableRemoveButton()

    let content = this.templateTarget.innerHTML.replaceAll("UNIQUE_ID", this.counterValue)
    this.listTarget.insertAdjacentHTML("beforeend", content)

    // Dispatch a custom event so that other controllers can perform relevant actions when a new item is added.
    const newItem = this.listTarget.lastElementChild
    const addItemEvent = new CustomEvent("newItemAdded", {
      bubbles: true,
      detail: { newItem },
    })
    document.dispatchEvent(addItemEvent)
  }

  removeItem(event) {
    event.preventDefault()
    const button = event.target.closest("[data-remove-button]")
    if (button.disabled) {
      return
    }

    let item = event.target.closest("[data-add-and-remove-item-target]")
    item.remove()

    this.conditionallyDisableRemoveButton()
  }
}
