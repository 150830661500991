import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="supplier-intelligence--nav"
export default class extends Controller {
  static targets = ["strategy", "tactics", "navLink"]
  strategyTarget: HTMLDivElement
  tacticsTarget: HTMLDivElement
  navLinkTargets: HTMLLinkElement[]

  static values = {
    activeClasses: Array<string>,
  }
  activeClassesValue: string[]

  strategyObserver = null
  tacticsObserver = null

  connect(): void {
    window.addEventListener("resize", this.updateTacticsObserverThreshold.bind(this))
    this.initTargetObservers()
    this.updateTacticsObserverThreshold()
  }

  disconnect(): void {
    window.removeEventListener("resize", this.updateTacticsObserverThreshold.bind(this))
    this.disconnectTargetObservers()
  }

  initTargetObservers(): void {
    this.strategyObserver = new IntersectionObserver(this.observerCallback, { threshold: 0.35 })
    this.tacticsObserver = new IntersectionObserver(this.observerCallback) // Threshold will be updated dynamically

    this.strategyObserver.observe(this.strategyTarget)
    this.tacticsObserver.observe(this.tacticsTarget)
  }

  disconnectTargetObservers(): void {
    this.strategyObserver.disconnect()
    this.tacticsObserver.disconnect()

    this.strategyObserver = null
    this.tacticsObserver = null
  }

  observerCallback = (entries): void => {
    entries.forEach((entry) => {
      const navLink = this.findNavLink(entry.target.id)

      if (entry.isIntersecting) {
        navLink.classList.add(...this.activeClassesValue)
      } else {
        navLink.classList.remove(...this.activeClassesValue)
      }
    })
  }

  updateTacticsObserverThreshold(): void {
    const threshold = this.calculateTreshold()
    this.tacticsObserver.disconnect() // Disconnect the observer
    this.tacticsObserver = new IntersectionObserver(this.observerCallback, { threshold })
    this.tacticsObserver.observe(this.tacticsTarget) // Reconnect the observer
  }

  calculateTreshold(): number {
    const elementRect = this.tacticsTarget.getBoundingClientRect()
    const viewportHeight = window.innerHeight
    const elementTop = Math.max(0, elementRect.top)
    const elementBottom = Math.min(viewportHeight, elementRect.bottom)
    const visibleHeight = elementBottom - elementTop
    const elementHeight = elementRect.height

    // Calculate the percentage of the element that is visible
    const visiblePercentage = visibleHeight / elementHeight

    return visiblePercentage < 0.5 ? 0.15 : 0.5
  }

  scrollToAnchor(event): void {
    event.preventDefault()

    const targetId = event.params?.anchorId

    let targetElement = null

    if (targetId === "supplier-intelligence-negotiation-strategy") {
      targetElement = this.strategyTarget
    } else if (targetId === "supplier-intelligence-negotiation-tactics") {
      targetElement = this.tacticsTarget
    }

    if (!targetElement) {
      console.error(`No anchor element found with id: ${targetId}`)
      return
    }

    targetElement.scrollIntoView({ behavior: "smooth" })
  }

  findNavLink(anchorId: string): HTMLElement {
    return this.navLinkTargets.find((navLink) => navLink.dataset["supplierIntelligence-NavAnchorIdParam"] === anchorId)
  }
}
