import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="documents-toggle"
export default class extends Controller {
  // bind() creates a new function with the given context, we need to store the
  // reference of that function so we can then remove the listener
  toggleButtonTextHandler = this.toggleButtonText.bind(this)

  connect() {
    document.addEventListener("SlidingSidebar:onToggle", this.toggleButtonTextHandler)
  }

  disconnect() {
    document.removeEventListener("SlidingSidebar:onToggle", this.toggleButtonTextHandler)
  }

  toggleSlidingSidebar() {
    document.dispatchEvent(new CustomEvent("SlidingSidebar:toggle"))
  }

  toggleButtonText(drawerEvent) {
    const drawerOpened = (drawerEvent as CustomEvent).detail.open
    const showButton = document.getElementById("sliding-sidebar-show")
    const hideButton = document.getElementById("sliding-sidebar-hide")
    if (drawerOpened) {
      this.displayHideButton(showButton, hideButton)
    } else {
      this.displayShowButton(showButton, hideButton)
    }
  }

  displayShowButton(showButton, hideButton) {
    showButton.classList.remove("hidden")
    hideButton.classList.add("hidden")
  }

  displayHideButton(showButton, hideButton) {
    showButton.classList.add("hidden")
    hideButton.classList.remove("hidden")
  }
}
