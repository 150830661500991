// stimulus controller
import { Controller } from "@hotwired/stimulus"
import { pluralize } from "../../utils/inflector"

export default class FieldObserverController extends Controller {
  static targets = ["field", "unsaved", "dialog", "discardChanges"]

  fieldTargets!: HTMLInputElement[]
  unsavedTarget!: HTMLElement
  dialogTarget!: HTMLDialogElement
  discardChangesTarget!: HTMLButtonElement

  declare hasUnsavedChanges: boolean

  countUnsavedChanges(): void {
    const unsavedChanges = this.fieldTargets.reduce((count, field) => {
      const isChanged =
        field.type === "checkbox"
          ? field.checked !== this.convertToBoolean(field.dataset.initialValue)
          : field.value !== (field.dataset.initialValue ?? "")

      return count + (isChanged ? 1 : 0)
    }, 0)

    const count = unsavedChanges
    this.hasUnsavedChanges = count > 0
    const message = count === 0 ? "" : pluralize(`${count} unsaved change`, count)
    this.unsavedTarget.textContent = message
  }

  convertToBoolean(value: string | undefined): boolean {
    const normalizedValue = /^true$/i.test(value)
    return normalizedValue
  }

  preventPageUnload(event: Event): void {
    if (this.hasUnsavedChanges) {
      event.preventDefault()

      this.dialogTarget.showModal()
      this.discardChangesTarget.dataset.nextPage = event.currentTarget.dataset.nextPage
    }
  }

  closeDialog(): void {
    this.dialogTarget.close()
  }

  discardChanges(event: Event): void {
    this.dialogTarget.close()
    Turbo.visit(event.currentTarget.dataset.nextPage)
  }
}
