import { Controller } from "@hotwired/stimulus"
import { disable } from "../utils"

// Connects to data-controller="department-spend-approval-group-template"
export default class extends Controller {
  static targets = ["editButton"]
  editButtonTargets: HTMLDivElement[]

  disableEditButtons() {
    this.editButtonTargets.forEach((button) => {
      const parent = button.parentElement
      parent.classList.add("pointer-events-none")
      disable(button)
    })
  }
}
