import { Controller } from "@hotwired/stimulus"
import { disable, enable, hide, show } from "../utils"
import { post } from "@rails/request.js"

// Connects to data-controller="contract-price-driver-form"
export default class extends Controller {
  static targets = ["newPriceDriverButtonWrapper", "unitCostField", "quantityField"]

  static values = {
    formChangeUrl: String,
    initialCount: String,
  }

  formChangeUrlValue: string

  hasFormTarget: boolean
  hasLineItemCaptionTarget: boolean

  formTarget: HTMLFormElement
  newPriceDriverButtonWrapperTarget: HTMLDivElement
  unitCostFieldTarget: HTMLInputElement
  quantityFieldTarget: HTMLInputElement

  onRemovedPriceDriverHandler = this.onRemovedPriceDriver.bind(this)

  connect() {
    document.addEventListener("nested-form:remove", this.onRemovedPriceDriverHandler)
  }

  disconnect() {
    document.removeEventListener("nested-form:remove", this.onRemovedPriceDriverHandler)
  }

  onFormChange(e): void {
    const { partial, preventDefault } = e.params
    if (preventDefault) {
      e.preventDefault()
    }

    const target = e.target
    const form = this.hasFormTarget ? this.formTarget : target.form
    const formData = new FormData(form)
    formData.set("total_price_drivers", this.totalPriceDrivers())

    if (e.target.checked) {
      hide(this.newPriceDriverButtonWrapperTarget)
      let priceDriverTemplates = document.getElementsByClassName("active_form")
      Array.from(priceDriverTemplates).forEach(function (element) {
        element.innerHTML = ""
      })

      this.resetCountToOne()
    } else {
      this.resetCountToDefault()
      show(this.newPriceDriverButtonWrapperTarget)
    }

    let url = this.formChangeUrlValue
    if (partial) {
      const params = new URLSearchParams()
      if (partial.includes(",")) {
        partial.split(",").forEach((p) => {
          params.append("partial[]", p)
        })
      } else {
        params.set("partial", partial)
      }
      url = `${url}?${params.toString()}`
    }

    post(url, {
      responseKind: "turbo-stream",
      body: formData,
    })
  }

  incrementCount(): void {
    let totalPriceDrivers = document.getElementsByClassName("price-driver-card").length
    this.getPriceDriverCountElement().innerHTML = `${totalPriceDrivers}`
  }

  resetCountToOne(): void {
    this.getPriceDriverCountElement().innerHTML = "1"
  }

  resetCountToDefault(): void {
    this.getPriceDriverCountElement().innerHTML = this.initialCountValue
  }

  decrementCount(): void {
    let countDiv = this.getPriceDriverCountElement()
    countDiv.innerHTML = String(Number(countDiv.innerHTML - 1))
  }

  onRemovedPriceDriver(e): void {
    this.toggleUnitCost(e)
    this.decrementCount()
  }

  toggleUnitCost(e): void {
    let eventType = e.type
    let firstUnitCostField = this.unitCostFieldTargets[0]
    let firstQuantityField = this.quantityFieldTargets[0]
    if (eventType === "nested-form:add" && this.unitCostFieldTargets.length > 1) {
      enable(firstUnitCostField)
      firstUnitCostField.value = null
      firstQuantityField.value = null
    } else if (this.unitCostFieldTargets.length == 1) {
      disable(firstUnitCostField)
      firstUnitCostField.value = null
      firstQuantityField.value = null
    }
  }

  private getPriceDriverCountElement(): Element {
    return document.getElementById("price-driver-count")
  }

  private totalPriceDrivers(): string {
    return this.getPriceDriverCountElement().innerHTML.trim()
  }
}
