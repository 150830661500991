export const CURRENCY_MASK_CONFIG = {
  DEFAULT: { mask: Number, scale: 2, thousandsSeparator: ",", padFractionalZeros: true, radix: ".", min: 0 },
  CLP: { mask: Number, scale: 0, thousandsSeparator: ",", min: 0 },
  HUF: { mask: Number, scale: 0, thousandsSeparator: ",", min: 0 },
  JPY: { mask: Number, scale: 0, thousandsSeparator: ",", min: 0 },
  KRW: { mask: Number, scale: 0, thousandsSeparator: ",", min: 0 },
  VND: { mask: Number, scale: 0, thousandsSeparator: ",", min: 0 },
}

export const currencyMaskConfigFor = (currency) => CURRENCY_MASK_CONFIG[currency] || CURRENCY_MASK_CONFIG["DEFAULT"]

// When setting the value of a masked field, we need to dispatch a "blur"
// event so the mask gets re-applied.
export const updateMaskedInputValue = (el, value) => {
  el.value = value
  el.dispatchEvent(new Event("blur", { bubbles: false }))
}
