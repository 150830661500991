import { Controller } from "@hotwired/stimulus"
import { updateMaskedInputValue } from "../utils/mask"

// Connects to data-controller="savings-form"
export default class extends Controller {
  static targets = [
    "baselineValueField",
    "contractIdField",
    "contractValueField",
    "savingsField",
    "distributionField",
    "distributionWrapper",
    "periodForm",
    "periodInput",
    "customSavingsField",
    "savingsGuaranteeField",
    "currencySelect",
  ]
  baselineValueFieldTarget: HTMLInputElement
  contractIdFieldTarget: HTMLSelectElement
  contractValueFieldTarget: HTMLInputElement
  savingsFieldTarget: HTMLInputElement
  customSavingsFieldTarget: HTMLInputElement
  distributionWrapperTarget: HTMLDivElement
  distributionFieldTarget: HTMLSelectElement
  savingsGuaranteeFieldTarget: HTMLSelectElement
  periodFormTargets: HTMLDivElement[]
  periodInputTargets: HTMLInputElement[]
  currencySelectTargets: HTMLSelectElement

  static values = {
    contractOptionsData: Object,
    requestCurrency: String,
  }

  contractOptionsDataValue: any
  requestCurrencyValue: String

  connect() {
    if (this.contractIdFieldTarget.value) {
      const { period_count } = this.contractOptionsDataValue[this.contractIdFieldTarget.value] || {}

      this.handleDistributionVisibility(period_count)
      this.handlePeriodVisibility()
      this.calculatePeriodSavings()
      this.handleCurrencyChange()
    }
  }

  // Event driven actions
  handleRelatedContractChange(event): void {
    const { value: contractValue, period_count: periodCount } =
      this.contractOptionsDataValue[this.contractIdFieldTarget.value] || {}

    this.contractValueFieldTarget.value = contractValue ? contractValue : ""
    this.savingsFieldTarget.value = this.calculateSavings(contractValue)

    this.handleDistributionVisibility(periodCount)
    this.handlePeriodVisibility()
    this.calculatePeriodSavings()
    this.handleCurrencyChange()
  }

  handleSavingChange(): void {
    updateMaskedInputValue(this.savingsFieldTarget, this.calculateSavings())
    this.calculatePeriodSavings()
  }

  handleDistributionChange(): void {
    this.handlePeriodVisibility()
    this.calculatePeriodSavings()
  }

  // Show and hide
  handleDistributionVisibility(periodCount): void {
    const shouldShowDistribution = periodCount > 1
    this.distributionWrapperTarget.hidden = !shouldShowDistribution
    this.distributionFieldTarget.value = shouldShowDistribution ? this.distributionFieldTarget.value : null
    this.distributionFieldTarget.required = shouldShowDistribution
  }

  handlePeriodVisibility(): void {
    const shouldShowPeriods = this.distributionFieldTarget.value == "custom"
    this.hidePeriodWrapper(this.periodFormTargets)

    if (shouldShowPeriods) {
      const activeContractPeriod = this.findActivePeriod()
      activeContractPeriod.hidden = !shouldShowPeriods
    }
  }

  handleCurrencyChange(): void {
    // if a related contract is selected, a contractIdFieldTarget.value will
    // be present. in this case, set all currency inputs to the selected
    // contract's currency.
    //
    // if no contract is selected from the contract select input, set all
    // currency inputs to the request's currency.
    if (this.contractIdFieldTarget.value) {
      const { currency } = this.contractOptionsDataValue[this.contractIdFieldTarget.value]

      this.currencySelectTargets.forEach((currencySelectTarget) => {
        currencySelectTarget.tomselect?.setValue(currency)
      })
    } else {
      this.currencySelectTargets.forEach((currencySelectTarget) => {
        currencySelectTarget.tomselect?.setValue(this.requestCurrencyValue)
      })
    }
  }

  // Calculations and assignments
  calculateSavings(presetContractValue = undefined): string {
    const baselineValue = this.baselineValueFieldTarget.value
    const contractValue = presetContractValue || this.contractValueFieldTarget.value

    return contractValue && baselineValue
      ? (parseFloat(baselineValue.replace(/,/g, "")) - parseFloat(contractValue.replace(/,/g, "")))
          .toFixed(2)
          .toString()
      : ""
  }

  calculatePeriodSavings(): void {
    // Don't reset inputs when distribution is custom - issue when going back to edit savings and values being set back to 0
    if (this.distributionFieldTarget.value !== "custom") {
      this.resetPeriodInputs(this.periodInputTargets)
    }
    const activePeriod = this.findActivePeriod()

    if (!activePeriod) {
      return
    }

    const activePeriodInputs = activePeriod.querySelectorAll("input.period-input")
    const contract = this.contractOptionsDataValue[this.contractIdFieldTarget.value]
    const contractSavings = this.getSavingsValue(contract)

    switch (this.distributionFieldTarget.value) {
      case "first_only":
        activePeriodInputs.forEach((periodInput, index) => {
          periodInput.disabled = false
          periodInput.value = index === 0 ? contractSavings : "0"
        })
        break

      case "even":
        const evenlyDistributedValue = Math.round(parseInt(contractSavings) / contract["period_count"])
        const sanitizedPeriodInputValue = isNaN(evenlyDistributedValue) ? "" : evenlyDistributedValue.toString()
        activePeriodInputs.forEach((periodInput) => {
          periodInput.disabled = false
          periodInput.value = sanitizedPeriodInputValue
        })
        break

      case "custom":
        activePeriodInputs.forEach((periodInput) => {
          periodInput.disabled = false
          periodInput.required = true
        })
        break

      default:
        activePeriodInputs[0].disabled = false
        updateMaskedInputValue(activePeriodInputs[0], contractSavings)
    }
  }

  // Period specific functions
  hidePeriodWrapper(periods): void {
    periods.forEach((period) => {
      period.hidden = true
    })
  }

  resetPeriodInputs = (periods): void => {
    periods.forEach((periodInput) => {
      periodInput.value = null
      periodInput.required = false
      periodInput.disabled = true
    })
  }

  findActivePeriod() {
    return this.periodFormTargets.find((el) => el.id == this.contractIdFieldTarget.value)
  }

  getSavingsValue = (contract): string => {
    if (this.savingsGuaranteeFieldTarget.value == "custom") {
      return this.customSavingsFieldTarget.value
    } else {
      return this.calculateSavings(contract.value)
    }
  }
}
