import { Controller } from "@hotwired/stimulus"
import { Turbo } from "@hotwired/turbo-rails"

// Connects to data-controller="workflows--external-task-starting-point"
export default class extends Controller {
  static targets = ["loader"]
  static values = {
    initializerUrl: String,
    initializerAsFrame: Boolean,
    linkToExistingUrl: String,
  }
  loaderTarget: HTMLElement
  initializerUrlValue: String
  initializerAsFrameValue: Boolean
  linkToExistingUrlValue: String

  connect() {}

  showLoader() {
    this.loaderTarget.classList.remove("hidden")
  }

  startingPointChanged(event) {
    this.showLoader()
    if (event.target.value == "new") {
      if (this.initializerAsFrameValue) {
        Turbo.visit(this.initializerUrlValue, { frame: "external_task_modal_content" })
      } else {
        Turbo.visit(this.initializerUrlValue)
      }
    } else {
      Turbo.visit(this.linkToExistingUrlValue, { frame: "external_task_modal_content" })
    }
  }
}
