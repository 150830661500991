import { Controller } from "@hotwired/stimulus"
import { hide, show } from "../../utils"

// Connects to data-controller="workflows--coupa-onboard-supplier"
export default class extends Controller {
  static targets = [
    "disabledButtonContainer",
    "enabledButtonContainer",
    "supplierOnboardingContainer",
    "matchingSupplierContainer",
    "form",
  ]

  disabledButtonContainerTarget: HTMLDivElement
  enabledButtonContainerTarget: HTMLDivElement
  supplierOnboardingContainerTarget: HTMLDivElement
  matchingSupplierContainerTarget: HTMLDivElement
  formTarget: HTMLFormElement

  connect() {
    this.toggleSubmit()
  }

  toggleSubmit() {
    if (this.formTarget.checkValidity()) {
      hide(this.disabledButtonContainerTarget)
      show(this.enabledButtonContainerTarget)
    } else {
      show(this.disabledButtonContainerTarget)
      hide(this.enabledButtonContainerTarget)
    }
  }

  showOnboardingSupplier(e: Event) {
    e.preventDefault()
    hide(this.matchingSupplierContainerTarget)
    show(this.supplierOnboardingContainerTarget)
  }

  showMatchingSupplier(e: Event) {
    e.preventDefault()
    hide(this.supplierOnboardingContainerTarget)
    show(this.matchingSupplierContainerTarget)
  }
}
