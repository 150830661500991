import { Controller } from "@hotwired/stimulus"
import { show, hide } from "../utils"

// Connects to data-controller="contract-discount-form"
export default class extends Controller {
  // targets
  static targets = [
    "discountAmountInput",
    "discountAmountWrapper",
    "discountPercentageInput",
    "discountPercentageWrapper",
    "discountStructure",
    "formWrapper",
  ]

  hasDiscountStructureTarget: boolean

  discountAmountInputTarget: HTMLInputElement
  discountAmountWrapperTarget: HTMLInputElement
  discountPercentageInputTarget: HTMLInputElement
  discountPercentageWrapperTarget: HTMLInputElement
  discountStructureTarget: HTMLInputElement
  formWrapperTarget: HTMLDivElement

  connect() {
    if (this.hasDiscountStructureTarget) {
      this.onDiscountStructureChange({ target: { value: this.discountStructureTarget.value } })
    }
  }

  onDiscountStructureChange(e) {
    switch (e.target.value) {
      case "Amount":
        show(this.discountAmountWrapperTarget)
        hide(this.discountPercentageWrapperTarget)
        this.discountAmountInputTarget.required = true
        this.discountPercentageInputTarget.required = false
        this.discountPercentageInputTarget.value = null
        break
      case "Percentage":
        show(this.discountPercentageWrapperTarget)
        hide(this.discountAmountWrapperTarget)
        this.discountPercentageInputTarget.required = true
        this.discountAmountInputTarget.required = false
        this.discountAmountInputTarget.value = null
        break
      default:
        hide(this.discountPercentageWrapperTarget)
        hide(this.discountAmountWrapperTarget)
        this.discountAmountInputTarget.required = false
        this.discountAmountInputTarget.value = null
        this.discountPercentageInputTarget.required = false
        this.discountPercentageInputTarget.value = null
    }
  }
}
