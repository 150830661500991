import { Controller } from "@hotwired/stimulus"
import { hide } from "../utils"

// Connects to data-controller="checkbox-group"
export default class extends Controller {
  static targets = ["checkbox"]
  static values = {
    key: String,
    required: Boolean,
  }

  id: String
  validationInput: HTMLInputElement
  keyValue: String
  requiredValue: Boolean
  checkedSet: Set<String>
  checkboxTargets: Array<HTMLInputElement>

  connect() {
    this.checkedSet = new Set(this.checkboxTargets.filter((c) => c.checked).map((c) => c.id))
    this.validationInput = this.appendValidationInput()
    this.syncToValidationInput()
  }

  onChange({ target }) {
    if (target.checked) {
      this.checkedSet.add(target.id)
    } else {
      this.checkedSet.delete(target.id)
    }
    this.syncToValidationInput()
  }

  onCardChange({ target }) {
    if (this.isSingularCheckbox(target)) {
      this.toggleSingularCheckbox(target)
    } else {
      this.toggleMultipleCheckbox(target)
    }

    this.syncToValidationInput()
  }

  isSingularCheckbox(target) {
    return target.dataset.singular === "true"
  }

  toggleMultipleCheckbox(target) {
    if (target.checked) {
      this.checkboxTargets.map((c) => {
        if (this.isSingularCheckbox(c)) {
          c.checked = false
          this.checkedSet.delete(c.id)
        }
      })

      this.checkedSet.add(target.id)
    } else {
      this.checkedSet.delete(target.id)
    }
  }

  toggleSingularCheckbox(target) {
    if (target.checked) {
      this.checkboxTargets.map((c) => {
        if (c !== target) {
          c.checked = false
        }
      })

      this.checkedSet = new Set([target.id])
    } else {
      this.checkedSet.delete(target.id)
    }
  }

  syncToValidationInput() {
    const anyChecked = this.checkedSet.size > 0
    this.validationInput.value = anyChecked ? "true" : null
  }

  appendValidationInput() {
    const input = document.createElement("input")
    input.setAttribute("id", `checkbox_group_${this.keyValue}_validation`)
    input.setAttribute("type", "text")
    if (this.requiredValue) {
      input.setAttribute("required", "")
    }
    hide(input)
    this.element.appendChild(input)
    return input
  }
}
