import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="edit-subscription-purchased-credits"
export default class extends Controller {
  static targets = ["numberField", "checkbox"]
  static values = {
    creditAmount: Number,
  }
  numberFieldTarget: HTMLInputElement
  checkboxTarget: HTMLInputElement

  connect() {
    this.toggleNumberField()
  }

  toggleNumberField() {
    if (this.checkboxTarget.checked) {
      this.numberFieldTarget.disabled = true
      this.numberFieldTarget.value = ""
    } else {
      this.numberFieldTarget.disabled = false
      this.numberFieldTarget.value = this.creditAmountValue
    }
  }
}
