import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="set-input-value"
export default class extends Controller {
  static targets = ["input"]
  inputTarget: HTMLInputElement

  connect() {}

  setValue({ params }) {
    this.inputTarget.value = params.value
  }
}
