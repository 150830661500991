import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="requests--review"
export default class extends Controller {
  static targets = ["form", "urgent", "urgencyNotes"]
  formTarget: HTMLFormElement
  urgentTarget: HTMLElement
  urgencyNotesTarget: HTMLElement

  static values = { urgent: Boolean }
  urgentValue: boolean

  connect() {
    this.displayUrgencyNotes(this.urgentValue)
  }

  updateForm(e) {
    let urgent = !(e.target.value == "0" || e.target.value == "false")
    this.displayUrgencyNotes(urgent)
  }

  displayUrgencyNotes(conditionApplied) {
    if (conditionApplied) {
      let urgencyNotesField = this.urgencyNotesTarget.content.cloneNode(true)
      this.formTarget.insertBefore(urgencyNotesField, this.urgentTarget.nextSibling)
    } else {
      let urgencyNotesField = document.getElementById("urgency_notes_container")
      if (urgencyNotesField) this.formTarget.removeChild(urgencyNotesField)
    }
  }
}
