import { Controller } from "@hotwired/stimulus"
import { show, hide } from "../utils"

// Connects to data-controller="review-assign-modal"
export default class extends Controller {
  static targets = [
    "rerenderTasksButton",
    "rerenderTaskId",
    "rerenderAssigneeId",
    "assignTasksView",
    "newSupplierContactView",
  ]

  rerenderTasksButtonTarget: HTMLButtonElement
  rerenderTaskIdTarget: HTMLInputElement
  rerenderAssigneeIdTarget: HTMLInputElement
  assignTasksViewTarget: HTMLElement
  newSupplierContactViewTarget: HTMLElement

  showSupplierContactForm(event) {
    this.rerenderTaskIdTarget.value = event.params.taskId
    hide(this.assignTasksViewTarget)
    show(this.newSupplierContactViewTarget)
  }

  hideSupplierContactForm() {
    show(this.assignTasksViewTarget)
    hide(this.newSupplierContactViewTarget)
  }

  rerenderTasks(newSupplierContactId: string) {
    this.rerenderAssigneeIdTarget.value = newSupplierContactId
    this.rerenderTasksButtonTarget.click()
  }

  handleSupplierContactSubmit(e) {
    e.detail.fetchResponse.response.json().then((data) => {
      const newSupplierContactId = data.contactId
      this.rerenderTasks(newSupplierContactId)
      this.hideSupplierContactForm()
    })
  }
}
