//the stimulus js controller will have an input target and output target, where we will get inputTarget.value.length
// and outputTarget will receive the value of the inputTarget.value.length/this.maxValue. when inputTarget.value.length
// is 0 or undefined the outputTarget should get this.minValue. When inputTarget.value.length is equal the this.maxValue
// we don't allow the user to type more characters.

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input", "output"]
  static values = { max: Number, min: Number }
  static classes = ["error"]

  connect() {
    this.updateOutput()
  }

  updateOutput() {
    const value = this.inputTarget.value.length
    this.outputTarget.classList.remove(this.errorClass)

    if (value === 0 || value === undefined) {
      this.setOutputText()
    } else if (value >= parseInt(this.maxValue, 10)) {
      this.inputTarget.value = this.inputTarget.value.slice(0, parseInt(this.maxValue, 10))
      this.setOutputText()
      this.outputTarget.classList.add(this.errorClass)
    } else {
      this.setOutputText()
    }
  }

  setOutputText() {
    this.outputTarget.textContent = `(${this.inputTarget.value.length} / ${this.maxValue} Char)`
  }
}
