import { Controller } from "@hotwired/stimulus"
import { isTomSelectInput } from "../utils"

// Connects to data-controller="suggested-ai-values"
export default class extends Controller {
  static targets = ["suggestedField", "finalField", "finalCurrencyField", "dropdown"]

  suggestedFieldTargets: HTMLCollectionOf<Element>
  finalFieldTarget: HTMLInputElement
  dropdownTarget: HTMLButtonElement
  finalCurrencyFieldTarget: HTMLButtonElement
  hasFinalCurrencyFieldTarget: boolean

  selectSuggestedValue(e) {
    const idOfTarget = e.currentTarget.id
    const fieldForThisSuggestion = this.suggestedFieldTargets.find((element) => element.id == idOfTarget)
    if (!fieldForThisSuggestion) return

    if (isTomSelectInput(this.finalFieldTarget)) {
      this.finalFieldTarget.tomselect.setValue(fieldForThisSuggestion.tomselect.getValue())
    } else {
      this.finalFieldTarget.value = fieldForThisSuggestion.value
      this.finalFieldTarget.classList.remove("is-empty")

      if (this.hasFinalCurrencyFieldTarget) {
        let currency = fieldForThisSuggestion.nextElementSibling.innerHTML
        this.finalCurrencyFieldTarget.tomselect.setValue(currency)
      }
    }
    this.dropdownTarget.click()
  }
}
