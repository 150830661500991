import { Controller } from "@hotwired/stimulus"
import { show, hide } from "../../utils"

// Connects to data-controller="discover--price-checks"
export default class extends Controller {
  static targets = [
    "filteredByContractSizeLowerBoundHeader",
    "filteredByContractSizeLowerToUpperBoundHeader",
    "filteredByContractSizeUpperBoundHeader",
    "unfilteredHeader",
    "filteredByContractSizeLowerBound",
    "filteredByContractSizeLowerToUpperBound",
    "filteredByContractSizeUpperBound",
    "unfiltered",
  ]

  changeFilter(event) {
    switch (event.target.value) {
      case "filtered_by_contract_size_lower_bound":
        this.hideOtherTargets("LowerBound")
        break
      case "filtered_by_contract_size_lower_to_upper_bound":
        this.hideOtherTargets("LowerToUpperBound")
        break
      case "filtered_by_contract_size_upper_bound":
        this.hideOtherTargets("UpperBound")
        break
      default:
        this.hideOtherTargets("unfiltered")
    }
  }

  hideOtherTargets(targetToShow) {
    let targetHeaderToShowName =
      targetToShow === "unfiltered" ? "unfilteredHeader" : `filteredByContractSize${targetToShow}Header`
    let targetToShowName = targetToShow === "unfiltered" ? "unfiltered" : `filteredByContractSize${targetToShow}`
    let otherTargets = Array.from(this.constructor.targets)

    otherTargets.splice(otherTargets.indexOf(targetHeaderToShowName), 1)
    otherTargets.splice(otherTargets.indexOf(targetToShowName), 1)

    show(this[`${targetHeaderToShowName}Target`])
    show(this[`${targetToShowName}Target`])

    otherTargets.forEach((item) => {
      if (this[`has${item.charAt(0).toUpperCase() + item.slice(1)}Target`]) {
        hide(this[`${item}Target`])
      }
    })
  }
}
