import React, { useState } from "react"

interface IProps {
  startingCount: number
}

const ExampleComponent = (props: IProps) => {
  const { startingCount } = props
  const [count, setCount] = useState(startingCount)

  return (
    <>
      <p className="mt-1 max-w-2xl text-xl text-gray-500">{count}</p>
      <br />
      <button
        type="button"
        onClick={() => setCount(count + 1)}
        className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-purple-500 hover:bg-purple-600  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        Counter ++
      </button>
    </>
  )
}

export default ExampleComponent
