import { Controller } from "@hotwired/stimulus"
import { show, hide } from "../../utils"
import * as EmailValidator from "email-validator"
import { NestedFormSyncer } from "../../utils/comment_form/NestedFormSyncer"

// Connects to data-controller="routing-modal-controller"
export default class extends Controller {
  static targets = [
    "contractRoutingSelect",
    "routeContractToSelect",
    "signatorySelect",
    "signatoryCopy",
    "supplierContactSelect",
    "genericEmailAddressContainer",
    "genericEmailAddressField",
    "routingForm",
    "commentContentHiddenField",
    "commentInternalHiddenField",
    "contractRoutingError",
    "routeError",
    "signatoryError",
    "supplierContactError",
    "emailError",
    "supplierContactCreationLink",
  ]
  contractRoutingSelectTarget: HTMLSelectElement
  routeContractToSelectTarget: HTMLSelectElement
  signatorySelectTarget: HTMLSelectElement
  signatoryCopyTarget: HTMLButtonElement
  supplierContactSelectTarget: HTMLSelectElement
  genericEmailAddressContainerTarget: HTMLDivElement
  genericEmailAddressFieldTarget: HTMLInputElement
  routingFormTarget: HTMLFormElement
  commentContentHiddenFieldTarget: HTMLInputElement
  commentInternalHiddenFieldTarget: HTMLInputElement
  contractRoutingErrorTarget: HTMLDivElement
  routeErrorTarget: HTMLDivElement
  signatoryErrorTarget: HTMLDivElement
  supplierContactErrorTarget: HTMLDivElement
  emailErrorTarget: HTMLDivElement
  supplierContactCreationLinkTarget: HTMLLinkElement

  static values = {
    initialContractRouting: String,
    initialRouteContractTo: String,
    initialSignatoryId: String,
    initialContactId: String,
    initialGenericEmailAddress: String,
    output: String,
  }

  initialContractRoutingValue: string
  initialRouteContractToValue: string
  initialSignatoryIdValue: string
  initialContactIdValue: string
  initialGenericEmailAddressValue: string
  outputValue: string

  connect() {
    this.contractRoutingSelectTarget.options[0].disabled = true
    this.setupForm(this.initialValues())
    this.updateSupplierContactCreationUrl()
  }

  setupForm(currentValues) {
    if (currentValues.contractRouting === "organization") {
      this.setupOrganizationForm()
    } else if (currentValues.contractRouting === "supplier") {
      this.setupSupplierForm()
    } else {
      this.setupEmptyForm()
    }
  }

  outputSelectedOption() {
    const targetElement = document.getElementById(this.outputValue)
    const selectedOption = this.signatorySelectTarget[this.signatorySelectTarget.selectedIndex]
    const email = selectedOption.dataset.email

    targetElement.value = email ? email : ""
    targetElement.setAttribute("data-clipboard-input-value", selectedOption.value ? email : "")
  }

  setupOrganizationForm() {
    this.resetRouteContractToSelect(this.organizationRouteContractToOptions())

    this.setupOrganizationRouteContractTo(this.initialRouteContractToValue)
  }

  setupOrganizationRouteContractTo(routeContractToValue) {
    this.unsetAndHideSelectElement(this.signatorySelectTarget)
    if (routeContractToValue === "supplier_contact") {
      this.unsetGenericEmailAddress()
      this.resetAndShowSelectElement(this.supplierContactSelectTarget, this.initialContactIdValue)
    } else if (routeContractToValue === "generic_email_address") {
      this.resetGenericEmailAddress()
      this.unsetAndHideSelectElement(this.supplierContactSelectTarget)
    } else {
      this.unsetGenericEmailAddress()
      this.unsetAndHideSelectElement(this.supplierContactSelectTarget)
    }
  }

  setupSupplierForm() {
    this.resetRouteContractToSelect(this.supplierRouteContractToOptions())

    this.setupSupplierRouteContractTo(this.initialRouteContractToValue)
  }

  setupSupplierRouteContractTo(routeContractToValue) {
    this.unsetGenericEmailAddress()
    this.resetAndShowSelectElement(this.supplierContactSelectTarget, this.initialContactIdValue)
    if (routeContractToValue === "commercial_executive") {
      this.unsetAndHideSelectElement(this.signatorySelectTarget)
    } else if (routeContractToValue === "signatory") {
      show(this.signatoryCopyTarget)
      this.resetAndShowSelectElement(this.signatorySelectTarget, this.initialSignatoryIdValue)
    } else {
      this.unsetAndHideSelectElement(this.signatorySelectTarget)
    }
  }

  setupEmptyForm() {
    this.unsetAndHideSelectElement(this.routeContractToSelectTarget)
    this.unsetAndHideSelectElement(this.signatorySelectTarget)
    hide(this.signatoryCopyTarget)
    this.unsetAndHideSelectElement(this.supplierContactSelectTarget)
    this.unsetGenericEmailAddress()
  }

  resetRouteContractToSelect(options) {
    this.routeContractToSelectTarget.options.length = 0
    options.forEach((option) => this.routeContractToSelectTarget.appendChild(option))
    options[0].disabled = true

    this.resetAndShowSelectElement(this.routeContractToSelectTarget, this.initialRouteContractToValue)
  }

  resetAndShowSelectElement(selectElement, initialValue) {
    Array.prototype.forEach.call(selectElement.options, (option, index) => {
      if (option.value === initialValue) {
        selectElement.selectedIndex = index
      }
    })
    selectElement.options[0].disabled = true
    selectElement.parentNode.classList.remove("hidden")
  }

  unsetAndHideSelectElement(selectElement) {
    selectElement.selectedIndex = null
    selectElement.parentNode.classList.add("hidden")
  }

  resetGenericEmailAddress() {
    this.genericEmailAddressFieldTarget.value = this.initialGenericEmailAddressValue
    this.genericEmailAddressContainerTarget.classList.remove("hidden")
  }

  unsetGenericEmailAddress() {
    this.genericEmailAddressFieldTarget.value = null
    this.genericEmailAddressContainerTarget.classList.add("hidden")
  }

  organizationRouteContractToOptions() {
    return [
      new Option("Select Route", ""),
      new Option("Supplier Contact", "supplier_contact"),
      new Option("Generic Email Address", "generic_email_address"),
    ]
  }

  supplierRouteContractToOptions() {
    return [
      new Option("Select Route", ""),
      new Option("Commercial Executive", "commercial_executive"),
      new Option("Signatory", "signatory"),
    ]
  }

  initialValues() {
    return {
      contractRouting: this.initialContractRoutingValue,
      routeContractTo: this.initialRouteContractToValue,
      signatoryId: this.initialSignatoryIdValue,
      contactId: this.initialContactIdValue,
      genericEmailAddress: this.initialGenericEmailAddressValue,
    }
  }

  currentValues() {
    return {
      contractRouting: this.getSelectCurrentValue(this.contractRoutingSelectTarget),
      routeContractTo: this.getSelectCurrentValue(this.routeContractToSelectTarget),
      signatoryId: this.getSelectCurrentValue(this.signatorySelectTarget),
      contactId: this.getSelectCurrentValue(this.supplierContactSelectTarget),
      genericEmailAddress: this.genericEmailAddressFieldTarget.value,
    }
  }

  getSelectCurrentValue(selectElement) {
    return selectElement.options[selectElement.selectedIndex].value
  }

  onContractRoutingChanged() {
    this.clearErrors()
    this.setupForm(this.currentValues())
    this.updateSupplierContactCreationUrl()
  }

  onRouteContractToChanged() {
    this.clearErrors()
    const currentValues = this.currentValues()
    if (currentValues.contractRouting === "organization") {
      this.setupOrganizationRouteContractTo(currentValues.routeContractTo)
    } else if (currentValues.contractRouting === "supplier") {
      this.setupSupplierRouteContractTo(currentValues.routeContractTo)
    }
    this.updateSupplierContactCreationUrl()
  }

  onSignatoryChanged() {
    this.hideError(this.signatoryErrorTarget, this.signatorySelectTarget)
    this.updateSupplierContactCreationUrl()
  }

  onSupplierContactChanged() {
    this.hideError(this.supplierContactErrorTarget, this.supplierContactSelectTarget)
    this.updateSupplierContactCreationUrl()
  }

  onGenericEmailAddressChanged() {
    this.hideError(this.emailErrorTarget, this.genericEmailAddressFieldTarget)
    this.updateSupplierContactCreationUrl()
  }

  updateSupplierContactCreationUrl() {
    let url = new URL(this.supplierContactCreationLinkTarget.href)
    url.searchParams.delete("routing_detail_contract_routing")
    url.searchParams.delete("routing_detail_route_contract_to")
    url.searchParams.delete("routing_detail_signatory_id")
    url.searchParams.delete("routing_detail_contact_id")
    url.searchParams.delete("routing_detail_generic_email_address")

    const currentValues = this.currentValues()
    url.searchParams.append("routing_detail_contract_routing", currentValues.contractRouting)
    url.searchParams.append("routing_detail_route_contract_to", currentValues.routeContractTo)
    url.searchParams.append("routing_detail_signatory_id", currentValues.signatoryId)
    url.searchParams.append("routing_detail_contact_id", currentValues.contactId)
    url.searchParams.append("routing_detail_generic_email_address", currentValues.genericEmailAddress)

    this.supplierContactCreationLinkTarget.href = url
  }

  submitForm() {
    if (this.routingFormTarget && this.isFormValid()) {
      // before submitting, we need to get the comment form values
      NestedFormSyncer.call(this.routingFormTarget)

      const commentContentField = document.getElementById("comment-content-field")
      if (commentContentField) {
        this.commentContentHiddenFieldTarget.value = commentContentField.value
      }

      const commentInternalField = document.getElementById("comment-internal-field")
      if (commentInternalField) {
        this.commentInternalHiddenFieldTarget.value = commentInternalField.value
      }

      this.dispatch("form-submitted")
      /*
      requestSubmit() sets format to :turbo_stream
      submit() sets the format to html
      Below ternary makes sure that request comes in as a turbo_stream when tasks are being completed from workflows
      */
      if (window.location.href.includes("/request_steps")) {
        this.routingFormTarget.requestSubmit()
      } else {
        window.location.href.includes("/portal") || window.location.href.includes("/supplier_portal")
          ? this.routingFormTarget.submit()
          : this.routingFormTarget.requestSubmit()
      }
    }
  }

  isFormValid() {
    let isValid = true
    this.clearErrors()

    const contractRouting = this.getSelectCurrentValue(this.contractRoutingSelectTarget)
    if (contractRouting == "organization") {
      isValid = this.isOrganizationSectionValid()
    } else if (contractRouting == "supplier") {
      isValid = this.isSupplierSectionValid()
    } else {
      this.showError(this.contractRoutingErrorTarget, this.contractRoutingSelectTarget)
      isValid = false
    }

    return isValid
  }

  isOrganizationSectionValid() {
    let isValid = true

    const route = this.getSelectCurrentValue(this.routeContractToSelectTarget)
    if (route == "supplier_contact") {
      const supplierContact = this.getSelectCurrentValue(this.supplierContactSelectTarget)
      if (!supplierContact) {
        this.showError(this.supplierContactErrorTarget, this.supplierContactSelectTarget)
        isValid = false
      }
    } else if (route == "generic_email_address") {
      if (!this.isGenericEmailAddressValid()) {
        this.showError(this.emailErrorTarget, this.genericEmailAddressFieldTarget)
        isValid = false
      }
    } else {
      this.showError(this.routeErrorTarget, this.routeContractToSelectTarget)
      isValid = false
    }

    return isValid
  }

  isSupplierSectionValid() {
    let isValid = true

    const route = this.getSelectCurrentValue(this.routeContractToSelectTarget)
    if (route == "signatory") {
      const signatory = this.getSelectCurrentValue(this.signatorySelectTarget)
      if (!signatory) {
        this.showError(this.signatoryErrorTarget, this.signatorySelectTarget)
        isValid = false
      }
    } else if (!route) {
      this.showError(this.routeErrorTarget, this.routeContractToSelectTarget)
      isValid = false
    }
    const supplierContact = this.getSelectCurrentValue(this.supplierContactSelectTarget)
    if (!supplierContact) {
      this.showError(this.supplierContactErrorTarget, this.supplierContactSelectTarget)
      isValid = false
    }

    return isValid
  }

  isGenericEmailAddressValid() {
    const email = this.genericEmailAddressFieldTarget.value
    return EmailValidator.validate(email)
  }

  clearErrors() {
    this.hideError(this.contractRoutingErrorTarget, this.contractRoutingSelectTarget)
    this.hideError(this.routeErrorTarget, this.routeContractToSelectTarget)
    this.hideError(this.signatoryErrorTarget, this.signatorySelectTarget)
    this.hideError(this.supplierContactErrorTarget, this.supplierContactSelectTarget)
    this.hideError(this.emailErrorTarget, this.genericEmailAddressFieldTarget)
  }

  showError(errorElement, formElement) {
    errorElement.classList.remove("hidden")
    formElement.classList.add("border-red-500")
  }

  hideError(errorElement, formElement) {
    errorElement.classList.add("hidden")
    formElement.classList.remove("border-red-500")
  }
}
