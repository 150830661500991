import IMask from "imask"
import { Controller } from "@hotwired/stimulus"
import StyledSelectController from "../styled_select_controller"
import TomSelect from "tom-select"
import { currencyMaskConfigFor } from "../../utils/mask"

type CurrencyOption = {
  id: string
  code: string
  name: string
  symbol: string
  flag_class: string
}

// Connects to data-controller="components--multi-currency-input"
export default class extends Controller {
  static outlets = ["styled-select"]
  styledSelectOutlet: StyledSelectController

  static targets = ["amount", "symbol", "code", "buttonSelect", "selectWrapper"]
  amountTarget: HTMLInputElement
  symbolTarget: HTMLSpanElement
  codeTarget: HTMLSpanElement
  buttonSelectTarget: HTMLButtonElement

  static values = { currencyOptions: Array, dispatchEvent: String, extendMaskConfig: Object, defaultCurrency: String }
  currencyOptionsValue: CurrencyOption[]
  dispatchEventValue: string
  extendMaskConfigValue: Object
  defaultCurrencyValue: string

  tomSelect: TomSelect
  amountMask: any

  connect(shouldApplyMask = true) {
    this.tomSelect = this.styledSelectOutlet.tomSelect

    const defaultCurrency = this.tomSelect.getValue() || this.defaultCurrencyValue

    this.refreshSymbolAndCode(defaultCurrency as string)
    if (shouldApplyMask) {
      this.applyMask()
    }
    this.setTabIndexToTsControl()

    this.tomSelect.on("blur", this.hideCurrencySelect)
    this.tomSelect.on("dropdown_close", this.hideCurrencySelect)
    this.tomSelect.on("change", this.onCurrencyChange)
  }

  reconnect() {
    // If the amount is being touched by the user, we don't want to apply the mask
    const shouldApplyMask = this.amountTarget !== document.activeElement
    // When this controller is reconnected, styled-select-controller is also being reconnected, which
    // destroys the tomSelect instance and cleans it up. Because of this, we don't need to disconnect
    // the controller here, we just need to reconnect it and attach the event listeners to the new
    // tomSelect instance.
    this.connect(shouldApplyMask)
  }

  applyMask = () => {
    // The browser should handle formatting if the input type is "number"
    if (this.amountTarget.type === "number") return false

    let maskConfig = currencyMaskConfigFor(this.tomSelect.getValue())

    // We currently don't support extending the mask config for currencies without decimal places
    if (maskConfig.scale !== 0) {
      maskConfig = { ...maskConfig, ...this.extendMaskConfigValue }
    }

    if (this.amountMask) {
      this.amountMask.updateOptions(maskConfig)
    } else {
      this.amountMask = IMask(this.amountTarget, maskConfig)
    }

    this.amountMask.updateValue()
    this.amountMask.updateControl()
  }

  // Because the tom select is hidden using z-index, it still can be accessed by tab navigation
  // To avoid unexpected behaviors, we set its tabindex to -1
  setTabIndexToTsControl = () => {
    this.tomSelect.control.setAttribute("tabindex", "-1")
  }

  refreshSymbolAndCode = (currencyCode: string) => {
    const selectedCurrency = this.currencyOptionsValue.find((c) => c.id == currencyCode)

    this.symbolTarget.innerHTML = selectedCurrency.symbol
    this.codeTarget.innerHTML = selectedCurrency.code

    const existingClassList = this.buttonSelectTarget.querySelector("span.label").classList.value
    const newClassList = existingClassList.replace(/\bfi fi-[a-z]{2}/, selectedCurrency.flag_class)
    this.buttonSelectTarget.querySelector("span.label").classList.value = newClassList

    this.recalculateAmountPadding()
  }

  showCurrencySelect = () => {
    const tomContainer = this.styledSelectOutlet.element.parentElement

    this.tomSelect.focus()
    tomContainer.classList.remove("-z-10")
    tomContainer.classList.add("z-20")
  }

  hideCurrencySelect = () => {
    const tomContainer = this.styledSelectOutlet.element.parentElement

    this.setTabIndexToTsControl()
    tomContainer.classList.add("-z-10")
    tomContainer.classList.remove("z-20")
  }

  onAmountChange = (event: Event) => {
    this.dispatchCurrencyOrAmountChangeEvent()
  }

  onCurrencyChange = (currency) => {
    this.applyMask()
    this.refreshSymbolAndCode(currency)
    this.hideCurrencySelect()
    this.dispatchCurrencyOrAmountChangeEvent()
  }

  dispatchCurrencyOrAmountChangeEvent = () => {
    if (this.hasDispatchEventValue) {
      const event = new CustomEvent(this.dispatchEventValue, {
        bubbles: true,
        detail: {
          currency: this.tomSelect.getValue(),
          amount: this.amountTarget.value,
        },
      })
      document.dispatchEvent(event)
    }
  }

  // Some currencies have symbols with multiple characters: C$, R$, etc
  // We need to update the amount's padding left so the symbol span (position absolute)
  // won't overlap with the input's value
  recalculateAmountPadding = () => {
    const extraPadding = 19
    const minimumPadding = 9

    // clientWidth may return 0 if the input is hidden at rendering time.
    // If that happens we grab the symbol's innerHTML ("$", "C$", etc) and multiply it by the minimum padding,
    // which is accurate enough for the first rendering.
    const width = this.symbolTarget.clientWidth || this.symbolTarget.innerHTML.length * minimumPadding

    this.amountTarget.style.paddingLeft = `${width + extraPadding}px`
  }
}
