import BarChartController from "../bar_chart_controller"

// Connects to data-controller="analytics--actual-spend-chart"
export default class extends BarChartController {
  static values = {
    labels: String,
    metadata: String,
    datasets: String,
    yAxisTitle: String,
    yAxisCurrency: String,
    expensesBaseUrl: String,
    supplierId: String,
    openLinkInNewTab: Boolean,
  }
  labelsValue: String
  yAxisCurrencyValue: String
  expensesBaseUrlValue: String
  supplierIdValue: String
  openLinkInNewTabValue: boolean

  metadataValue: string
  metadata: Record<string, any>[]

  tootlipOptions() {
    const metadata = JSON.parse(this.metadataValue)

    return {
      enabled: false,
      padding: 12,
      yAlign: "bottom",
      external: (context) => {
        const currency = this.yAxisCurrencyValue

        // Tooltip Element
        let tooltipEl = document.getElementById("chartjs-tooltip")

        // Create element on first render
        if (!tooltipEl) {
          tooltipEl = document.createElement("div")
          tooltipEl.id = "chartjs-tooltip"
          tooltipEl.innerHTML = "<table></table>"
          tooltipEl.className = "text-white body-text"
          document.body.appendChild(tooltipEl)
        }

        // Hide if no tooltip
        const tooltipModel = context.tooltip
        if (tooltipModel.opacity === 0) {
          tooltipEl.style.opacity = 0
          return
        }

        // Set caret Position
        tooltipEl.classList.remove("above", "below", "no-transform")
        if (tooltipModel.yAlign) {
          tooltipEl.classList.add(tooltipModel.yAlign)
        } else {
          tooltipEl.classList.add("no-transform")
        }

        // Set Text
        if (tooltipModel.body) {
          const bodyHTML = tooltipModel.dataPoints
            .map((el) => {
              let value = el.formattedValue
              const elmMetadata = metadata[el.dataIndex]

              if (currency) {
                value = el.raw.toLocaleString("en-US", {
                  style: "currency",
                  currency: currency,
                  maximumFractionDigits: 0,
                })
                value = `${value} ${currency}`
              }

              if (elmMetadata === undefined) {
                return `<div class="whitespace-nowrap">${value}</div>`
              }

              const isOpenPeriod = elmMetadata.accounting_period_status === "open"

              const periodIconColor = isOpenPeriod ? "bg-gray-300" : "bg-chart-blue"
              const periodText = isOpenPeriod ? "Open Period" : "Closed Period"
              const periodIcon = `<div class="h-2 w-2 rounded-sm mt-1.5 ${periodIconColor}"></div>`
              const periodLabel = `<div class="boxy-text--long text-gray-300 ">${periodText}</div>`

              return `
                <div class="flex space-x-2 justify-start">
                  ${elmMetadata.accounting_period_id ? periodIcon : ""}
                  <div class="flex flex-col space-y-2 whitespace-nowrap">
                      <div class="flex flex-col space-y-1">
                        ${elmMetadata.accounting_period_id ? periodLabel : ""}
                        <div class="body-text--long text-white">${elmMetadata.sublabel}</div>
                        <div class="text-lg text-white">${value}</div>
                      </div>
                      <div class="caption text-gray-300">
                        <span>Click to see Expense details</span>
                        <i class="fas fa-external-link ml-1"></i>
                      </div>
                  </div>
                </div>
              `
            })
            .join("</br>")

          tooltipEl.innerHTML = `
                <style>
                 .tooltip-content {
                    min-width: 200px;
                    background-color: black;
                    text-align: left;
                    position: absolute;
                    z-index: 1;
                    bottom: 160%;
                    left: 50%;
                    margin-bottom: 15px;
                    margin-left: -115px;
                    background-color: rgba(45,55,72, 0.9);
                    display:flex;
                    flex-direction:column;
                    align-items: flex-start;
                    padding: 10px 16px;
                    gap: 5px;
                    border-radius: 6px;
                    font-style: normal;
                    font-size: 14px;
                    line-height: 20px;
                    letter-spacing: 0.25px;
                    color: #FFFFFF;
                  }
                 .tooltip-content:after {
                  content: "";
                  position: absolute;
                  top: 100%;
                  left: 50%;
                  margin-left: -5px;
                  border-width: 5px;
                  border-style: solid;
                  border-color: rgba(45,55,72, 0.9) transparent transparent transparent;
                 }
                </style>
                <div class="tooltip-content">
                    ${bodyHTML}
                </div>
              `
        }

        const position = context.chart.canvas.getBoundingClientRect()

        // Display, position, and set styles for font
        tooltipEl.style.opacity = 1
        tooltipEl.style.position = "absolute"
        tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + "px"
        tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + "px"
        tooltipEl.style.padding = tooltipModel.padding + "px " + tooltipModel.padding + "px"
        tooltipEl.style.pointerEvents = "none"
      },
    }
  }

  onClick = (_event, element) => {
    if (!this.expensesBaseUrlValue || !element || element.length === 0) {
      return
    }

    const labels = JSON.parse(this.labelsValue)
    const metadata = JSON.parse(this.metadataValue)
    const accountingPeriodId = metadata[element[0].index]?.accounting_period_id
    let filter = ""

    if (accountingPeriodId) {
      filter = "focus_period=" + accountingPeriodId
    } else {
      filter = "focus_month=" + labels[element[0].index]
    }

    if (this.supplierIdValue) {
      filter = `${filter}&filter_by[tropic_supplier][]=${this.supplierIdValue}`
    }

    const expensesUrl = `${this.expensesBaseUrlValue}?${filter}`

    if (this.openLinkInNewTabValue) {
      return window.open(expensesUrl, "_blank")
    }
    window.location.href = expensesUrl
  }

  onHover = (event, element) => {
    event.native.target.style.cursor = element[0] ? "pointer" : "default"
  }
}
