import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="active-filters"
export default class extends Controller {
  static values = {
    count: Number,
    groupName: String,
  }
  countValue: number
  groupNameValue: string

  connect() {
    this.setActiveGroupBy()
    this.setActiveFiltersCount()
  }

  setActiveGroupBy(): void {
    const groupByButtonElement = document.getElementById("group-by-button")
    const groupByButtonTextElement = document.getElementById("group-by-button-text")
    const clearGroupByButtonElement = document.getElementById("clear-group-by-button")

    if (!groupByButtonElement) {
      return
    }

    if (this.groupNameValue) {
      const groupNameSpan = document.createElement("span")
      groupNameSpan.innerText = this.groupNameValue
      groupNameSpan.classList.add("!font-normal")

      groupByButtonTextElement.innerText = `Grouped by: `
      groupByButtonTextElement.appendChild(groupNameSpan)
      groupByButtonElement.classList.add("button-secondary-active")
      clearGroupByButtonElement?.classList.remove("hidden")
    } else {
      groupByButtonTextElement.innerText = `Group by`
      groupByButtonElement.classList.remove("button-secondary-active")
      clearGroupByButtonElement?.classList.add("hidden")
    }
  }

  setActiveFiltersCount(): void {
    const filterButtonElement = document.getElementById("filters-button")
    const activeFilterElement = document.getElementById("active-filters-count")

    if (!filterButtonElement) {
      return
    }

    if (this.countValue > 0) {
      activeFilterElement.innerText = this.countValue.toString()
      filterButtonElement.classList.add("button-secondary-active")
    } else {
      if (activeFilterElement) {
        activeFilterElement.innerText = ""
      }
      filterButtonElement.classList.remove("button-secondary-active")
    }
  }
}
