import { Controller } from "@hotwired/stimulus"
import {
  show,
  hide,
  disableStyledSelect,
  enableStyledSelect,
  buttonAsSubmitting,
  stringMatchInArray,
} from "../../utils"

// Connects to data-controller="workflows--new-workflow-form"
export default class extends Controller {
  static values = {
    existingWorkflowNames: Array<String>,
  }
  existingWorkflowNamesValue: string[]

  static targets = [
    "newWorkflowForm",
    "nameField",
    "nameFieldMissingError",
    "nameFieldTakenError",
    "managedBySelect",
    "managedBySelectError",
    "managedBySelectContainer",
    "orgWorkflowTemplateTypeSelect",
    "orgWorkflowTemplateTypeSelectContainer",
    "tropicWorkflowTemplateTypeSelect",
    "tropicWorkflowTemplateTypeSelectContainer",
    "tropicTemplateWorkflowSelect",
    "tropicTemplateWorkflowSelectError",
    "tropicTemplateWorkflowSelectContainer",
    "orgTemplateWorkflowSelect",
    "orgTemplateWorkflowSelectError",
    "orgTemplateWorkflowSelectContainer",
    "submitButton",
  ]

  newWorkflowFormTarget: HTMLFormElement
  nameFieldTarget: HTMLInputElement
  nameFieldMissingErrorTarget: HTMLDivElement
  nameFieldTakenErrorTarget: HTMLDivElement
  managedBySelectTarget: HTMLSelectElement
  managedBySelectErrorTarget: HTMLDivElement
  managedBySelectContainerTarget: HTMLDivElement
  orgWorkflowTemplateTypeSelectTarget: HTMLSelectElement
  orgWorkflowTemplateTypeSelectContainerTarget: HTMLDivElement
  tropicWorkflowTemplateTypeSelectTarget: HTMLSelectElement
  tropicWorkflowTemplateTypeSelectContainerTarget: HTMLDivElement
  tropicTemplateWorkflowSelectTarget: HTMLSelectElement
  tropicTemplateWorkflowSelectErrorTarget: HTMLDivElement
  tropicTemplateWorkflowSelectContainerTarget: HTMLDivElement
  orgTemplateWorkflowSelectTarget: HTMLSelectElement
  orgTemplateWorkflowSelectErrorTarget: HTMLDivElement
  orgTemplateWorkflowSelectContainerTarget: HTMLDivElement
  submitButtonTarget: HTMLButtonElement

  connect() {}

  onManagedByChange(): void {
    if (this.managedBySelectTarget.tomselect.getValue() === "tropic") {
      show(this.tropicWorkflowTemplateTypeSelectContainerTarget)
      enableStyledSelect(this.tropicWorkflowTemplateTypeSelectTarget)

      hide(this.orgWorkflowTemplateTypeSelectContainerTarget)
      disableStyledSelect(this.orgWorkflowTemplateTypeSelectTarget)

      this.onWorkflowTemplateTypeSelected()
    } else if (this.managedBySelectTarget.tomselect.getValue() === "organization") {
      hide(this.tropicWorkflowTemplateTypeSelectContainerTarget)
      disableStyledSelect(this.tropicWorkflowTemplateTypeSelectTarget)

      show(this.orgWorkflowTemplateTypeSelectContainerTarget)
      enableStyledSelect(this.orgWorkflowTemplateTypeSelectTarget)

      this.onWorkflowTemplateTypeSelected()
    } else {
      hide(this.workflowTemplateTypeSelectContainerTarget)

      hide(this.tropicTemplateWorkflowSelectContainerTarget)
      disableStyledSelect(this.tropicTemplateWorkflowSelectTarget)

      hide(this.orgTemplateWorkflowSelectContainerTarget)
      disableStyledSelect(this.orgTemplateWorkflowSelectTarget)
    }
  }

  workflowTemplateTypeSelectTarget() {
    if (this.managedBySelectTarget.tomselect.getValue() === "tropic") {
      return this.tropicWorkflowTemplateTypeSelectTarget
    } else if (this.managedBySelectTarget.tomselect.getValue() === "organization") {
      return this.orgWorkflowTemplateTypeSelectTarget
    }
  }

  onWorkflowTemplateTypeSelected(): void {
    if (this.workflowTemplateTypeSelectTarget().tomselect.getValue() === "existing") {
      if (this.managedBySelectTarget.tomselect.getValue() === "tropic") {
        show(this.tropicTemplateWorkflowSelectContainerTarget)
        enableStyledSelect(this.tropicTemplateWorkflowSelectTarget)

        hide(this.orgTemplateWorkflowSelectContainerTarget)
        disableStyledSelect(this.orgTemplateWorkflowSelectTarget)
      } else if (this.managedBySelectTarget.tomselect.getValue() === "organization") {
        show(this.orgTemplateWorkflowSelectContainerTarget)
        enableStyledSelect(this.orgTemplateWorkflowSelectTarget)

        hide(this.tropicTemplateWorkflowSelectContainerTarget)
        disableStyledSelect(this.tropicTemplateWorkflowSelectTarget)
      }
    } else {
      hide(this.orgTemplateWorkflowSelectContainerTarget)
      disableStyledSelect(this.orgTemplateWorkflowSelectTarget)

      hide(this.tropicTemplateWorkflowSelectContainerTarget)
      disableStyledSelect(this.tropicTemplateWorkflowSelectTarget)
    }
  }

  submitForm() {
    if (this.newWorkflowFormTarget && this.isFormValid()) {
      buttonAsSubmitting(this.submitButtonTarget)
      this.newWorkflowFormTarget.submit()
    }
  }

  isFormValid() {
    let isValid = true
    this.clearErrors()

    const borderColor = "rgb(216 44 13)"

    if (this.nameFieldTarget.value === "") {
      this.showError(this.nameFieldMissingErrorTarget, this.nameFieldTarget)
      isValid = false
    }

    if (stringMatchInArray(this.nameFieldTarget.value, this.existingWorkflowNamesValue, true)) {
      this.showError(this.nameFieldTakenErrorTarget, this.nameFieldTarget)
      isValid = false
    }

    if (!this.managedBySelectTarget.tomselect.getValue()) {
      this.showError(
        this.managedBySelectErrorTarget,
        this.styledSelectWrapper(this.managedBySelectContainerTarget),
        borderColor,
      )
      isValid = false
    }

    if (this.workflowTemplateTypeSelectTarget().tomselect.getValue() === "existing") {
      if (this.managedBySelectTarget.tomselect.getValue() === "tropic") {
        if (!this.tropicTemplateWorkflowSelectTarget.tomselect.getValue()) {
          this.showError(
            this.tropicTemplateWorkflowSelectErrorTarget,
            this.styledSelectWrapper(this.tropicTemplateWorkflowSelectContainerTarget),
            borderColor,
          )
          isValid = false
        }
      } else if (this.managedBySelectTarget.tomselect.getValue() === "organization") {
        if (!this.orgTemplateWorkflowSelectTarget.tomselect.getValue()) {
          this.showError(
            this.orgTemplateWorkflowSelectErrorTarget,
            this.styledSelectWrapper(this.orgTemplateWorkflowSelectContainerTarget),
            borderColor,
          )
          isValid = false
        }
      }
    }

    return isValid
  }

  clearErrors() {
    const borderColor = "rgb(203 213 224)"

    this.hideError(this.nameFieldMissingErrorTarget, this.nameFieldTarget)
    this.hideError(this.nameFieldTakenErrorTarget, this.nameFieldTarget)
    this.hideError(
      this.managedBySelectErrorTarget,
      this.styledSelectWrapper(this.managedBySelectContainerTarget),
      borderColor,
    )
    this.hideError(
      this.tropicTemplateWorkflowSelectErrorTarget,
      this.styledSelectWrapper(this.tropicTemplateWorkflowSelectContainerTarget),
      borderColor,
    )
    this.hideError(
      this.orgTemplateWorkflowSelectErrorTarget,
      this.styledSelectWrapper(this.orgTemplateWorkflowSelectContainerTarget),
      borderColor,
    )
  }

  showError(errorElement, formElement, borderColor = null) {
    show(errorElement)
    if (borderColor) {
      formElement.setAttribute("style", `border-color: ${borderColor}`)
    } else {
      formElement.classList.add("border-red-500")
    }
  }

  hideError(errorElement, formElement, borderColor = null) {
    hide(errorElement)
    if (borderColor) {
      formElement.setAttribute("style", `border-color: ${borderColor}`)
    } else {
      formElement.classList.remove("border-red-500")
    }
  }

  styledSelectWrapper(targetContainer) {
    return targetContainer.getElementsByClassName("ts-wrapper")[0]
  }
}
