import { Controller } from "@hotwired/stimulus"
import { patch } from "@rails/request.js"

// Connects to data-controller="auto-save"
export default class extends Controller {
  static targets = ["autoSaveStatus"]
  static values = {
    updateUrl: String,
  }

  updateUrlValue: string

  autoSaveStatusTarget: HTMLElement
  saveHandler = this.save.bind(this)

  connect() {
    window.addEventListener("AutoSave:save", this.saveHandler)
  }

  disconnect() {
    window.removeEventListener("AutoSave:save", this.saveHandler)
  }

  async save(e) {
    const { param, value } = e.detail
    this.autoSaveStatusTarget.textContent = "Saving..."
    const data = { document: { [param]: value } }

    try {
      const response = await patch(this.updateUrlValue, {
        responseKind: "json",
        body: JSON.stringify(data),
      })
      if (response.ok) {
        this.autoSaveStatusTarget.textContent = "Saved"
        setTimeout(() => (this.autoSaveStatusTarget.textContent = ""), 4000)
      } else {
        const res = await response.json
        this.autoSaveStatusTarget.textContent = `failed to save: ${res.errors}`
      }
    } catch (error) {
      this.autoSaveStatusTarget.textContent = `failed to save: ${error}`
    }
  }
}
