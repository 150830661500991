import { Controller } from "@hotwired/stimulus"
import TriggerFormController from "./trigger_form_controller"

// Connects to data-controller="task-stage"
class TaskStageController extends Controller {
  static targets = ["stageSelect"]
  stageSelectTarget: HTMLSelectElement

  static outlets = ["trigger-form"]
  triggerFormOutlet: TriggerFormController
  triggerFormOutlets: Array<TriggerFormController>

  connect() {
    this.updateTriggers()
  }

  updateTriggers() {
    this.triggerFormOutlets.forEach((element) => element.update())
  }

  updateAllTriggerOptions() {
    this.triggerFormOutlets.forEach((element) => element.updateTriggeredByOptions())
  }
}

export default TaskStageController
