import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="turbo-loading"
export default class extends Controller {
  static targets = ["loader", "content"]
  loaderTargets: HTMLElement[]
  contentTargets: HTMLElement[]

  submitStart(_event) {
    this.loaderTargets.forEach((element) => element.classList.remove("hidden"))
    this.contentTargets.forEach((element) => element.classList.add("hidden"))
  }
}
