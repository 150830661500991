import { Controller } from "@hotwired/stimulus"
import { post } from "@rails/request.js"
import { hide, show } from "../utils"

// Connects to data-controller="contract-rate-schedule-form"
export default class extends Controller {
  static targets = ["form", "emptyState", "rateSchedulesWrapper"]

  static values = {
    formChangeUrl: String,
  }

  formChangeUrlValue: string

  hasFormTarget: boolean
  hasEmptyStateTarget: boolean
  hasRateSchedulesWrapper: boolean

  formTarget: HTMLFormElement
  emptyStateTarget: HTMLDivElement
  rateSchedulesWrapperTarget: HTMLDivElement

  connect() {}

  onFormChange(e): void {
    const { partial, preventDefault, addRate } = e.params
    if (preventDefault) {
      e.preventDefault()
    }

    const target = e.target
    const form = this.hasFormTarget ? this.formTarget : target.form
    const formData = new FormData(form)

    if (addRate == true) {
      this.addRate(formData)
    }

    let url = this.formChangeUrlValue
    if (partial) {
      const params = new URLSearchParams()
      if (partial.includes(",")) {
        partial.split(",").forEach((p) => {
          params.append("partial[]", p)
        })
      } else {
        params.set("partial", partial)
      }
      url = `${url}?${params.toString()}`
    }

    post(url, {
      responseKind: "turbo-stream",
      body: formData,
    })
  }

  toggleRatesSchedules() {
    setTimeout(() => {
      let totalVisibleForms = 0
      const nestedForms = this.element.querySelectorAll(".nested-form-wrapper")
      nestedForms.forEach((el: HTMLElement) => {
        if (el.style.display == "none") {
          this.updateRequiredFields(el)
        } else {
          totalVisibleForms++
        }
      })

      if (totalVisibleForms > 0) {
        hide(this.emptyStateTarget)
        show(this.rateSchedulesWrapperTarget)
      } else {
        show(this.emptyStateTarget)
        hide(this.rateSchedulesWrapperTarget)
      }
    }, 0)
  }

  private updateRequiredFields(el: HTMLElement) {
    const fields = el.querySelectorAll("input, select")
    fields.forEach((field: HTMLInputElement | HTMLSelectElement) => {
      field.required = false
    })
  }

  private addRate(formData: FormData) {
    formData.set("add_rate_schedule", "true")
  }
}
