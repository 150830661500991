import { Controller } from "@hotwired/stimulus"
import { Turbo } from "@hotwired/turbo-rails"

// Connects to data-controller="requests--workflow"
export default class extends Controller {
  toggleMyTasksPathValue: string
  loaderTarget: HTMLInputElement

  static targets = ["loader"]

  static values = {
    toggleMyTasksPath: String,
  }

  connect() {}

  toggleMyTasks(e) {
    this.loaderTarget.classList.remove("hidden")
    Turbo.visit(this.toggleMyTasksPathValue)
  }
}
