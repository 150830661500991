import { Controller } from "@hotwired/stimulus"
import { Turbo } from "@hotwired/turbo-rails"
import { get } from "@rails/request.js"

// Connects to data-controller="requests--price-check"
export default class extends Controller {
  static targets = ["form"]
  formTarget: HTMLFormElement

  static values = { url: String }
  urlValue: string

  proposedLineItemsChanged(e) {
    const formData = new FormData(this.formTarget)
    const params = new URLSearchParams(formData)
    const queryString = params.toString()
    get(this.urlValue + "?" + queryString, {
      responseKind: "turbo-stream",
    }).then(() => Turbo.cache.clear())
  }
}
