import { Controller } from "@hotwired/stimulus"
import { disable, enable } from "../../utils"

// Connects to data-controller="department--spend-and-budget-filters"
export default class extends Controller {
  formTarget: HTMLFormElement
  spendDateFilterTarget: HTMLSelectElement
  spendDateFilterMinTarget: HTMLInputElement
  spendDateFilterMaxTarget: HTMLInputElement
  dateRangeFieldsContainerTarget: HTMLDivElement
  submitButtonTarget: HTMLButtonElement

  static targets = [
    "form",
    "spendDateFilter",
    "spendDateFilterMin",
    "spendDateFilterMax",
    "dateRangeFieldsContainer",
    "submitButton",
  ]

  connect(): void {
    if (this.hasEmptyFields()) {
      disable(this.submitButtonTarget)
    }

    this.toggleDateRangeFields()
  }

  toggleDateRangeFields(): void {
    if (this.shouldShowDateRangeFields()) {
      this.dateRangeFieldsContainerTarget.classList.remove("hidden")
    } else {
      this.dateRangeFieldsContainerTarget.classList.add("hidden")
    }
  }

  toggleSubmitButton(): void {
    if (this.hasEmptyFields()) {
      disable(this.submitButtonTarget)
    } else {
      enable(this.submitButtonTarget)
    }
  }

  clearFilters(): void {
    this.spendDateFilterTarget.value = ""
    this.spendDateFilterMinTarget.value = ""
    this.spendDateFilterMaxTarget.value = ""
    this.formTarget.requestSubmit()
  }

  hasEmptyFields(): boolean {
    if (this.shouldShowDateRangeFields()) {
      return !this.spendDateFilterMinTarget.value || !this.spendDateFilterMaxTarget.value
    }

    return !this.spendDateFilterTarget.value
  }

  shouldShowDateRangeFields(): boolean {
    return this.spendDateFilterTarget.value === "between"
  }
}
