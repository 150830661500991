import { DirectUpload } from "@rails/activestorage"
import Dropzone from "dropzone"
import { findElement, removeElement } from "../utils"
import { ErrorType } from "../utils/constants"
import { createHiddenInput } from "../utils/files"
import { ICustomDropzoneOptions } from "../utils/constants"

export class DirectUploadDropzone {
  containerTarget: HTMLElement
  inputTarget: HTMLInputElement
  dropzoneTarget: HTMLElement
  dropzoneOptions: ICustomDropzoneOptions
  dropZone: Dropzone

  constructor(containerTarget: HTMLElement, options: ICustomDropzoneOptions) {
    this.containerTarget = containerTarget
    this.dropzoneOptions = options

    this.initTargets()
    this.createDropzone()
  }

  initTargets() {
    this.dropzoneTarget = findElement(this.containerTarget, "#dropzone-container")
    this.inputTarget = findElement(this.dropzoneTarget, "input[id^='dropzone-input_']")

    this.hideInput()
  }

  hideInput() {
    this.inputTarget.disabled = true
    this.inputTarget.style.display = "none"
  }

  createDropzone() {
    // We assign the file-drop_ id with an SecureRandom.uuid which allow many instances of a dropzone on a single page
    const fileDropTarget = findElement(this.dropzoneTarget, "div[id^='file-drop_']")
    this.dropZone = new Dropzone(`div>#${fileDropTarget.id}`, {
      url: this.inputTarget.getAttribute("data-direct-upload-url"),
      uploadMultiple: true,
      autoQueue: false,
      // Keep at the end so the passed options override the above defaults
      ...this.dropzoneOptions,
    })

    this.bindDefaultEvents()
  }

  removeErrors() {
    const errorElement = findElement(this.containerTarget, `#${this.dropzoneTemplateId}`)
    errorElement && removeElement(errorElement)
  }

  get dropzoneTemplateId() {
    return "dropzone-error-template"
  }

  validateFiles(files): boolean {
    return this.verifyMaxFiles(files)
  }

  verifyMaxFiles(files) {
    const { maxFilesPerDrop } = this.dropzoneOptions
    if (maxFilesPerDrop && files.length > maxFilesPerDrop) {
      this.dropZone.emit("maxfilesperdropexceeded", maxFilesPerDrop)
      return false
    }

    return true
  }

  bindDefaultEvents() {
    this.dropZone.on("drop", (e) => {
      for (let i = 0; i < e.dataTransfer.files.length; i++) {
        const file = e.dataTransfer.files[i]
        file.upload_method = "draganddrop"
      }
    })

    this.dropZone.on("addedfiles", (f) => {
      const files = Array.from(f)

      if (!files.some((file) => file.status === "error")) {
        this.removeErrors()
      }

      const isValid = this.validateFiles(files)

      if (isValid) {
        for (const file of files) {
          // this set timeout is needed for dropzone to process the file
          setTimeout(() => {
            const directUpload = createDirectUploadController(
              this,
              file,
              this.inputTarget.getAttribute("data-direct-upload-url"),
              this.inputTarget,
            )

            file.accepted && directUpload.start()
          }, 0)
        }
      }
    })
  }
}

class DirectUploadController {
  directUpload: DirectUpload
  dropZone: Dropzone
  inputTarget: HTMLInputElement
  file: any
  source: any
  hiddenInput: HTMLInputElement
  xhr: XMLHttpRequest
  target: HTMLInputElement

  constructor(source, file, url, target) {
    this.directUpload = createDirectUpload(file, url)
    this.source = source
    this.file = file
    this.target = target
  }

  start() {
    if (this.file.size == 0) {
      removeElement(this.hiddenInput)
      this.emitDropzoneError("File contains no content.")
      return
    }
    this.emitDropzoneUploading()
    try {
      this.directUpload.create((error, attributes) => {
        if (error) {
          removeElement(this.hiddenInput)
          this.emitDropzoneError(error)
        } else {
          this.file.signed_id = attributes.signed_id
          this.hiddenInput = createHiddenInput(this.file, this.target)
          this.emitDropzoneSuccess()
        }
      })
    } catch (err) {
      removeElement(this.hiddenInput)
      this.emitDropzoneError({
        ...err,
        type: ErrorType.SERVER_ERROR,
      })
    }
  }

  emitDropzoneUploading() {
    this.file.status = Dropzone.UPLOADING
    this.source.dropZone.emit("processing", this.file)
  }

  emitDropzoneError(error) {
    this.file.status = Dropzone.ERROR
    this.source.dropZone.emit("error", this.file, error)
    this.source.dropZone.emit("complete", this.file)
  }

  emitDropzoneSuccess() {
    this.file.status = Dropzone.SUCCESS
    this.source.dropZone.emit("success", this.file)
    this.source.dropZone.emit("complete", this.file)
  }
}

function createDirectUploadController(source, file, url, target) {
  return new DirectUploadController(source, file, url, target)
}

function createDirectUpload(file, url) {
  return new DirectUpload(file, url)
}
