import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="details-layout"
export default class extends Controller {
  static targets = ["gridContainer", "leftPaneContent", "rightPaneContent"]

  gridContainerTarget: HTMLDivElement
  leftPaneContentTarget: HTMLDivElement
  rightPaneContentTarget: HTMLDivElement

  // bind() creates a new function with the given context, we need to store the
  // reference of that function so we can then remove the listener
  resetColumnsHandler = this.resetColumns.bind(this)
  stackColumnsHandler = this.stackColumns.bind(this)

  connect() {
    document.addEventListener("DetailsLayout:resetColumns", this.resetColumnsHandler)
    document.addEventListener("DetailsLayout:stackColumns", this.stackColumnsHandler)
  }

  disconnect() {
    document.removeEventListener("DetailsLayout:resetColumns", this.resetColumnsHandler)
    document.removeEventListener("DetailsLayout:stackColumns", this.stackColumnsHandler)
  }

  resetColumns() {
    // Makes the container a grid to reset the columns
    this.gridContainerTarget.classList.add("grid")
    this.gridContainerTarget.classList.remove("space-y-4")
    document.dispatchEvent(new CustomEvent("DetailsLayout:onColumnsReset"))
  }

  stackColumns() {
    // Makes the container a block to force it's children to stack
    this.gridContainerTarget.classList.remove("grid")
    this.gridContainerTarget.classList.add("space-y-4")
    document.dispatchEvent(new CustomEvent("DetailsLayout:onColumnsStacked"))
  }
}
