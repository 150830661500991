import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="workflows--ironclad-initializer"
export default class extends Controller {
  static targets = ["loader"]
  loaderTarget: HTMLElement

  connect() {}

  showLoader() {
    this.loaderTarget.classList.remove("hidden")
  }
}
