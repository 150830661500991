import { Controller } from "@hotwired/stimulus"
import { disable } from "../utils"

export default class extends Controller {
  static targets = ["form", "submit"]
  formTarget: HTMLFormElement
  submitTarget: HTMLButtonElement
  hasSubmitTarget: boolean

  submitButton: HTMLButtonElement | null

  connect() {
    this.setSubmitButtonTarget()
  }

  submit(): void {
    disable(this.submitButton)
    this.formTarget.requestSubmit()
  }

  setSubmitButtonTarget() {
    if (this.hasSubmitTarget) {
      this.submitButton = this.submitTarget
    } else {
      this.submitButton = this.formTarget.querySelector("input[type=submit]")
    }
  }
}
