import { Controller } from "@hotwired/stimulus"
import { post } from "@rails/request.js"
import { hide, show } from "../utils"

// This will replace the contract-line-item-form and new-contract-line-item-form controllers
// when the 1) contract_line_items_enhancements flag is released and 2) the old CLI data model is removed
// TODO: Rename this to contract-line-item-form controller to match the template name

// Connects to data-controller="contract-line-items-new-model-form"
export default class extends Controller {
  static targets = [
    "form",
    "lineItemCaption",
    "supplierLineItemName",
    "addDiscountDescriptionWrapper",
    "discountDescriptionWrapper",
  ]

  static values = {
    formChangeUrl: String,
  }

  formChangeUrlValue: string

  hasFormTarget: boolean
  hasLineItemCaptionTarget: boolean

  formTarget: HTMLFormElement
  addDiscountDescriptionWrapper: HTMLDivElement
  discountDescriptionWrapper: HTMLDivElement

  async onFormChange(e): Promise<void> {
    const { quantityCostPeriod, hasDiscount, partial, preventDefault } = e.params
    if (preventDefault) {
      e.preventDefault()
    }

    const target = e.target
    const form = this.hasFormTarget ? this.formTarget : target.form
    const formData = new FormData(form)

    if (hasDiscount !== undefined) {
      this.toggleDiscount(formData, hasDiscount)
    }

    if (quantityCostPeriod !== undefined) {
      this.setQuantityCostPeriod(target, formData, quantityCostPeriod)
    }

    let url = this.formChangeUrlValue
    if (partial) {
      const params = new URLSearchParams()
      if (partial.includes(",")) {
        partial.split(",").forEach((p) => {
          params.append("partial[]", p)
        })
      } else {
        params.set("partial", partial)
      }
      url = `${url}?${params.toString()}`
    }

    this.setPendingFlag()
    await post(url, {
      responseKind: "turbo-stream",
      body: formData,
    })
    this.clearPendingFlag()
  }

  toggleDiscountField(e: Event) {
    e.preventDefault()

    const isFieldShown = !this.getDiscountDescriptionWrapper().classList.contains("hidden")

    if (isFieldShown) {
      hide(this.getDiscountDescriptionWrapper())
      show(this.getAddDiscountDescriptionWrapper())
    } else {
      show(this.getDiscountDescriptionWrapper())
      hide(this.getAddDiscountDescriptionWrapper())
    }
  }

  private toggleDiscount(formData: FormData, hasDiscount: boolean) {
    if (hasDiscount) {
      formData.set("contract_line_item[line_discount_present]", "true")
    } else {
      formData.set("contract_line_item[line_discount_present]", "false")
      formData.set("contract_line_item[line_discount_type]", "")
      formData.delete("contract_line_item[line_item_total_list_price]")
      formData.delete("contract_line_item[line_item_discount_amount]")
      formData.delete("contract_line_item[line_discount_percentage]")
      formData.delete("contract_line_item[line_discount_description]")
    }
  }

  private setQuantityCostPeriod(target: HTMLInputElement, formData: FormData, quantityCostPeriod: string) {
    formData.set("contract_line_item[line_quantity_cost_period]", quantityCostPeriod)
  }

  // Since the fields of the form are shown/hidden dynamically, there is no guaranty
  // these elements are going to be present in the form on page load.
  // These methods will look for the elements in the most recent DOM if the stimulus targets do not exist
  private getAddDiscountDescriptionWrapper() {
    return (
      this.addDiscountDescriptionWrapper ||
      this.element.querySelector('[data-contract-line-items-new-model-form-target="addDiscountDescriptionWrapper"]')
    )
  }

  private getDiscountDescriptionWrapper() {
    return (
      this.discountDescriptionWrapper ||
      this.element.querySelector('[data-contract-line-items-new-model-form-target="discountDescriptionWrapper"]')
    )
  }

  private setPendingFlag() {
    if (this.hasFormTarget) {
      this.formTarget.setAttribute("data-form-rerender-pending", "true")
    }
  }

  private clearPendingFlag() {
    if (this.hasFormTarget) {
      this.formTarget.removeAttribute("data-form-rerender-pending")
    }
  }
}
