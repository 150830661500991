import { Controller } from "@hotwired/stimulus"
import { CommentFormFinalizer } from "../utils/comment_form/comment_form_finalizer"
import { TrixEditor } from "../utils/comment_form/trix_types"
import { TrixWordFinder } from "../utils/comment_form/trix_word_finder"
import { TrixWordReplacer } from "../utils/comment_form/trix_word_replacer"
import { hide } from "../utils/index"

export type MentionDetails = { fullName: string; userId: string }

// Connects to data-controller="comment-form"
export default class extends Controller {
  static targets = [
    "commentToggle",
    "internalToggle",
    "internalField",
    "commentSubmit",
    "buttonsContainer",
    "contentField",
    "commentForm",
    "hrefInput",
    "confirmationLinkButton",
    "wrapElement",
    "mentionedUserIds",
    "standaloneSubmitButtonLabel",
  ]
  commentToggleTarget: HTMLDivElement
  internalToggleTarget: HTMLDivElement
  internalFieldTarget: HTMLInputElement
  commentSubmitTarget: HTMLDivElement
  buttonsContainerTarget: HTMLDivElement
  contentFieldTarget: HTMLInputElement
  commentFormTarget: HTMLFormElement
  hrefInputTarget: HTMLInputElement
  confirmationLinkButtonTarget: HTMLAnchorElement
  wrapElementTarget: HTMLDivElement
  mentionedUserIdsTarget: HTMLInputElement
  standaloneSubmitButtonLabelTarget: HTMLDivElement
  hasStandaloneSubmitButtonLabelTarget: boolean
  hasContentFieldTarget: boolean

  static values = {
    isAdminContext: Boolean,
    renderSubmitButtons: Boolean,
    taskModalContext: Boolean,
    hasConfirmationModal: Boolean,
    isEditingContext: Boolean,
    internalContext: Boolean,
    parentFormId: String,
    parentFormNeedsCommentData: Boolean,
    parentFormSubmitButtonId: String,
  }
  isAdminContextValue: boolean
  renderSubmitButtonsValue: boolean
  taskModalContextValue: boolean
  hasConfirmationModalValue: boolean
  isEditingContextValue: boolean
  internalContextValue: boolean
  parentFormIdValue: string
  parentFormNeedsCommentDataValue: string
  parentFormSubmitButtonIdValue: string
  editor: TrixEditor

  draftKey: string
  fieldHasContent: boolean

  connect() {
    // Strip /request_activites off the end of the path because they're equivalent for the purposes of saving drafts.
    const parentModal = this.commentFormTarget?.closest("turbo-frame#modal")?.getAttribute("src")

    if (parentModal && parentModal != "") {
      // Request Tasks are nested in a modal, so we need to use the modal's URL as the key
      // to differentiate between comments on different request tasks.
      this.draftKey = `${parentModal}###comment-draft`
    } else {
      this.draftKey = `${window.location.pathname.replace(/\/request_activities$/, "")}###comment-draft`
    }

    this.fieldHasContent = false

    if (this.hasContentFieldTarget) {
      this.contentFieldTarget.addEventListener("trix-initialize", this.trixInitialize.bind(this))
    }

    if (this.parentFormNeedsCommentDataValue) this.syncDataWithParentForm()
  }

  disconnect() {
    this.fieldHasContent = false
    this.contentFieldTarget.removeEventListener("trix-initialize", this.trixInitialize)
    this.commentFormTarget.removeEventListener("trix-change", this.onContentChange)
    this.commentFormTarget.removeEventListener("trix-before-paste", this.onBeforePaste)
  }

  trixInitialize() {
    let loaded = this.loadDraft()
    this.editor = this.contentFieldTarget["editor"]
    this.onContentChange()
    // https://github.com/basecamp/trix#observing-editor-changes
    this.commentFormTarget.addEventListener("trix-change", this.onContentChange.bind(this))
    this.commentFormTarget.addEventListener("trix-before-paste", this.onBeforePaste.bind(this))

    if (!loaded) {
      // loadDraft will set the internal state if it loaded, so don't redo that here
      if (this.isAdminContextValue && this.isEditingContextValue) {
        this.internalContextValue ? this.markAsInternal() : this.markAsComment()
      } else {
        this.isAdminContextValue ? this.markAsInternal() : this.markAsComment()
      }
    }
  }

  onCommentClick() {
    if (this.internalToggleValueHasntChanged({ from: "false" })) return
    this.markAsComment()
  }

  notifyWordChangeWatchers() {
    this.dispatch("wordChanged", {
      detail: {
        internal: this.internalFieldTarget.value === "true",
        ...TrixWordFinder.call(this.editor),
      },
    })
  }

  processMentionedUser({ detail: { fullName, userId } }: CustomEvent<MentionDetails>) {
    // Trix requires data attributes to prefixed with "trix"
    TrixWordReplacer.call(this.editor, `@${fullName}`, { trixTropicUserId: userId })
  }

  removeMentionBoldness(el: Element) {
    if (el.tagName === "STRONG" && el.textContent?.startsWith("@")) {
      el.replaceWith(el.textContent)

      return
    } else if (el.children?.length > 0) {
      Array.from(el.children).forEach(this.removeMentionBoldness.bind(this))
    }
  }

  onBeforePaste(e: any) {
    try {
      if (e.paste.hasOwnProperty("html")) {
        let div = document.createElement("div")
        div.innerHTML = e.paste.html
        Array.from(div.children).forEach(this.removeMentionBoldness.bind(this))
        e.paste.html = div.innerHTML
      }
    } catch (e) {
      console.error(e)
    }
  }

  onContentChange() {
    if (this.renderSubmitButtonsValue) {
      const buttons = this.buttonsContainerTarget.querySelectorAll("button, a")

      if (this.contentFieldTarget.value === "") {
        this.fieldHasContent = false
        buttons.forEach((button) => button.classList.add("disabled", "pointer-events-none"))
      } else if (!this.fieldHasContent) {
        this.fieldHasContent = true
        buttons.forEach((button) => button.classList.remove("disabled", "pointer-events-none"))
      }
    }

    this.saveDraft()
    this.notifyWordChangeWatchers()
  }

  onInternalClick() {
    if (this.internalToggleValueHasntChanged({ from: "true" })) return
    this.markAsInternal()
  }

  // onLinkCreation action and hrefInput target are in trix.js
  onLinkCreation(e) {
    // On Enter or checkmark button click.
    if ((e.type === "keydown" && e.keyCode === 13) || e.type === "click") {
      if (!["https", "http"].some((protocol) => this.hrefInputTarget.value.includes(protocol))) {
        this.hrefInputTarget.value = `https://${this.hrefInputTarget.value}`
      }
    }
  }

  onFormSubmit() {
    this.gatherCommentData()
    this.clearDraft() // Order matters: Make sure the draft is cleared after User ID Mentions get processed so those changes aren't resaved
  }

  onSubmitButtonClicked() {
    const shouldSubmit = this.commentFormTarget && this.contentFieldTarget.value !== ""
    if (shouldSubmit) {
      if (this.taskModalContextValue) {
        const currentFormUrlString = this.commentFormTarget.action
        let newFormUrl = new URL(currentFormUrlString)
        newFormUrl.searchParams.append("keep_modal_open", "true")
        this.commentFormTarget.action = newFormUrl.href
        this.commentFormTarget.requestSubmit()
        this.commentFormTarget.action = currentFormUrlString

        this.contentFieldTarget.value = ""
      } else {
        this.commentFormTarget.submit()
      }
    }
  }

  markAsComment() {
    this.internalFieldTarget.value = "false"
    if (this.isAdminContextValue) {
      this.commentToggleTarget.classList.add("text-purple-500")
      this.internalToggleTarget.classList.remove("text-purple-500")
    }

    if (this.renderSubmitButtonsValue) {
      if (this.hasConfirmationModalValue) {
        this.confirmationLinkButtonTarget.classList.remove("hidden")
        this.commentSubmitTarget.classList.add("hidden")
      } else {
        this.commentSubmitTarget.innerHTML = "Comment"
        if (this.hasStandaloneSubmitButtonLabelTarget) {
          this.standaloneSubmitButtonLabelTarget.innerHTML = "Not ready to complete this? Submit a comment instead."
        }
      }
    }

    this.wrapElementTarget.classList.remove("tropic-background")
    this.wrapElementTarget.classList.add("bg-white")
    this.contentFieldTarget.setAttribute("placeholder", "Leave a comment or question...")
    this.saveDraft()
  }

  markAsInternal() {
    this.internalFieldTarget.value = "true"
    if (this.isAdminContextValue) {
      this.internalToggleTarget.classList.add("text-purple-500")
      this.commentToggleTarget.classList.remove("text-purple-500")
    }

    if (this.renderSubmitButtonsValue) {
      if (this.hasConfirmationModalValue) {
        this.confirmationLinkButtonTarget.classList.add("hidden")
        this.commentSubmitTarget.classList.remove("hidden")
      }
      this.commentSubmitTarget.innerHTML = "Submit Internal Note"
      if (this.hasStandaloneSubmitButtonLabelTarget) {
        this.standaloneSubmitButtonLabelTarget.innerHTML = "Not ready to complete this? Submit a note."
      }
    }

    this.wrapElementTarget.classList.add("tropic-background")
    this.wrapElementTarget.classList.remove("bg-white")
    this.contentFieldTarget.setAttribute("placeholder", "Share a comment that will only be visible to Tropic Staff")
    this.saveDraft()
  }

  internalToggleValueHasntChanged({ from }: { from: string }): boolean {
    return this.internalFieldTarget.value === from
  }

  gatherCommentData() {
    const { content, mentionedUserIds } = CommentFormFinalizer.call(this.contentFieldTarget.value)

    this.contentFieldTarget.value = content
    this.mentionedUserIdsTarget.value = mentionedUserIds.join(",")
  }

  loadDraft(): boolean {
    if (this.isEditingContextValue) return false

    let json = localStorage.getItem(this.draftKey)
    if (json && json != "") {
      const { content, internal } = JSON.parse(json)
      this.contentFieldTarget.value = content
      if (internal) {
        this.markAsInternal()
      } else {
        this.markAsComment()
      }

      return true
    }

    return false
  }

  saveDraft() {
    if (this.contentFieldTarget.innerHTML != "" && !this.isEditingContextValue) {
      // Don't save a blank on changing because disconnect throws a change event
      // That overwrites the draft with a blank.
      let json = JSON.stringify({
        content: this.contentFieldTarget.innerHTML,
        internal: this.internalFieldTarget.value === "true",
      })
      localStorage.setItem(this.draftKey, json)
    }

    if (this.contentFieldTarget.innerHTML == "" && !this.isEditingContextValue) {
      this.clearDraft()
    }
  }

  clearDraft() {
    localStorage.removeItem(this.draftKey)
  }

  createCommentElementsOnParentForm = (): [HTMLInputElement, HTMLInputElement, HTMLInputElement] => {
    const parentForm = document.querySelector(`#${this.parentFormIdValue}`) as HTMLFormElement

    return ["comment_content", "comment_internal", "comment_mentioned_user_ids"].map((name) => {
      const field = document.createElement("input")
      field.setAttribute("name", name)
      field.setAttribute("form", this.parentFormIdValue)
      hide(field)
      parentForm.appendChild(field)

      return field
    })
  }

  syncDataWithParentForm = () => {
    const submitButton = document.querySelector(`#${this.parentFormSubmitButtonIdValue}`)
    submitButton.addEventListener("click", this.sendDataToParentForm, { capture: true })
  }

  sendDataToParentForm = () => {
    this.gatherCommentData()

    const [contentField, internalField, mentionedUserIdsField] = this.createCommentElementsOnParentForm()

    contentField.value = this.contentFieldTarget.value
    internalField.value = this.internalFieldTarget.value
    mentionedUserIdsField.value = this.mentionedUserIdsTarget.value
  }
}
