import { Controller } from "@hotwired/stimulus"
import { hide, show } from "../utils"

// Connects to data-controller="document-connections"
export default class extends Controller {
  static targets = ["contractSelectWrapper", "moveDocumentButton", "contractField", "supplierSearchWrapper"]

  contractSelectWrapperTarget: HTMLDivElement
  supplierSearchWrapperTarget: HTMLDivElement

  displayExistingFields() {
    hide(this.supplierSearchWrapperTarget)
    show(this.contractSelectWrapperTarget)
  }

  displayNewFields() {
    this.contractSelectWrapperTarget.querySelector("select").value = ""
    hide(this.contractSelectWrapperTarget)
    show(this.supplierSearchWrapperTarget)
  }
}
