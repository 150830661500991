import { Controller } from "@hotwired/stimulus"
import React from "react"
import ReactDOM from "react-dom/client"

import LegacyNonlinearWorkflow from "../react/components/workflows/nonlinear/legacyNonlinearWorkflow"

export default class extends Controller {
  static values = {
    component: String,
    props: Object,
    modal: Boolean,
  }

  componentValue: string
  propsValue: object
  modalValue: boolean

  connect() {
    if (this.modalValue) {
      this.root = ReactDOM.createRoot(this.element)
      this.root.render(React.createElement(LegacyNonlinearWorkflow, this.propsValue))
    }
  }

  disconnect() {
    if (!this.root) return
    this.root.unmount()
  }
}
