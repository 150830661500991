import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="nested-form-confirm-delete"
// This controller can be used with the nested-form controller when you need to
// handle a confirm delete modal. It works by dispatching the `confirmDelete`
// event for other controllers to listen for and act on.
export default class extends Controller {
  static values = {
    formId: String,
  }
  formIdValue: string

  confirmDelete(e: Event) {
    this.dispatch("confirmDelete", { detail: { formId: this.formIdValue } })
  }
}
