import { Controller } from "@hotwired/stimulus"
import { showShallowModeModal } from "../../utils"

// Connects to data-controller="business-groups--level-form"
export default class extends Controller {
  static targets = ["confirmDeleteModalWrapper", "markForDestructionField", "spendPeriodField", "endDate"]
  confirmDeleteModalWrapperTarget: HTMLDivElement
  hasConfirmDeleteModalWrapperTarget: boolean

  confirmDeleteLevel = (event: Event) => {
    event.preventDefault()
    if (this.hasConfirmDeleteModalWrapperTarget) {
      showShallowModeModal(this.confirmDeleteModalWrapperTarget, this.application)
    }
  }

  deleteLevel = () => {
    this.markForDestructionFieldTarget.value = 1
    this.markForDestructionFieldTarget.dispatchEvent(new Event("change", { bubbles: true }))
  }
}
