import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="removable-contract-connections-component"
export default class extends Controller {
  static targets = ["contractIds"]

  remove(event) {
    const originatingElement = event.currentTarget
    const contractId = originatingElement.dataset.contractId

    let contractIds = this.contractIdsTarget.value.split(" ")
    contractIds = contractIds.filter((id) => id !== contractId)
    this.contractIdsTarget.value = contractIds.join(" ")
    originatingElement.closest(".contract-connection").remove()
  }
}
