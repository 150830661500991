import { isBottomButtonSelected, isRightButtonSelected, isLeftButtonSelected } from "./utils"

const taskState = ({ data }) => {
  const editPath = `workflow_tasks/${data.taskId}/edit`
  const isEditing = new URL(document.location).pathname.includes(editPath)

  const sourceTaskIds = new URL(document.location).searchParams.getAll("source_task_ids[]")
  const targetTaskIds = new URL(document.location).searchParams.getAll("target_task_ids[]")

  const rightButtonSelected = isRightButtonSelected({
    taskId: data.taskId,
    targets: data.targets,
    sourceTaskIds,
    targetTaskIds,
    path: data.addTaskPathRight,
  })

  const bottomButtonSelected = isBottomButtonSelected({
    taskId: data.taskId,
    triggers: data.triggers,
    targets: data.targets,
    sourceTaskIds,
    targetTaskIds,
    path: data.addTaskPathBottom,
  })

  const leftButtonSelected = isLeftButtonSelected({
    taskId: data.taskId,
    targetTaskIds,
  })

  const isAddingNewTask = rightButtonSelected || bottomButtonSelected || leftButtonSelected

  return { isEditing, rightButtonSelected, bottomButtonSelected, leftButtonSelected, isAddingNewTask }
}

export { taskState }
