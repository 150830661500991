import { Controller } from "@hotwired/stimulus"
import IMask from "imask"

// Connects to data-controller="request-form--currency-field"
export default class extends Controller {
  static targets = ["field"]

  fieldTarget: HTMLInputElement

  connect() {
    if (this.hasFieldTarget) {
      this.maskField()
    }
  }

  maskField(): void {
    IMask(this.fieldTarget, {
      mask: [
        { mask: "" },
        {
          mask: "num",
          lazy: true,
          blocks: {
            num: {
              mask: Number,
              scale: 2,
              thousandsSeparator: ",",
              padFractionalZeros: true,
              radix: ".",
            },
          },
        },
      ],
    })
  }
}
