import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="empty-field"
export default class extends Controller {
  static targets = ["field"]

  fieldTarget: HTMLInputElement

  connect() {
    this.checkValue(this.fieldTarget?.value || "")
    this.fieldTarget.addEventListener("change", (e: any) => this.checkValue(e.target.value))
  }

  checkValue(value) {
    if (value === "") {
      this.fieldTarget.classList.add("is-empty")
    } else {
      this.fieldTarget.classList.remove("is-empty")
    }
  }
}
