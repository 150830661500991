import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="auto-scroll"
export default class extends Controller {
  static targets = ["targetElement"]
  static values = {
    scrollOnConnect: {
      default: false,
      type: Boolean,
    },
    // Scroll behavior: auto, smooth
    behavior: {
      default: "auto",
      type: String,
    },
    // Scroll vertical alignment: start, center, end, nearest
    block: {
      default: "start",
      type: String,
    },
    // Scroll horizontal alignment: start, center, end, nearest
    inline: {
      default: "start",
      type: String,
    },
    // Scroll delay in milliseconds
    delay: {
      default: 0,
      type: Number,
    },
  }

  hasTargetElementTarget: boolean
  targetElementTarget: HTMLElement

  scrollOnConnectValue: boolean
  behaviorValue: string
  blockValue: string
  inlineValue: string
  delayValue: number

  connect() {
    if (this.scrollOnConnectValue) {
      this.scroll()
    }
  }

  scroll() {
    const targetEl = this.hasTargetElementTarget ? this.targetElementTarget : this.element
    setTimeout(() => {
      targetEl.scrollIntoView({
        behavior: this.behaviorValue as ScrollBehavior,
        block: this.blockValue as ScrollLogicalPosition,
        inline: this.inlineValue as ScrollLogicalPosition,
      })
    }, this.delayValue)
  }
}
