import { Controller } from "@hotwired/stimulus"
import { getMonthsAndDaysString, getMonthsAndDays } from "../../utils/dates"
import { Dictionary } from "../../utils/types"

interface DurationTargets {
  startDateTarget: HTMLInputElement
  endDateTarget: HTMLInputElement
  durationTarget: HTMLInputElement
}

interface DurationController extends Controller, DurationTargets {}

type UseDurationCalculation = UseDurationControllerCalculation | TargetsProvidedForCalculation

interface UseDurationControllerCalculation {
  controller: DurationController
  targets?: DurationTargets
}

interface TargetsProvidedForCalculation {
  controller: Controller
  targets: DurationTargets
}

/**
 * Mixin to calculate the duration between a given start date and end date target. Will update the duration targe value
 * with the number of Months and Days between the two dates.
 *
 * @param  params {
 *   controller: Controller | DurationController, (required) - this is either a regular stimulus controller and
 *   you pass in the targets, or you pass in a DurationController and the targets are defined on the controller itself
 *
 *   targets - (optional) - if you pass in a regular stimulus controller, you must pass in the targets, otherwise
 *   can be used to override the targets on the controller
 * }
 */
export function useDurationCalculation({ controller, targets }: UseDurationCalculation) {
  const { startDateTarget, endDateTarget, durationTarget } = targets || (controller as DurationController)

  connect()

  // Functions
  function connect() {
    assignCalculateAction({ calculateDuration })
    calculateDuration()
  }

  // assign data-action change->calculateDuration to the start and end date targets
  // and set the the method on the controller
  function assignCalculateAction(actions: Dictionary<() => void>) {
    const controllerName = controller.identifier

    // assign the action functions to the controller
    Object.assign(controller, actions)

    // assign the data-action attributes to the targets with corresponding action
    Object.keys(actions).forEach((action) => {
      setDataActionAttribute({
        field: startDateTarget,
        controllerName,
        actionName: action,
      })
      setDataActionAttribute({
        field: endDateTarget,
        controllerName,
        actionName: action,
      })
    })
  }

  function calculateDuration() {
    const startDate = startDateTarget.value
    const endDate = endDateTarget.value

    if (startDate && endDate) {
      const { result: datesAreValid, message: errorMessage } = areDatesValid(startDate, endDate)

      durationTarget.value = datesAreValid ? getMonthsAndDaysString(startDate, endDate) : errorMessage
    } else {
      durationTarget.value = ""
    }
  }
}

interface SetDataActionAttributes {
  field: HTMLInputElement
  controllerName: string
  actionName: string
  eventType?: string
}

function setDataActionAttribute({ field, controllerName, actionName, eventType = "change" }: SetDataActionAttributes) {
  const existingAction = field.getAttribute("data-action")

  const newDataAction = `${eventType}->${controllerName}#${actionName}`

  if (existingAction?.includes(newDataAction)) return

  field.setAttribute("data-action", `${existingAction || ""} ${newDataAction}`)
}

function areDatesValid(
  startDate: string,
  endDate: string,
): { result: false; message: string } | { result: true; message: null } {
  if (startDate === "" || endDate === "") {
    return {
      result: false,
      message: "Start and end dates are required",
    }
  }

  const parsedStartDate = new Date(startDate)
  const parsedEndDate = new Date(endDate)
  const endDateYear = parsedEndDate.getFullYear()

  if (parsedStartDate > parsedEndDate) {
    // the user is probably still typing
    if (endDateYear.toString().length <= 3) {
      return {
        result: false,
        message: "--",
      }
    } else {
      return {
        result: false,
        message: "Start date must be before end date",
      }
    }
  }

  const { months } = getMonthsAndDays(startDate, endDate)
  if (months > 120) {
    return {
      result: false,
      message: "Duration cannot be more than 10 years",
    }
  }

  return {
    result: true,
    message: null,
  }
}
