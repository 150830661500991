import { Dropdown } from "tailwindcss-stimulus-components"

export default class NavItemDropdownController extends Dropdown {
  static targets = ["iconOpen", "iconClose"]

  iconOpenTarget: HTMLSpanElement
  iconCloseTarget: HTMLSpanElement

  openValueChanged(value, previousValue) {
    super.openValueChanged()

    if (this.openValue) {
      this.iconOpenTarget.classList.remove("hidden")
      this.iconCloseTarget.classList.add("hidden")
    } else {
      this.iconCloseTarget.classList.remove("hidden")
      this.iconOpenTarget.classList.add("hidden")
    }
  }
}
