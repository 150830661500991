import { Controller } from "@hotwired/stimulus"
import { show, hide, removeRequired, setRequired } from "../utils"

// Connects to data-controller="review-assign-task"
export default class extends Controller {
  static targets = [
    "actionField",
    "assigneeSelectContainer",
    "assigneeSelect",
    "assignLaterText",
    "skipText",
    "assignButton",
    "assignLaterButton",
    "skipButton",
    "reopenButton",
  ]

  actionFieldTarget: HTMLInputElement
  assigneeSelectContainerTarget: HTMLElement
  assigneeSelectTarget: HTMLElement
  assignLaterTextTarget: HTMLElement
  skipTextTarget: HTMLElement

  assignButtonTarget: HTMLElement
  assignLaterButtonTarget: HTMLElement
  skipButtonTarget: HTMLElement
  reopenButtonTarget: HTMLElement

  connect() {
    this.resetView()
    switch (this.actionFieldTarget.value) {
      case "assign":
        this.viewAssign()
        break
      case "assignLater":
        this.viewAssignLater()
        break
      case "skip":
        this.viewSkip()
        break
    }
  }

  assign(e) {
    e.preventDefault()

    this.resetView()
    this.viewAssign()
  }

  assignLater(e) {
    e.preventDefault()

    this.resetView()
    this.viewAssignLater()
  }

  skip(e) {
    e.preventDefault()

    this.resetView()
    this.viewSkip()
  }

  resetView() {
    hide(this.assigneeSelectContainerTarget)
    hide(this.assignLaterTextTarget)
    hide(this.skipTextTarget)

    hide(this.assignLaterButtonTarget)
    hide(this.assignButtonTarget)
    hide(this.skipButtonTarget)
    hide(this.reopenButtonTarget)
  }

  viewAssign() {
    this.actionFieldTarget.value = "assign"
    setRequired(this.assigneeSelectTarget)

    show(this.assigneeSelectContainerTarget)
    show(this.assignLaterButtonTarget)
    show(this.skipButtonTarget)
  }

  viewAssignLater() {
    this.actionFieldTarget.value = "assignLater"
    removeRequired(this.assigneeSelectTarget)

    show(this.assignLaterTextTarget)
    show(this.assignButtonTarget)
    show(this.skipButtonTarget)
  }

  viewSkip() {
    this.actionFieldTarget.value = "skip"
    removeRequired(this.assigneeSelectTarget)

    show(this.skipTextTarget)
    show(this.reopenButtonTarget)
    show(this.assignLaterButtonTarget)
  }
}
