import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="otp-input"
export default class extends Controller {
  static targets = ["inputOne", "inputTwo", "inputThree", "inputFour"]
  inputOneTarget: HTMLInputElement
  inputTwoTarget: HTMLInputElement
  inputThreeTarget: HTMLInputElement
  inputFourTarget: HTMLInputElement

  getLastDigit(value: string): number {
    const numStr = value.slice(-1)
    return +numStr
  }

  validateCode(code: string): boolean {
    return code.length === 4 && Number.isInteger(+code)
  }

  getClipboardText(e) {
    const clipboardData = e.clipboardData || window.clipboardData
    return clipboardData.getData("Text")
  }

  paste(e): void {
    e.stopPropagation()
    e.preventDefault()

    const code = this.getClipboardText(e)
    const isValidCode = this.validateCode(code)

    if (isValidCode) {
      this.inputOneTarget.value = code.charAt(0)
      this.inputTwoTarget.value = code.charAt(1)
      this.inputThreeTarget.value = code.charAt(2)
      this.inputFourTarget.value = code.charAt(3)
    }
  }

  onFocus(e): void {
    e.target.select()
  }
  onKeyUp(e): void {
    e.target.select()
  }

  onKeyDown(e): void {
    const nextInput = e.target.nextElementSibling
    const prevInput = e.target.previousElementSibling
    if (e.keyCode == "8" || e.keyCode == "46") {
      e.preventDefault()
      // delete & backspace
      e.target.value = null
      if (prevInput !== null) {
        prevInput.focus()
      }
    } else if (e.keyCode == "37") {
      // left arrow
      if (prevInput !== null) {
        prevInput.focus()
      }
    } else if (e.keyCode == "39") {
      // right arrow
      if (nextInput !== null) {
        nextInput.focus()
      }
    }
  }

  onInputChange(e): void {
    e.preventDefault()

    const nextInput = e.target.nextElementSibling
    const currentValue = e.target.value

    const shouldMoveForward = nextInput && currentValue !== ""
    const shouldRemoveFocus = currentValue !== "" && nextInput === null

    if (shouldMoveForward) {
      nextInput.focus()
    } else if (shouldRemoveFocus) {
      e.target.blur()
    }
  }
}
