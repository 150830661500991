import { Controller } from "@hotwired/stimulus"
import { show, hide } from "../../utils"
import { pluralizeWithDelimiter } from "../../utils/inflector"

// Connects to data-controller="request-form-editor--page-archive-modal"
export default class extends Controller {
  static targets = ["title", "text", "pageSelect", "initialBtnsContainer", "moveQuestionsBtn", "deleteQuestionsBtn"]

  titleTarget: HTMLHeadingElement
  textTarget: HTMLParagraphElement
  pageSelectTarget: HTMLSelectElement
  initialBtnsContainerTarget: HTMLDivElement
  moveQuestionsBtnTarget: HTMLButtonElement
  deleteQuestionsBtnTarget: HTMLLinkElement

  static values = {
    pageTitle: String,
    pageFormFieldCount: Number,
  }

  pageTitleValue: string
  pageFormFieldCountValue: number

  showMoveQuestionsState(): void {
    hide(this.initialBtnsContainerTarget)

    show(this.moveQuestionsBtnTarget)
    show(this.pageSelectTarget)

    this.titleTarget.innerHTML = "Move Questions"
    this.textTarget.innerHTML =
      "Remove the selected questions from the current page and add them to the bottom of the new page you select."
  }

  showDeleteQuestionsState(): void {
    let questionMessage = ""
    hide(this.initialBtnsContainerTarget)
    show(this.deleteQuestionsBtnTarget)

    questionMessage = pluralizeWithDelimiter("question", this.pageFormFieldCountValue)

    this.titleTarget.innerHTML = "Are you sure?"
    this.textTarget.innerHTML = `"${this.pageTitleValue}" and ${questionMessage} will be deleted.`
  }
}
