import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["scheduledLink", "pastLink"]
  scheduledLinkTarget: HTMLElement
  pastLinkTarget: HTMLElement

  toggleHighlight(e: Event): void {
    if (e.target == this.scheduledLinkTarget) {
      this.scheduledLinkTarget.classList.add("scope-pill-on")
      this.scheduledLinkTarget.classList.remove("scope-pill-off")
      this.pastLinkTarget.classList.remove("scope-pill-on")
      this.pastLinkTarget.classList.add("scope-pill-off")
    }

    if (e.target == this.pastLinkTarget) {
      this.scheduledLinkTarget.classList.remove("scope-pill-on")
      this.scheduledLinkTarget.classList.add("scope-pill-off")
      this.pastLinkTarget.classList.remove("scope-pill-off")
      this.pastLinkTarget.classList.add("scope-pill-on")
    }
  }
}
