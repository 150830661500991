import { Controller } from "@hotwired/stimulus"
import { Stage, Task } from "../../utils/types"
import { show, hide, enable, disable } from "../../utils"
import RequestTaskForm__TaskStageController from "./task_stage_controller"

// Connects to data-controller="request-task-form--trigger-fields"
class RequestTaskForm__TriggerFieldsController extends Controller {
  static targets = ["sourceTask", "targetTask"]
  sourceTaskTarget: HTMLSelectElement
  targetTaskTarget: HTMLSelectElement

  static values = { stageMap: Array }
  stageMapValue: Array<Stage>

  static outlets = ["request-task-form--task-stage"]
  requestTaskFormTaskStageOutlet: RequestTaskForm__TaskStageController

  connect() {
    this.updateOptions()
  }

  updateOptions() {
    this.updateSourceTaskOptions()
    this.updateTargetTaskOptions()
  }

  private updateSourceTaskOptions() {
    if (this.hasRequestTaskFormTaskStageOutlet) {
      const permittedTasks = this.tasksInOrBeforeCurrentStage()

      Array.apply(null, this.sourceTaskTarget.options).map((option) => {
        if (permittedTasks.includes(option.value) || option.value == "request_submitted") {
          show(option)
          enable(option)
        } else {
          hide(option)
          disable(option)
        }
      })
    }
  }

  private updateTargetTaskOptions() {
    if (this.hasRequestTaskFormTaskStageOutlet) {
      const permittedTasks = this.tasksInOrAfterCurrentStage()

      Array.apply(null, this.targetTaskTarget.options).map((option) => {
        if (permittedTasks.includes(option.value)) {
          show(option)
          enable(option)
        } else {
          hide(option)
          disable(option)
        }
      })
    }
  }

  private tasksInOrBeforeCurrentStage() {
    if (this.hasRequestTaskFormTaskStageOutlet) {
      const currentStageName = this.requestTaskFormTaskStageOutlet.stageSelectTarget.value
      const currentStageOrder = this.stageMapValue.filter((stage) => {
        return stage.name === currentStageName
      })[0].order

      const stageTasks = this.stageMapValue.map((stage) => {
        if (stage.order <= currentStageOrder) {
          return stage.tasks
        }
      })

      return stageTasks
        .flat()
        .filter((task): task is Task => task !== undefined && task.id !== this.targetTaskTarget.value)
        .map((task) => task.id)
    }
  }

  private tasksInOrAfterCurrentStage() {
    if (this.hasRequestTaskFormTaskStageOutlet) {
      const currentStageName = this.requestTaskFormTaskStageOutlet.stageSelectTarget.value
      const currentStageOrder = this.stageMapValue.filter((stage) => {
        return stage.name === currentStageName
      })[0].order

      const stageTasks = this.stageMapValue.map((stage) => {
        if (stage.order >= currentStageOrder) {
          return stage.tasks
        }
      })

      return stageTasks
        .flat()
        .filter((task): task is Task => task !== undefined && task.id !== this.sourceTaskTarget.value)
        .map((task) => task.id)
    }
  }
}

export default RequestTaskForm__TriggerFieldsController
