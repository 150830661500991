import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="submit-on-change"
export default class extends Controller {
  static targets = ["form", "field"]

  formTarget: HTMLFormElement
  fieldTargets: HTMLInputElement[] | HTMLSelectElement[]

  submitHandler = this.submit.bind(this)

  connect() {
    this.attachHandlers()
  }

  disconnect() {
    this.detachHandlers()
  }

  submit() {
    this.formTarget.submit()
  }

  private attachHandlers() {
    this.fieldTargets.forEach((field) => {
      field.addEventListener("change", this.submitHandler)
    })
  }

  private detachHandlers() {
    this.fieldTargets.forEach((field) => {
      field.removeEventListener("change", this.submitHandler)
    })
  }
}
