import { Controller } from "@hotwired/stimulus"
import { clearOldErrors } from "../../utils/validations"
import TabsController from "../tabs_controller"

type FormInput = HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement

// Connects to data-controller="purchase-orders--line-item-row-validation"
export default class extends Controller {
  static targets = ["container"]
  containerTarget: HTMLDivElement

  static outlets = ["tabs"]
  tabsOutlet!: TabsController

  static values = {
    itemType: String,
    expenseValidationRules: [
      {
        key: String,
        required: Boolean,
      },
    ],
    itemValidationRules: [
      {
        key: String,
        required: Boolean,
      },
    ],
  }
  itemTypeValue: string
  expenseValidationRulesValue: { key: string; required: boolean }[]
  itemValidationRulesValue: { key: string; required: boolean }[]

  onSubmitStartHandler = this.onSubmitStart.bind(this)

  connect(): void {
    document.addEventListener("turbo:submit-start", this.onSubmitStartHandler)
  }

  disconnect(): void {
    document.removeEventListener("turbo:submit-start", this.onSubmitStartHandler)
  }

  onSubmitStart(event): void {
    const form = event.target as HTMLFormElement

    if (this.itemTypeValue === "expense_line_item") {
      this.validateExpenseLineItems()
    } else {
      this.validateItemLineItems()
    }

    // Re-trigger form validation
    const isFormValid = form.checkValidity()
    if (isFormValid) {
      clearOldErrors(form)
      return
    }

    // prevent the form from being submitted
    event.preventDefault()
    event.detail.formSubmission.stop()
  }

  validateExpenseLineItems(): void {
    if (!this.shouldValidateExpenses()) return

    this.expenseValidationRulesValue.forEach(({ key, required }) => {
      const skipField = this.isExpenseCategoryWithAccountSet(key) || this.isAccountWithCategorySet(key) || !required
      if (skipField) return

      const field = this.queryFieldWithinRow(key)
      field.required = true
    })
  }

  validateItemLineItems(): void {
    if (!this.shouldValidateItems()) return

    this.itemValidationRulesValue.forEach(({ key, required }) => {
      const skipField = !required || key === "amount"
      if (skipField) return

      const field = this.queryFieldWithinRow(key)
      field.required = true
    })
  }

  isExpenseCategoryWithAccountSet(key: string): boolean {
    return key === "accounting_expense_category_id" && this.fieldHasSetValue("accounting_account_id")
  }

  isAccountWithCategorySet(key: string): boolean {
    return key === "accounting_account_id" && this.fieldHasSetValue("accounting_expense_category_id")
  }

  queryFieldWithinRow(key: string): FormInput {
    // ex: [name="purchase_order[expense_line_items][][expense_category_id]"]
    const fieldName = `[name="purchase_order[${this.itemTypeValue}s][][${key}]"]`
    return this.containerTarget.querySelector<FormInput>(fieldName)
  }

  fieldHasSetValue(key: string): boolean {
    const field = this.queryFieldWithinRow(key)
    return !!field?.value
  }

  shouldValidateItems(): boolean {
    const rows = document.querySelectorAll('div[id^="line-item_line_item"]')

    if (rows.length === 0) return false
    if (rows.length > 1 || this.tabsOutlet.currentTabValue === "item_line_item") return true

    const itemRow = rows[0]
    const itemIdName = `[name="purchase_order[item_line_items][][accounting_item_id]"]`
    const itemIdField = itemRow.querySelector<FormInput>(itemIdName)

    return !!itemIdField?.value
  }

  shouldValidateExpenses(): boolean {
    const rows = document.querySelectorAll('div[id^="line-expense_line_item"]')

    if (rows.length === 0) return false
    if (rows.length > 1 || this.tabsOutlet.currentTabValue === "expense_line_item") return true

    const expenseRow = rows[0]

    const categoryIdName = `[name="purchase_order[expense_line_items][][expense_category_id]"]`
    const categoryIdField = expenseRow.querySelector<FormInput>(categoryIdName)

    const accountIdName = `[name="purchase_order[expense_line_items][][accounting_account_id]"]`
    const accountIdField = expenseRow.querySelector<FormInput>(accountIdName)

    console.log(categoryIdField?.value, accountIdField?.value)
    return !!categoryIdField?.value || !!accountIdField?.value
  }
}
