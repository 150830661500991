import { Controller } from "@hotwired/stimulus"
import { disable, enable } from "../utils"
import { DropzoneFile } from "dropzone"
import { EVENT_FILE_ADDED, EVENT_FILE_REMOVED, FileChangeEventDetail } from "./docupload_controller"
import PanelAccordionGroupComponentController from "./canopy/panel_accordion_group_component_controller"

// Connects to data-controller="document-uploader-component"
export default class extends Controller {
  static outlets = ["canopy--panel-accordion-group-component"]
  static targets = ["currentFileSignature", "documentsForm", "documentSaveButton", "submitButton"]

  currentFileSignatureTarget: HTMLInputElement
  documentsFormTarget: HTMLFormElement
  submitButtonTarget: HTMLButtonElement
  filePanelOpenTriggerMap: Map<string, HTMLElement> = new Map()
  panelOpenTriggerFileMap: Map<HTMLElement, DropzoneFile> = new Map()
  savedAndUnsavedFilesMap: Map<string, string> = new Map()

  canopyPanelAccordionGroupComponentOutlet: PanelAccordionGroupComponentController

  allFilesAreSaved = (): boolean => {
    return Array.from(this.savedAndUnsavedFilesMap.values()).every((value) => value === "saved")
  }

  disableEnableSubmitButton = (): void => {
    this.allFilesAreSaved() ? enable(this.submitButtonTarget) : disable(this.submitButtonTarget)
  }

  connect = (): void => {
    window.addEventListener(EVENT_FILE_ADDED, this.handleFileAdded)
    window.addEventListener(EVENT_FILE_REMOVED, this.handleFileRemoved)
  }

  disconnect = (): void => {
    window.removeEventListener(EVENT_FILE_ADDED, this.handleFileAdded)
    window.removeEventListener(EVENT_FILE_REMOVED, this.handleFileRemoved)
  }

  handleFileAdded = (e: CustomEvent<FileChangeEventDetail>): void => {
    const file = e.detail.file
    const fileListItem = this.searchDomForFileListItem(file)
    this.linkFileToPanelTrigger(file, fileListItem)
    this.savedAndUnsavedFilesMap.set(file.signed_id, "unsaved")
    this.disableEnableSubmitButton()

    fileListItem.click()
  }

  handleFileRemoved = (e: CustomEvent<FileChangeEventDetail>): void => {
    const file = e.detail.file
    const fileListItem = this.filePanelOpenTriggerMap.get(file.signed_id)

    if (this.currentFileSignatureTarget.value === file.signed_id) {
      this.canopyPanelAccordionGroupComponentOutlet.hidePanelForTrigger(fileListItem)
      this.currentFileSignatureTarget.value = ""
    }

    this.filePanelOpenTriggerMap.delete(file.signed_id)
    this.panelOpenTriggerFileMap.delete(fileListItem)
    this.savedAndUnsavedFilesMap.delete(file.signed_id)
    this.disableEnableSubmitButton()
  }

  linkFileToPanelTrigger = (file: DropzoneFile, fileListItem: HTMLElement): void => {
    this.filePanelOpenTriggerMap.set(file.signed_id, fileListItem)
    this.panelOpenTriggerFileMap.set(fileListItem, file.signed_id)
  }

  onDocumentSaved = (): void => {
    this.savedAndUnsavedFilesMap.set(this.currentFileSignatureTarget.value, "saved")

    this.disableEnableSubmitButton()
  }

  searchDomForFileListItem = (file: DropzoneFile): Element | undefined => {
    return this.element.querySelector(`[id="${file.upload.uuid}"]`)
  }

  showLinkedFileInDynamicPanel = (e: MouseEvent): void => {
    const fileListItem = e.currentTarget as HTMLElement

    const fileSignature = this.panelOpenTriggerFileMap.get(fileListItem)
    if (this.currentFileSignatureTarget.value === fileSignature) return

    this.currentFileSignatureTarget.value = fileSignature
    this.documentsFormTarget.requestSubmit()
  }
}
