import { Controller } from "@hotwired/stimulus"
import * as EmailValidator from "email-validator"
import { show, hide, buttonAsSubmitting, buttonAsNotSubmitting } from "../../utils"
import { manuallyClearRequiredErrors } from "../../utils/validations"

// Connects to data-controller="workflows--ironclad-initializer-form"
export default class extends Controller {
  static targets = [
    "loader",
    "form",
    "inputError",
    "emailInput",
    "submitButton",
    "numberInput",
    "monetaryInput",
    "fieldUploadList",
    "errors",
  ]
  loaderTarget: HTMLElement
  errorsTarget: HTMLElement
  formTarget: HTMLFormElement
  inputErrorTargets: HTMLElement[]
  emailInputTargets: HTMLInputElement[]
  numberInputTargets: HTMLInputElement[]
  submitButtonTarget: HTMLButtonElement
  monetaryInputTargets: HTMLInputElement[]
  fieldUploadListTargets: HTMLInputElement[]

  connect() {}

  showLoader() {
    show(this.loaderTarget)
  }

  clearErrors() {
    this.inputErrorTargets.forEach((inputErrorTarget) => {
      hide(inputErrorTarget)
    })
    hide(this.errorsTarget)
  }

  checkValidity() {
    let errors = []
    this.clearErrors()

    // Make sure all required fields have a value
    const formFields = Array.from(this.formTarget.elements)

    for (const field of formFields) {
      if (field instanceof HTMLInputElement || field instanceof HTMLSelectElement) {
        if (field.hasAttribute("required")) {
          if (!field.checkValidity()) {
            errors.push(`"${field.getAttribute("data-display-name")}": please fill in a value`)
          } else {
            manuallyClearRequiredErrors(field, field.closest(".invalid"))
          }
        }
      }
    }

    this.emailInputTargets.forEach((emailInput) => {
      if (emailInput.value) {
        if (!EmailValidator.validate(emailInput.value)) {
          const errorElement = emailInput.closest(".input-field-wrapper").querySelector(".input-error")
          errorElement.innerHTML = "must be a valid email address"
          show(errorElement)
          errors.push(`"${emailInput.getAttribute("data-display-name")}": must be a valid email address`)
        }
      }
    })

    this.numberInputTargets.forEach((numberInput) => {
      if (numberInput.value) {
        // Regular expression to match numbers with or without decimals
        const numberPattern = /^\d+(\.\d+)?$/
        if (!numberPattern.test(numberInput.value)) {
          const errorElement = numberInput.closest(".input-field-wrapper").querySelector(".input-error")
          errorElement.innerHTML = "must be a valid number"
          show(errorElement)
          errors.push(`"${numberInput.getAttribute("data-display-name")}": must be a valid number`)
        }
      }
    })

    this.fieldUploadListTargets.forEach((fieldUploadList) => {
      const fileNameInputs = [...fieldUploadList.querySelectorAll("input[name='file_name[]']")]
      const fileNames = fileNameInputs.map((fileNameInput) => fileNameInput.value)

      // make sure all required file upload has a selected file
      const requiredSpan = fieldUploadList.closest(".input-field-wrapper").querySelector("span.required")
      if (requiredSpan && fileNames.length === 0) {
        const errorElement = fieldUploadList.closest(".input-field-wrapper").querySelector(".input-error")
        errorElement.innerHTML = "This field is required"
        errors.push(`"${fieldUploadList.getAttribute("data-display-name")}": please select at least one file`)
        show(errorElement)
        // return early since if there's no selected file, we won't need to check for duplications.
        return
      }

      const containsDuplicates = new Set(fileNames).size !== fileNames.length
      if (containsDuplicates) {
        const errorElement = fieldUploadList.closest(".input-field-wrapper").querySelector(".input-error")
        errorElement.innerHTML = "must not have duplicate file names"
        errors.push(`"${fieldUploadList.getAttribute("data-display-name")}": must not contain duplicated files`)
        show(errorElement)
      }
    })

    const uniqErrors = [...new Set(errors)]
    if (uniqErrors.length) {
      const errorTitleElement = this.errorsTarget.querySelector(".error-title")
      errorTitleElement.innerHTML = `${
        uniqErrors.length > 1
          ? uniqErrors.length + " errors prohibited this record from being saved:"
          : uniqErrors.length + " error prohibited this record from being saved:"
      } `
      const errorContentElement = this.errorsTarget.querySelector(".error-content")
      errorContentElement.innerHTML = `${uniqErrors.map((error) => "<li>" + error + "</li>").join("")}`
      show(this.errorsTarget)
      this.errorsTarget.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      })
    }

    return !uniqErrors.length
  }

  submitForm() {
    buttonAsSubmitting(this.submitButtonTarget)

    if (this.checkValidity()) {
      this.showLoader()
      this.unmaskMonetaryValues()
      this.formTarget.requestSubmit()
    } else {
      buttonAsNotSubmitting(this.submitButtonTarget, "Link Workflow")
    }
  }

  unmaskMonetaryValues() {
    this.monetaryInputTargets.forEach((monetaryInput) => {
      const currentValue = monetaryInput.value
      if (currentValue) {
        // Remove the thousand separators from the monetary value
        monetaryInput.value = currentValue.replace(/,/g, "")
      }
    })
  }

  updateCountry(e) {
    const countryInputEle = e.target
    const addressIsRequired = countryInputEle.hasAttribute("required")
    const newCountryName = countryInputEle.value
    const addressGroup = e.target.closest(".address-group")
    let addRequiredClasses = []
    let removeRequiredClasses = []

    if (newCountryName) {
      // US address validation is enforced if U.S.A, USA, United States, or United States of America are used as the country.
      // https://developer.ironcladapp.com/docs/launch-a-workflow
      if (["U.S.A", "USA", "United States", "United States of America"].includes(newCountryName)) {
        // US addresses require street, locality, region, postcode, and country.
        addRequiredClasses = ["address-line-1", "city", "state-or-province", "postal-code"]
      } else {
        // Non-US addresses require street (lines), locality, and country
        addRequiredClasses = ["address-line-1", "city"]
        removeRequiredClasses = ["state-or-province", "postal-code"]
      }
    } else {
      if (addressIsRequired) {
        removeRequiredClasses = ["state-or-province", "postal-code"]
      } else {
        removeRequiredClasses = ["address-line-1", "city", "state-or-province", "postal-code"]
      }
    }

    removeRequiredClasses.forEach((inputClass) => {
      const optionalInput = addressGroup.querySelector(`.${inputClass}`)
      optionalInput.required = false
      const asteriskSpan = optionalInput.closest(".address-field").querySelector("span.required")
      hide(asteriskSpan)

      // also remove validation error from previous submission
      optionalInput.classList.remove("border-red-500")
      const errorMessage = optionalInput.closest(".address-field").querySelector("div.text-red-500")
      if (errorMessage) {
        errorMessage.remove()
      }
    })

    addRequiredClasses.forEach((inputClass) => {
      const requiredInput = addressGroup.querySelector(`.${inputClass}`)
      requiredInput.required = true
      const asteriskSpan = requiredInput.closest(".address-field").querySelector("span.required")
      show(asteriskSpan)
    })
  }
}
