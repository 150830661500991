import { Controller } from "@hotwired/stimulus"
import { show } from "../utils"

// Connects to data-controller="toggle"
export default class extends Controller {
  static targets = ["content", "option"]
  static classes = ["toggle"]
  static values = { select: String }

  DEFAULT_TOGGLE_CLASSES = ["hidden"]

  classesToToggle: string[]
  contentTargets: HTMLElement[]
  hasToggleClass: boolean
  optionTargets: HTMLElement[]
  toggleClasses: string[]
  selectValue: String

  connect() {
    this.classesToToggle = this.hasToggleClass ? this.toggleClasses : this.DEFAULT_TOGGLE_CLASSES

    // Hide the radio selections and show the target element immediately
    if (this.selectValue) {
      this.toggle()
      const selection = this.element.querySelector(`#${this.selectValue}`)
      if (selection) {
        show(selection)
      }
    }
  }

  toggle() {
    this.contentTargets.forEach((target) => {
      this.classesToToggle.forEach((c) => {
        target.classList.toggle(c)
      })
    })
  }

  toggleTarget(el) {
    this.contentTargets.forEach((target) => {
      target.remove()
    })
    this.optionTargets.forEach((target) => {
      const dataId = el.target.closest("[data-id]")?.getAttribute("data-id")
      if (dataId === target.getAttribute("id")) {
        show(target)
      } else {
        target.remove()
      }
    })
  }
}
