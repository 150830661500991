import { Controller } from "@hotwired/stimulus"

export default class ToggleSyncController extends Controller {
  static targets = ["expenseToggle", "itemToggle"]

  // Explicitly declare the types for the targets
  expenseToggleTarget: HTMLInputElement
  itemToggleTarget: HTMLInputElement

  connect() {
    if (!this.expenseToggleTarget.checked && !this.itemToggleTarget.checked) {
      this.expenseToggleTarget.checked = true
      this.itemToggleTarget.checked = true
    }
  }

  syncToggles(event: Event) {
    const changedToggle = event.target as HTMLInputElement
    const otherToggle = this.expenseToggleTarget === changedToggle ? this.itemToggleTarget : this.expenseToggleTarget

    // If both toggles are unchecked, check the other toggle
    if (!changedToggle.checked && !otherToggle.checked) {
      otherToggle.checked = true
    }
  }
}
