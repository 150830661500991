import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="document-dependant-link"
export default class extends Controller {
  static targets = ["anchor"]

  hasAnchorTarget: boolean
  anchorTarget: HTMLAnchorElement

  updateDocumentIdHandler = this.updateDocumentId.bind(this)
  connect() {
    document.addEventListener("DocumentViewer:onDocumentIdChange", this.updateDocumentIdHandler)
  }

  disconnect() {
    document.removeEventListener("DocumentViewer:onDocumentIdChange", this.updateDocumentIdHandler)
  }

  updateDocumentId(e: CustomEvent) {
    const { documentId } = e.detail
    const link = this.hasAnchorTarget ? this.anchorTarget : (this.element as HTMLAnchorElement)
    const url = new URL(link.href)

    if (documentId) {
      url.searchParams.set("document_id", documentId)
    } else {
      url.searchParams.delete("document_id")
    }

    link.href = url.toString()
  }
}
