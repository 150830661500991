import { Controller } from "@hotwired/stimulus"
import { enter, leave } from "el-transition"

// Connects to data-controller="alert"
export default class extends Controller {
  static targets = ["wrapper"]
  wrapperTarget: Element

  close() {
    leave(this.wrapperTarget)
  }
}
