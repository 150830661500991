import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react"
import ReactFlow, {
  Background,
  Controls,
  ReactFlowProvider,
  SelectionMode,
  addEdge,
  useEdgesState,
  useNodesState,
  useViewport,
  useReactFlow,
  useStoreApi,
  Node,
  Edge as ReactFlowEdge,
} from "reactflow"
import "reactflow/dist/style.css"
import ELK from "elkjs/lib/elk.bundled.js"

import EdgeConnectorNode from "../edgeConnectorNode"
import Edge from "./edge"
import DummyCardNode from "./dummyCardNode"
import StageNode from "./stageNode"
import Spinner from "../../spinner/spinner"
import requestRequestSubmittedTaskCardNode from "./requestRequestSubmittedTaskCardNode"
import inquirySubmittedTaskCardNode from "./inquirySubmittedTaskCardNode"
import workflowRootTaskCardNode from "./workflowRootTaskCardNode"
import { RequestTaskCardNode } from "./requestTaskCardNode"
import { WorkflowTaskCardNode } from "./workflowTaskCardNode"
import WorkflowContextProvider, { useWorkflow } from "./workflowContext"
import {
  highlightPath,
  resetNodeStyles,
  highlightCurrentEdge,
  unhighlightCurrentEdge,
  highlightCurrentNode,
  unhighlightCurrentNode,
  ignoredNode,
} from "./utils"
import approvalChainInitiatedTaskCardNode from "./approvalChainInitiatedTaskCardNode"
import subworkflowInitiatedTaskCardNode from "./subworkflowInitiatedTaskCardNode"
import StageNavigation from "./stageNavigation"
import { IStageNode } from "../../../../utils/types"
import { generateGraph } from "./graphHelper"

export const defaultViewportPosition = { x: 50, y: 100, zoom: 1 }
export const defaultEditable = true

const nodeTypes = {
  dummyCard: DummyCardNode,
  edgeConnector: EdgeConnectorNode,
  requestTaskCard: RequestTaskCardNode,
  stage: StageNode,
  workflowTaskCard: WorkflowTaskCardNode,
  requestRequestSubmittedTaskCard: requestRequestSubmittedTaskCardNode,
  inquirySubmittedTaskCard: inquirySubmittedTaskCardNode,
  workflowRootTaskCard: workflowRootTaskCardNode,
  approvalChainInitiatedTaskCard: approvalChainInitiatedTaskCardNode,
  subworkflowInitiatedTaskCard: subworkflowInitiatedTaskCardNode,
}

const elk = new ELK()

const getLayoutedElements = (nodes, edges, options = {}) => {
  const graph = {
    id: "root",
    layoutOptions: options,
    children: nodes.map((node) => ({
      ...node,
      // Adjust the target and source handle positions based on the layout
      // direction.
      targetPosition: "left",
      sourcePosition: "right",

      // Hardcode a width and height for elk to use when layouting.
      width: 300,
      height: 200,
    })),
    edges: edges,
  }

  return elk
    .layout(graph)
    .then((layoutedGraph) => ({
      nodes: layoutedGraph.children.map((node) => ({
        ...node,
        // React Flow expects a position property on the node instead of `x`
        // and `y` fields.
        position: { x: node.x, y: node.y },
      })),

      edges: layoutedGraph.edges,
    }))
    .catch(console.error)
}

const Flow = (props) => {
  const selectedTaskIdParam = new URL(document.location).searchParams.getAll("selected")[0]
  const { initialViewport, height, width, initialNodes, initialEdges, newUrlOnDeselect, ...otherProps } = props
  const {
    setSelectedTaskId,
    selectedTaskId,
    setSelectedTaskRequestStage,
    setDrawerOpened,
    hoveredTaskId,
    setTaskIsUnsaved,
    redirectToTask,
  } = useWorkflow()
  const { getViewport } = useReactFlow()
  const store = useStoreApi()
  const { addSelectedNodes } = store.getState()
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes)
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges)
  const edgeTypes = useMemo(() => ({ edge: Edge }), [])

  const onConnect = useCallback(
    (params) => {
      setEdges((eds) => addEdge(params, eds))
    },
    [setEdges],
  )

  const stageNodes = nodes.filter((node) => node.type === "stage") as IStageNode[]
  const taskNodes = nodes.filter((node) => node.type !== "stage")

  const onSelectionChange = useCallback(
    (selectedElements, clearSelection = false) => {
      if (clearSelection) {
        resetNodeStyles(setEdges, setNodes)
        setSelectedTaskId("")
        setSelectedTaskRequestStage("")

        if (newUrlOnDeselect) {
          history.replaceState({}, null, newUrlOnDeselect)
        }

        return
      }

      const selectedNode = selectedElements.nodes[0]
      if (selectedNode) {
        highlightPath(selectedNode, nodes, edges, setEdges, setNodes)

        if (selectedNode?.id !== selectedTaskId) {
          setSelectedTaskId(selectedNode?.id)
        }

        setSelectedTaskRequestStage(selectedNode?.data?.requestStage)
      }
    },
    [selectedTaskId],
  )

  const onBackgroundClick = () => {
    redirectToTask(newUrlOnDeselect, JSON.stringify(getViewport()), null, selectedTaskId)
  }

  const onEdgeMouseEnter = (_: React.MouseEvent, edge: ReactFlowEdge): void => {
    highlightCurrentEdge(edge, setEdges, setNodes)
  }

  const onEdgeMouseLeave = (_) => {
    unhighlightCurrentEdge(setEdges, setNodes)
  }

  const onNodeMouseEnter = (_: React.MouseEvent, node: Node) => {
    if (hoveredTaskId === node.data.taskId || ignoredNode(node)) return

    highlightCurrentNode(node, nodes, edges, setNodes, setEdges)
  }

  const onNodeMouseLeave = (_: React.MouseEvent, node: Node) => {
    unhighlightCurrentNode(setNodes, setEdges)
  }

  const { setViewport } = useReactFlow()
  const { x, y, zoom } = useViewport()

  useEffect(() => {
    const currentViewportParams = { x: x, y: y, zoom: zoom }
    setViewport(currentViewportParams)
  }, [x, y, zoom])

  useEffect(() => {
    setViewport(initialViewport)
  }, [setViewport])

  useEffect(() => {
    const selectedTaskIdParam = new URL(document.location).searchParams.getAll("selected")[0]
    const selectedNodes = nodes.filter((node) => node.id === selectedTaskIdParam)
    onSelectionChange({ nodes: selectedNodes }, false)
  }, [])

  useEffect(() => {
    if (selectedTaskId == "") {
      resetNodeStyles(setEdges, setNodes)
    }
  }, [selectedTaskId])

  useEffect(() => {
    const handleDiscardChanges = (e: CustomEvent) => {
      const { previousSelectedTaskId } = e.detail
      addSelectedNodes([previousSelectedTaskId])
    }

    const handleTaskFormChanges = (e: CustomEvent) => {
      const { total } = e.detail
      setTaskIsUnsaved(total > 0)
    }

    document.addEventListener("WorkflowSidebar:onCloseUnsavedChangesModal", handleDiscardChanges)
    document.addEventListener("WorkflowSidebar:onFormValidationFailed", handleDiscardChanges)
    document.addEventListener("FormChangeDetector:onChange", handleTaskFormChanges)
    return () => {
      document.removeEventListener("WorkflowSidebar:onCloseUnsavedChangesModal", handleDiscardChanges)
      document.removeEventListener("WorkflowSidebar:onFormValidationFailed", handleDiscardChanges)
      document.removeEventListener("FormChangeDetector:onChange", handleTaskFormChanges)
    }
  }, [])

  const onLayout = useCallback(
    ({ useInitialNodes = false }) => {
      const ns = useInitialNodes ? initialNodes : nodes
      const es = useInitialNodes ? initialEdges : edges
      const elkOptions = {
        "elk.algorithm": "layered",
        "elk.direction": "RIGHT",
        "elk.edgeRouting": "NETWORK_SIMPLEX",
        "elk.layered.mergeEdges": "true",
        "elk.layered.spacing.nodeNodeBetweenLayers": "100",
        "elk.spacing.nodeNode": "50",
        "elk.layered.nodePlacement.strategy": "BRANDES_KOEPF",
      }

      getLayoutedElements(ns, es, elkOptions).then(({ nodes: layoutedNodes, edges: layoutedEdges }) => {
        layoutedNodes
          .filter((node) => node.type === "stage")
          .forEach((stageNode) => {
            const stage = stageNode.id
            const tasks = layoutedNodes.filter((node) => node.data.requestStage === stage)
            const topMostTask = layoutedNodes.reduce(
              (acc, task) => (task.position.y < acc.position.y ? task : acc),
              tasks[0],
            )
            const leftMostTask = tasks.reduce((acc, task) => (task.position.x < acc.position.x ? task : acc), tasks[0])

            const x = leftMostTask.position.x - 18
            const y = topMostTask.position.y + 160

            stageNode.position.x = x
            stageNode.position.y = y
          })

        setNodes(layoutedNodes)
        setEdges(layoutedEdges)
      })
    },
    [nodes, edges],
  )

  // Calculate the initial layout on mount.
  useLayoutEffect(() => {
    onLayout({ direction: "DOWN", useInitialNodes: true })
  }, [])

  return (
    <ReactFlow
      defaultViewport={initialViewport}
      onPaneClick={() => {
        onBackgroundClick()
        resetNodeStyles(setEdges, setNodes, false)
      }}
      edgeTypes={edgeTypes}
      onConnect={onConnect}
      nodes={nodes}
      edges={edges}
      onNodesChange={onNodesChange}
      onEdgesChange={onEdgesChange}
      onSelectionChange={onSelectionChange}
      onEdgeMouseEnter={onEdgeMouseEnter}
      onEdgeMouseLeave={onEdgeMouseLeave}
      onNodeMouseEnter={onNodeMouseEnter}
      onNodeMouseLeave={onNodeMouseLeave}
      {...otherProps}
    >
      {stageNodes.length > 0 && <StageNavigation stageNodes={stageNodes} taskNodes={taskNodes} />}
      {props.children}
    </ReactFlow>
  )
}

const NonlinearWorkflow = ({
  viewportPosition,
  delayLoad,
  preventScroll,
  editable,
  workflow,
  subworkflow,
  tasks,
  stages = [],
  requestView = false,
  newUrlOnDeselect = "",
}) => {
  const ref = useRef(null)

  const [isLoading, setIsLoading] = useState(delayLoad)
  const [height, setHeight] = useState(0)
  const [width, setWidth] = useState(0)
  const [preventScrolling] = useState(preventScroll)
  const initialViewportPosition = viewportPosition || defaultViewportPosition

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false)
    }, 150)
    return () => clearTimeout(timer)
  }, [])

  useEffect(() => {
    setHeight(ref.current.offsetHeight)
    setWidth(ref.current.offsetWidth)
  }, [])

  const proOptions = { hideAttribution: true }

  const { nodes, edges } = generateGraph(tasks, stages, requestView)

  return isLoading ? (
    <div className="w-full h-full react-flow" ref={ref}>
      <Spinner />
    </div>
  ) : (
    <div className={"h-full w-full"} ref={ref}>
      <ReactFlowProvider>
        <WorkflowContextProvider editable={editable} isWorkflow={workflow} isSubworkflow={subworkflow}>
          <Flow
            initialNodes={nodes}
            initialEdges={edges}
            onlyRenderVisibleElements={true}
            nodeTypes={nodeTypes}
            proOptions={proOptions}
            height={height}
            width={width}
            preventScrolling={preventScrolling}
            selectionMode={SelectionMode.Full}
            newUrlOnDeselect={newUrlOnDeselect}
            initialViewport={initialViewportPosition}
          >
            <Background color="#aaa" gap={16} />
            <Controls showInteractive={false} position={"bottom-left"} className={"flex"} />
          </Flow>
        </WorkflowContextProvider>
      </ReactFlowProvider>
    </div>
  )
}

export default NonlinearWorkflow
