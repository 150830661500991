import React from "react"
import { Handle, Position, useNodes } from "reactflow"
import { getVisualBuilderHandleStyles } from "../../../../utils"
import { useWorkflow } from "./workflowContext"
import { AddFirstTaskButton } from "./addFirstTaskButton"
import { AssignPurchaseCategoryCardNode } from "./assignPurchaseCategoryCardNode"
import { EditIntakeFormCardNode } from "./editIntakeFormCardNode"

export const workflowRootTaskCardNode = (props) => {
  const { data } = props
  return !data?.requestCategory ? <AssignPurchaseCategoryCardNode {...props} /> : <EditIntakeFormCardNode {...props} />
}

export default workflowRootTaskCardNode
