import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="skeleton"
export default class extends Controller {
  static targets = ["loader"]
  loaderTarget: Element

  get isPreview() {
    return document.documentElement.hasAttribute("data-turbo-preview")
  }

  connect() {
    if (!this.isPreview) {
      this.loaderTarget.classList.remove("hidden")
    } else {
    }
  }
}
