import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="form-editor--display-criteria-multi-select"
export default class extends Controller {
  static targets = ["operatorSelect", "singleContainer", "multiContainer"]
  operatorSelectTarget: HTMLSelectElement
  singleContainerTarget: HTMLFieldSetElement
  multiContainerTarget: HTMLFieldSetElement

  connect() {
    this.toggle()
  }

  toggle() {
    const isMulti = this.operatorSelectTarget.value === "incl"

    this.singleContainerTarget.disabled = isMulti
    this.multiContainerTarget.disabled = !isMulti
  }
}
