import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="docusign--recipients-list"
export default class extends Controller {
  static targets = ["container", "newRecipient", "orderingCheckbox", "recipientTemplate"]

  containerTarget: HTMLElement
  orderingCheckboxTarget: HTMLInputElement
  newRecipientTarget: HTMLElement
  recipientTemplateTarget: HTMLElement

  addRecipient = (e: MouseEvent) => {
    e.preventDefault()
    const elementIdPrefix = crypto.randomUUID()
    const content = this.recipientTemplateTarget.innerHTML
      .replace(/ORDER_ID/g, `${elementIdPrefix}_ORDER_ID`)
      .replace(/FIRST_NAME_ID/g, `${elementIdPrefix}_FIRST_NAME_ID`)
      .replace(/LAST_NAME_ID/g, `${elementIdPrefix}_LAST_NAME_ID`)
      .replace(/EMAIL_ID/g, `${elementIdPrefix}_EMAIL_ID`)
      .replace(/ROLE_ID/g, `${elementIdPrefix}_ROLE_ID`)
    this.newRecipientTarget.insertAdjacentHTML("beforebegin", content)
    this.processOrderingVisibility()
  }

  hideOrderingFields = () => {
    this.orderNumberTargets().forEach((e) => {
      e.classList.add("hidden")
    })
  }

  orderNumberTargets = () => {
    return this.containerTarget.querySelectorAll(".order-number")
  }

  processOrderingVisibility = () => {
    const enabled = this.orderingCheckboxTarget.checked
    enabled ? this.showOrderingFields() : this.hideOrderingFields()
  }

  removeRecipient = (e: MouseEvent) => {
    e.preventDefault()

    const wrapper = (e.target as HTMLElement).closest(".recipient-wrapper")
    wrapper.remove()
  }

  showOrderingFields = () => {
    this.orderNumberTargets().forEach((e) => {
      e.classList.remove("hidden")
    })
  }
}
