import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="filter-list"
export default class extends Controller {
  static targets = ["search", "listItems"]
  searchTarget: HTMLInputElement
  listItemsTarget: HTMLUListElement

  filter() {
    const search = this.searchTarget.value.toLowerCase()
    const listItems = this.listItemsTarget.children

    for (let i = 0; i < listItems.length; i++) {
      const item = listItems[i] as HTMLElement
      const text = item.textContent || ""
      const match = text.toLowerCase().includes(search)

      if (match) {
        item.classList.remove("hidden")
      } else {
        item.classList.add("hidden")
      }
    }
  }
}
