import { Controller } from "@hotwired/stimulus"
import * as EmailValidator from "email-validator"

// Connects to data-controller="workflows--supplier-contact-modal"
export default class extends Controller {
  static targets = [
    "continueButton",
    "saveButton",
    "initialEmailText",
    "fieldsContainer",
    "emailAddressField",
    "emailInvalidError",
    "emailExistsError",
  ]

  continueButtonTarget: HTMLButtonElement
  saveButtonTarget: HTMLButtonElement
  initialEmailTextTarget: HTMLDivElement
  fieldsContainerTarget: HTMLDivElement
  emailAddressFieldTarget: HTMLInputElement
  emailInvalidErrorTarget: HTMLDivElement
  emailExistsErrorTarget: HTMLDivElement

  static values = {
    existingContactEmails: String,
  }

  existingContactEmailsValue: string

  connect() {}

  validateEmail() {
    if (this.isEmailValid()) {
      this.continueButtonTarget.classList.add("hidden")
      this.initialEmailTextTarget.classList.add("hidden")

      this.saveButtonTarget.classList.remove("hidden")
      this.fieldsContainerTarget.classList.remove("hidden")
    }
  }

  isEmailValid() {
    let isValid = true
    this.clearErrors()

    const email = this.emailAddressFieldTarget.value

    if (!EmailValidator.validate(email)) {
      this.showError(this.emailInvalidErrorTarget, this.emailAddressFieldTarget)
      isValid = false
    }

    if (JSON.parse(this.existingContactEmailsValue).includes(email)) {
      this.showError(this.emailExistsErrorTarget, this.emailAddressFieldTarget)
      isValid = false
    }

    return isValid
  }

  clearErrors() {
    this.hideError(this.emailInvalidErrorTarget, this.emailAddressFieldTarget)
    this.hideError(this.emailExistsErrorTarget, this.emailAddressFieldTarget)
  }

  showError(errorElement, formElement) {
    errorElement.classList.remove("hidden")
    formElement.classList.add("border-red-500")
  }

  hideError(errorElement, formElement) {
    errorElement.classList.add("hidden")
    formElement.classList.remove("border-red-500")
  }
}
