import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="workflows--edit-workflow-form"
export default class extends Controller {
  static targets = [
    "approvalChainsCheckbox",
    "approvalChainsContainer",
    "approvalChainIdField",
    "approvalChainIdContainer",
    "approvalChainSelectionField",
  ]
  approvalChainsCheckboxTarget: HTMLInput
  approvalChainsContainerTarget: HTMLElement
  approvalChainIdFieldTarget: HTMLInput
  approvalChainIdContainerTarget: HTMLInput
  approvalChainSelectionFieldTarget: HTMLInput

  static values = {
    approvalChainsEnabled: Boolean,
  }
  approvalChainsEnabledValue: boolean

  connect() {
    this.setInitialApprovalChainsCheckbox()
    this.setInitialApprovalChainIdSelect()
  }

  setInitialApprovalChainsCheckbox(): void {
    this.approvalChainsCheckboxTarget.checked = this.approvalChainsEnabledValue
    this.approvalChainsContainerTarget.hidden = !this.approvalChainsEnabledValue
  }

  setInitialApprovalChainIdSelect(): void {
    const hidden = this.approvalChainSelectionFieldTarget.value === "Dynamic"
    this.setApprovalChainId(hidden)
  }

  toggleApprovalChainId(event): void {
    const hidden = event.target.value === "Dynamic"
    this.setApprovalChainId(hidden)
  }

  setApprovalChainId(hidden): void {
    if (hidden) {
      this.approvalChainIdFieldTarget.value = ""
    }
    this.approvalChainIdContainerTarget.hidden = hidden
  }

  toggleApprovalChains(event): void {
    this.approvalChainsContainerTarget.hidden = !event.target.checked
  }
}
