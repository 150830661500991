import { Controller } from "@hotwired/stimulus"
import { setSearchParams } from "../../utils/urls"

// Connects to data-controller="bill--attachment-viewer"
export default class extends Controller {
  static values = { documentId: String }

  connect() {
    this.setIdParams()
  }

  setIdParams() {
    const urlParams = new URLSearchParams(window.location.search)
    urlParams.set("document_id", this.documentIdValue)
    setSearchParams(urlParams)
  }
}
