import { Controller } from "@hotwired/stimulus"
import { v4 as uuidv4 } from "uuid"
import { hide, show } from "../utils"

// Connects to data-controller="templates"
export default class extends Controller {
  static targets = ["container", "spawn", "template", "deleteButton"]

  containerTarget: HTMLDivElement
  spawnTarget: HTMLDivElement
  templateTarget: HTMLDivElement
  deleteButtonTargets: HTMLButtonElement[]

  static values = {
    // The minimum number of clones to generate if there aren't any at connection
    min: {
      type: Number,
      default: 1,
    },
    // The text in the HTML which is replaced with a unique cloneId
    cloneText: {
      type: String,
      default: "NEW_CLONE",
    },
    // The selector for the individual clones
    cloneWrapperSelector: {
      type: String,
      default: ".clone-wrapper",
    },
  }
  minValue: number
  cloneTextValue: string
  cloneWrapperSelectorValue: string

  connect(): void {
    if (this.shouldShowDefaultClones()) {
      this.createDefaultClones()
    }
    if (document.querySelectorAll(".clone-wrapper").length <= this.minValue) {
      this.deleteButtonTargets.forEach((button) => hide(button))
    }
  }

  createClone(e) {
    e.preventDefault()

    this.spawnTarget.insertAdjacentHTML("beforebegin", this.buildClone())
    if (document.querySelectorAll(".clone-wrapper").length > this.minValue) {
      this.deleteButtonTargets.forEach((button) => show(button))
    }
  }

  buildClone() {
    const cloneId = `clone_${uuidv4()}`
    const template = this.templateTarget

    const expression = `${this.cloneTextValue}`
    const regExp = new RegExp(expression, "g")

    return template.innerHTML.replace(regExp, cloneId)
  }

  createDefaultClones() {
    this.spawnTarget.insertAdjacentHTML("beforebegin", this.buildDefaultClones())
  }

  buildDefaultClones() {
    const clonesQuantity = this.minValue
    const content = []

    for (let i = 1; i <= clonesQuantity; i++) {
      content.push(this.buildClone())
    }

    return content.join("")
  }

  removeClone(e) {
    e.preventDefault()
    const wrapper = e.target.closest(this.cloneWrapperSelectorValue)
    wrapper.remove()
    if (document.querySelectorAll(".clone-wrapper").length <= this.minValue) {
      this.deleteButtonTargets.forEach((button) => hide(button))
    }
  }

  shouldShowDefaultClones() {
    return this.minValue && !this.hasClonesRendered()
  }

  hasClonesRendered() {
    return this.containerTarget.querySelectorAll(this.cloneWrapperSelectorValue).length > 0
  }
}
