import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="confirmation-buttons"
export default class extends Controller {
  static targets = ["confirmation", "approve", "reject", "request", "initial"]

  targetMap = {
    approve: "approveTarget",
    reject: "rejectTarget",
    request: "requestTarget",
  }
  hide(el) {
    el.classList.add("hidden")
  }

  show(el) {
    el.classList.remove("hidden")
  }

  back() {
    this.hide(this.confirmationTarget)

    if (this.hasApproveTarget) {
      this.hide(this.approveTarget)
    }
    if (this.hasRejectTarget) {
      this.hide(this.rejectTarget)
    }
    if (this.hasRequestTarget) {
      this.hide(this.requestTarget)
    }

    this.show(this.initialTarget)
  }

  showConfirmation(event) {
    const type = event.currentTarget.dataset.type

    this.show(this.confirmationTarget)
    if (type !== undefined) {
      this.show(this[this.targetMap[type]])
    }
    this.hide(this.initialTarget)
  }
}
