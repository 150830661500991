import { Controller } from "@hotwired/stimulus"
import { hide, show, toggle, disable, enable } from "../utils"

// Connects to data-controller="contract-setup-drawer"
export default class extends Controller {
  static targets = ["contract", "documentViewer", "saveButton", "submitButton"]

  contractTarget: HTMLDivElement
  documentViewerTarget: HTMLDivElement
  saveButtonTarget: HTMLDivElement
  submitButtonTarget: HTMLDivElement

  hasContractTarget: boolean
  hasDocumentViewerTarget: boolean
  hasSaveButtonTarget: boolean
  hasSubmitButtonTarget: boolean

  onSubmitEnabledHandler = this.onSubmitEnabled.bind(this)
  onSubmitDisabledHandler = this.onSubmitDisabled.bind(this)

  connect() {
    window.addEventListener("ContractSetupFrom:enableSubmit", this.onSubmitEnabledHandler)
    window.addEventListener("ContractSetupFrom:disableSubmit", this.onSubmitDisabledHandler)
  }

  disconnect() {
    window.removeEventListener("ContractSetupFrom:enableSubmit", this.onSubmitEnabledHandler)
    window.removeEventListener("ContractSetupFrom:disableSubmit", this.onSubmitDisabledHandler)
  }

  toggleDocumentViewer() {
    if (this.hasDocumentViewerTarget) {
      toggle(this.documentViewerTarget)
    }
  }

  showDocumentViewer() {
    if (this.hasContractTarget) {
      this.contractTarget.classList.add("w-2/5")
    }
    if (this.hasDocumentViewerTarget) {
      show(this.documentViewerTarget)
    }
  }

  hideDocumentViewer() {
    if (this.hasContractTarget) {
      this.contractTarget.classList.remove("w-2/5")
    }
    if (this.hasDocumentViewerTarget) {
      hide(this.documentViewerTarget)
    }
  }

  dispatchClickSubmit() {
    window.dispatchEvent(new CustomEvent("ContractSetupForm:clickSubmit"))
  }

  dispatchClickSave() {
    window.dispatchEvent(new CustomEvent("ContractSetupForm:clickSave"))
  }

  onSubmitEnabled() {
    if (this.hasSaveButtonTarget) {
      this.saveButtonTarget.innerText = "Save & Submit for Review"
    } else if (this.hasSubmitButtonTarget) {
      enable(this.submitButtonTarget)
    }
  }

  onSubmitDisabled() {
    if (this.hasSaveButtonTarget) {
      this.saveButtonTarget.innerText = "Save"
    } else if (this.hasSubmitButtonTarget) {
      disable(this.submitButtonTarget)
    }
  }
}
