import { Controller } from "@hotwired/stimulus"
import { isEmpty } from "../utils"

// Connects to data-controller="highlight-on-document"
export default class extends Controller {
  static targets = ["inputField"]
  static values = {
    documentType: String,
    boundingPolys: Array,
  }
  static polyObject = {
    fieldName: String,
    page: Number,
    normalizedVertices: Array,
  }

  documentTypeValue: string
  boundingPolysValue: Array<polyObject>
  inputFieldTargets: Array<HTMLDivElement>

  connect() {
    if (this.documentTypeValue === "pdf") {
      this.setupPdfjsEventListener()
      this.setupFieldListeners()
    }
    if (this.documentTypeValue === "image") {
      const image = document.getElementById("image-viewer")
      // Ensure the image has a valid src attribute
      if (image && image.src) {
        // Check if the image is already loaded
        if (image.complete) {
          this.setupImagePolygons()
          this.setupFieldListeners()
        } else {
          // Add an event listener for when the image loads
          image.addEventListener("load", () => {
            this.setupImagePolygons()
            this.setupFieldListeners()
          })

          // Optionally, handle the case where the image fails to load
          image.addEventListener("error", () => {
            console.error("Failed to load image.")
          })
        }
      }
    }
  }

  setupImagePolygons() {
    const image = document.getElementById("overlay")
    const imageWidth = image.clientWidth
    const imageHeight = image.clientHeight

    this.boundingPolysValue.forEach((poly) => {
      if (!isEmpty(poly.page)) {
        const boundingPoly = poly.normalizedVertices
        const polygonContainer = document.createElement("div")
        polygonContainer.className = `polygon-container ${poly.fieldName}`
        polygonContainer.style.position = "absolute"
        polygonContainer.style.left = `${boundingPoly[0].x * imageWidth}px`
        polygonContainer.style.top = `${boundingPoly[0].y * imageHeight}px`
        polygonContainer.style.width = `${(boundingPoly[1].x - boundingPoly[0].x) * imageWidth + 10}px`
        polygonContainer.style.height = `${(boundingPoly[2].y - boundingPoly[0].y) * imageHeight + 10}px`
        polygonContainer.style.zIndex = "5"

        image.appendChild(polygonContainer)

        polygonContainer.addEventListener("mouseenter", () => {
          polygonContainer.classList.add("hovered")
        })

        polygonContainer.addEventListener("mouseleave", () => {
          polygonContainer.classList.remove("hovered")
        })
      }
    })
  }

  // Setup listeners for field hover events
  setupFieldListeners() {
    this.inputFieldTargets.forEach((inputField) => {
      inputField
        .closest(".input-highlight")
        .addEventListener("mouseenter", this.handleFieldHover.bind(this, true, inputField.id))
      inputField
        .closest(".input-highlight")
        .addEventListener("mouseleave", this.handleFieldHover.bind(this, false, inputField.id))
    })
  }

  // Handle hover events on input fields
  handleFieldHover(hover, fieldId) {
    const fieldMapping = Object.values(this.boundingPolysValue).find((mapping) => mapping.fieldName === fieldId)

    if (fieldMapping) {
      this.highlightText(fieldMapping, hover)
      if (hover) {
        this.scrollToText(fieldMapping)
      }
    }
  }

  // Highlight text in the document
  highlightText(fieldMapping, highlight) {
    let polygonContainer
    if (this.documentTypeValue === "pdf") {
      const iframe = document.getElementById("pdf-viewer")
      const iframeWindow = iframe.contentWindow
      const pdfViewerApplication = iframeWindow.PDFViewerApplication
      if (!pdfViewerApplication) return
      const page = pdfViewerApplication.pdfViewer.getPageView(fieldMapping.page)
      if (!isEmpty(page)) {
        polygonContainer = page.div.querySelector(`.polygon-container.${fieldMapping.fieldName}`)
      }
    } else {
      const image = document.getElementById("overlay")
      if (!image) return
      polygonContainer = image.querySelector(`.polygon-container.${fieldMapping.fieldName}`)
    }

    if (polygonContainer) {
      polygonContainer.style.backgroundColor = highlight ? "rgb(168, 85, 247, 0.2)" : ""
    }
  }

  // Scroll to the relevant text in the document
  scrollToText({ page, fieldName }) {
    if (this.documentTypeValue === "pdf") {
      const iframe = document.getElementById("pdf-viewer")
      const iframeWindow = iframe.contentWindow
      const pdfViewerApplication = iframeWindow.PDFViewerApplication
      if (!pdfViewerApplication) return
      const pdfPage = pdfViewerApplication.pdfViewer.getPageView(page)
      if (!isEmpty(pdfPage)) {
        const polygonContainer = pdfPage.div.querySelector(`.polygon-container.${fieldName}`)

        if (polygonContainer) {
          polygonContainer.scrollIntoView({ behavior: "instant", block: "end", inline: "start" })
          const elementTop = polygonContainer.getBoundingClientRect().top
          const newScrollTop = elementTop - 100
          pdfPage.div.scrollTo({ top: newScrollTop, behavior: "instant" })
        }
      }
    } else {
      const image = document.getElementById("overlay")
      if (!image) return
      const polygonContainer = image.querySelector(`.polygon-container.${fieldName}`)

      if (polygonContainer) {
        polygonContainer.scrollIntoView({ behavior: "instant", block: "end", inline: "start" })
        image.scrollTo({ top: polygonContainer.offsetTop - 100, behavior: "instant" })
      }
    }
  }

  setupPdfjsEventListener() {
    const iframe = document.getElementById("pdf-viewer")
    if (!iframe) return

    iframe.addEventListener("load", () => {
      // Access the iframe's window and document
      const iframeWindow = iframe.contentWindow
      const maxRetries = 10
      let attempts = 0

      const checkPdfViewerApplication = () => {
        attempts++
        if (iframeWindow.PDFViewerApplication) {
          this.addPdfjsEventListeners(iframeWindow.PDFViewerApplication)
        } else if (attempts < maxRetries) {
          console.error("PDFViewerApplication is not available. Retrying...")
          setTimeout(checkPdfViewerApplication, 100)
        } else {
          console.error("Failed to load PDFViewerApplication when the max retries have reached.")
        }
      }

      checkPdfViewerApplication()
    })
  }

  addPdfjsEventListeners(pdfViewerApplication) {
    const maxRetries = 10
    let attempts = 0

    const checkEventBus = () => {
      attempts++
      if (pdfViewerApplication.eventBus) {
        pdfViewerApplication.eventBus.on("pagerendered", (event) => {
          const pageNumber = event.pageNumber
          this.setupPdfHighlights(pdfViewerApplication, pageNumber)
        })
      } else if (attempts < maxRetries) {
        console.error("eventBus is not available. Retrying...")
        setTimeout(checkEventBus, 100)
      } else {
        console.error("Failed to load eventBus when the max retries have reached.")
      }
    }
    checkEventBus()
  }

  setupPdfHighlights(pdfViewerApplication, pageNumber) {
    // Access the specific page element
    const page = pdfViewerApplication.pdfViewer.getPageView(pageNumber - 1).div
    const pdfWidth = page.clientWidth
    const pdfHeight = page.clientHeight

    // Filter polygons for the current page
    const polygonsForCurrentPage = this.boundingPolysValue.filter((poly) => poly.page === pageNumber - 1)

    // Draw polygons
    polygonsForCurrentPage.forEach((poly) => {
      const boundingPoly = poly.normalizedVertices
      const polygonContainer = document.createElement("div")
      polygonContainer.className = `polygon-container ${poly.fieldName}`
      polygonContainer.style.position = "absolute"
      polygonContainer.style.left = `${boundingPoly[0].x * pdfWidth}px`
      polygonContainer.style.top = `${boundingPoly[0].y * pdfHeight}px`
      polygonContainer.style.width = `${(boundingPoly[1].x - boundingPoly[0].x) * pdfWidth}px`
      polygonContainer.style.height = `${(boundingPoly[2].y - boundingPoly[0].y) * pdfHeight}px`
      polygonContainer.style.zIndex = "1000"

      page.appendChild(polygonContainer)

      polygonContainer.addEventListener("mouseenter", () => {
        polygonContainer.classList.add("hovered")
      })

      polygonContainer.addEventListener("mouseleave", () => {
        polygonContainer.classList.remove("hovered")
      })
    })
  }
}
