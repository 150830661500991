import { Controller } from "@hotwired/stimulus"
import { show, buttonAsSubmitting, buttonAsNotSubmitting, hide } from "../utils"

// Connects to data-controller="purchase-requisition-form"
export default class extends Controller {
  static targets = [
    "loader",
    "form",
    "submitAsTropicDraftButton",
    "submitAsDraftButton",
    "submitForApprovalButton",
    "attachment",
  ]
  loaderTarget: HTMLElement
  formTarget: HTMLFormElement
  submitAsDraftButtonTarget: HTMLButtonElement
  submitAsTropicDraftButtonTarget: HTMLButtonElement
  submitForApprovalButtonTarget: HTMLButtonElement
  attachmentTargets?: HTMLElement[]

  connect() {}

  showLoader() {
    show(this.loaderTarget)
  }

  submitFormAsTropicDraft(e) {
    e.preventDefault()
    buttonAsSubmitting(this.submitAsTropicDraftButtonTarget)

    if (this.checkValidity()) {
      this.showLoader()
      const url = new URL(this.formTarget.action)
      url.searchParams.set("tropic_draft", "true")
      this.formTarget.action = url
      this.formTarget.requestSubmit()
    } else {
      buttonAsNotSubmitting(this.submitAsTropicDraftButtonTarget, "Save")
    }
  }

  submitFormAsDraft(e) {
    e.preventDefault()
    buttonAsSubmitting(this.submitAsDraftButtonTarget)

    if (this.checkValidity()) {
      this.showLoader()
      this.formTarget.requestSubmit()
    } else {
      buttonAsNotSubmitting(this.submitAsDraftButtonTarget, "Submit as Draft")
    }
  }

  submitFormForApproval(e) {
    e.preventDefault()
    buttonAsSubmitting(this.submitForApprovalButtonTarget)

    if (this.checkValidity()) {
      this.showLoader()
      const url = new URL(this.formTarget.action)
      url.searchParams.set("approval", "true")
      this.formTarget.action = url
      this.formTarget.requestSubmit()
    } else {
      buttonAsNotSubmitting(this.submitForApprovalButtonTarget, "Submit for Approval")
    }
  }

  checkValidity() {
    let valid = true
    const formFields = Array.from(this.formTarget.elements)

    for (const field of formFields) {
      if (
        field.hasAttribute("minlength") ||
        field.hasAttribute("maxlength") ||
        field.hasAttribute("min") ||
        field.hasAttribute("max") ||
        field.hasAttribute("required")
      ) {
        if (!field.checkValidity()) {
          valid = false
        }
      }
    }

    // make sure all required attachment fields have a file uploaded
    const scrollToAttachmentError = valid
    let firstAttachmentWithError

    this.attachmentTargets?.forEach((attachment) => {
      const requiredSpan = attachment.querySelector("span.required:not(.hidden)")
      const file = attachment.querySelector("[name = 'file_name[]']") || attachment.querySelector(".attachment-wrapper")
      const errorElement = attachment.querySelector(".input-error")
      hide(errorElement)

      if (requiredSpan && !file) {
        show(errorElement)
        firstAttachmentWithError ??= attachment
        valid = false
      }
    })

    // if there are no other errors, scroll to the the attachment so the user can see the error message.
    if (scrollToAttachmentError && firstAttachmentWithError) {
      firstAttachmentWithError.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      })
    }

    return valid
  }
}
