import { Controller } from "@hotwired/stimulus"
import { hide, show } from "../utils"

// Connects to data-controller="document-reassignment"
export default class extends Controller {
  static targets = [
    "contractSelectWrapper",
    "moveDocumentButton",
    "newOrExistingField",
    "contractField",
    "supplierSearchWrapper",
    "confirmMessageWrapper",
  ]
  contractSelectWrapperTarget: HTMLDivElement
  newOrExistingFieldTarget: HTMLSelectElement
  contractFieldTarget: HTMLSelectElement
  supplierSearchWrapperTarget: HTMLDivElement
  confirmMessageWrapperTarget: HTMLDivElement

  contractSelected = ""
  supplierSelected = ""

  connect() {
    this.toggleContractSupplierFields()
    document.addEventListener("turbo:submit-end", this.handleSubmit)
    hide(this.confirmMessageWrapperTarget)

    this.contractFieldTarget.addEventListener("change", (e: Event) => this.onContractSelected(e))
    this.supplierSearchWrapperTarget
      .querySelector("[data-controller='supplier-search']")
      .addEventListener("select", (e: CustomEvent) => this.onSupplierSelected(e))
  }

  disconnect() {
    document.removeEventListener("turbo:submit-end", this.handleSubmit)
  }

  toggleContractSupplierFields() {
    hide(this.contractSelectWrapperTarget)
    hide(this.supplierSearchWrapperTarget)
    hide(this.confirmMessageWrapperTarget)
    if (this.newOrExistingFieldTarget.value === "existing") {
      this.dispatch("toggleContractSupplierFields")
      show(this.contractSelectWrapperTarget)
      if (this.contractSelected) {
        show(this.confirmMessageWrapperTarget)
      }
    } else if (this.newOrExistingFieldTarget.value === "new") {
      this.contractSelectWrapperTarget.querySelector("select").value = ""
      show(this.supplierSearchWrapperTarget)
      if (this.supplierSelected) {
        show(this.confirmMessageWrapperTarget)
      }
    }
  }

  handleSubmit = (e: CustomEvent) => {
    const url = e.detail.fetchResponse.response.url

    // Adding a new supplier from the supplier search will cause the modal
    // to close earlier so this handles submitting the form and closing the
    // modal only when the actual form is submitted.
    if (e.detail.success && url.includes("reassignments")) {
      this.dispatch("handleSubmit")
    }
  }

  onContractSelected = (event: Event) => {
    const value = (event.target as HTMLSelectElement).value
    this.contractSelected = value
    if (value) {
      show(this.confirmMessageWrapperTarget)
    } else {
      hide(this.confirmMessageWrapperTarget)
    }
  }

  onSupplierSelected = (event: CustomEvent) => {
    const value = event?.detail?.value
    this.supplierSelected = value
    if (value) {
      show(this.confirmMessageWrapperTarget)
    } else {
      hide(this.confirmMessageWrapperTarget)
    }
  }
}
