import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="canopy--button-group-component"
export default class extends Controller {
  static targets = ["button", "indicator"]
  declare readonly buttonTargets: HTMLButtonElement[]
  declare readonly indicatorTarget: HTMLElement

  connect(): void {
    const activeButton = this.buttonTargets.find((btn) => btn.dataset.state === "active")
    if (activeButton) {
      this.moveIndicator(activeButton)
    }
  }

  setActive(event: Event): void {
    const clickedButton = event.currentTarget as HTMLButtonElement
    this.buttonTargets.forEach((button) => this.updateButtonState(button, button === clickedButton))
    this.moveIndicator(clickedButton)
  }

  updateButtonState(button: HTMLButtonElement, isActive: boolean): void {
    const activeClasses = (button.dataset.activeClass || "").split(" ")
    const inactiveClasses = (button.dataset.inactiveClass || "").split(" ")

    if (isActive) {
      button.classList.remove(...inactiveClasses)
      button.classList.add(...activeClasses)
      button.dataset.state = "active"
    } else {
      button.classList.remove(...activeClasses)
      button.classList.add(...inactiveClasses)
      button.dataset.state = "inactive"
    }
  }

  moveIndicator(activeButton: HTMLElement): void {
    const rect = activeButton.getBoundingClientRect()
    const parentRect = this.element.getBoundingClientRect()
    const leftPosition = rect.left - parentRect.left + this.element.scrollLeft
    const positionAdjustment = 1

    // Uses CSS variables to set the width, left, and top of the indicator
    // These are established in the class of ".button-group-component .active-indicator"
    // In our Tailwind CSS file
    this.indicatorTarget.style.setProperty("--indicator-width", `${rect.width - positionAdjustment}px`)
    this.indicatorTarget.style.setProperty("--indicator-left", `${leftPosition}px`)
    this.indicatorTarget.style.setProperty("--indicator-top", `${rect.top - parentRect.top}px`)
  }
}
