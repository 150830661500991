import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="purchase-order-form"
export default class extends Controller {
  static targets = ["form"]
  formTarget: HTMLFormElement

  submit(): void {
    const action = this.formTarget.action
    this.formTarget.action = `${action}?vendor_changed=true`
    this.formTarget.requestSubmit()
  }
}
