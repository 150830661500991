import { Controller } from "@hotwired/stimulus"
import { createPopper } from "@popperjs/core"

// Connects to data-controller="boxplot-tooltips"
export default class extends Controller {
  static values = {
    placement: { type: String, default: "top" },
    offset: { type: Array, default: [0, 12] },
  }

  connect() {
    this.points = [
      this.setupPoint("min"),
      this.setupPoint("q1"),
      this.setupPoint("median"),
      this.setupPoint("q3"),
      this.setupPoint("max"),
    ]
  }

  setupPoint(point) {
    const element = this.element.querySelector(`.${point}-point`)
    const tooltip = this.element.querySelector(`.${point}-tooltip`)
    const instance = this.initializeTooltip(element, tooltip)

    return {
      element,
      tooltip,
      instance,
    }
  }

  initializeTooltip(element, tooltip) {
    return createPopper(element, tooltip, {
      placement: this.placementValue,
      modifiers: [
        {
          name: "offset",
          options: {
            offset: this.offsetValue,
          },
        },
        {
          name: "preventOverflow",
        },
      ],
    })
  }

  activatePoint(point) {
    const { element, tooltip, instance } = point
    tooltip.setAttribute("data-show", "true")
    instance.update()
    element.classList.add("fill-pink-600")
    element.classList.remove("fill-purple-600")
    this.activePoint = point
  }

  disableActivePoint() {
    if (!this.activePoint) {
      return
    }

    const { element, tooltip } = this.activePoint
    tooltip.removeAttribute("data-show")

    // leaves median point always pink
    if (!element.dataset.alwayson) {
      element.classList.add("fill-purple-600")
      element.classList.remove("fill-pink-600")
    }

    this.activePoint = null
  }

  onLeave() {
    this.disableActivePoint()
  }

  onMove({ clientX, clientY }) {
    let shortestDistance = Infinity
    let closestPoint

    this.points.forEach((point) => {
      const { element } = point
      const { x, y } = element.getClientRects()[0]
      // euclidean distance
      const distance = Math.sqrt(Math.pow(x - clientX, 2) + Math.pow(y - clientY, 2))
      if (distance < shortestDistance) {
        shortestDistance = distance
        closestPoint = point
      }
    })

    if (this.activePoint !== closestPoint) {
      this.disableActivePoint()
      this.activatePoint(closestPoint)
    }
  }
}
