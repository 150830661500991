import { Controller } from "@hotwired/stimulus"
import { hide, show } from "../utils"

// Connects to data-controller="turn-off-renewal-reminder"
export default class extends Controller {
  static targets = ["checkbox", "warning"]

  hasCheckboxTarget: boolean
  hasWarningTarget: boolean

  checkboxTarget: HTMLInputElement
  warningTarget: HTMLDivElement

  connect() {
    if (this.hasCheckboxTarget) {
      const { checked } = this.checkboxTarget
      this.toggleWarning(!checked)
    }
  }

  onCheckboxChange(event: Event) {
    const { checked } = <HTMLInputElement>event.target
    this.toggleWarning(!checked)
  }

  toggleWarning(enabled = true) {
    if (!this.hasWarningTarget) {
      return
    }

    if (enabled) {
      hide(this.warningTarget)
    } else {
      show(this.warningTarget)
    }
  }
}
