import { Controller } from "@hotwired/stimulus"
import { show, hide } from "../../utils"

// Connects to data-controller="spend-management--product-apps-toggler"
export default class extends Controller {
  static targets = ["openToggler", "closeToggler", "productAppRow"]

  openTogglerTarget: HTMLDivElement
  openTogglerTargets: [HTMLDivElement]
  closeTogglerTarget: HTMLDivElement
  closeTogglerTargets: [HTMLDivElement]
  productAppRowTarget: HTMLDivElement
  productAppRowTargets: [HTMLDivElement]

  connect() {}

  open(event) {
    const productId = event.params.productId
    const openToggler = this.openTogglerTargets.find((t) => t.dataset.productId === productId)
    const closeToggler = this.closeTogglerTargets.find((t) => t.dataset.productId === productId)
    hide(openToggler)
    show(closeToggler)
    const productAppRows = this.productAppRowTargets.filter((t) => t.dataset.productId === productId)
    productAppRows.forEach((r) => show(r))
  }

  close(event) {
    const productId = event.params.productId
    const openToggler = this.openTogglerTargets.find((t) => t.dataset.productId === productId)
    const closeToggler = this.closeTogglerTargets.find((t) => t.dataset.productId === productId)
    hide(closeToggler)
    show(openToggler)
    const productAppRows = this.productAppRowTargets.filter((target) => target.dataset.productId === productId)
    productAppRows.forEach((row) => hide(row))
  }
}
