import { Controller } from "@hotwired/stimulus"
import { show, hide } from "../utils"

// Connects to data-controller="onboarding-checklist"
export default class extends Controller {
  static targets = ["checklistItem", "checklistItemContent"]

  checklistItemTargets: HTMLElement[]
  checklistItemContentTargets: HTMLElement[]

  selectChecklistItem(event) {
    this.checklistItemTargets.forEach((item) => {
      if (item.id.includes(event.params.group)) {
        if (item.id.includes(event.params.field)) {
          item.classList.add("text-purple-600")
          item.classList.remove("text-gray-600")
        } else {
          item.classList.remove("text-purple-600")
          item.classList.add("text-gray-600")
        }
      }
    })
    this.checklistItemContentTargets.forEach((itemContent) => {
      if (itemContent.id.includes(event.params.group)) {
        if (itemContent.id.includes(event.params.field)) {
          show(itemContent)
        } else {
          hide(itemContent)
        }
      }
    })
  }
}
