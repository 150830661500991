import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="accrual-accounting--require-selection"
export default class extends Controller {
  static targets = ["submitButton", "watchable"]

  watchableTargets: HTMLInputElement[]

  submitButton: HTMLButtonElement | null

  inputsToWatch: HTMLInputElement[]
  hasWatchableTarget: boolean
  onChangeFunction: () => void
  blockSubmitIfNoChangesFunction: (event) => void

  connect() {
    this.onChangeFunction = this.onChange.bind(this)
    this.blockSubmitIfNoChangesFunction = this.blockSubmitIfNoChanges.bind(this)

    this.setInputsToWatch()
    this.setSubmitButtonTarget()

    if (this.areAnySelectionsChecked()) {
      this.enableSubmitButton()
    } else {
      this.disableSubmitButton()
    }

    this.element.addEventListener("submit", this.blockSubmitIfNoChangesFunction)
    if (this.inputsToWatch) {
      this.inputsToWatch.forEach((input) => {
        input.addEventListener("change", this.onChangeFunction)
      })
    }
  }

  disconnect(): void {
    this.element.removeEventListener("submit", this.blockSubmitIfNoChangesFunction)
    if (this.inputsToWatch) {
      this.inputsToWatch.forEach((input) => input.removeEventListener("change", this.onChangeFunction))
    }
  }

  blockSubmitIfNoChanges(event) {
    if (!this.areAnySelectionsChecked()) {
      event.preventDefault()
    }
  }

  onChange() {
    const hasSelection = this.areAnySelectionsChecked()
    if (hasSelection) {
      this.enableSubmitButton()
    } else {
      this.disableSubmitButton()
    }
  }

  setInputsToWatch() {
    if (this.hasWatchableTarget) {
      this.inputsToWatch = this.watchableTargets.concat(this.selectAllToggle())
    }
  }

  selectAllToggle(): HTMLInputElement {
    return this.element.querySelector("#select_all") as HTMLInputElement
  }

  setSubmitButtonTarget() {
    if (!this.submitButton) {
      const form = this.element.closest("form")
      const submitButton = form.querySelectorAll("input[type=submit][class=button-primary]")[0]
      this.submitButton = submitButton
    }
  }

  areAnySelectionsChecked() {
    if (!this.inputsToWatch || this.inputsToWatch.length == 0) {
      return false
    }

    for (let input of this.inputsToWatch) {
      if (input.checked) {
        return true
      }
    }

    return false
  }

  disableSubmitButton() {
    if (this.submitButton) {
      this.submitButton.disabled = true
      this.submitButton.classList.add("disabled")
    }
  }

  enableSubmitButton() {
    if (this.submitButton) {
      this.submitButton.disabled = false
      this.submitButton.classList.remove("disabled")
    }
  }
}
