import { Controller } from "@hotwired/stimulus"
import { showShallowModeModal } from "../utils"

// Connects to data-controller="contract-form-currency"
export default class extends Controller {
  static targets = [
    "confirmCurrencyModalWrapper",
    "contractCurrencyField",
    "contractedSpendCurrencyField",
    "periodCurrencyField",
  ]
  confirmCurrencyModalWrapperTarget: HTMLDivElement
  contractCurrencyFieldTarget: HTMLSelectElement
  contractedSpendCurrencyFieldTargets: HTMLSelectElement[]
  periodCurrencyFieldTargets: HTMLSelectElement[]

  connect() {
    if (this.hasContractCurrencyFieldTarget) {
      this.previousCurrency = this.contractCurrencyFieldTarget.value
      this.updateContractedSpendCurrencies(this.contractCurrencyFieldTarget.value)
      this.updatePeriods(this.contractCurrencyFieldTarget.value)
    }
  }

  onCurrencyChange(event: Event) {
    const currency = event.target.value
    this.renderConfirmationModal(currency)
    this.updateContractedSpendCurrencies(currency)
    this.updatePeriods(currency)
  }

  confirmCurrencyChange() {
    this.previousCurrency = this.contractCurrencyFieldTarget.value
  }

  revertCurrencyChange() {
    this.contractCurrencyFieldTarget.tomselect?.setValue(this.previousCurrency)
    this.updateContractedSpendCurrencies(this.previousCurrency)
    this.updatePeriods(this.previousCurrency)
  }

  renderConfirmationModal(currency: string) {
    if (this.hasConfirmCurrencyModalWrapperTarget) {
      showShallowModeModal(this.confirmCurrencyModalWrapperTarget, this.application)
    }
  }

  updatePeriods(currency: string) {
    if (this.hasPeriodCurrencyFieldTarget) {
      this.periodCurrencyFieldTargets.forEach((periodCurrencyField) => {
        periodCurrencyField.tomselect?.setValue(currency)
      })
    }
  }

  updateContractedSpendCurrencies(currency: string) {
    if (this.hasContractedSpendCurrencyFieldTarget) {
      this.contractedSpendCurrencyFieldTargets.forEach((contractedSpendCurrencyField) => {
        contractedSpendCurrencyField.tomselect?.setValue(currency)
      })
    }
  }
}
