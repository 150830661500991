import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="information-needed"
export default class extends Controller {
  static targets = ["recipientsSelect"]

  static values = {
    documentProvidersIds: Array,
  }

  recipientsSelectTarget: HTMLSelectElement

  documentProvidersIdsValue: string[]

  addDocumentProviders(event: Event) {
    event.preventDefault()
    const payload = {
      target: this.recipientsSelectTarget,
      items: this.documentProvidersIdsValue || [],
    }
    window.dispatchEvent(new CustomEvent("StyledSelect:addItems", { detail: payload }))
  }

  disableValidation(event: Event) {
    const form = (event.target as any).form
    form.noValidate = true
  }
}
