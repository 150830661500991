import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="link-existing-external-task"
export default class extends Controller {
  static targets = ["linkExistingExternalTaskForm", "loader"]
  linkExistingExternalTaskFormTarget: HTMLFormElement
  loaderTarget: HTMLElement

  connect() {}

  showLoader() {
    this.loaderTarget.classList.remove("hidden")
  }

  submitForm() {
    if (this.linkExistingExternalTaskFormTarget.checkValidity()) {
      this.linkExistingExternalTaskFormTarget.requestSubmit()
    }
  }
}
