import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["sectionCounter"]
  static outlets = ["toggle-related-inputs"]

  eventListeners = []

  connect() {
    this.countEnabled()
    this.observeChanges()
  }

  disconnect() {
    this.eventListeners.forEach(({ element, listener }) => {
      element.removeEventListener("click", listener)
    })
  }

  countEnabled() {
    const checkedArray = []
    this.toggleRelatedInputsOutlets.forEach((outlet) => {
      //lets count the number of checkbox inputs that are checked inside outlet
      let checked = outlet.mainInputTarget.checked

      if (checked) {
        checkedArray.push(checked)
      }
    })

    this.sectionCounterTarget.textContent = checkedArray.length
  }

  observeChanges() {
    this.toggleRelatedInputsOutlets.forEach((outlet) => {
      const listener = () => {
        this.countEnabled()
      }

      outlet.mainInputTarget.addEventListener("click", listener)

      this.eventListeners.push({ element: outlet.mainInputTarget, listener })
    })
  }
}
