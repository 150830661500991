import { availableOption, onlyOneOptionIsVisible } from "../../utils/request_form/optionsHelper"
import { FormFieldKey, FormFieldKind, IFormField } from "../../utils/constants"

type FieldSelector = (_: IFormField) => void

const AUTO_SELECTABLE_KEYS: FormFieldKey = ["request_type"]

export class RequestFormFieldAutoSelector {
  private form: HTMLFormElement
  private formFields: IFormField[]

  static call = (form: HTMLFormElement, formFields: IFormField[]): void => {
    return new RequestFormFieldAutoSelector(form, formFields).call()
  }

  private call = (): void => {
    this.formFields.forEach((ff: IFormField) => {
      if (!AUTO_SELECTABLE_KEYS.includes(ff.key)) return

      const selectField = this.FIELD_VALUE_SELECTORS[ff.kind]
      if (!selectField) return
      selectField(ff)
    })
  }

  private selectOnlyVisibleOption = (field: IFormField): void => {
    if (!onlyOneOptionIsVisible(field)) return

    const input = Array.from(this.form.elements).find((e) => {
      return e.getAttribute("name") === `form[${field.key}]` && e.getAttribute("value") === availableOption(field).title
    }) as HTMLElement

    input.click()
  }

  private FIELD_VALUE_SELECTORS: { [key in FormFieldKind]: FieldSelector } = {
    RadioButton: this.selectOnlyVisibleOption,
  }

  private constructor(form: HTMLFormElement, formFields: IFormField[]) {
    this.form = form
    this.formFields = formFields
  }
}
