import { Controller } from "@hotwired/stimulus"
import { enter, leave } from "el-transition"
import { trigger } from "../utils/events"
import { setSearchParams } from "../utils/urls"

export default class extends Controller {
  static targets = ["drawer", "wrapper", "body", "className"]

  static values = {
    blockCloseOnFormSubmit: Boolean,
    // Allows to bind the drawer with a specific param, so it gets added/removed from the
    // URL when the Drawer gets opened/closed
    bindToQueryStringParam: {
      default: false,
      type: Boolean,
    },
    queryStringParamKey: {
      default: "",
      type: String,
    },
    queryStringParamValue: {
      default: "",
      type: String,
    },
    removeQueryStringParamOnClose: {
      default: true,
      type: Boolean,
    },
    removeQueryStringParamOnDisconnect: {
      default: true,
      type: Boolean,
    },
    localDrawer: {
      default: false,
      type: Boolean,
    },
  }

  drawerTarget: HTMLDivElement
  wrapperTarget: HTMLDivElement
  bodyTarget: HTMLDivElement
  classNameTarget: HTMLDivElement

  hasClassNameTarget: boolean

  blockCloseOnFormSubmitValue: boolean
  bindToQueryStringParamValue: boolean
  queryStringParamKeyValue: string
  queryStringParamValueValue: string
  removeQueryStringParamOnCloseValue: boolean
  removeQueryStringParamOnDisconnectValue: boolean
  localDrawerValue: boolean

  get isPreview() {
    return document.documentElement.hasAttribute("data-turbo-preview")
  }

  updateQueryStringParamHandler = this.updateQueryStringParam.bind(this)

  onDrawerOpenFunction: (event) => void

  connect() {
    if (!this.isPreview && !this.localDrawerValue) {
      this.open()
    }

    if (this.shouldAppendQueryStringParam()) {
      this.attachQueryStringParam()
    }

    if (this.localDrawerValue) {
      this.onDrawerOpenFunction = this.open.bind(this)
      document.addEventListener("drawer:open", this.onDrawerOpenFunction)
    }

    document.addEventListener("turbo:submit-end", this.handleSubmit)
    document.addEventListener("Drawer:updateQueryStringParam", this.updateQueryStringParamHandler)
  }

  disconnect() {
    document.removeEventListener("turbo:submit-end", this.handleSubmit)
    document.removeEventListener("Drawer:updateQueryStringParam", this.updateQueryStringParamHandler)

    if (this.shouldAppendQueryStringParam() && this.removeQueryStringParamOnDisconnectValue) {
      this.detachQueryStringParam()
    }

    if (this.localDrawerValue) {
      document.removeEventListener("drawer:open", this.open)
    }
  }

  open() {
    if (this.localDrawerValue) {
      this.drawerTarget.classList.add("inset-0")
    }

    enter(this.wrapperTarget)
    enter(this.bodyTarget)
  }

  close() {
    trigger("drawer:close", {})

    if (this.localDrawerValue) {
      this.drawerTarget.classList.remove("inset-0")
      leave(this.wrapperTarget)
      leave(this.bodyTarget)
    } else {
      leave(this.wrapperTarget)
      leave(this.bodyTarget).then(() => {
        // Remove the drawer element after the fade out so it doesn't blanket the screen
        this.element.remove()
      })

      // Remove src reference from parent frame element
      // Without this, turbo won't re-open the modal on subsequent clicks
      this.element.closest("turbo-frame").src = undefined

      if (this.shouldAppendQueryStringParam() && this.removeQueryStringParamOnCloseValue) {
        this.detachQueryStringParam()
      }
    }
  }

  toggleClassName() {
    if (this.hasClassNameTarget) {
      const className = this.classNameTarget.dataset.className || ""
      const alternativeClassName = this.classNameTarget.dataset.alternativeClassName || ""
      this.classNameTarget.classList.toggle(className)
      this.classNameTarget.classList.toggle(alternativeClassName)
    }
  }

  setAlternativeClassName() {
    if (this.hasClassNameTarget) {
      const className = this.classNameTarget.dataset.className || ""
      const alternativeClassName = this.classNameTarget.dataset.alternativeClassName || ""
      this.classNameTarget.classList.remove(className)
      this.classNameTarget.classList.add(alternativeClassName)
    }
  }

  setDefaultClassName() {
    if (this.hasClassNameTarget) {
      const className = this.classNameTarget.dataset.className || ""
      const alternativeClassName = this.classNameTarget.dataset.alternativeClassName || ""
      this.classNameTarget.classList.add(className)
      this.classNameTarget.classList.remove(alternativeClassName)
    }
  }

  handleKeyup(e: KeyboardEvent) {
    if (e.code == "Escape") {
      this.close()
    }
  }

  handleSubmit = (e: CustomEvent) => {
    if (this.blockCloseOnFormSubmitValue) {
      return
    }

    if (e.detail.success) {
      this.close()
    }
  }

  shouldAppendQueryStringParam() {
    return this.bindToQueryStringParamValue && this.queryStringParamKeyValue && this.queryStringParamValueValue
  }

  attachQueryStringParam() {
    const url = new URL(window.location.toString())
    if (!url.searchParams.has(this.queryStringParamKeyValue)) {
      url.searchParams.set(this.queryStringParamKeyValue, this.queryStringParamValueValue)
      setSearchParams(url.searchParams)
    }
  }

  detachQueryStringParam() {
    const url = new URL(window.location.toString())
    if (url.searchParams.has(this.queryStringParamKeyValue)) {
      url.searchParams.delete(this.queryStringParamKeyValue)
      setSearchParams(url.searchParams)
    }
  }

  // Allows to programmatically change the query string param either by a custom event:
  //    document.dispatch("Drawer:updateQueryStringParam", {detail: {queryStringParamValue: "newVal"})
  // Or by calling the stimulus action:
  //    <a href="#" data-action="drawer#updateQueryStringParam" data-query-string-param-value="newVal"></a>
  updateQueryStringParam(event: CustomEvent) {
    if (!this.shouldAppendQueryStringParam()) {
      return
    }

    let value = null
    if (event.detail.queryStringParamValue) {
      value = event.detail.queryStringParamValue
    } else {
      const element = event.currentTarget || event.target
      value = (element as HTMLDivElement).dataset.queryStringParamValue
    }

    const url = new URL(window.location.toString())
    if (value && url.searchParams.has(this.queryStringParamKeyValue)) {
      url.searchParams.set(this.queryStringParamKeyValue, value)
      setSearchParams(url.searchParams)
    }
  }
}
