import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="components--save-or-cancel-buttons"
export default class extends Controller {
  static targets = ["buttonsContainer"]
  buttonsContainerTarget: HTMLDivElement

  buttonClassList = ["disabled:opacity-50", "cursor-wait"]
  anchorClassList = ["opacity-50", "pointer-events-none"]

  connect() {
    document.addEventListener("turbo:submit-start", this.disableActions)
    document.addEventListener("turbo:submit-end", this.enableActions)
  }

  disconnect() {
    document.removeEventListener("turbo:submit-start", this.disableActions)
    document.removeEventListener("turbo:submit-end", this.enableActions)
  }

  disableActions = () => {
    this.findAnchors().forEach((el) => {
      el.classList.add(...this.anchorClassList)
    })

    this.findButtons().forEach((el) => {
      el.classList.add(...this.buttonClassList)
      el.disabled = true
    })
  }

  enableActions = () => {
    this.findAnchors().forEach((el) => {
      el.classList.remove(...this.anchorClassList)
    })

    this.findButtons().forEach((el) => {
      el.classList.remove(...this.buttonClassList)
      el.disabled = false
    })
  }

  findButtons = () => this.buttonsContainerTarget.querySelectorAll("button, input[type=submit]")
  findAnchors = () => this.buttonsContainerTarget.querySelectorAll("a")
}
