import { Controller } from "@hotwired/stimulus"
import { buildUrl } from "../../utils/urls"

// Connects to data-controller="supplier-intelligence--filter-url-params"
export default class extends Controller {
  linkTagTarget: HTMLAnchorElement
  scopeSelectTarget: HTMLSelectElement
  categorySelectTarget: HTMLSelectElement

  scopeToggleOrgActiveSupplierTarget: HTMLInputElement
  subCategorySelectTarget: HTMLSelectElement
  hasSubCategorySelectTarget: boolean

  static targets: string[] = [
    "linkTag",
    "scopeSelect",
    "scopeToggleOrgActiveSupplier",
    "categorySelect",
    "subCategorySelect",
  ]

  static values = {
    basePath: String,
  }
  basePathValue: string

  connect() {
    this.setlinkTagTargetHref()
  }

  setlinkTagTargetHref(): void {
    const dropdownScope = this.scopeSelectTarget.value
    let scopes

    if (this.scopeToggleOrgActiveSupplierTarget.checked) {
      scopes = `${this.scopeToggleOrgActiveSupplierTarget.value}`
    } else {
      scopes = dropdownScope
    }

    let category_id

    if (this.hasSubCategorySelectTarget && this.subCategorySelectTarget.value) {
      category_id = this.subCategorySelectTarget.value
    } else {
      category_id = this.categorySelectTarget.value
    }

    const params = {
      scopes,
      category_id: category_id || "",
      source: "filter",
    }

    this.linkTagTarget.href = buildUrl(this.basePathValue, params)
  }
}
