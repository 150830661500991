import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="turbo-redirect"
export default class extends Controller {
  connect() {
    this.element.addEventListener("turbo:before-fetch-response", this.handleRedirectResponse)
  }

  disconnect() {
    this.element.removeEventListener("turbo:before-fetch-response", this.handleRedirectResponse)
  }

  // See: https://github.com/hotwired/turbo/issues/138#issuecomment-1030772855
  handleRedirectResponse(e) {
    if (typeof e.detail.fetchResponse !== "undefined") {
      let redirectLocation = e.detail.fetchResponse.response.headers.get("turbo_location")
      if (redirectLocation) {
        e.preventDefault()
        Turbo.cache.clear()
        Turbo.visit(redirectLocation)
      }
    }
  }
}
