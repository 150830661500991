import { Controller } from "@hotwired/stimulus"
import { useMutation } from "stimulus-use"
import * as Sentry from "@sentry/browser"

// Connects to data-controller="explo--error-reporter"
export default class extends Controller {
  connect() {
    useMutation(this, { subtree: true, childList: true })
  }

  mutate() {
    // .data-panel-error is when a single tile inside an explo dashboard has an issue
    const errorPanel = this.element.querySelector(".data-panel-error")
    if (errorPanel) {
      console.log("Error panel found")
      Sentry.captureException(new Error("Explo Error Panel Found"))
    }

    // A role=alert pops up when the dashbaord can't load at all.
    // But this is very generic, so it may over-report, we don't know
    const alertPanel = this.element.querySelector("[role='alert']")
    if (alertPanel) {
      console.log("Alert panel found")
      Sentry.captureException(new Error("Explo Alert Panel Found"))
    }
  }
}
