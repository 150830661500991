import React, { useEffect, useLayoutEffect, useRef, useState } from "react"
import ReactFlow, { Background, Controls, useReactFlow, ReactFlowProvider } from "reactflow"
import "reactflow/dist/style.css"

import { generateGraph } from "./graphHelper"
import { cardDimensions, RequestStepCardNode } from "./requestStepCardNode"
import Spinner from "../../spinner/spinner"

const nodeTypes = {
  requestStepCard: RequestStepCardNode,
}

const Flow = (props) => {
  const { height, viewportPosition, width, ...otherProps } = props
  const [viewportParams, setViewportParams] = useState({ x: 0, y: 0, zoom: 1 })
  const reactFlowInstance = useReactFlow()

  useLayoutEffect(() => {
    const params = {
      x: -(viewportPosition.x - width * 0.5 + cardDimensions.width * 0.5),
      y: -(viewportPosition.y - height * 0.5 + cardDimensions.height * 0.5),
      zoom: 1,
    }
    setViewportParams(params)
  }, [height, width])

  useEffect(() => {
    reactFlowInstance.setViewport(viewportParams)
  }, [viewportParams])

  return <ReactFlow {...otherProps} />
}

const LinearWorkflow = ({ tasks, viewportPosition, delayLoad, preventScroll }) => {
  const ref = useRef(null)
  const [isLoading, setIsLoading] = useState(delayLoad)
  const [height, setHeight] = useState(0)
  const [width, setWidth] = useState(0)
  const [preventScrolling] = useState(preventScroll)

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false)
    }, 150)
    return () => clearTimeout(timer)
  }, [])

  useLayoutEffect(() => {
    setHeight(ref.current.offsetHeight)
    setWidth(ref.current.offsetWidth)
  }, [])

  const proOptions = { hideAttribution: true }

  const { nodes, edges } = generateGraph(tasks)

  return isLoading ? (
    <div className="h-full w-full react-flow react-flow-gradient-bg" ref={ref}>
      <Spinner />
    </div>
  ) : (
    <div className={"h-full w-full react-flow-gradient-bg"} ref={ref}>
      <ReactFlowProvider>
        <Flow
          nodes={nodes}
          edges={edges}
          onlyRenderVisibleElements={true}
          nodeTypes={nodeTypes}
          proOptions={proOptions}
          viewportPosition={viewportPosition}
          height={height}
          width={width}
          preventScrolling={preventScrolling}
        >
          <Background color="#aaa" gap={16} />
          <Controls showInteractive={false} />
        </Flow>
      </ReactFlowProvider>
    </div>
  )
}

export default LinearWorkflow
