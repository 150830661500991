import { Controller } from "@hotwired/stimulus"
import { enable, disable } from "../../../javascript/utils"
import { convertDateStringInput } from "../../utils/dates"

// Connects to data-controller="organization--deactivation-modal"

export default class extends Controller {
  static targets = ["dateInput", "deactivationDate", "submit"]

  connect() {
    this.toggleSubmitButton()
  }
  onChange() {
    this.toggleSubmitButton()

    if (this.dateInputTarget.value) {
      enable(this.submitTarget)
      this.deactivationDateTarget.innerText = ` will be deactivated on ${convertDateStringInput(
        this.dateInputTarget.value,
      )}`
    } else {
      this.deactivationDateTarget.innerText = "is not currently scheduled for deactivation"
    }
  }

  toggleSubmitButton() {
    if (this.dateInputTarget.value) {
      enable(this.submitTarget)
    } else {
      disable(this.submitTarget)
    }
  }
}
