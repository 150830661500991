import { Controller } from "@hotwired/stimulus"
import { validate } from "email-validator"

// Connects to data-controller="number-field"
export default class extends Controller {
  static targets = ["input"]

  static values = {
    // Prevents to type in specific keys
    // Example: "ab." -> will prevent to type in the letters "a", "b", and character "."
    filterKeys: {
      default: "",
      type: String,
    },
    // Prevents to type in values that does not match the pattern
    // Example: "^[A-Za-z]+$" -> will only accept letters to be typed in
    pattern: {
      default: "",
      type: String,
    },
  }

  hasFilterKeysValue: boolean
  hasPatternValue: boolean
  filterKeysValue: string
  patternValue: string

  hasInputTarget: boolean
  inputTarget: HTMLInputElement

  filterHandler = this.filter.bind(this)
  validateHandler = this.validate.bind(this)

  connect() {
    if (this.hasInputTarget && this.needsFilter()) {
      this.inputTarget.addEventListener("keydown", this.filterHandler)
    }

    if (this.hasInputTarget && this.needsValidation()) {
      this.inputTarget.addEventListener("input", this.validateHandler)
    }
  }

  disconnect() {
    if (this.hasInputTarget && this.needsFilter()) {
      this.inputTarget.removeEventListener("keyup", this.filterHandler)
    }

    if (this.hasInputTarget && this.needsValidation()) {
      this.inputTarget.removeEventListener("input", this.validateHandler)
    }
  }

  needsFilter() {
    return this.hasFilterKeysValue && !!this.filterKeysValue
  }

  needsValidation() {
    return this.hasPatternValue && !!this.patternValue
  }

  filter(e: KeyboardEvent) {
    if (this.filterKeysValue.includes(e.key)) {
      e.preventDefault()
    }
  }

  validate(e: InputEvent) {
    if (e.data === ".") {
      return
    }

    const regex = new RegExp(this.patternValue)
    const target = e.target as HTMLInputElement
    const value = target.value as string

    if (regex.test(value)) {
      if (target.value != value) {
        target.value = value
      }
    } else {
      target.value = value.slice(0, -1)
    }
  }
}
