import React from "react"

const Spinner = () => {
  return (
    <div className="absolute inset-0 flex justify-center items-center z-normal bg-white/80">
      <div
        style={{ borderTopColor: "transparent" }}
        className="w-16 h-16 border-4 border-purple-200 border-solid rounded-full animate-spin"
      ></div>
    </div>
  )
}

export default Spinner
