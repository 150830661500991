import BarChartController from "../bar_chart_controller"

// Connects to data-controller="analytics--provisioned-users-chart"
export default class extends BarChartController {
  static values = {
    labels: String,
    datasets: String,
    yAxisTitle: String,
    yAxisCurrency: String,
  }
  yAxisCurrencyValue: String
  tootlipOptions() {
    return {
      enabled: false,
      padding: 12,
      yAlign: "bottom",
      external: (context) => {
        const currency = this.yAxisCurrencyValue

        // Tooltip Element
        let tooltipEl = document.getElementById("chartjs-tooltip")

        // Create element on first render
        if (!tooltipEl) {
          tooltipEl = document.createElement("div")
          tooltipEl.id = "chartjs-tooltip"
          tooltipEl.innerHTML = "<table></table>"
          tooltipEl.className = "body-text text-white"
          document.body.appendChild(tooltipEl)
        }

        // Hide if no tooltip
        const tooltipModel = context.tooltip
        if (tooltipModel.opacity === 0) {
          tooltipEl.style.opacity = 0
          return
        }

        // Set caret Position
        tooltipEl.classList.remove("above", "below", "no-transform")
        if (tooltipModel.yAlign) {
          tooltipEl.classList.add(tooltipModel.yAlign)
        } else {
          tooltipEl.classList.add("no-transform")
        }

        // Set Text
        if (tooltipModel.body) {
          const bodyHTML = tooltipModel.dataPoints
            .map((el) => {
              let value = el.formattedValue
              let logoUrl = el.raw.logo
              let logoImage = '<i class="fa-regular fa-building"></i>'
              if (logoUrl) {
                logoImage = `<img src="${logoUrl}" class="inline-block align-super rounded object-fill text-[0px]" alt="Supplier Logo" />`
              }
              return `<span class="font-semibold inline-flex justify-center items-center">
                                        <div class="w-6 h-6 justify-center items-center mr-1">
                                            <div class="flex justify-center items-center bg-gray-200 rounded w-full h-full">
                                              ${logoImage}
                                            </div>
                                        </div>
                                        ${el.label}
                                    </span>
                                    <span>Provisioned Users: <b>${value}</b></span>`
            })
            .join("</br>")

          tooltipEl.innerHTML = `
                <style>
                 .tooltip-content {
                    min-width: 220px;
                    background-color: black;
                    text-align: left;
                    position: absolute;
                    z-index: 1;
                    bottom: 160%;
                    left: 50%;
                    margin-bottom: 20px;
                    margin-left: -110px;
                    background-color: rgba(45,55,72, 0.9);
                    display:flex;
                    flex-direction:column;
                    align-items: flex-start;
                    padding: 10px 16px;
                    gap: 5px;
                    border-radius: 6px;
                    font-style: normal;
                    font-size: 14px;
                    line-height: 20px;
                    letter-spacing: 0.25px;
                    color: #FFFFFF;
                  }
                 .tooltip-content:after {
                  content: "";
                  position: absolute;
                  top: 100%;
                  left: 50%;
                  margin-left: -5px;
                  border-width: 5px;
                  border-style: solid;
                  border-color: rgba(45,55,72, 0.9) transparent transparent transparent;
                 }
                </style>
                <div class="tooltip-content">
                    ${bodyHTML}
                </div>
              `
        }

        const position = context.chart.canvas.getBoundingClientRect()

        // Display, position, and set styles for font
        tooltipEl.style.opacity = 1
        tooltipEl.style.position = "absolute"
        tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + "px"
        tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + "px"
        tooltipEl.style.padding = tooltipModel.padding + "px " + tooltipModel.padding + "px"
        tooltipEl.style.pointerEvents = "none"
      },
    }
  }
}
