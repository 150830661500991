export interface TrackingEventPayload {
  [key: string]: any
}

const sendEvent = (eventName: string, payload: TrackingEventPayload = {}): Promise<any> => {
  const csrfToken = (document.querySelector("[name='csrf-token']") as HTMLMetaElement | null)?.content

  if (!csrfToken) {
    return Promise.resolve()
  }

  return fetch("/track", {
    method: "POST",
    headers: {
      "X-CSRF-Token": csrfToken,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ event: eventName, ...(payload || {}) }),
    keepalive: true,
  })
}

const createEvent = (
  eventName: string,
  payload: TrackingEventPayload = {},
  originElement: HTMLElement = null,
  listener: string = null,
  loggingEnabled = false,
) => {
  if (!eventName) {
    throw Error('Could not track event. "eventName" is required.')
  }

  if (loggingEnabled) {
    console.group("Tracking event", eventName)
    console.log("Event Name:", eventName)
    console.log("Event Payload:", payload)
    if (originElement) {
      console.log("Element:", originElement)
    }
    if (listener) {
      console.log("Listener:", listener)
    }
    console.groupEnd()
  }

  sendEvent(eventName, payload).then((response) => {
    // We don't really need to wait or do anything with the response
    if (loggingEnabled) {
      console.log("Tracking Response:", response)
    }
  })
}

/*
 Allows to tack events on the frontend

 Enable Logging anywhere:
    Tracking.loggingEnabled = true

 Track event:
    Tracking.createEvent("my_custom_event", {hello: "world"})
 */

export const Tracking = {
  loggingEnabled: false,
  createEvent: (
    eventName: string,
    payload: TrackingEventPayload = {},
    originElement: HTMLElement = null,
    listener: string = null,
  ) => createEvent(eventName, payload, originElement, listener, Tracking.loggingEnabled),
}
