import { Controller } from "@hotwired/stimulus"
import { show, hide } from "../utils/index"

// Connects to data-controller="populate-input-values"
export default class extends Controller {
  static targets = ["getValueInput", "setValueToInput", "clickElement"]
  getValueInputTarget: HTMLInputElement
  setValueToInputTarget: HTMLInputElement
  clickElementTarget: HTMLDivElement

  connect() {
    this.showHideClickElement()
  }

  toggle(e): void {
    const value = e.target.value
    this.showHideClickElement(value)
  }

  showHideClickElement(value?: string): void {
    if (value) {
      show(this.clickElementTarget)
    } else {
      hide(this.clickElementTarget)
    }
  }

  setValueToInput(): void {
    if (this.getValueInputTarget.value) {
      this.setValueToInputTarget.value = this.getValueInputTarget.value
    }
  }
}
