import Dagre from "@dagrejs/dagre"

const DAGRE_SETTINGS = {
  rankdir: "LR",
  align: "UL",
}
const POSITION_MULTIPLIER = 7

const position = ({ x, y }) => ({ x: x * POSITION_MULTIPLIER, y: y * POSITION_MULTIPLIER })

const requestStepNode = (task) => {
  const { data } = task

  return {
    id: task.id,
    sourcePosition: "right",
    targetPosition: "left",
    data,
    position: position(task),
    zIndex: 2,
    draggable: false,
    connectable: false,
    selectable: false,
    type: "requestStepCard",
  }
}

const generateEdges = (tasks) => {
  return tasks.reduce((edges, task, index) => {
    const lastTaks = tasks[tasks.length - 1]

    if (task.id === lastTaks.id) {
      return edges
    }

    const source = task.id
    const target = tasks[index + 1].id

    const edge = {
      id: `${source}-${target}`,
      source,
      target,
      type: "step",
      pathOptions: {
        offset: 0,
        borderRadius: 20,
      },
      style: {
        stroke: "#6A778A",
        strokeWidth: 2,
      },
      markerEnd: {
        type: "arrowclosed",
        color: "#6A778A",
      },
    }

    return [edge, ...edges]
  }, [])
}

export const generateGraph = (tasks) => {
  const g = new Dagre.graphlib.Graph()
  g.setGraph(DAGRE_SETTINGS).setDefaultEdgeLabel(() => ({}))

  tasks.forEach((task) => g.setNode(task.id, task))

  const edges = generateEdges(tasks)

  edges.forEach((edge) => g.setEdge(edge.source, edge.target))

  Dagre.layout(g)

  const nodes = tasks.map((task) => requestStepNode(g.node(task.id)))

  return { nodes, edges }
}
