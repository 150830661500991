import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="organization-preferences--marketing-materials"
export default class extends Controller {
  static targets = ["hideable", "option", "logoPermission"]
  hideableTargets: any
  logoTarget: HTMLInputElement
  optionTargets: HTMLInputElement[]

  requiredInputsMap: { [key: string]: Boolean } = {}
  hiddenCssClass = "hidden"

  logoTargetConnected(t) {
    if (t.value === "0" && t.checked) {
      this.hideElements()
    }
  }

  onRadioButtonClick(e): void {
    const value = e.target.value

    if (value === "0") {
      this.hideElements()
    } else {
      this.showElements()
    }
  }

  connect(): void {
    this.buildrequiredInputsMap()
  }

  buildrequiredInputsMap(): void {
    this.optionTargets.forEach((optionTarget) => {
      const isRequired = optionTarget.required
      if (isRequired) {
        this.requiredInputsMap[`${optionTarget.id}`] = true
      }
    })
  }

  hideElements(): void {
    this.hideableTargets.forEach((el) => {
      el.classList.add(this.hiddenCssClass)
    })

    this.optionTargets.forEach((optionTarget) => {
      // remove any values already selected.
      optionTarget.checked = false

      const isRequired = this.requiredInputsMap[optionTarget.id]
      if (isRequired) {
        optionTarget.required = false
      }
    })
  }

  showElements(): void {
    this.hideableTargets.forEach((el) => {
      el.classList.remove(this.hiddenCssClass)
    })

    this.optionTargets.forEach((optionTarget) => {
      const isRequired = this.requiredInputsMap[optionTarget.id]
      if (isRequired) {
        optionTarget.required = true
      }
    })
  }
}
