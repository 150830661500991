import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="analytics--usage-drawer"
export default class extends Controller {
  switchUrlTarget: HTMLLinkElement

  static targets = ["switchUrl"]
  switchDrawer() {
    this.switchUrlTarget.click()
  }
}
