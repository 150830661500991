import { Controller } from "@hotwired/stimulus"
import { hide, show } from "../utils"

// Connects to data-controller="main-multi-collapse"
export default class extends Controller {
  static values = {
    allOpenInitial: Boolean,
    alwaysHideButtons: Boolean,
  }
  allOpenInitialValue: boolean
  alwaysHideButtonsValue: boolean

  static targets = ["groupItem", "openButton", "closeButton", "subMultiCollapse"]
  groupItemTargets: [HTMLDivElement]
  openButtonTarget: HTMLDivElement
  closeButtonTarget: HTMLDivElement
  subMultiCollapseTargets: [HTMLDivElement]

  connect() {
    if (this.alwaysHideButtonsValue) {
      this.hideButtons()
    } else if (this.allOpenInitialValue) {
      this.switchButtons(false)
    }
  }

  openAllSections(event) {
    this.callOnAllSections(event, "open")
  }

  closeAllSections(event) {
    this.callOnAllSections(event, "close")
  }

  checkGroupItemStatus() {
    const itemOpenStatuses = new Set()
    this.groupItemTargets.forEach((groupItem) => {
      const collapseController = this.application.getControllerForElementAndIdentifier(groupItem, "collapse")
      itemOpenStatuses.add(collapseController.isOpenStatus())
    })
    if (itemOpenStatuses.size == 1) {
      const allOpen = itemOpenStatuses.values().next().value
      this.switchButtons(!allOpen)
    }
  }

  callOnAllSections(event, functionName) {
    if (this.subMultiCollapseTargets.length > 0) {
      this.subMultiCollapseTargets.forEach((subMultiCollapse) => {
        const subMultiCollapseController = this.application.getControllerForElementAndIdentifier(
          subMultiCollapse,
          "sub-multi-collapse",
        )
        subMultiCollapseController[`${functionName}AllSections`](event, true)
      })
    } else {
      this.groupItemTargets.forEach((groupItem) => {
        const collapseController = this.application.getControllerForElementAndIdentifier(groupItem, "collapse")
        collapseController[functionName]()
      })
    }

    if (functionName == "open") {
      this.switchButtons(false)
    } else {
      this.switchButtons(true)
    }
  }

  switchButtons(showOpenButton) {
    if (!this.alwaysHideButtonsValue) {
      if (showOpenButton) {
        hide(this.closeButtonTarget)
        show(this.openButtonTarget)
      } else {
        show(this.closeButtonTarget)
        hide(this.openButtonTarget)
      }
    }
  }

  hideButtons() {
    hide(this.closeButtonTarget)
    hide(this.openButtonTarget)
  }
}
