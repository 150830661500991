// /app/javascript/controllers/showhide_controller.js
import { Controller } from "@hotwired/stimulus"
import { show, hide, setRequired, removeRequired } from "../../utils"

// Connects to data-controller="settings--role"
export default class extends Controller {
  static targets = ["allAccess", "departmentWrapper", "departmentField", "key"]

  static values = {
    index: String,
    organizationRoles: Array<String>,
    departmentRoles: Array<String>,
  }

  allAccessTarget: HTMLSpanElement
  departmentWrapperTarget: HTMLElement
  departmentFieldTarget: HTMLSelectElement
  keyTarget: HTMLSelectElement
  hasKeyTarget: boolean
  organizationRolesValue: Array<string>
  departmentRolesValue: Array<string>

  connect() {
    if (this.hasKeyTarget) {
      this.toggleDepartmentFields()
    }
  }

  toggleDepartmentFields() {
    if (this.departmentRolesValue.includes(this.keyTarget.value)) {
      show(this.departmentWrapperTarget)
      setRequired(this.departmentFieldTarget)
      hide(this.allAccessTarget)
    } else if (this.organizationRolesValue.includes(this.keyTarget.value)) {
      hide(this.departmentWrapperTarget)
      removeRequired(this.departmentFieldTarget)
      show(this.allAccessTarget)
    }
  }
}
