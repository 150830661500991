import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="price-checks-v2--line-item--button"
export default class extends Controller {
  static values = {
    formId: String,
    buttonId: String,
  }
  formIdValue: string
  buttonIdValue: string

  connect() {}

  createPriceCheckLineItem() {
    const form = document.getElementById(this.formIdValue) as HTMLFormElement
    form.setAttribute("data-turbo_method", "POST")
    const button = document.getElementById(this.buttonIdValue) as HTMLButtonElement
    button.click()
  }
}
