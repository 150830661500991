import { Controller } from "@hotwired/stimulus"
import { TargetObserver } from "@hotwired/stimulus/dist/types/core/target_observer"
import { disableTooltip, enableTooltip } from "../../utils"

// Connects to data-controller="organization-preferences--other-preferences"

const checkedRadioButtons = {}

export default class extends Controller {
  static targets = ["radio"]
  radioTargets: any

  connect() {
    this.radioTargets.map((targetEl) => {
      disableTooltip(targetEl)
    })

    this.radioTargets.map((targetEl) => {
      const { checked, id, value } = targetEl
      if (checked) {
        const currentColumnTargets = this.filterTargets(id, value)
        this.disableTargets(currentColumnTargets)
        // remove the value number from the id.
        this.updateCheckedRadioButtons(id.slice(0, -2), value)
      }
    })
  }

  radioClick({ target: eventTarget }): void {
    const currentColumnTargets = this.filterTargets(eventTarget.id, eventTarget.value)

    const eventTargetID = eventTarget.id.slice(0, -2)

    if (checkedRadioButtons[eventTargetID]) {
      if (checkedRadioButtons[eventTargetID] === eventTarget.value) {
        this.uncheckTarget(eventTarget)
        this.enableTargets(currentColumnTargets)
        this.updateCheckedRadioButtons(eventTargetID, null)
      } else {
        const previousTargetID = `${eventTargetID}_${checkedRadioButtons[eventTargetID]}`
        const previousTargetValue = checkedRadioButtons[eventTargetID]
        const previousColumnTargets = this.filterTargets(previousTargetID, previousTargetValue)

        this.enableTargets(previousColumnTargets)
        this.disableTargets(currentColumnTargets)

        this.updateCheckedRadioButtons(eventTargetID, eventTarget.value)
      }
    } else {
      this.disableTargets(currentColumnTargets)
      this.updateCheckedRadioButtons(eventTargetID, eventTarget.value)
    }
  }

  filterTargets(eventID, eventValue) {
    return this.radioTargets.filter(
      (target) => target.value && target.value.includes(eventValue) && target.id !== eventID,
    )
  }

  uncheckTarget(targetEl) {
    targetEl.checked = false
  }

  disableTargets(targetElements) {
    targetElements.forEach((targetEl) => {
      if (targetEl.type === "radio") {
        targetEl.checked = false
        targetEl.disabled = true

        targetEl.classList.add("disabled")
        targetEl.style.cursor = "not-allowed"
        enableTooltip(targetEl)
      } else {
        // parent element
        targetEl.classList.add("disabled")
      }
    })
  }

  enableTargets(targetElements) {
    targetElements.forEach((targetEl) => {
      if (targetEl.type === "radio") {
        targetEl.disabled = false
        targetEl.style.cursor = ""
        targetEl.classList.remove("disabled")
        disableTooltip(targetEl)
      }
    })
  }

  updateCheckedRadioButtons(id, value) {
    checkedRadioButtons[id] = value
  }
}
