import { Controller } from "@hotwired/stimulus"
import { Chart } from "chart.js"

// Connects to data-controller="chart"
export default class ChartController extends Controller {
  predefinedColors = [
    "#6B63E8",
    "#E27D58",
    "#38A169",
    "#3182CE",
    "#6A778A",
    "#E263C5",
    "#D69E2E",
    "#C53030",
    "#2A4365",
    "#A84090",
  ]
  static values = {
    type: String,
    datasets: String,
  }
  typeValue: string
  datasetsValue: string
  chart: Chart

  connect() {
    const datasets = JSON.parse(this.datasetsValue)
    const config = this.initializeConfig(datasets)
    if (datasets) {
      this.chart = new Chart(this.element, config)
    }
  }

  getColor(colorPalette) {
    return colorPalette.length > 0 ? colorPalette.shift() : this.randomColor()
  }

  randomColor() {
    let min = 26
    let max = 235
    let r = Math.floor(Math.random() * (max - min) + min)
    let g = Math.floor(Math.random() * (max - min) + min)
    let b = Math.floor(Math.random() * (max - min) + min)
    return "rgb(" + r + "," + g + "," + b + ")"
  }

  datasetOptions() {
    return {}
  }

  chartOptions() {
    return {}
  }

  chartPlugins() {
    return []
  }

  chartData(datasets) {
    let colorPalette = [...this.predefinedColors]
    return {
      datasets: datasets.map((dataset) => {
        let color = this.getColor(colorPalette)
        return {
          ...dataset,
          ...this.datasetOptions(),
          pointBorderColor: color,
          borderColor: color,
          backgroundColor: color,
        }
      }),
    }
  }

  initializeConfig(datasets) {
    return {
      type: this.typeValue,
      data: this.chartData(datasets),
      options: this.chartOptions(),
      plugins: this.chartPlugins(),
    }
  }
}
