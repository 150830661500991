import { Controller } from "@hotwired/stimulus"
import { setSearchParams } from "../utils/urls"

// Connects to data-controller="admin-request-contract"
export default class extends Controller {
  static values = {
    contractId: String,
  }
  contractIdValue: string

  connect(): void {
    this.setContractIdParam()
  }

  disconnect(): void {
    this.deleteContractIdParam()
  }

  setContractIdParam(): void {
    const urlParams = new URLSearchParams(window.location.search)
    urlParams.set("contract_id", this.contractIdValue)
    setSearchParams(urlParams)
  }

  deleteContractIdParam(): void {
    const urlParams = new URLSearchParams(window.location.search)
    urlParams.delete("contract_id")
    setSearchParams(urlParams)
  }
}
