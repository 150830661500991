import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="turbo-visit"
export default class extends Controller {
  static values = { url: String }

  urlValue: string

  initialize() {
    this.visitURL()
  }

  visitURL() {
    Turbo.visit(this.urlValue)
  }
}
