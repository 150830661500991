import { Controller } from "@hotwired/stimulus"
import { get, put } from "@rails/request.js"
import { Turbo } from "@hotwired/turbo-rails"

// Connects to data-controller="connections--drawer"
export default class extends Controller {
  updateConnectionPathValue: string
  supplierSlugValue: string
  connectionActive: boolean
  static values = {
    updateConnectionPath: String,
    supplierSlug: String,
    connectionActive: Boolean,
  }
  boundMessageEvent: any

  initialize(): void {
    this.boundMessageEvent = this.messageHandler.bind(this)
  }

  connect() {
    window.addEventListener("message", this.boundMessageEvent)
  }

  disconnect() {
    window.removeEventListener("message", this.boundMessageEvent)
  }

  messageHandler(event) {
    this.updateConnection(event, this.updateConnectionPathValue)
  }

  async updateConnection({ data }, updatePath) {
    if (typeof data === "string") {
      const parsedData = JSON.parse(data)
      if (
        parsedData?.type === "connectionStatusChange" &&
        parsedData?.payload?.provider?.includes(this.supplierSlugValue) &&
        parsedData.payload.connected !== this.connectionActive
      ) {
        const promise = await put(updatePath, {
          body: { connected: parsedData.payload.connected },
          contentType: "application/json",
        })
        if (promise.ok && promise.response.redirected) {
          Turbo.visit(`${promise.response.url}&redirected=true`)
        }
      }
    }
  }
}
