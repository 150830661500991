import { Controller } from "@hotwired/stimulus"
import { hide } from "../../utils"

// Connects to data-controller="request-form--file-field"
export default class extends Controller {
  static values = {
    key: String,
    required: Boolean,
  }

  keyValue: String
  requiredValue: Boolean

  connect() {
    this.validationInput = this.appendValidationInput()
  }

  onEnableDocUpload() {
    this.validationInput.value = "true"
  }

  onDisableDocUpload() {
    this.validationInput.value = null
  }

  appendValidationInput() {
    const input = document.createElement("input")
    input.setAttribute("id", `file_field_${this.keyValue}_validation`)
    input.setAttribute("type", "text")
    if (this.requiredValue) {
      input.setAttribute("required", "")
    }
    hide(input)
    this.element.appendChild(input)
    return input
  }
}
