import { Controller } from "@hotwired/stimulus"
import { buttonAsNotSubmitting, buttonAsSubmitting, hide, show } from "../utils"
import { get } from "@rails/request.js"

// Connects to data-controller="coupa-easy-form"
export default class extends Controller {
  static targets = ["loader", "form", "submitButton", "attachment", "conditionallyRenderedWidget"]
  static values = {
    getOptionsUrl: String,
  }

  getOptionsUrlValue: string
  loaderTarget: HTMLElement
  formTarget: HTMLFormElement
  submitButtonTarget: HTMLButtonElement
  attachmentTargets?: HTMLElement[]
  conditionallyRenderedWidgetTargets?: HTMLElement[]

  connect() {
    this.hideWidgets(this.conditionallyRenderedWidgetTargets)
    document.addEventListener("newItemAdded", this.hideConditionallyRenderedWidgets.bind(this))
    this.formTarget.addEventListener("turbo:submit-end", this.handleSubmitEnd.bind(this))
  }

  hideConditionallyRenderedWidgets(e) {
    const newItem = e.detail.newItem
    if (!newItem) return

    const widgets = newItem.querySelectorAll('[data-coupa-easy-form-target="conditionallyRenderedWidget"]')
    this.hideWidgets(widgets)
  }

  hideWidgets(widgets) {
    widgets.forEach((widget) => {
      hide(widget.closest(".input-field-wrapper"))
    })
  }

  async applyConditionalRendering(e) {
    const fieldName = e.target.getAttribute("data-field-name")
    let inFlightRequest = 0

    const childFormGroup = e.target.closest('[data-add-and-remove-item-target="item"]')
    const widgets = childFormGroup
      ? childFormGroup.querySelectorAll('[data-coupa-easy-form-target="conditionallyRenderedWidget"]')
      : this.conditionallyRenderedWidgetTargets

    for (const widget of widgets) {
      const renderCondition = JSON.parse(widget.dataset.renderCondition)
      const { widgetId } = widget.dataset
      const { render_widget_field_names } = renderCondition
      if (!render_widget_field_names.includes(fieldName)) continue

      let renderConditionHaslink = !!renderCondition?.link_url
      if (this.shouldRenderDependentWidget(renderCondition, e.target.value, fieldName)) {
        if (renderConditionHaslink) {
          widget.tomselect.clear()
          inFlightRequest++
          this.showLoader()
          await this.fetchAndLoadWidgetOptions(widget, render_widget_field_names, widgetId)
          inFlightRequest--
        } else {
          this.showWidget(widget)
        }
      } else {
        this.hideWidget(widget, renderConditionHaslink)
      }
    }

    if (!inFlightRequest) {
      this.hideLoader()
    }
  }

  async fetchAndLoadWidgetOptions(widget, render_widget_field_names, widgetId) {
    const paramObj = render_widget_field_names.reduce((acc, name) => {
      const ele = document.querySelector(`[data-field-name="${name}"]`)
      acc[name] = ele.value
      return acc
    }, {})

    const pathArgParams = Object.entries(paramObj)
      .map(
        ([key, value]: [string, string | number]) =>
          `path_args[${encodeURIComponent(key)}]=${encodeURIComponent(value)}`,
      )
      .join("&")

    const res = await get(`${this.getOptionsUrlValue}?widget_id=${encodeURIComponent(widgetId)}&${pathArgParams}`, {
      responseKind: "json",
    })
    if (res.ok) {
      const data = await res.json
      widget.tomselect.clearOptions()
      widget.tomselect.addOptions(data)
      this.showWidget(widget)
    } else {
      this.insertErrorElement(widget)
    }
  }

  showWidget(widget) {
    show(widget.closest(".input-field-wrapper"))
  }

  hideWidget(widget, renderConditionHaslink) {
    if (renderConditionHaslink) {
      widget.tomselect.clear()
    } else {
      widget.value = ""
    }
    hide(widget.closest(".input-field-wrapper"))
  }

  insertErrorElement(widget) {
    const errorElement = document.createElement("div")
    errorElement.className = "widget-render-error"
    errorElement.innerHTML = `<p class='mb-4 rounded-md text-sm text-yellow-700 bg-yellow-100 p-3 mt-3'>${widget.dataset.fieldName}: Something went wrong when attempting to render this form field</p>`
    widget.closest(".input-field-wrapper").insertAdjacentElement("afterend", errorElement)
  }
  shouldRenderDependentWidget(renderCondition, value, id) {
    if (!value.length) return false

    const { render_widget_field_names, render_widget_for_value } = renderCondition
    if (render_widget_field_names.length === 1) {
      return render_widget_for_value === "" || render_widget_for_value == value
    } else {
      return render_widget_field_names
        .filter((name) => name !== id)
        .every((name) => {
          const ele = document.querySelector(`[data-field-name="${name}"]`)
          return ele.value.length !== 0
        })
    }
  }

  showLoader() {
    show(this.loaderTarget)
  }

  hideLoader() {
    hide(this.loaderTarget)
  }

  showTaskModal() {
    show(document.querySelector("#modal-container"))
  }

  reenableForm() {
    this.hideLoader()
    buttonAsNotSubmitting(this.submitButtonTarget, "Submit")
  }

  submitForm(e) {
    e.preventDefault()
    buttonAsSubmitting(this.submitButtonTarget)

    if (this.checkValidity()) {
      this.showLoader()
      this.formTarget.requestSubmit()
    } else {
      buttonAsNotSubmitting(this.submitButtonTarget, "Submit")
    }
  }

  handleSubmitEnd(event) {
    if (!event.detail.success) {
      this.scrollToTop()
    }
  }

  scrollToTop() {
    window.scrollTo(0, 0)
  }

  checkValidity() {
    let valid = true
    const formFields = Array.from(this.formTarget.elements)

    for (const field of formFields) {
      if (field.hasAttribute("required")) {
        if (!field.checkValidity()) {
          valid = false
        }
      }
    }

    // make sure all required attachment fields have a file uploaded
    const scrollToAttachmentError = valid
    let firstAttachmentWithError

    this.attachmentTargets?.forEach((attachment) => {
      const requiredSpan = attachment.querySelector("span.required:not(.hidden)")
      const file = attachment.querySelector("[name = 'file_name[]']") || attachment.querySelector(".attachment-wrapper")
      const errorElement = attachment.querySelector(".input-error")
      hide(errorElement)

      if (requiredSpan && !file) {
        show(errorElement)
        firstAttachmentWithError ??= attachment
        valid = false
      }
    })

    // if there are no other errors, scroll to the the attachment so the user can see the error message.
    if (scrollToAttachmentError && firstAttachmentWithError) {
      firstAttachmentWithError.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      })
    }

    return valid
  }
}
