import { post } from "@rails/request.js"
import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="clipboard"
export default class extends Controller {
  static targets = ["source", "button", "buttonWrapper"]
  static values = { alertNotice: String }

  alertNoticeValue: String
  sourceTarget: HTMLInputElement
  buttonTarget: HTMLLinkElement
  buttonWrapperTarget: HTMLInputElement
  hasButtonTarget: boolean
  hasButtonWrapperTarget: boolean
  hasSourceTarget: boolean

  sourceTargetConnected() {
    if (this.sourceTarget.id) {
      this.connectMutationObserver()
    }

    if (!this.sourceTarget.value) {
      this.disableButton()
    }
  }

  triggerOnChange() {
    if (this.sourceTarget.value) {
      this.enableButton()
    } else {
      this.disableButton()
    }
  }

  connectMutationObserver() {
    /*
      This observer will listen on any attribute changes.
      The only reason for the data attribute (data-clipboard-input-value) that gets changed here is to
      mirror the current value of the input when it gets change by another controller.
      This way we are able to enable/disable the button if the current value is empty.
    */
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === "attributes") {
          this.triggerOnChange()
        }
      })
    })

    observer.observe(this.sourceTarget, {
      attributes: true, //configure it to listen to attribute changes
    })
  }

  disableButton() {
    if (this.hasButtonTarget) {
      this.buttonTarget.classList.add("pointer-events-none")
    }
    if (this.hasButtonWrapperTarget) {
      this.buttonWrapperTarget.classList.add("disabled")
    }
  }

  enableButton() {
    if (this.hasButtonTarget) {
      this.buttonTarget.classList.remove("pointer-events-none")
    }
    if (this.hasButtonWrapperTarget) {
      this.buttonWrapperTarget.classList.remove("disabled")
    }
  }

  copy(): void {
    if (this.hasSourceTarget) {
      navigator.clipboard.writeText(this.sourceTargetText())
      if (this.alertNoticeValue) {
        post("/flash", { body: { notice: this.alertNoticeValue }, responseKind: "turbo-stream" })
      }
    }
  }

  sourceTargetText() {
    return (this.sourceTarget.value || this.sourceTarget.innerText).trim()
  }
}
