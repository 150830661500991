import { Controller } from "@hotwired/stimulus"

const SCROLL_UP = "transform-none"
const SCROLL_DOWN = "-translate-y-full"

// Connects to data-controller="sticky-header"
export default class extends Controller {
  headerTarget: HTMLDivElement

  static targets: string[] = ["header"]

  lastScroll = 0

  initialize(): void {
    this.scrollListenerCallback = this.scrollListenerCallback.bind(this)
  }

  connect(): void {
    document.addEventListener("scroll", this.scrollListenerCallback)
  }

  disconnect(): void {
    document.removeEventListener("scroll", this.scrollListenerCallback)
  }

  scrollListenerCallback(): void {
    const header = this.headerTarget
    const currentScroll = window.scrollY

    if (currentScroll <= 0) {
      this.showHeader()
      return
    }

    const scrollDownClassIsActive = header.classList.contains(SCROLL_DOWN)
    const scrollYPostionIsPastHeaderHeight = currentScroll > header.getBoundingClientRect().height

    const isScrollingDown =
      currentScroll > this.lastScroll && scrollYPostionIsPastHeaderHeight && !scrollDownClassIsActive
    const isScrollingUp = currentScroll < this.lastScroll && scrollDownClassIsActive

    if (isScrollingDown) {
      this.hideHeader()
      this.hideDropdownMenu()
    } else if (isScrollingUp) {
      this.showHeader()
    }

    this.lastScroll = currentScroll
  }

  hideHeader(): void {
    this.headerTarget.classList.remove(SCROLL_UP)
    this.headerTarget.classList.add(SCROLL_DOWN)
  }

  showHeader(): void {
    this.headerTarget.classList.add(SCROLL_UP)
    this.headerTarget.classList.remove(SCROLL_DOWN)
  }

  hideDropdownMenu(): void {
    document.body.click
  }
}
