import { Controller } from "@hotwired/stimulus"
import { Tracking } from "../utils/tracking"

// Connects to data-controller="supplier-intelligence-tracking"
// NOTE This makes use of utils/tracking.ts
export default class extends Controller {
  static values = {
    supplierName: String,
  }
  supplierNameValue: string

  trackInteractedWithLever(event): void {
    Tracking.createEvent(
      "interacted_with_lever",
      {
        entry_point: "Intelligence",
        supplier_name: this.supplierNameValue,
        lever_name: event.target.id,
        lever_state:
          event.target.closest("[data-controller='collapse']").attributes["data-collapse-is-open-value"].value ===
          "true"
            ? "open"
            : "closed",
      },
      event.target,
    )
  }

  trackClickedLink(event): void {
    const linkElement = event.target.closest("a")
    Tracking.createEvent(
      "clicked_link",
      {
        supplier_name: this.supplierNameValue,
        title: linkElement.innerText,
        href: linkElement.href,
      },
      event.target,
    )
  }
}
