// Connects to data-controller="hide-card"
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["cardSection"]

  initialize() {
    this.hideCardSectionIfNeeded()
  }

  hide(e: MouseEvent) {
    e.preventDefault()

    localStorage.setItem("hideCardSection", "true")
    this.hideCardSectionIfNeeded()
  }

  private hideCardSectionIfNeeded() {
    const hideCardSection = localStorage.getItem("hideCardSection")

    if (hideCardSection && this.cardSectionTarget) {
      this.cardSectionTarget.style.display = "none"
    }
  }
}
