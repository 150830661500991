import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="click-box"
export default class extends Controller {
  static targets = ["link"]

  linkTarget: HTMLAnchorElement
  hasLinkTarget: boolean

  primaryLink: HTMLAnchorElement | null = null

  connect() {
    if (this.hasLinkTarget) {
      this.primaryLink = this.linkTarget
    } else {
      this.primaryLink = this.element.getElementsByTagName("a")[0]
    }
  }

  click(event) {
    if (this.allowNormalPropagation(event)) return

    // Re-dispatch the event to the primary link to preserve extra held keys (e.g. cmd+click)
    const newEvent = new MouseEvent("click", event)
    newEvent["redispatched"] = true

    this.primaryLink?.dispatchEvent(newEvent)
  }

  allowNormalPropagation(event) {
    return event["redispatched"] || this.clickedPrimaryLink(event)
  }

  clickedPrimaryLink(event) {
    let target = event.target
    while (target && !target.isSameNode(this.element)) {
      if (target.isSameNode(this.primaryLink)) {
        return true
      }
      target = target.parentNode
    }
    return false
  }
}
