import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"
import { hide, show } from "../../utils"

// Connects to data-controller="request-form-editor--field-editor"
export default class extends Controller {
  static targets = ["formContainer", "preview", "card"]
  cardTarget: HTMLDivElement
  previewTarget: HTMLDivElement
  formContainerTarget: HTMLDivElement

  static values = {
    editFormUrl: String,
    isEditable: Boolean,
  }
  editFormUrlValue: string
  isEditableValue: boolean

  editStateCardClasses = ["border-l-8", "border-l-purple-500"]

  showInlineEditForm(): void {
    const shouldShowEditForm = this.isEditableValue && this.formContainerTarget?.classList.contains("hidden")

    if (shouldShowEditForm) {
      show(this.formContainerTarget)
      get(this.editFormUrlValue, { responseKind: "turbo-stream" })
      hide(this.previewTarget)
      this.toggleCardEditStateStyling()
    }
  }

  hideInlineEditForm(): void {
    const shouldHideEditForm = !this.formContainerTarget?.classList.contains("hidden")

    if (shouldHideEditForm) {
      hide(this.formContainerTarget)
      show(this.previewTarget)
      this.toggleCardEditStateStyling()
    }
  }

  toggleCardEditStateStyling(): void {
    const shouldRemoveEditStyling = this.cardTarget?.classList.contains("border-l-8")

    if (shouldRemoveEditStyling) {
      this.cardTarget?.classList.remove(...this.editStateCardClasses)
    } else {
      this.cardTarget?.classList.add(...this.editStateCardClasses)
    }
  }
}
