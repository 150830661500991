import { Controller } from "@hotwired/stimulus"
import { hide, isShown, removeRequired, setRequired, show } from "../../utils"

// Connects to data-controller="workflows--task-assignment-type"
export default class extends Controller {
  static targets = [
    "assigneeIdField",
    "assignmentTypeSelect",
    "ceManagerAssigneeIdSelect",
    "ceManagerUserSelectContainer",
    "multipleAssigneeSelectedWarning",
    "supplierPortalUserAssigneeIdSelect",
    "supplierPortalUserIdSelectContainer",
    "teammateAssigneeIdSelect",
    "teammateUserSelectContainer",
    "tropicStaffAssigneeIdSelect",
    "tropicStaffUserSelectContainer",
    "userSelectContainer",
  ]
  assigneeIdFieldTarget: HTMLInputElement
  assignmentTypeSelectTarget: HTMLSelectElement
  ceManagerAssigneeIdSelectTarget: HTMLInputElement
  ceManagerUserSelectContainerTarget: HTMLDivElement
  multipleAssigneeSelectedWarningTarget: HTMLDivElement
  supplierPortalUserAssigneeIdSelectTarget: HTMLInputElement
  supplierPortalUserIdSelectContainerTarget: HTMLDivElement
  teammateAssigneeIdSelectTarget: HTMLInputElement
  teammateUserSelectContainerTarget: HTMLDivElement
  tropicStaffAssigneeIdSelectTarget: HTMLInputElement
  tropicStaffUserSelectContainerTarget: HTMLDivElement
  userSelectContainerTarget: HTMLSelectElement

  static values = {
    assigneeId: String,
    assigneesCount: Number,
    assignmentType: String,
    ceManagerUserId: String,
  }
  assigneeIdValue: string
  assigneesCountValue: number
  assignmentTypeValue: string
  ceManagerUserIdValue: string

  connect() {
    if (this.assigneesCountValue > 1) {
      show(this.multipleAssigneeSelectedWarningTarget)
    }
    this.setAssignmentType(this.assignmentTypeValue)
  }

  onAssignmentTypeChange(event) {
    this.buildUserSelectList(event.target.value)
  }

  onAssigneeIdChange(_event) {
    this.conditionallyRenderMultipleAssigneeSelectedWarning()
  }

  conditionallyRenderMultipleAssigneeSelectedWarning() {
    if (
      isShown(this.teammateAssigneeIdSelectTarget) &&
      this.teammateAssigneeIdSelectTarget.tomselect.getValue().length > 1
    ) {
      show(this.multipleAssigneeSelectedWarningTarget)
    } else {
      hide(this.multipleAssigneeSelectedWarningTarget)
    }
  }

  buildUserSelectList(assignmentType) {
    switch (assignmentType) {
      case "teammate":
        this.onTeammateAssignmentType()
        break
      case "tropic_staff":
        this.onTropicStaffAssignmentType()
        break
      case "commercial_executive_manager":
        this.onCeManagerAssignmentType()
        break
      case "supplier_portal_user":
        this.onSupplierPortalUserAssignmentType()
        break
      default:
        this.onUnassignedAssignmentType()
    }
  }

  onTeammateAssignmentType() {
    show(this.teammateUserSelectContainerTarget)
    setRequired(this.teammateAssigneeIdSelectTarget)
    this.hideTropicStaffUserSelectContainer()
    this.hideCeManagerUserSelectContainer()
    this.hideSupplierPortalUserSelectContainer()

    this.onUserAssignmentType()
  }

  onTropicStaffAssignmentType() {
    show(this.tropicStaffUserSelectContainerTarget)
    setRequired(this.tropicStaffAssigneeIdSelectTarget)
    this.hideTeammateUserSelectContainer()
    this.hideCeManagerUserSelectContainer()
    this.hideSupplierPortalUserSelectContainer()
    this.onUserAssignmentType()
  }

  onCeManagerAssignmentType() {
    show(this.ceManagerUserSelectContainerTarget)
    setRequired(this.ceManagerAssigneeIdSelectTarget)
    this.hideTeammateUserSelectContainer()
    this.hideTropicStaffUserSelectContainer()
    this.hideSupplierPortalUserSelectContainer()
    this.onUserAssignmentType()
  }

  onSupplierPortalUserAssignmentType() {
    if (this.hasSupplierPortalUserIdSelectContainerTarget) {
      this.hideTeammateUserSelectContainer()
      this.hideCeManagerUserSelectContainer()
      this.hideTropicStaffUserSelectContainer()

      if (
        this.supplierPortalUserAssigneeIdSelectTarget.tomselect &&
        JSON.stringify(this.supplierPortalUserAssigneeIdSelectTarget.tomselect.options) !== "{}"
      ) {
        show(this.supplierPortalUserIdSelectContainerTarget)
        this.onUserAssignmentType()
      } else {
        this.onUnassignedAssignmentType()
      }
    }
  }

  onUserAssignmentType() {
    show(this.userSelectContainerTarget)
    this.selectAssigneeId(this.assigneeIdValue)
  }

  onUnassignedAssignmentType() {
    hide(this.userSelectContainerTarget)
    this.setAssigneeId(null)

    this.hideTeammateUserSelectContainer()
    this.hideCeManagerUserSelectContainer()
    this.hideTropicStaffUserSelectContainer()
    this.hideSupplierPortalUserSelectContainer()
  }

  hideTeammateUserSelectContainer() {
    hide(this.teammateUserSelectContainerTarget)
    removeRequired(this.teammateAssigneeIdSelectTarget)
    if (this.teammateAssigneeIdSelectTarget.tomselect) {
      this.clearTomselect(this.teammateAssigneeIdSelectTarget)
    }
  }

  hideCeManagerUserSelectContainer() {
    hide(this.ceManagerUserSelectContainerTarget)
    removeRequired(this.ceManagerAssigneeIdSelectTarget)
    if (this.ceManagerAssigneeIdSelectTarget.tomselect) {
      this.clearTomselect(this.ceManagerAssigneeIdSelectTarget)
    }
  }

  hideTropicStaffUserSelectContainer() {
    hide(this.tropicStaffUserSelectContainerTarget)
    removeRequired(this.tropicStaffAssigneeIdSelectTarget)
    if (this.tropicStaffAssigneeIdSelectTarget.tomselect) {
      this.clearTomselect(this.tropicStaffAssigneeIdSelectTarget)
    }
  }

  hideSupplierPortalUserSelectContainer() {
    if (this.hasSupplierPortalUserIdSelectContainerTarget) {
      hide(this.supplierPortalUserIdSelectContainerTarget)
      if (this.supplierPortalUserAssigneeIdSelectTarget.tomselect) {
        this.clearTomselect(this.supplierPortalUserAssigneeIdSelectTarget)
      }
    }
  }

  onTeammateUserSelected() {
    this.setAssigneeId(this.teammateAssigneeIdSelectTarget.tomselect.getValue())
  }

  onTropicStaffUserSelected() {
    this.setAssigneeId(this.tropicStaffAssigneeIdSelectTarget.tomselect.getValue())
  }

  onCeManagerUserSelected() {
    this.setAssigneeId(this.ceManagerAssigneeIdSelectTarget.tomselect.getValue())
  }

  onSupplierPortalUserSelected() {
    if (this.hasSupplierPortalUserAssigneeIdSelectTarget) {
      this.setAssigneeId(this.supplierPortalUserAssigneeIdSelectTarget.tomselect.getValue())
    }
  }

  selectAssigneeId(assigneeId) {
    const selectedAssignmentType = this.assignmentTypeSelectTarget.value

    switch (selectedAssignmentType) {
      case "teammate":
        this.updateUserSelectElement(this.teammateAssigneeIdSelectTarget, assigneeId)
        break
      case "tropic_staff":
        this.updateUserSelectElement(this.tropicStaffAssigneeIdSelectTarget, assigneeId)
        break
      case "commercial_executive_manager":
        this.updateUserSelectElement(this.ceManagerAssigneeIdSelectTarget, this.ceManagerUserIdValue)
        break
      case "supplier_portal_user":
        this.updateUserSelectElement(this.supplierPortalUserAssigneeIdSelectTarget, assigneeId)
        break
    }
  }

  updateUserSelectElement(selectElement, value) {
    if (selectElement.tomselect) {
      if (value) {
        selectElement.tomselect.addItem(value)
        if (selectElement.tomselect.getItem(value)) {
          this.setAssigneeId(value)
        } else {
          this.setAssigneeId(null)
        }
      } else {
        this.clearTomselect(selectElement)
        this.setAssigneeId(null)
      }
    }
  }

  setAssigneeId(assigneeId) {
    this.assigneeIdFieldTarget.value = assigneeId
    this.assigneeIdFieldTarget.dispatchEvent(new Event("change"))
  }

  setAssignmentType(assignmentType) {
    this.assignmentTypeSelectTarget.value = assignmentType
    this.assignmentTypeSelectTarget.dispatchEvent(new Event("change"))
  }

  clearTomselect(selectElement) {
    selectElement.tomselect.clear()
    // calling `clear()` on tomselect doesn't remove the `selected` attribute on the options
    // https://github.com/orchidjs/tom-select/issues/545
    selectElement.querySelectorAll("option[selected]").forEach((option) => {
      option.removeAttribute("selected")
    })
  }
}
