import { DateTime, Interval } from "luxon"
import { pluralizeWithDelimiter } from "./inflector"

const dateUnitDisplay = (value: number, unit: "second" | "hour" | "day" | "month" | "year"): string => {
  return `${pluralizeWithDelimiter(unit, value)}`
}

export const getMonthsAndDays = (start: string, end: string) => {
  const startDate = DateTime.fromFormat(start, "y-M-d")
  const endDate = DateTime.fromFormat(end, "y-M-d")
  const invalidDateMsg = "Invalid date range"

  if (startDate > endDate) {
    return invalidDateMsg
  }

  const durationConfig = ["months", "days"]
  return Interval.fromDateTimes(startDate, endDate).toDuration(durationConfig, {}).values
}

export const getMonthsAndDaysString = (start: string, end: string): string => {
  const { months, days } = getMonthsAndDays(start, end)

  return `${dateUnitDisplay(months, "month")}, ${dateUnitDisplay(Math.floor(days), "day")}`
}

export const getNewDate = (start: string, months: number, days: number): string => {
  return DateTime.fromFormat(start, "y-M-d").plus({ months: months, days: days }).toISODate()
}

export const isValidDate = (date: Date): boolean => {
  return date instanceof Date && !Number.isNaN(date.getTime())
}

export const convertDateStringInput = (date: string) => {
  return DateTime.fromISO(date).toFormat("MMMM d, yyyy")
}
