import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="request-form-editor--field-display-criteria"
export default class extends Controller {
  static targets = ["toggle", "fieldset", "questionField", "questionOptions"]
  toggleTarget: HTMLInputElement
  fieldsetTarget: HTMLFieldSetElement
  questionFieldTarget: HTMLSelectElement
  questionOptionsTarget: HTMLDivElement

  static values = {
    formFieldKey: String,
  }
  formFieldKeyValue: string

  initialize() {
    this.toggleForm()
    this.showQuestionOptions()
  }

  toggleForm = () => {
    const { checked } = this.toggleTarget

    this.fieldsetTarget.disabled = !checked
  }

  showQuestionOptions = () => {
    const selectedQuestion = this.questionFieldTarget.value
    const template = document.getElementById(`form_question_${selectedQuestion}_${this.formFieldKeyValue}`)

    this.questionOptionsTarget.innerHTML = template.innerHTML
  }
}
