import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="collapsible-scopes"
export default class extends Controller {
  static targets = ["buttonText", "menuLauncher"]
  static values = {
    current: {
      type: String,
      default: "",
    },
    scopes: String,
  }

  buttonTextTarget: HTMLElement
  menuLauncherTarget: Element

  currentValue: string
  scopesValue: string

  highlightCurrentScope(customEvent: any) {
    const overflowChildren = customEvent.detail.overflowChildren
    if (this.currentValue) {
      const currentChild = overflowChildren.find((child) => child.classList.contains(`key-${this.currentValue}`))

      if (currentChild) {
        const scopes = JSON.parse(this.scopesValue)
        const currentScope = scopes[this.currentValue]

        this.buttonTextTarget.innerText = currentScope?.title || this.currentValue
        this.menuLauncherTarget.classList.add("scope-pill-on", "text-purple-700")
        this.menuLauncherTarget.classList.remove("scope-pill-off")
      } else {
        this.buttonTextTarget.innerText = "More"
        this.menuLauncherTarget.classList.add("scope-pill-off")
        this.menuLauncherTarget.classList.remove("text-purple-700")
      }
    }
  }
}
