import { appendToSiblingParent } from "."

export const getFileId = (file): string => {
  return file.upload.uuid
}

export const createHiddenInput = (file, target): HTMLInputElement => {
  const input = document.createElement("input")
  const inputTarget = target

  input.type = "hidden"
  input.name = `${inputTarget.name}`
  input.multiple = true
  input.id = `${getFileId(file)}-file-input`
  input.value = file.signed_id

  appendToSiblingParent({ node: input, siblingNode: inputTarget })

  return input
}
