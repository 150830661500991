import { Controller } from "@hotwired/stimulus"
import { getStringLiterals } from "./mixins/use_typed_stimulus_ctrl"
import { show, hide } from "../utils"
import NestedFormController from "./nested_form_controller"

// Connects to data-controller="trigger-forms"
class TriggerFormsController extends Controller {
  static targets = getStringLiterals([
    "anyTriggerAlertContainer",
    "multipleTriggersSelected",
    "singleTriggerSelected",
    "triggerConditionSelect",
    "triggeredBy",
  ])
  anyTriggerAlertContainerTarget: HTMLDivElement
  multipleTriggersSelectedTarget: HTMLDivElement
  singleTriggerSelectedTarget: HTMLDivElement
  triggerConditionSelectTarget: HTMLSelectElement
  triggeredByTargets: Array<HTMLSelectElement>

  static outlets = ["nested-form"]
  nestedFormOutlet: NestedFormController

  static values = { triggerCondition: String }
  triggerConditionValue: string

  connect() {
    this.toggleFormDescription()
  }

  toggleFormDescription(): void {
    if (this.triggeredByTargets.length === 1) {
      show(this.singleTriggerSelectedTarget)
      hide(this.multipleTriggersSelectedTarget)
    } else if (this.triggeredByTargets.length === 0) {
      show(this.singleTriggerSelectedTarget)
      hide(this.multipleTriggersSelectedTarget)
      this.nestedFormOutlet.add()
    } else {
      show(this.multipleTriggersSelectedTarget)
      hide(this.singleTriggerSelectedTarget)
      this.updateTriggerConditionText()
    }
  }

  private updateTriggerConditionText(): void {
    if (this.triggerConditionSelectTarget.value === "all") {
      hide(this.anyTriggerAlertContainerTarget)
    } else {
      show(this.anyTriggerAlertContainerTarget)
    }
  }
}

export default TriggerFormsController
