import { CommentLinksFinalizer } from "./comment_links_finalizer"

export class CommentFormFinalizer {
  private allMentionNodes: HTMLElement[]
  private container: HTMLDivElement
  static MENTIONS_SELECTOR = "span.tropic-mention"

  static call = (content: string): FinalizedCommentData => {
    return new CommentFormFinalizer(content).call()
  }

  private call = (): FinalizedCommentData => {
    if (this.noMentionsInFinalComment())
      return {
        content: this.createLinksInContent(this.container.innerHTML),
        mentionedUserIds: [],
      }

    Array.from(this.container.querySelectorAll("figure")).map(this.replaceFigureWithMentionNode)

    return {
      content: this.createLinksInContent(this.container.innerHTML),
      mentionedUserIds: this.mentionedUserIds(),
    }
  }

  private mentionedUserIds = (): string[] => {
    return this.allMentionNodes.map((node) => node.dataset.trixTropicUserId)
  }

  private noMentionsInFinalComment = (): boolean => this.allMentionNodes.length === 0

  private replaceFigureWithMentionNode = (figure: HTMLElement): HTMLElement | undefined => {
    const mentionNode = figure.querySelector(CommentFormFinalizer.MENTIONS_SELECTOR)
    if (!mentionNode) return

    figure.after(mentionNode)
    figure.remove()
    return mentionNode as HTMLElement
  }

  private createLinksInContent = (textBlock: string) => {
    const sanitizedTextBlock = textBlock.replaceAll("&nbsp;", " ")

    return CommentLinksFinalizer.call(sanitizedTextBlock)
  }

  private constructor(content: string) {
    this.container = document.createElement("div")
    this.container.innerHTML = content
    this.allMentionNodes = Array.from(this.container.querySelectorAll(CommentFormFinalizer.MENTIONS_SELECTOR))
  }
}

export type FinalizedCommentData = {
  content: string
  mentionedUserIds: string[]
}
