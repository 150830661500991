import { Controller } from "@hotwired/stimulus"
import { disable, enable } from "../utils"

// Connects to data-controller="bulk-contracts-import-modal"
export default class extends Controller {
  static targets = ["submit"]

  submitTarget: HTMLButtonElement

  onDocumentUploadedHandler = this.onDocumentUploaded.bind(this)
  onDocumentRemovedHandler = this.onDocumentRemoved.bind(this)

  connect() {
    window.addEventListener("Docupload:submitEnabled", this.onDocumentUploadedHandler)
    window.addEventListener("Docupload:documentRemoved", this.onDocumentRemovedHandler)

    this.disableSubmit()
  }

  disconnect() {
    window.removeEventListener("Docupload:submitEnabled", this.onDocumentUploadedHandler)
    window.removeEventListener("Docupload:documentRemoved", this.onDocumentRemovedHandler)
  }

  onDocumentUploaded() {
    this.enableSubmit()
  }

  onDocumentRemoved() {
    this.disableSubmit()
  }

  enableSubmit() {
    enable(this.submitTarget)
  }

  disableSubmit() {
    disable(this.submitTarget)
  }
}
