import { Controller } from "@hotwired/stimulus"
import { isEqual, startCase } from "lodash"
import { show, hide } from "../utils"

// Connects to data-controller="contracts-bulk-edit-form"
export default class extends Controller {
  static targets = [
    "warning",
    "contractOwnersSelect",
    "departmentSelect",
    "termSelect",
    "docProvidersSelect",
    "setupStatusSelect",
    "contractOwnersReset",
    "docProvidersReset",
    "departmentReset",
    "termReset",
    "setupStatusReset",
  ]

  warningTarget: HTMLDivElement

  contractOwnersSelectTarget: HTMLSelectElement
  departmentSelectTarget: HTMLSelectElement
  termSelectTarget: HTMLSelectElement
  hasTermSelectTarget: boolean
  docProvidersSelectTarget: HTMLSelectElement
  hasDocProvidersSelectTarget: boolean
  setupStatusSelectTarget: boolean

  contractOwnersResetTarget: HTMLLinkElement
  docProvidersResetTarget: HTMLLinkElement
  departmentResetTarget: HTMLLinkElement
  termResetTarget: HTMLLinkElement
  hasTermResetTarget: boolean
  setupStatusResetTarget: HTMLLinkElement

  static values = {
    contracts: {
      type: Array,
      default: [],
    },
  }

  contractsValue: any[]

  hasMultipleContractOwners = false
  hasMultipleDocProviders = false
  hasMultipleDepartments = false
  hasMultipleTerms = false
  hasMultipleSetupStatuses = false

  contractOwnersChanged = false
  departmentsChanged = false
  termsChanged = false
  docProvidersChanged = false
  setupStatusesChanged = false

  // bind() creates a new function with the given context, we need to store the
  // reference of that function, so we can then remove the listener
  onValueChangedHandler = this.onValueChange.bind(this)
  resetContractOwnersHandler = this.resetContractOwners.bind(this)
  resetDepartmentsHandler = this.resetDepartments.bind(this)
  resetTermsHandler = this.resetTerms.bind(this)
  resetDocProvidersHandler = this.resetDocProviders.bind(this)
  resetSetupStatusesHandler = this.resetSetupStatuses.bind(this)

  connect() {
    this.parseContractsValue()
    this.attachListeners()

    this.toggleWarningMessage()
    this.toggleResetLinks()
    this.checkForMultipleValues()

    // We need a delay to allow the browser the full render the modal
    setTimeout(() => {
      this.setPlaceholders()
      this.setDefaultValues()
    }, 500)
  }

  disconnect() {
    this.detachListeners()
  }

  parseContractsValue(): void {
    this.contractsValue = this.contractsValue.map((contract) => ({
      ...contract,
      contractOwners: contract.contract_owners.map((o) => o.user_id),
      docProviders: contract.document_providers.map((p) => p.user_id),
    }))
    delete this.contractsValue.contract_owners
    delete this.contractsValue.document_providers
  }

  setPlaceholders(): void {
    if (this.hasMultipleDepartments) {
      this.setStyledSelectPlaceholder(this.departmentSelectTarget)
    }
    if (this.hasMultipleTerms && this.hasTermSelectTarget) {
      this.setStyledSelectPlaceholder(this.termSelectTarget)
    }
  }

  setDefaultValues(): void {
    if (this.contractsValue.length < 1) {
      return
    }

    if (!this.hasMultipleContractOwners) {
      this.addItemsToStyledSelect(this.contractOwnersSelectTarget, this.contractsValue[0].contractOwners)
    }
    if (!this.hasMultipleDepartments) {
      this.addItemsToStyledSelect(this.departmentSelectTarget, [this.contractsValue[0].department_id])
    }
    if (!this.hasMultipleTerms && this.hasTermSelectTarget) {
      this.addItemsToStyledSelect(this.termSelectTarget, [this.contractsValue[0].term])
    }
    if (this.hasDocProvidersSelectTarget && !this.hasMultipleDocProviders) {
      this.addItemsToStyledSelect(this.docProvidersSelectTarget, this.contractsValue[0].docProviders)
    }
    if (this.hasSetupStatusSelectTarget && !this.hasMultipleSetupStatuses) {
      this.addItemsToStyledSelect(this.setupStatusSelectTarget, [this.contractsValue[0].setupStatus])
    }
  }

  onValueChange(event: CustomEvent): void {
    const { origin, value } = event.detail

    this.changeDetection(origin, value)
    this.toggleWarningMessage()
    this.toggleResetLinks()
  }

  resetContractOwners(e): void {
    e.preventDefault()
    this.resetField(
      this.contractOwnersSelectTarget,
      this.hasMultipleContractOwners,
      this.contractsValue[0].contractOwners,
      "contractOwnersChanged",
    )
  }

  resetDepartments(e): void {
    e.preventDefault()
    this.resetField(
      this.departmentSelectTarget,
      this.hasMultipleDepartments,
      [this.contractsValue[0].department_id],
      "departmentsChanged",
    )
  }

  resetTerms(e): void {
    e.preventDefault()
    if (this.hasTermSelectTarget) {
      this.resetField(this.termSelectTarget, this.hasMultipleTerms, [this.contractsValue[0].term], "termsChanged")
    }
  }

  resetDocProviders(e): void {
    e.preventDefault()
    this.resetField(
      this.docProvidersSelectTarget,
      this.hasMultipleDocProviders,
      this.contractsValue[0].docProviders,
      "docProvidersChanged",
    )
  }

  resetSetupStatuses(e): void {
    e.preventDefault()
    this.resetField(
      this.setupStatusSelectTarget,
      this.hasMultipleSetupStatuses,
      [this.contractsValue[0].setupStatus],
      "setupStatusesChanged",
    )
  }

  private attachListeners(): void {
    window.addEventListener("StyledSelect:valueChanged", this.onValueChangedHandler)

    this.contractOwnersResetTarget.addEventListener("click", this.resetContractOwnersHandler)
    this.departmentResetTarget.addEventListener("click", this.resetDepartmentsHandler)
    if (this.hasTermResetTarget) {
      this.termResetTarget.addEventListener("click", this.resetTermsHandler)
    }
    if (this.hasDocProvidersResetTarget) {
      this.docProvidersResetTarget.addEventListener("click", this.resetDocProvidersHandler)
    }
    if (this.hasSetupStatusResetTarget) {
      this.setupStatusResetTarget.addEventListener("click", this.resetSetupStatusesHandler)
    }
  }

  private detachListeners(): void {
    window.removeEventListener("StyledSelect:valueChanged", this.onValueChangedHandler)

    this.contractOwnersResetTarget.removeEventListener("click", this.resetContractOwnersHandler)
    this.departmentResetTarget.removeEventListener("click", this.resetDepartmentsHandler)
    if (this.hasTermResetTarget) {
      this.termResetTarget.removeEventListener("click", this.resetTermsHandler)
    }
    if (this.hasDocProvidersResetTarget) {
      this.docProvidersResetTarget.removeEventListener("click", this.resetDocProvidersHandler)
    }
    if (this.hasSetupStatusResetTarget) {
      this.setupStatusResetTarget.removeEventListener("click", this.resetSetupStatusesHandler)
    }
  }

  private toggleWarningMessage(): void {
    const fieldsChanged = [
      this.contractOwnersChanged,
      this.departmentsChanged,
      this.termsChanged,
      this.docProvidersChanged,
      this.setupStatusesChanged,
    ]

    if (fieldsChanged.some((f) => !!f)) {
      show(this.warningTarget)
    } else {
      hide(this.warningTarget)
    }
  }

  private toggleResetLinks(): void {
    hide(this.contractOwnersResetTarget)
    hide(this.departmentResetTarget)
    if (this.hasTermResetTarget) {
      hide(this.termResetTarget)
    }
    if (this.hasDocProvidersResetTarget) {
      hide(this.docProvidersResetTarget)
    }
    if (this.hasSetupStatusResetTarget) {
      hide(this.setupStatusResetTarget)
    }

    if (this.contractOwnersChanged) {
      show(this.contractOwnersResetTarget)
    }
    if (this.departmentsChanged) {
      show(this.departmentResetTarget)
    }
    if (this.termsChanged && this.hasTermResetTarget) {
      show(this.termResetTarget)
    }
    if (this.docProvidersChanged) {
      show(this.docProvidersResetTarget)
    }
    if (this.setupStatusesChanged) {
      show(this.setupStatusResetTarget)
    }
  }

  private resetField(selectTarget, hasMultiple, defaultValue, changedKey): void {
    this.clearStyledSelect(selectTarget)
    if (!hasMultiple) {
      this.addItemsToStyledSelect(selectTarget, defaultValue)
    }
    if (this[changedKey] !== "undefined") {
      this[changedKey] = false
    }
    this.toggleResetLinks()
    this.toggleWarningMessage()
  }

  private setStyledSelectPlaceholder(element, placeholder = "Multiple values selected"): void {
    window.dispatchEvent(
      new CustomEvent("StyledSelect:setPlaceholder", {
        detail: { target: element, placeholder },
      }),
    )
  }

  private addItemsToStyledSelect(element, items): void {
    const payload = {
      target: element,
      items: items || [],
      silent: true,
    }
    window.dispatchEvent(new CustomEvent("StyledSelect:addItems", { detail: payload }))
  }

  private clearStyledSelect(element): void {
    const payload = {
      target: element,
      silent: true,
    }
    window.dispatchEvent(new CustomEvent("StyledSelect:clear", { detail: payload }))
  }

  private checkForMultipleValues(): void {
    if (this.contractsValue.length < 1) {
      return
    }

    const initial = {
      hasMultipleContractOwners: false,
      hasMultipleDepartments: false,
      hasMultipleTerms: false,
      hasMultipleDocProviders: false,
      contractOwners: this.contractsValue[0].contractOwners,
      departmentId: this.contractsValue[0].department_id,
      term: this.contractsValue[0].term,
      docProviders: this.contractsValue[0].docProviders,
      setupStatus: this.contractsValue[0].setupStatus,
    }
    const result = this.contractsValue.reduce((acc, contract) => {
      const contractOwners = contract.contractOwners
      const departmentId = contract.department_id
      const term = contract.term
      const docProviders = contract.docProviders
      const setupStatus = contract.setupStatus

      const hasMultipleContractOwners = !acc.hasMultipleContractOwners
        ? !isEqual(acc.contractOwners, contractOwners)
        : true
      const hasMultipleDepartments = !acc.hasMultipleDepartments ? !isEqual(acc.departmentId, departmentId) : true
      const hasMultipleTerms = !acc.hasMultipleTerms ? !isEqual(acc.term, term) : true
      const hasMultipleDocProviders = !acc.hasMultipleDocProviders ? !isEqual(acc.docProviders, docProviders) : true
      const hasMultipleSetupStatuses = !acc.hasMultipleSetupStatuses ? !isEqual(acc.setupStatus, setupStatus) : true

      return {
        hasMultipleContractOwners,
        hasMultipleDepartments,
        hasMultipleTerms,
        hasMultipleDocProviders,
        hasMultipleSetupStatuses,
        contractOwners,
        departmentId,
        term,
        docProviders,
        setupStatus,
      }
    }, initial)

    this.hasMultipleContractOwners = result.hasMultipleContractOwners
    this.hasMultipleDepartments = result.hasMultipleDepartments
    this.hasMultipleTerms = result.hasMultipleTerms
    this.hasMultipleDocProviders = result.hasMultipleDocProviders
    this.hasMultipleSetupStatuses = result.hasMultipleSetupStatuses
  }

  private changeDetection(origin, value) {
    const targets = [this.contractOwnersSelectTarget, this.departmentSelectTarget]
    if (this.hasTermSelectTarget) {
      targets.push(this.termSelectTarget)
    }

    if (this.hasDocProvidersSelectTarget) {
      targets.push(this.docProvidersSelectTarget)
    }

    if (this.hasSetupStatusSelectTarget) {
      targets.push(this.setupStatusSelectTarget)
    }

    const hasMultipleValues = [
      this.hasMultipleContractOwners,
      this.hasMultipleDepartments,
      this.hasMultipleTerms,
      this.hasMultipleDocProviders,
      this.hasMultipleSetupStatuses,
    ]

    const fieldsChanged = [
      "contractOwnersChanged",
      "departmentsChanged",
      "termsChanged",
      "docProvidersChanged",
      "setupStatusesChanged",
    ]

    const contractKeys = ["contractOwners", "department_id", "term", "docProviders", "setupStatus"]

    if (targets.includes(origin)) {
      show(this.warningTarget)
      const index = targets.indexOf(origin)
      const hasMultipleValue = hasMultipleValues[index]

      if (hasMultipleValue) {
        this[fieldsChanged[index]] =
          (typeof value === "string" && !!value) || (typeof value === "object" && Array.isArray(value) && value.length)
      } else {
        this[fieldsChanged[index]] = !isEqual(this.contractsValue[0][contractKeys[index]], value)
      }
    }
  }
}
