import React, { useContext, useEffect, useState } from "react"

const dataForStatus = (taskStatus: string) => {
  const defaultIconColor = "text-gray-500"
  switch (taskStatus) {
    case "Not Started":
      return {
        bgColor: "bg-gray-100",
        icon: "fa-lock",
        iconColor: defaultIconColor,
        textColor: "text-gray-600",
      }
    case "Opened":
      return {
        bgColor: "bg-purple-500",
        icon: "fa-adjust",
        iconColor: "text-purple-100",
        textColor: "text-white",
      }
    case "Completed":
      return {
        bgColor: "bg-green-100",
        icon: "fa-circle-check",
        textColor: "text-green-800",
        iconColor: "text-green-600",
      }
    case "Skipped":
      return {
        bgColor: "bg-gold-100",
        icon: "fa-forward",
        textColor: "text-gold-800",
        iconColor: "text-gold-600",
      }
    case "Changes Requested":
      return {
        bgColor: "bg-purple-100",
        icon: "fa-rotate-right",
        textColor: "text-purple-600",
        iconColor: defaultIconColor,
      }
    case "Snoozed":
      return {
        bgColor: "bg-gray-100",
        icon: "fa-alarm-snooze",
        textColor: "text-gray-600",
        iconColor: defaultIconColor,
      }
    case "Rejected":
      return {
        bgColor: "bg-red-100",
        icon: "fa-circle-xmark",
        textColor: "text-red-800",
        iconColor: "text-red-600",
      }
  }
}

interface RequestStatusBarProps {
  taskStatus: string
}

export const RequestStatusBar = ({ taskStatus }: RequestStatusBarProps) => {
  const { bgColor, icon, textColor, iconColor } = dataForStatus(taskStatus)

  return (
    <div className={`rounded-t-md h-9 ${bgColor} ${textColor} subtitle pl-4 flex items-center space-x-2`}>
      <i className={`fa-solid ${icon} ${iconColor} text-lg`}></i>
      <div>{taskStatus}</div>
    </div>
  )
}
