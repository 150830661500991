import { Controller } from "@hotwired/stimulus"
import { show, hide, isEmpty } from "../utils"
import { Task } from "../utils/types"
import { getStringLiterals } from "./mixins/use_typed_stimulus_ctrl"
import TriggerFormsController from "./trigger_forms_controller"

type Task = {
  name: string
  id: string
}

// Connects to data-controller="simplified-trigger-form"
class SimplifiedTriggerFormController extends Controller {
  static outlets = ["trigger-forms"]
  triggerFormsOutlet: TriggerFormsController

  static values = { rootId: String, tasks: Array }
  rootIdValue: string
  tasksValue: Array<Task>

  static targets = getStringLiterals(["triggeredBy"])
  triggeredByTarget: HTMLSelectElement

  connect() {
    this.updateTriggeredByOptions()
    this.toggleRemoveButtons()
    this.toggleAddTriggerButton()
    this.triggerFormsOutlet.toggleFormDescription()
  }

  handleTriggeredByFieldChange() {
    this.toggleAddTriggerButton()
    this.triggerFormsOutlet.toggleFormDescription()
  }

  remove(e?: Event) {
    this.element.remove()

    this.toggleRemoveButtons()
  }

  updateTriggeredByOptions() {
    Array.apply(null, this.triggeredByTarget.options).map((option) => {
      if (this.unselectedTriggeredByValues().includes(option.value)) {
        show(option)
      } else {
        hide(option)
      }
    })
  }

  private validTasks() {
    let updatedTasks = this.tasksValue.filter((task): task is Task => task !== undefined).map((task) => task.id)

    if (this.allowRoot()) {
      updatedTasks.unshift(this.rootIdValue)
    }

    return updatedTasks
  }

  private allowRoot(): boolean {
    return document.querySelectorAll("#workflow_task_triggers__triggered_by").length === 1
  }

  private selectedTriggeredByValues(): Array<string> {
    let currentTriggeredByDropdowns = this.currentTriggeredByDropdowns()
    return Array.from(currentTriggeredByDropdowns)
      .map((dropdown) => dropdown.value)
      .filter((e) => e)
  }

  private currentTriggeredByDropdowns(): NodeListOf<HTMLSelectElement> {
    return document.querySelectorAll("#workflow_task_triggers__triggered_by") as NodeListOf<HTMLSelectElement>
  }

  // Array of selectable task ids that are not already selected on the page
  private unselectedTriggeredByValues(): Array<string> {
    let unselectedvalidTasks = this.validTasks().filter((task) => !this.selectedTriggeredByValues().includes(task))
    if (this.allowRoot()) {
      unselectedvalidTasks.unshift(this.rootIdValue)
    }
    let allOptionValuesInTriggeredByDropdown = Array.from(this.triggeredByTarget.options).map((option) => option.value)
    // Return the unselected tasks that are also available in the triggered by dropdown:
    return unselectedvalidTasks.filter((task) => allOptionValuesInTriggeredByDropdown.includes(task))
  }

  // Returns the "Add Trigger" button
  private addTriggerButton(): HTMLButtonElement | null {
    return document.querySelector('button[data-action="nested-form#add"]')
  }

  private toggleRemoveButtons() {
    const removeButtons = document.querySelectorAll("[data-simplified-trigger-form-target='removeButton']")

    if (removeButtons.length > 1) {
      removeButtons.forEach((triggerForm) => show(triggerForm))
    } else if (removeButtons.length === 1) {
      hide(removeButtons[0])
    }
    this.updateTriggeredByOptions()
    this.toggleAddTriggerButton()
    this.triggerFormsOutlet.toggleFormDescription()
  }

  // Hide the "Add Trigger" button if all tasks have been selected as triggers
  // and all trigger types have been selected OR if the only selected trigger
  // is "Request Submitted"
  private toggleAddTriggerButton(): void {
    if (isEmpty(this.unselectedTriggeredByValues()) || this.onlyTriggerIsRoot() || this.maxTriggersAdded()) {
      hide(this.addTriggerButton())
    } else {
      show(this.addTriggerButton())
    }
  }

  private onlyTriggerIsRoot(): boolean {
    const selectedTriggeredByValues = this.selectedTriggeredByValues()

    return selectedTriggeredByValues.length === 1 && selectedTriggeredByValues[0] === this.rootIdValue
  }

  private maxTriggersAdded(): boolean {
    return this.currentTriggeredByDropdowns().length === 10
  }
}

export default SimplifiedTriggerFormController
