import { Controller } from "@hotwired/stimulus"
import { disable, enable, hide, show } from "../utils"

export default class extends Controller {
  static targets = ["coveringUserField", "bulkReassignCheckbox", "bulkReassignContainer"]
  bulkReassignCheckboxTarget: HTMLElement
  coveringUserFieldTarget: HTMLElement
  bulkReassignContainerTarget: HTMLElement

  connect() {
    if (this.coveringUserFieldTarget.value) {
      enable(this.bulkReassignCheckboxTarget)
      show(this.bulkReassignContainerTarget)
    } else {
      disable(this.bulkReassignCheckboxTarget)
      hide(this.bulkReassignContainerTarget)
    }
  }

  toggleBulkReassignCheckbox(e) {
    if (e.target.value) {
      enable(this.bulkReassignCheckboxTarget)
      show(this.bulkReassignContainerTarget)
    } else {
      disable(this.bulkReassignCheckboxTarget)
      hide(this.bulkReassignContainerTarget)
    }
  }
}
