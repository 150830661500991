import { Controller } from "@hotwired/stimulus"
import { setSearchParams } from "../utils/urls"

// Connects to data-controller="document-viewer"
export default class extends Controller {
  static values = {
    documentId: String,
  }
  documentIdValue: string

  connect() {
    this.setDocumentIdParam()
  }

  disconnect() {
    this.deleteDocumentIdParam()
  }

  setDocumentIdParam(): void {
    const urlParams = new URLSearchParams(window.location.search)
    urlParams.set("document_id", this.documentIdValue)
    setSearchParams(urlParams)
    this.dispatchDocumentIdChange(this.documentIdValue)
  }

  deleteDocumentIdParam(): void {
    const urlParams = new URLSearchParams(window.location.search)
    urlParams.delete("document_id")
    setSearchParams(urlParams)
    this.dispatchDocumentIdChange(null)
  }

  dispatchDocumentIdChange(documentId: string) {
    document.dispatchEvent(new CustomEvent("DocumentViewer:onDocumentIdChange", { detail: { documentId } }))
  }
}
