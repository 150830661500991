import { Controller } from "@hotwired/stimulus"
import { showShallowModeModal } from "../utils"

// Connects to data-controller="department-template-spend-approval-level-form"
export default class extends Controller {
  static targets = ["confirmUpdateSpendLevelWrapper"]
  confirmUpdateSpendLevelWrapperTarget: HTMLDivElement

  toggleConfirmUpdateSpendLevelModal() {
    if (this.hasConfirmUpdateSpendLevelWrapperTarget) {
      showShallowModeModal(this.confirmUpdateSpendLevelWrapperTarget, this.application)
    }
  }
}
