import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="logo-upload"
export default class extends Controller {
  static targets = ["output", "input"]
  outputTarget: HTMLParagraphElement
  inputTarget: HTMLInputElement

  docFile: File | null = null

  change(): void {
    this.outputTarget.textContent = this.inputTarget.files[0].name
  }
}
