import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="radio-button"
export default class extends Controller {
  static targets = ["wrapper", "input"]

  static values = {
    outerCheckedClass: String,
  }

  hasInputTarget: boolean

  inputTarget: HTMLInputElement
  wrapperTarget: HTMLElement
  outerCheckedClassValue: string

  toggleCheckedClassHandler = this.toggleCheckedClass.bind(this)

  connect() {
    this.appendListeners()
    this.toggleCheckedClass()
  }

  disconnect() {
    this.removeListeners()
  }

  appendListeners() {
    if (this.hasInputTarget && this.outerCheckedClassValue) {
      this.inputTarget.addEventListener("change", this.toggleCheckedClassHandler)
    }
  }

  removeListeners() {
    if (this.hasInputTarget && this.outerCheckedClassValue) {
      this.inputTarget.removeEventListener("change", this.toggleCheckedClassHandler)
    }
  }

  toggleCheckedClass() {
    if (!this.hasInputTarget || !this.outerCheckedClassValue) {
      return
    }

    const classes = this.outerCheckedClassValue.split(" ")
    if (this.inputTarget.checked) {
      // Remove the checked class from all the radios of the same group
      const groupName = this.inputTarget.name
      const radios = document.querySelectorAll(`input[type="radio"][name="${groupName}"]`)
      radios.forEach((radio) => {
        radio.closest("[data-radio-button-target='wrapper']").classList.remove(...classes)
      })

      // Add the class to the current checked radio
      this.wrapperTarget.classList.add(...classes)
    }
  }
}
