import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="select-form-submit"
export default class extends Controller {
  static targets = ["form"]
  formTarget: HTMLFormElement

  submit(): void {
    this.formTarget.requestSubmit()
  }
}
