import { Controller } from "@hotwired/stimulus"

const NON_USER_INPUTS_TO_SKIP = new Set(["authenticity_token", "commit"])
const LEAVING_MESSAGE = "Are you sure you want to leave this page? Changes you made will not be saved."

export default class extends Controller {
  static targets = ["watchable"]
  static values = {
    shouldBlockLeaving: Boolean,
    ignoreTurboVisits: Boolean,
  }

  shouldBlockLeavingValue: boolean
  hasShouldBlockLeavingValue: boolean
  ignoreTurboVisitsValue: boolean
  leavingPageFunction: (event: BeforeUnloadEvent) => void
  setShouldBlockLeavingFunction: () => void
  watchableTargets: HTMLInputElement[]

  connect() {
    // must set this to a common function so the reference is saved for the "disconnect"
    this.leavingPageFunction = this.leavingPage.bind(this)
    this.setShouldBlockLeavingFunction = this.setShouldBlockLeaving.bind(this)
    window.addEventListener("beforeunload", this.leavingPageFunction)

    this.watchableTargets.forEach((input) => input.addEventListener("change", this.setShouldBlockLeavingFunction))

    if (!this.ignoreTurboVisitsValue) {
      window.addEventListener("turbo:before-visit", this.leavingPageFunction)
    }
  }

  disconnect(): void {
    window.removeEventListener("beforeunload", this.leavingPageFunction)

    this.watchableTargets.forEach((input) => input.removeEventListener("change", this.setShouldBlockLeavingFunction))

    if (!this.ignoreTurboVisitsValue) {
      window.removeEventListener("turbo:before-visit", this.leavingPageFunction)
    }
  }

  // prevent leaving page either with refresh or url navigation when editing
  leavingPage(event) {
    if (this.shouldBlockLeaving) {
      if (event.type == "turbolinks:before-visit" || event.type == "turbo:before-visit") {
        if (!window.confirm(LEAVING_MESSAGE)) {
          event.preventDefault()
        }
      } else {
        event.returnValue = LEAVING_MESSAGE
        return event.returnValue
      }
    }
  }

  setShouldBlockLeaving() {
    this.shouldBlockLeavingValue = true
  }

  get shouldBlockLeaving() {
    // if the attribute is present use that value as the source of truth
    if (this.hasShouldBlockLeavingValue) {
      return this.shouldBlockLeavingValue
    } else {
      // otherwise check if any user form inputs have a value
      return this.userInputs.some((input) => input.value != null && input.value !== "")
    }
  }

  get userInputs() {
    const form = this.element.closest("form")

    if (form) {
      const formInputs = form.querySelectorAll("input")
      const formSelects = form.querySelectorAll("select")
      const formTextAreas = form.querySelectorAll("textarea")

      const allInputs = [...formInputs, ...formSelects, ...formTextAreas]

      const userInputs = allInputs.filter((input) => !NON_USER_INPUTS_TO_SKIP.has(input.name))

      return userInputs
    } else {
      return []
    }
  }
}
