export type Id = string | number

export type StringLiteral<T extends string> = T

export type NumberOrStringLiteral<T> = T extends Id ? (Id extends T ? never : T) : never

export type TypedDictionary<Key, Value> = Record<NumberOrStringLiteral<Key>, Value>
export type Dictionary<T> = TypedDictionary<Id, T>

/**
 * Takes an array of strings and returns the same array, but with an implied string literal type.
 *
 * @param values string array
 * @returns the same string array, but with an implied string literal type
 */
export const getStringLiterals = <Type extends string>(values: StringLiteral<Type>[]) => {
  // @ts-expect-error - TS doesn't know that the values are string literals
  return [...(values as const)]
}

export interface Trigger {
  id: string
  name: string
}

export type Task = {
  name: string
  id: string
}

export type Stage = {
  order: Number
  name: string
  tasks: Array<Task>
}

export interface IStageNode {
  connectable: boolean
  data: {
    dropdownName: string
    jumpToViewport: { x: number; y: 100; zoom: 1 }
    name: string
    order: number
  }
  draggable: boolean
  id: string
  position: { x: number; y: number }
  selectable: boolean
  sourcePosition: string
  targetPosition: string
  type: "stage"
}
