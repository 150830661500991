import { Controller } from "@hotwired/stimulus"
import { show, hide } from "../../utils"

// Connects to data-controller="spend-management--period-filter"
export default class extends Controller {
  static targets = ["fiscalYearSelect", "ytdPeriodSelect", "periodTypeSelect", "customPeriodSelect", "periodScope"]
  static values = {
    periodsOptionsData: String,
  }

  fiscalYearSelectTarget: HTMLSelectElement
  ytdPeriodSelectTarget: HTMLSelectElement
  periodTypeSelectTarget: HTMLSelectElement
  customPeriodSelectTarget: HTMLSelectElement
  periodScopeTarget: HTMLInputElement

  periodsOptionsDataValue: string
  periodsOptionsData: Record<string, any>[]

  onTabChangeFunction: (event) => void

  connect() {
    this.onTabChangeFunction = this.onTabChange.bind(this)
    window.addEventListener("TabsComponent:tabChanged", this.onTabChangeFunction)

    this.periodsOptionsData = JSON.parse(this.periodsOptionsDataValue)
  }

  disconnect() {
    window.removeEventListener("TabsComponent:tabChanged", this.onTabChangeFunction)
  }

  clearFilters(): void {
    const form = this.periodScopeTarget.form

    if (form) {
      this.periodScopeTarget.value = ""
      this.currentFiscalYearScope().checked = false
      this.clearSelects()
      form.requestSubmit()
    }
  }

  refreshPeriodOptions(): void {
    this.refreshYearToDateOptions()
    this.refreshCustomOptions()
  }

  onFiscalYearScopeChange(): void {
    const form = this.periodScopeTarget.form

    if (form && this.yearToDateFiscalYearScope()) {
      this.refreshYearToDateOptions()
      show(form.querySelector("#fiscal_year_scope_ytd"))
      hide(form.querySelector("#fiscal_year_scope_custom"))
    } else if (form && this.customFiscalYearScope()) {
      this.refreshCustomOptions()
      hide(form.querySelector("#fiscal_year_scope_ytd"))
      show(form.querySelector("#fiscal_year_scope_custom"))
    }
  }

  private yearToDateFiscalYearScope(): boolean {
    return this.currentFiscalYearScope()?.value === "year_to_date"
  }

  private customFiscalYearScope(): boolean {
    return this.currentFiscalYearScope()?.value === "custom"
  }

  private currentFiscalYearScope(): HTMLInputElement {
    const form = this.periodScopeTarget.form

    if (form) {
      return form.querySelector('input[name^="filter_by[fiscal_year_scope]"]:checked')
    }
  }

  private refreshYearToDateOptions(): void {
    const yearId = this.fiscalYearSelectTarget.value
    const periodType = "month"

    this.updateSelectOptions(this.ytdPeriodSelectTarget, this.getOptions(periodType, yearId))
  }

  private refreshCustomOptions(): void {
    const yearId = this.fiscalYearSelectTarget.value
    const periodType = this.periodTypeSelectTarget.value

    this.updateSelectOptions(this.customPeriodSelectTarget, this.getOptions(periodType, yearId))
  }

  private onTabChange(event): void {
    const value = event.detail.value

    this.periodScopeTarget.value = value
  }

  private getOptions(periodType: string, yearId: string): object[] {
    return this.periodsOptionsData.filter(
      (option) => option.data.period_type === periodType && option.data.year_id === yearId,
    )
  }

  private updateSelectOptions(selectTarget: HTMLSelectElement, options: object[]): void {
    const select = selectTarget.tomselect
    select.clear()
    select.clearOptions()
    select.addOptions(options)

    if (options.length > 0) {
      select.addItem(options[0].value)
    }
  }

  private clearSelects(): void {
    const selects = [
      this.fiscalYearSelectTarget,
      this.ytdPeriodSelectTarget,
      this.periodTypeSelectTarget,
      this.customPeriodSelectTarget,
    ]

    selects.forEach((select) => {
      select.tomselect && select.tomselect.clear()
      select.value = ""
    })
  }
}
