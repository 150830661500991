// This will replace the contract-line-item-form controller
// when the contract_line_items_enhancements flag is released
// TODO: Rename this to contract-line-item-form controller to match the template name

import { Controller } from "@hotwired/stimulus"
import { post } from "@rails/request.js"
import { hide, show } from "../utils"
import { useDurationCalculation } from "./mixins/use_duration_calculation"
import { useTypedStimulusCtrl } from "./mixins/use_typed_stimulus_ctrl"

// Connects to data-controller="new-contract-line-item-form"
class NewContractLineItemFormController extends Controller {
  static targets = [
    "form",
    "startDate",
    "endDate",
    "duration",
    "lineItemCaption",
    "supplierLineItemName",
    "pricingStructureField",
    "discountsPresentField",
    "perUnitDiscountTypeRadios",
    "lineItemDiscountTypeRadios",
  ]

  static values = {
    formChangeUrl: String,
  }

  formChangeUrlValue: string

  hasFormTarget: boolean
  hasLineItemCaptionTarget: boolean
  hasPricingStructureFieldTarget: boolean
  hasDiscountsPresentFieldFieldTarget: boolean
  hasPerUnitDiscountTypeRadiosTargets: boolean
  hasLineItemDiscountTypeRadiosTargets: boolean

  formTarget: HTMLFormElement
  lineItemCaptionTarget: HTMLElement
  supplierLineItemNameTarget: HTMLSelectElement
  pricingStructureFieldTarget: HTMLElement
  discountsPresentFieldTarget: HTMLElement
  perUnitDiscountTypeRadiosTargets: HTMLInputElement[]
  lineItemDiscountTypeRadiosTargets: HTMLInputElement[]

  connect() {
    useTypedStimulusCtrl(this, NewContractLineItemFormController.values, NewContractLineItemFormController.targets)
    useDurationCalculation({ controller: this as any })
  }

  onLineItemChange({ detail: { isUserAdded, origin } }) {
    const isLineItemSelectEvent = origin.id === this.supplierLineItemNameTarget.id
    if (!isLineItemSelectEvent || !this.hasLineItemCaptionTarget) return

    if (isUserAdded) {
      show(this.lineItemCaptionTarget)
    } else {
      hide(this.lineItemCaptionTarget)
    }
  }

  onFormChange(e): void {
    const { partial } = e.params
    const target = e.target
    const form = this.hasFormTarget ? this.formTarget : target.form
    const formData = new FormData(form)

    this.prepareForm(target, formData)

    let url = this.formChangeUrlValue
    if (partial) {
      const params = new URLSearchParams()
      params.append("partial", partial)
      url = `${url}?${params.toString()}`
    }

    post(url, {
      responseKind: "turbo-stream",
      body: formData,
    })
  }

  prepareForm(target: HTMLElement, formData: FormData) {
    if (this.hasPricingStructureChanged(target)) {
      this.resetPriceFields(formData)
    }

    if (this.hasDiscountPresentChanged(target)) {
      this.resetDiscountTypeFields(formData)
    }

    if (this.hasPerUnitDiscountTypeChanged(target)) {
      this.resetPerUnitDiscountFields(formData)
    }

    if (this.hasLineItemDiscountTypeChanged(target)) {
      this.resetLineItemDiscountFields(formData)
    }
  }

  hasPricingStructureChanged(target: HTMLElement): boolean {
    const el = this.hasPricingStructureFieldTarget
      ? this.pricingStructureFieldTarget
      : this.element.querySelector('[data-new-contract-line-item-form-target="pricingStructureField"]')

    return el && target == el
  }

  hasDiscountPresentChanged(target: HTMLElement): boolean {
    const el = this.hasDiscountsPresentFieldFieldTarget
      ? this.discountsPresentFieldTarget
      : this.element.querySelector('[data-new-contract-line-item-form-target="discountsPresentField"]')

    return el && target == el
  }

  hasPerUnitDiscountTypeChanged(target: HTMLElement): boolean {
    const els = this.hasPerUnitDiscountTypeRadiosTargets
      ? this.perUnitDiscountTypeRadiosTargets
      : this.element.querySelectorAll('[data-new-contract-line-item-form-target="perUnitDiscountTypeRadios"]')

    return els.length > 0 && !!Array.from(els).find((el) => target == el)
  }

  hasLineItemDiscountTypeChanged(target: HTMLElement): boolean {
    const els = this.hasLineItemDiscountTypeRadiosTargets
      ? this.lineItemDiscountTypeRadiosTargets
      : this.element.querySelectorAll('[data-new-contract-line-item-form-target="lineItemDiscountTypeRadios"]')

    return els.length > 0 && !!Array.from(els).find((el) => target == el)
  }

  resetPriceFields(formData: FormData) {
    this.resetFields(formData, [
      "quantity",
      "discounts_present",
      // Fixed fields
      "total_actual_price",
      // Per Unit fields
      "unit_cost_period",
      "per_unit_actual_price",
    ])

    this.resetDiscountTypeFields(formData)
  }

  resetDiscountTypeFields(formData: FormData) {
    this.resetFields(formData, ["per_unit_discount_type", "discount_type"])

    this.resetLineItemDiscountFields(formData)
    this.resetPerUnitDiscountFields(formData)
  }

  resetLineItemDiscountFields(formData: FormData) {
    this.resetFields(formData, ["discount_percentage", "discount_amount", "total_list_price", "discount_description"])
  }

  resetPerUnitDiscountFields(formData: FormData) {
    this.resetFields(formData, [
      "per_unit_discount_percentage",
      "per_unit_discount_amount",
      "per_unit_list_price",
      "per_unit_discount_description",
    ])
  }

  private resetFields(formData: FormData, fields: string[]) {
    fields.forEach((f) => {
      formData.delete(`contract_line_item[${f}]`)
    })
  }
}

export default NewContractLineItemFormController
