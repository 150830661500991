import React from "react"
import { EdgeProps, getSmoothStepPath } from "reactflow"

export default function Edge({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  markerEnd,
}: EdgeProps) {
  const [staticPath, labelX, labelY, offsetX, offsetY] = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  })

  return <path id={id} style={style} className="react-flow__edge-path" d={staticPath} markerEnd={markerEnd} />
}
