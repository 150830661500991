import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="edit-supplier-budget-match"
export default class extends Controller {
  connect() {
    document.addEventListener("turbo:submit-end", this.handleSubmit)
  }

  disconnect() {
    document.removeEventListener("turbo:submit-end", this.handleSubmit)
  }

  handleSubmit = (e: CustomEvent) => {
    const url = e.detail.fetchResponse.response.url

    // Adding a new supplier from the supplier search will cause the modal
    // to close earlier so this handles submitting the form and closing the
    // modal only when the actual form is submitted.
    if (e.detail.success && url.includes("update_supplier_budget_match")) {
      this.dispatch("handleSubmit")
    }
  }
}
