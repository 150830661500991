import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="teammates-form"
export default class extends Controller {
  static targets = ["sendInviteCheckbox", "sendInviteWarning"]
  sendInviteWarningTarget: HTMLDivElement

  toggleInviteWarning(event): void {
    if (event.target.checked) {
      this.sendInviteWarningTarget.classList.add("hidden")
    } else {
      this.sendInviteWarningTarget.classList.remove("hidden")
    }
  }
}
