import React, { useContext, useEffect, useState } from "react"

interface TaskDueDateProps {
  dueDate: string | null
  dueDateIcon: string | null
  dueDateIconColor: string | null
  dueDateTextColor: string | null
  taskStatus: string
}

export const TaskDueDate = ({
  dueDate,
  dueDateIcon,
  dueDateIconColor,
  dueDateTextColor,
  taskStatus,
}: TaskDueDateProps) => {
  const phrase = dueDatePhrase(dueDate, taskStatus)
  const borderColor = dueDate ? dueDateIconColor.replace("text", "border") : "border-gray-500"

  return (
    <div className="flex flex-row items-center space-x-2">
      <div className={`flex items-center justify-center w-5 h-5 border ${borderColor} rounded-full`}>
        <i
          className={`fa-solid fa-${dueDateIcon} fa-2xs ${dueDate ? dueDateIconColor : "text-gray-500"} text-[8px]`}
        ></i>
      </div>
      <div className={`text-sm font-normal ${dueDate ? dueDateTextColor : "text-gray-600"} truncate`}>
        {phrase} {dueDate}
      </div>
    </div>
  )
}

function dueDatePhrase(dueDate: string | null, taskStatus: string): string {
  if (dueDate && taskStatus === "Rejected") {
    return "Rejected"
  } else if (dueDate && taskStatus === "Completed") {
    return "Completed"
  } else if (dueDate && taskStatus == "Skipped") {
    return "Skipped"
  } else if (!dueDate) {
    return "No Due Date"
  } else {
    return "Due"
  }
}
