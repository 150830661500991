import { Controller } from "@hotwired/stimulus"
import { show, hide, showShallowModeModal } from "../utils"

// Connects to data-controller="request-management-rules"
export default class extends Controller {
  static targets = [
    "outerContainer",
    "noneditableConditionsContainer",
    "editableConditionsContainer",
    "editButtonContainer",
    "formButtonsContainer",
    "lastConditionIndexField",
    "conditionToRemoveIndexField",
    "addConditionButtonContainer",
    "removeFirstConditionContainer",
  ]

  outerContainerTarget: HTMLDivElement
  noneditableConditionsContainerTarget: HTMLDivElement
  editableConditionsContainerTarget: HTMLDivElement
  editButtonContainerTarget: HTMLDivElement
  formButtonsContainerTarget: HTMLDivElement
  lastConditionIndexFieldTarget: HTMLInputElement
  conditionToRemoveIndexFieldTarget: HTMLInputElement
  addConditionButtonContainerTarget: HTMLDivElement
  removeFirstConditionContainerTarget: HTMLDivElement

  static values = {
    maxConditionIndex: Number,
  }

  maxConditionIndexValue: number

  connect() {}

  toggleEditableView() {
    hide(this.noneditableConditionsContainerTarget)
    show(this.editableConditionsContainerTarget)

    hide(this.editButtonContainerTarget)
    show(this.formButtonsContainerTarget)

    if (parseInt(this.lastConditionIndexFieldTarget.value) > 0) {
      show(this.removeFirstConditionContainerTarget)
    }
  }

  toggleNoneditableView() {
    show(this.noneditableConditionsContainerTarget)
    hide(this.editableConditionsContainerTarget)

    show(this.editButtonContainerTarget)
    hide(this.formButtonsContainerTarget)

    hide(this.removeFirstConditionContainerTarget)
  }

  addCondition() {
    const newLastConditionIndex = parseInt(this.lastConditionIndexFieldTarget.value) + 1
    this.lastConditionIndexFieldTarget.value = newLastConditionIndex

    let conditionToShow = this.editableConditionsContainerTarget.querySelector(
      `#editable-condition-${newLastConditionIndex}-container`,
    )
    show(conditionToShow)

    let separatorToShow = this.editableConditionsContainerTarget.querySelector(
      `#editable-condition-${newLastConditionIndex - 1}-separator`,
    )
    show(separatorToShow)

    if (newLastConditionIndex === this.maxConditionIndexValue) {
      hide(this.addConditionButtonContainerTarget)

      let lastSeparator = this.editableConditionsContainerTarget.querySelector(
        `#editable-condition-${newLastConditionIndex}-separator`,
      )
      show(lastSeparator)
    }

    if (newLastConditionIndex > 0) {
      show(this.removeFirstConditionContainerTarget)
    }
  }

  showRemoveConditionModal(event) {
    const removeButton = event.currentTarget
    this.conditionToRemoveIndexFieldTarget.value = removeButton.dataset.conditionToRemoveIndex

    showShallowModeModal(this.outerContainerTarget, this.application)
  }

  clearConditionToRemoveIndexField() {
    this.clearConditionToRemoveIndexField.value = ""
  }

  removeCondition() {
    const newLastConditionIndex = parseInt(this.lastConditionIndexFieldTarget.value) - 1
    this.lastConditionIndexFieldTarget.value = newLastConditionIndex

    let conditionToHide = this.editableConditionsContainerTarget.querySelector(
      `#editable-condition-${newLastConditionIndex + 1}-container`,
    )
    hide(conditionToHide)

    let separatorToHide = this.editableConditionsContainerTarget.querySelector(
      `#editable-condition-${newLastConditionIndex}-separator`,
    )
    hide(separatorToHide)

    if (newLastConditionIndex === this.maxConditionIndexValue - 1) {
      show(this.addConditionButtonContainerTarget)

      let lastSeparator = this.editableConditionsContainerTarget.querySelector(
        `#editable-condition-${newLastConditionIndex}-separator`,
      )
      show(lastSeparator)
    }

    if (newLastConditionIndex === 0) {
      hide(this.removeFirstConditionContainerTarget)
    }

    const conditionToRemoveIndex = parseInt(this.conditionToRemoveIndexFieldTarget.value)

    for (let i = conditionToRemoveIndex; i < this.maxConditionIndexValue; i++) {
      this.copyConditionData(i, i + 1)
    }

    this.pasteDataIntoCondition(this.maxConditionIndexValue, this.defaultConditionData())
  }

  defaultConditionData() {
    return {
      budgetComparatorSelectedIndex: 0,
      budgetValue: "4999.00",
      requestTypeComparatorSelectedIndex: 0,
      requestTypeValue: [],
      supplierComparatorSelectedIndex: 0,
      supplierValue: [],
    }
  }

  copyConditionData(toIndex, fromIndex) {
    const budgetComparatorField = this.findConditionField(fromIndex, "select", "budget_cents", "comparator")
    const budgetValueField = this.findConditionField(fromIndex, "input", "budget", "value")
    const requestTypeComparatorField = this.findConditionField(fromIndex, "select", "request_type", "comparator")
    const requestTypeValueField = this.findConditionField(fromIndex, "select", "request_type", "value")
    const supplierComparatorField = this.findConditionField(fromIndex, "select", "supplier_id", "comparator")
    const supplierValueField = this.findConditionField(fromIndex, "select", "supplier_id", "value")

    const conditionToCopyData = {
      budgetComparatorSelectedIndex: budgetComparatorField.selectedIndex,
      budgetValue: budgetValueField.value,
      requestTypeComparatorSelectedIndex: requestTypeComparatorField.selectedIndex,
      requestTypeValue: requestTypeValueField.tomselect.getValue(),
      supplierComparatorSelectedIndex: supplierComparatorField.selectedIndex,
      supplierValue: supplierValueField.tomselect.getValue(),
    }

    this.pasteDataIntoCondition(toIndex, conditionToCopyData)
  }

  pasteDataIntoCondition(conditionIndex, data) {
    const budgetComparatorField = this.findConditionField(conditionIndex, "select", "budget_cents", "comparator")
    budgetComparatorField.selectedIndex = data.budgetComparatorSelectedIndex

    const budgetValueField = this.findConditionField(conditionIndex, "input", "budget", "value")
    budgetValueField.value = data.budgetValue

    const requestTypeComparatorField = this.findConditionField(conditionIndex, "select", "request_type", "comparator")
    requestTypeComparatorField.selectedIndex = data.requestTypeComparatorSelectedIndex

    const requestTypeValueField = this.findConditionField(conditionIndex, "select", "request_type", "value")
    requestTypeValueField.tomselect.setValue(data.requestTypeValue)

    const supplierComparatorField = this.findConditionField(conditionIndex, "select", "supplier_id", "comparator")
    supplierComparatorField.selectedIndex = data.supplierComparatorSelectedIndex

    const supplierValueField = this.findConditionField(conditionIndex, "select", "supplier_id", "value")
    supplierValueField.tomselect.setValue(data.supplierValue.slice())
  }

  onBudgetComparatorChanged(event) {
    const selector = event.currentTarget
    const conditionIndex = selector.dataset.conditionIndex
    const newComparator = selector.value

    const condition = this.findCondition(conditionIndex)
    const budgetRangeContainer = condition.querySelector(`#condition-${conditionIndex}-budget-range-container`)
    const budgetValueContainer = condition.querySelector(`#condition-${conditionIndex}-budget-value-container`)
    const budgetValueField = this.findConditionField(conditionIndex, "input", "budget", "value")
    const budgetValue0Field = this.findConditionField(conditionIndex, "input", "budget", "value_0")
    const budgetValue1Field = this.findConditionField(conditionIndex, "input", "budget", "value_1")

    if (newComparator === "between") {
      show(budgetRangeContainer)
      hide(budgetValueContainer)

      budgetValueField.setAttribute("disabled", "")
      budgetValue0Field.removeAttribute("disabled")
      budgetValue1Field.removeAttribute("disabled")
    } else {
      hide(budgetRangeContainer)
      show(budgetValueContainer)

      budgetValueField.removeAttribute("disabled")
      budgetValue0Field.setAttribute("disabled", "")
      budgetValue1Field.setAttribute("disabled", "")
    }
  }

  findConditionField(conditionIndex, idPrefix, field, type) {
    const condition = this.findCondition(conditionIndex)
    const fieldId = `#${idPrefix}-condition_${conditionIndex}_${field}_${type}`
    return condition.querySelector(fieldId)
  }

  findCondition(index) {
    return this.editableConditionsContainerTarget.querySelector(`#editable-condition-${index}-container`)
  }
}
