import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="form-change-detector"
export default class extends Controller {
  static targets = ["form", "counterEl"]

  formTarget: HTMLFormElement
  counterElTarget: HTMLElement

  hasCounterElTarget: boolean

  static values = {
    counterLabel: {
      default: "{count} Unsaved Change",
      type: String,
    },
    counterPluralLabel: {
      default: "{count} Unsaved Changes",
      type: String,
    },
    ignoredSelector: {
      default: "",
      type: String,
    },
    // Useful when a nested-form adds the new form to the DOM,
    // and it does not change any input value but still you want that
    // change to be detected
    detectNestedFormAddition: {
      default: false,
      type: Boolean,
    },
    // Useful when a nested-form removes the form from the DOM
    // instead of changing the _destroy input
    detectNestedFormRemoval: {
      default: false,
      type: Boolean,
    },
  }

  counterLabelValue: string
  counterPluralLabelValue: string
  ignoredSelectorValue: string
  detectNestedFormAdditionValue: boolean
  detectNestedFormRemovalValue: boolean

  private fieldsChanged: string[] = []
  private inputsChanged: (HTMLInputElement | HTMLSelectElement)[] = []

  // bind() creates a new function with the given context, we need to store the
  // reference of that function so we can then remove the listener
  private registerChangeHandler = this.registerChange.bind(this)
  private detectNestedFormChangeHandler = this.detectNestedFormChange.bind(this)

  connect() {
    this.addFormListeners()
  }

  formTargetConnected() {
    this.addFormListeners()
  }

  disconnect() {
    this.removeFormListeners()
  }

  getTotalChanges() {
    return this.fieldsChanged.length
  }

  getFieldNames() {
    return this.fieldsChanged
  }

  getInputsChanged() {
    return this.inputsChanged
  }

  updateCounterEl() {
    if (!this.hasCounterElTarget) {
      return
    }

    let textContent = ""
    if (this.getTotalChanges() > 1 && this.hasCounterPluralLabel()) {
      textContent = this.counterPluralLabelValue.replace("{count}", this.getTotalChanges().toString())
    } else if (this.getTotalChanges() === 0) {
      textContent = ""
    } else {
      textContent = this.counterLabelValue.replace("{count}", this.getTotalChanges().toString())
    }

    this.counterElTarget.textContent = textContent
  }

  emitChanges() {
    document.dispatchEvent(
      new CustomEvent("FormChangeDetector:onChange", {
        detail: {
          form: this.formTarget,
          total: this.getTotalChanges(),
          changes: { fieldNames: this.getFieldNames(), inputs: this.getInputsChanged() },
        },
      }),
    )
  }

  registerChange(e) {
    if (this.ignoredSelectorValue !== "" && e.target.matches(this.ignoredSelectorValue)) {
      return
    }

    const fieldName: string = e.target.name
    if (!this.fieldsChanged.includes(fieldName)) {
      this.fieldsChanged.push(fieldName)
      this.inputsChanged.push(e.target)
      this.updateCounterEl()
      this.emitChanges()
    }
  }

  removeChange(e) {
    const fieldName: string = e.target.name
    if (this.fieldsChanged.includes(fieldName)) {
      this.fieldsChanged.splice(this.fieldsChanged.indexOf(fieldName), 1)
      this.inputsChanged.splice(this.inputsChanged.indexOf(e.target), 1)
      this.updateCounterEl()
      this.emitChanges()
    }
  }

  private addFormListeners() {
    this.formTarget.addEventListener("input", this.registerChangeHandler)
    if (this.detectNestedFormAdditionValue) {
      this.element.addEventListener("nested-form:add", this.detectNestedFormChangeHandler)
    }
    if (this.detectNestedFormRemovalValue) {
      this.element.addEventListener("nested-form:remove", this.detectNestedFormChangeHandler)
    }
  }

  private removeFormListeners() {
    this.formTarget.removeEventListener("input", this.registerChangeHandler)
    if (this.detectNestedFormAdditionValue) {
      this.element.removeEventListener("nested-form:add", this.detectNestedFormChangeHandler)
    }
    if (this.detectNestedFormRemovalValue) {
      this.element.removeEventListener("nested-form:remove", this.detectNestedFormChangeHandler)
    }
  }

  private hasCounterPluralLabel() {
    return this.counterPluralLabelValue.trim().length > 0
  }

  private detectNestedFormChange(e) {
    this.fieldsChanged.push(e.type)
    this.emitChanges()
  }
}
