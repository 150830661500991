import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="form-editor--question-bulk-actions"
export default class extends Controller {
  static targets = ["formSelect", "pagesContainer"]

  formSelectTarget: HTMLSelectElement
  pagesContainerTarget: HTMLDivElement

  connect() {
    this.populatePages()
  }

  populatePages() {
    const formId = this.formSelectTarget.value
    const template = document.getElementById(`form_${formId}_pages`)

    this.pagesContainerTarget.innerHTML = template.innerHTML
  }
}
