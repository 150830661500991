import { Controller } from "@hotwired/stimulus"
import { getMonthsAndDays, getNewDate } from "../utils/dates"
import { show, hide } from "../utils"
import { debounce, round } from "lodash"
import { useDurationCalculation } from "./mixins/use_duration_calculation"
import { updateMaskedInputValue } from "../utils/mask"

// Connects to data-controller="contract-form"
export default class extends Controller {
  // targets
  static targets = [
    "paymentTermsSelection",
    "paymentTermsOther",
    "paymentMethodSelection",
    "paymentMethodOther",
    "supplierDetails",
    "startDateField",
    "endDateField",
    "contractDurationField",
    "productSupplierWrapper",
    "statusField",
    "contractReplacementWrapper",
    "inlineSubmitButtons",
    "stackedSubmitButtons",
    "contractValueField",
    "pricingDetailsSelection",
    "periodContractValueFields",
    "periodStartDateField",
    "periodEndDateField",
    "sendRenewalReminderField",
    "triggerDateFieldContainer",
    "triggerDateFieldPlaceholder",
    "unnotifiableNoticeContainer",
  ]
  paymentTermsSelectionTarget: HTMLSelectElement
  paymentTermsOtherTarget: HTMLDivElement
  paymentMethodSelectionTarget: HTMLSelectElement
  paymentMethodOtherTarget: HTMLDivElement
  startDateFieldTarget: HTMLInputElement
  hasStartDateFieldTarget: boolean
  endDateFieldTarget: HTMLInputElement
  hasEndDateFieldTarget: boolean
  contractDurationFieldTarget: HTMLInputElement
  hasContractDurationFieldTarget: boolean
  productSupplierWrapperTarget: HTMLDivElement
  statusFieldTarget: HTMLSelectElement
  contractReplacementWrapperTarget: HTMLDivElement
  inlineSubmitButtons: HTMLDivElement
  stackedSubmitButtons: HTMLDivElement
  toggleButton: HTMLButtonElement
  inlineSubmitButtonsTarget: HTMLElement
  stackedSubmitButtonsTarget: HTMLElement
  hasStackedSubmitButtonsTarget: boolean
  contractValueFieldTarget: HTMLInputElement
  hasContractValueFieldTarget: boolean
  hasStatusFieldTarget: boolean
  pricingDetailsSelectionTarget: HTMLSelectElement
  hasPricingDetailsSelectionTarget: boolean
  periodContractValueFieldsTargets: HTMLInputElement[]
  hasPeriodStartDateFieldTarget: boolean
  periodStartDateFieldTargets: HTMLInputElement[]
  hasPeriodEndDateFieldTarget: boolean
  periodEndDateFieldTargets: HTMLInputElement[]
  sendRenewalReminderFieldTarget: HTMLInputElement
  triggerDateFieldContainerTargets: HTMLElement[]
  triggerDateFieldPlaceholderTargets: HTMLElement[]
  hasSendRenewalReminderFieldTarget: boolean
  hasUnnotifiableNoticeContainerTarget: boolean

  onContractValueChange = (e: InputEvent) => {
    this.calculatePeriodContractValue(e)
  }
  debouncedOnContractValueChange = debounce(this.onContractValueChange, 1000)

  connect() {
    this.paymentTermsSelectionTarget.addEventListener("change", (e) =>
      this.togglePaymentTermsOther((<HTMLSelectElement>e.target).value),
    )
    this.paymentMethodSelectionTarget.addEventListener("change", (e) =>
      this.togglePaymentMethodOther((<HTMLSelectElement>e.target).value),
    )
    if (this.hasSendRenewalReminderFieldTarget) {
      this.sendRenewalReminderFieldTarget.addEventListener("change", (e) =>
        this.toggleTriggerDateFields((<HTMLInputElement>e.target).checked),
      )
      this.toggleTriggerDateFields(this.sendRenewalReminderFieldTarget.checked)
    }
    if (this.hasUnnotifiableNoticeContainerTarget) {
      this.toggleTriggerDateFields(true)
    }
    this.contractValueFieldTarget.addEventListener("input", this.debouncedOnContractValueChange)
    if (this.hasPricingDetailsSelectionTarget) {
      this.pricingDetailsSelectionTarget.addEventListener("change", this.onContractValueChange)
    }
    this.togglePaymentTermsOther(this.paymentTermsSelectionTarget.value)
    this.togglePaymentMethodOther(this.paymentMethodSelectionTarget.value)
    this.computePeriodDates()
    this.toggleProductSupplier()
    this.toggleReplacementContractField()

    if (this.hasContractDurationFieldTarget) {
      useDurationCalculation({
        controller: this,
        targets: {
          startDateTarget: this.startDateFieldTarget,
          endDateTarget: this.endDateFieldTarget,
          durationTarget: this.contractDurationFieldTarget,
        },
      })
    }

    document.addEventListener("SlidingSidebar:onToggle", this.toggleSubmitButtons.bind(this))
  }

  disconnect() {
    this.paymentTermsSelectionTarget.removeEventListener("change", (e) =>
      this.togglePaymentTermsOther((<HTMLSelectElement>e.target).value),
    )
    this.paymentMethodSelectionTarget.removeEventListener("change", (e) =>
      this.togglePaymentMethodOther((<HTMLSelectElement>e.target).value),
    )
    this.contractValueFieldTarget.removeEventListener("input", this.debouncedOnContractValueChange)
    if (this.hasPricingDetailsSelectionTarget) {
      this.pricingDetailsSelectionTarget.removeEventListener("change", this.onContractValueChange)
    }

    if (this.hasSendRenewalReminderFieldTarget) {
      this.sendRenewalReminderFieldTarget.removeEventListener("change", (e) =>
        this.toggleTriggerDateFields((<HTMLInputElement>e.target).checked),
      )
    }
    document && document.removeEventListener("SlidingSidebar:onToggle", this.toggleSubmitButtons.bind(this))
  }

  toggleSubmitButtons(drawerEvent) {
    if (!this.hasStackedSubmitButtonsTarget) {
      return
    }
    const drawerOpened = (drawerEvent as CustomEvent).detail.open
    if (drawerOpened) {
      this.stackedSubmitButtonsTarget.classList.remove("hidden")
      this.inlineSubmitButtonsTarget.classList.add("hidden")
    } else {
      this.stackedSubmitButtonsTarget.classList.add("hidden")
      this.inlineSubmitButtonsTarget.classList.remove("hidden")
    }
  }

  onSupplierIsResellerChanged(e): void {
    const value = e.target.value === "true"
    this.toggleProductSupplier(value)
  }

  onStatusChanged(e): void {
    const value = e.target.value
    this.toggleReplacementContractField(value)
  }

  toggleReplacementContractField(changedValue?: string): void {
    if (this.hasStatusFieldTarget) {
      const setValue = this.statusFieldTarget.value

      if (changedValue === "replaced" || setValue === "replaced") {
        show(this.contractReplacementWrapperTarget)
      } else {
        hide(this.contractReplacementWrapperTarget)
      }
    }
  }

  toggleProductSupplier(value?: boolean): void {
    const isSupplierReseller = (<HTMLInputElement>document.getElementById("contract_supplier_is_reseller_true")).checked
    if (isSupplierReseller || value) {
      show(this.productSupplierWrapperTarget)
    } else {
      hide(this.productSupplierWrapperTarget)
    }
  }

  togglePaymentTermsOther(paymentTerms) {
    if (paymentTerms === "other") {
      show(this.paymentTermsOtherTarget)
    } else {
      hide(this.paymentTermsOtherTarget)
    }
  }

  togglePaymentMethodOther(paymentMethod) {
    if (paymentMethod === "other") {
      show(this.paymentMethodOtherTarget)
    } else {
      hide(this.paymentMethodOtherTarget)
    }
  }

  toggleTriggerDateFields(checked: boolean) {
    if (checked) {
      this.triggerDateFieldContainerTargets.forEach((el) => hide(el))
      this.triggerDateFieldPlaceholderTargets.forEach((el) => show(el))
    } else {
      this.triggerDateFieldContainerTargets.forEach((el) => show(el))
      this.triggerDateFieldPlaceholderTargets.forEach((el) => hide(el))
    }
  }

  computePeriodDates(): void {
    if (
      !this.hasStartDateFieldTarget ||
      !this.hasEndDateFieldTarget ||
      !this.hasPeriodStartDateFieldTarget ||
      !this.hasPeriodEndDateFieldTarget
    ) {
      return
    }
    const contractStartDate = this.startDateFieldTarget.value
    const contractEndDate = this.endDateFieldTarget.value

    if (this.contractDatesInvalid(contractStartDate, contractEndDate).result) {
      return
    }

    const { months, days } = getMonthsAndDays(contractStartDate, contractEndDate)
    if (this.contractDurationOverOneYear(months, days)) {
      const numOfPeriodForms = this.periodStartDateFieldTargets.filter((el) => !el.hasAttribute("data-ignore")).length
      let totalPeriods = Math.ceil(months / 12)

      const addExtraPeriodForPartialMonth = months % 12 === 0 && days > 0
      if (addExtraPeriodForPartialMonth) {
        totalPeriods += 1
      }
      const numPeriods = totalPeriods - numOfPeriodForms

      // Triggers: nested-form#addMultiple
      this.dispatch("addPeriods", { detail: { numPeriods: numPeriods } })

      for (let i = 0; i < totalPeriods; i++) {
        this.setPeriodDate(this.periodStartDateFieldTargets[i], this.computePeriodStartDate(contractStartDate, i))
        this.setPeriodDate(
          this.periodEndDateFieldTargets[i],
          this.computePeriodEndDate(contractStartDate, contractEndDate, i, totalPeriods),
        )
      }
    } else {
      this.setPeriodDate(this.periodStartDateFieldTargets[0], contractStartDate)
      this.setPeriodDate(this.periodEndDateFieldTargets[0], contractEndDate)
    }
  }

  contractDatesInvalid(
    contractStartDate: string,
    contractEndDate: string,
  ): { result: true; message: string } | { result: false; message: null } {
    if (contractStartDate === "" || contractEndDate === "") {
      return {
        result: true,
        message: "Contract start and end dates are required",
      }
    }

    const parsedStartDate = new Date(contractStartDate)
    const parsedEndDate = new Date(contractEndDate)

    const endDateYear = parsedEndDate.getFullYear()

    if (parsedStartDate > parsedEndDate) {
      // the user is probably still typing
      if (endDateYear < 1000) {
        return {
          result: true,
          message: "--",
        }
      } else {
        return {
          result: true,
          message: "Start date must be before end date",
        }
      }
    }

    const { months, _days } = getMonthsAndDays(contractStartDate, contractEndDate)
    if (months > 120) {
      return {
        result: true,
        message: "Contract duration cannot be more than 10 years",
      }
    }

    return {
      result: false,
      message: null,
    }
  }

  contractDurationOverOneYear(months: number, days: number): boolean {
    return months > 12 || (months === 12 && days !== 0)
  }

  setPeriodDate(periodDateField: HTMLInputElement, newDate: string): void {
    if (!periodDateField || periodDateField.value !== "") {
      return
    }

    periodDateField.value = newDate
  }

  computePeriodStartDate(contractStartDate: string, currentIndex: number): string {
    if (currentIndex === 0) {
      return contractStartDate
    } else {
      const months = 12 * currentIndex
      return getNewDate(contractStartDate, months, 0)
    }
  }

  computePeriodEndDate(
    contractStartDate: string,
    contractEndDate: string,
    currentIndex: number,
    totalPeriods: number,
  ): string {
    if (currentIndex + 1 === totalPeriods) {
      return contractEndDate
    } else {
      const months = 12 * (currentIndex + 1)
      const days = -1
      return getNewDate(contractStartDate, months, days)
    }
  }

  calculatePeriodContractValue(e) {
    const numOfPeriods = this.periodContractValueFieldsTargets.filter((el) => !el.hasAttribute("data-ignore")).length
    const pricing = this.hasPricingDetailsSelectionTarget ? this.pricingDetailsSelectionTarget.value : ""
    const contractValueNoCommas = this.contractValueFieldTarget.value.replace(/,/g, "")
    const totalValue = this.hasContractValueFieldTarget ? contractValueNoCommas : 0

    if (pricing === "constant" && numOfPeriods > 1) {
      if (numOfPeriods > 0) {
        const periodValue = round(Number(totalValue) / numOfPeriods, 2)
        this.periodContractValueFieldsTargets.forEach((el) => {
          updateMaskedInputValue(el, periodValue.toString())
        })
      }
    } else if (numOfPeriods === 1) {
      this.periodContractValueFieldsTargets.forEach((el) => {
        updateMaskedInputValue(el, totalValue.toString())
      })
    }
  }
}
