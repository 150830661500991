import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="price-checks-v2--line-item--radio-button"
export default class extends Controller {
  static values = {
    formId: String,
  }
  formIdValue: string

  connect() {}

  submitForm() {
    const form = document.getElementById(this.formIdValue) as HTMLFormElement
    form.requestSubmit()
  }
}
