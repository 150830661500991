import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="organization-preferences--documents"
export default class extends Controller {
  static targets = ["disableable", "disableTrigger"]
  disableableTargets: any
  disableTriggerTargets: any

  disableTriggerTargetConnected(connectedTarget) {
    if (connectedTarget.checked) {
      const targetsToDisable = this.filterTargets(connectedTarget)
      targetsToDisable.forEach(this.disableTarget)
    }
  }

  radioClick({ target: eventTarget }): void {
    if (this.disableTriggerTargets.some((trigger) => trigger === eventTarget)) {
      const targetsToDisable = this.filterTargets(eventTarget)

      targetsToDisable.forEach(this.disableTarget)
    } else {
      const targetsToEnable = this.filterTargets(eventTarget)

      let i = 0
      // break out of the loop if targets were never set to disabled in the first place.
      while (targetsToEnable[targetsToEnable.length - 1].disabled === true) {
        targetsToEnable[i].disabled = false
        targetsToEnable[i].style.cursor = ""
        targetsToEnable[i].classList.remove("disabled")
        i++
      }
    }
  }

  filterTargets(eventTarget) {
    const eventID = eventTarget.id.replace("preferences_", "").slice(0, -2)
    return this.disableableTargets.filter(({ id: targetID }) => targetID && targetID.includes(eventID))
  }

  disableTarget(targetEl) {
    if (targetEl.type === "radio") {
      targetEl.checked = false
      targetEl.disabled = true

      targetEl.classList.add("disabled")
      targetEl.style.cursor = "not-allowed"
    } else {
      // parent element
      targetEl.classList.add("disabled")
    }
  }
}
