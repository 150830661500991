import { TrixEditor } from "./trix_types"
import { TrixWordFinder } from "./trix_word_finder"

export class TrixWordReplacer {
  data: Record<string, string>
  editor: TrixEditor
  word: string

  static call = (editor: TrixEditor, word: string, data: Record<string, string> = {}) => {
    new this(editor, word, data).call()
  }

  private call = () => {
    this.deleteWordAtCaret()

    const attachment = new Trix.Attachment({
      contentType: "tropic-mention",
      content: this.mentionNode().outerHTML,
    })
    this.editor.insertAttachment(attachment)
    this.editor.insertString(" ")
  }

  private mentionNode = (): HTMLSpanElement => {
    const boldedUserName = document.createElement("strong")
    boldedUserName.innerText = this.word

    const userInfoNode = document.createElement("span")
    userInfoNode.classList.add("tropic-mention")
    userInfoNode.appendChild(boldedUserName)
    Object.keys(this.data).forEach((k: string) => {
      userInfoNode.dataset[k] = this.data[k]
    })

    return userInfoNode
  }

  private deleteWordAtCaret = () => {
    const { positionEnd, positionStart } = TrixWordFinder.call(this.editor)
    this.editor.setSelectedRange([positionStart, positionEnd])
    this.editor.deleteInDirection("forward")
  }

  private constructor(editor: TrixEditor, word: string, data: Record<string, string>) {
    this.editor = editor
    this.word = word
    this.data = data
  }
}
