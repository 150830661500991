import { Controller } from "@hotwired/stimulus"
import { clearInput, clearSelect, disable, enable, hide, show } from "../utils"

// Connects to data-controller="contract-renewal-type-form"
export default class extends Controller {
  static targets = ["endDateField", "expiresUponWrapper", "expiresUponField"]

  endDateFieldTarget: HTMLInputElement
  expiresUponWrapperTarget: HTMLDivElement
  expiresUponFieldTarget: HTMLSelectElement

  renewalTypeValue: string
  endDateValue: string

  onNotApplicableChange(event: Event) {
    const { checked } = event.target as HTMLInputElement
    this.toggleExpiresUponField(checked)
  }

  private toggleExpiresUponField(checked: boolean) {
    if (checked) {
      clearInput(this.endDateFieldTarget)
      disable(this.endDateFieldTarget)
      show(this.expiresUponWrapperTarget)
    } else {
      clearSelect(this.expiresUponFieldTarget, 0)
      enable(this.endDateFieldTarget)
      hide(this.expiresUponWrapperTarget)
    }
  }
}
