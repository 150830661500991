import { Controller } from "@hotwired/stimulus"
import { hide, show } from "../../utils/index"

/* Connects to data-controller="canopy--panel-accordion-group-component"
 */
export default class extends Controller {
  static values = {
    dynamicPanelsSelector: String,
  }

  dynamicPanelsSelectorValue: string
  panelTriggersMap: Map<HTMLElement, HTMLElement> = new Map()
  visibleDynamicPanels: Set<HTMLDivElement> = new Set()

  findPanel = (panelId: string): HTMLElement => {
    return this.element.querySelector(`[id="${panelId}"]`)
  }

  hidePanelOnClose = (e: MouseEvent) => {
    const button = e.currentTarget as HTMLButtonElement
    const panel = button.closest(this.dynamicPanelsSelectorValue) as HTMLDivElement

    hide(panel)
    this.setOrUnsetAsCurrentAccordionPanel(panel)
    this.visibleDynamicPanels.delete(panel)
  }

  setOrUnsetAsCurrentAccordionPanel = (panel: HTMLDivElement) => {
    panel.querySelector("details > summary").click()
  }

  showPanelOnOpen = (e: Event) => {
    const trigger = e.currentTarget as HTMLElement
    const panelId = e.params.panelId
    const panel = this.findPanel(panelId)

    this.panelTriggersMap.set(trigger, panelId)

    if (!this.visibleDynamicPanels.has(panel)) {
      show(panel)
      this.setOrUnsetAsCurrentAccordionPanel(panel)
      this.visibleDynamicPanels.add(panel)
    }
  }
}
