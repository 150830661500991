import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="auto-timezone-setter-form"
export default class extends Controller {
  static targets = ["timezoneField", "form"]

  formTarget: HTMLFormElement
  timezoneFieldTarget: HTMLInputElement

  connect = () => {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
    this.timezoneFieldTarget.value = timezone === "UTC" ? "Etc/UTC" : timezone
    this.formTarget.submit()
  }
}
