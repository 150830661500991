import { Dropdown } from "tailwindcss-stimulus-components"

// Connects to data-controller="dropdown"
export default class extends Dropdown {
  get transitionOptions() {
    // override all transition classes to eliminate transformations and their stacking contexts
    return {
      enter: "",
      enterFrom: "",
      enterTo: "",
      leave: "",
      leaveFrom: "",
      leaveTo: "",
      toggleClass: "hidden",
    }
  }
}
