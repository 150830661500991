import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="csrfer"
export default class extends Controller {
  static targets = ["field"]

  fieldTarget: HTMLInputElement

  connect() {
    const csrfMetaTag = document.querySelector("meta[name='csrf-token']")
    const csrfToken = csrfMetaTag.getAttribute("content")

    this.fieldTarget.setAttribute("value", csrfToken)
  }
}
