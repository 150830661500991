import * as Sentry from "@sentry/browser"

document.addEventListener("turbo:frame-missing", (event) => {
  const {
    detail: { response, visit },
  } = event

  event.preventDefault()

  visit(response.url)
})
