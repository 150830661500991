import { Controller } from "@hotwired/stimulus"
import { show, hide } from "../utils"
import { TomInput } from "tom-select/src/types/core"

// Connects to data-controller="nonlinear-task-groups"
export default class extends Controller {
  static values = {
    groupLabelNames: Array<String>,
  }
  groupLabelNamesValue: string[]

  static targets = ["groupLabelCaption", "groupLabelName"]
  groupLabelCaptionTarget: HTMLElement
  groupLabelNameTarget: TomInput

  connect() {}

  onGroupLabelChange() {
    const selectedLabel = this.groupLabelNameTarget?.tomselect?.getValue() as string
    if (selectedLabel) {
      if (!this.groupLabelNamesValue.includes(selectedLabel)) {
        show(this.groupLabelCaptionTarget)
      } else {
        hide(this.groupLabelCaptionTarget)
      }
    } else {
      hide(this.groupLabelCaptionTarget)
    }
  }
}
