/*
 *  This function allows to open a URL in a new browser tab based on a query string param.
 *  Useful when you need to open a new tab after a controller response:
 *    redirect_to path(new_tab: "https://google.com") => /path?new_tab=https://google.com
 */
const newTabHandler = () => {
  const queryParams = new URLSearchParams(window.location.search)
  if (queryParams.get("new_tab")) {
    window.open(queryParams.get("new_tab"), "_blank")
    if (queryParams.get("reload")) {
      queryParams.delete("reload")
      queryParams.delete("new_tab")
      const currentUrl = window.location.toString().replace(window.location.search, "")
      window.location.href = `${currentUrl}${queryParams.toString() ? "?" + queryParams.toString() : ""}`
    }
  }
}

window.newTabHandler = newTabHandler
