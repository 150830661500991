import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="quickfilter"
export default class extends Controller {
  static targets = ["checkbox", "checkedIcon", "uncheckedIcon"]

  CHECKED_CLASSES = ["border-purple-200", "bg-purple-100", "hover:bg-purple-100"]
  UNCHECKED_CLASSES = ["border-base", "bg-white", "hover:bg-gray-100"]

  checkboxTarget: HTMLInputElement
  checkedIconTarget: HTMLElement
  uncheckedIconTarget: HTMLElement
  connect() {}

  toggle() {
    if (this.checkboxTarget.checked) {
      this.element.classList.remove(...this.CHECKED_CLASSES)
      this.element.classList.add(...this.UNCHECKED_CLASSES)
      this.checkedIconTarget.classList.add("hidden")
      this.uncheckedIconTarget.classList.remove("hidden")
      this.checkboxTarget.checked = false
    } else {
      this.element.classList.remove(...this.UNCHECKED_CLASSES)
      this.element.classList.add(...this.CHECKED_CLASSES)
      this.checkedIconTarget.classList.remove("hidden")
      this.uncheckedIconTarget.classList.add("hidden")
      this.checkboxTarget.checked = true
    }

    this.checkboxTarget.dispatchEvent(new Event("change", { bubbles: true }))
  }
}
