import { Controller } from "@hotwired/stimulus"
import { show, hide } from "../utils"

// Connects to data-controller="fade-out"
export default class extends Controller {
  static targets = ["content", "openElement", "closeElement"]

  static values = {
    open: {
      type: Boolean,
      default: false,
    },
    initialHeight: {
      type: Number,
      default: 200,
    },
    gradientColor: {
      type: String,
      default: "#ffffff",
    },
    gradientHeight: {
      type: String,
      default: "150px",
    },
    gradientWidth: {
      type: String,
      default: "100%",
    },
    transitionDuration: {
      type: Number,
      default: 0.3,
    },
  }

  openValue: boolean
  initialHeightValue: number
  gradientColorValue: string
  gradientHeightValue: string
  gradientWidthValue: string
  transitionDurationValue: number

  contentTarget: HTMLElement
  closeElementTarget: HTMLElement
  openElementTarget: HTMLElement
  gradientElement: HTMLDivElement

  hasContentTarget: boolean
  hasOpenElementTarget: boolean
  hasCloseElementTarget: boolean

  contentHeight: number
  toggleHandler = this.toggle.bind(this)

  connect() {
    if (!this.hasContentTarget) {
      throw new Error("Missing 'content' target and is required")
    }

    if (!this.hasOpenElementTarget) {
      throw new Error("Missing 'openElement' target and is required")
    }

    this.contentHeight = this.contentTarget.scrollHeight
    this.addFadeOutStyles()
    this.attachHandlers()

    if (this.hasCloseElementTarget) {
      hide(this.closeElementTarget)
    }

    if (this.openValue) {
      this.expandContent()
    }
  }

  disconnect() {
    this.removeFadeOutStyles()
    this.detachHandlers()
  }

  attachHandlers() {
    this.openElementTarget.addEventListener("click", this.toggleHandler)
    if (this.hasCloseElementTarget) {
      this.closeElementTarget.addEventListener("click", this.toggleHandler)
    }
  }

  detachHandlers() {
    this.openElementTarget.removeEventListener("click", this.toggleHandler)
    if (this.hasCloseElementTarget) {
      this.closeElementTarget.removeEventListener("click", this.toggleHandler)
    }
  }

  toggle() {
    if (this.contentTarget.classList.contains("fade-out-opened")) {
      this.collapseContent()
    } else {
      this.expandContent()
    }
  }

  expandContent() {
    this.contentTarget.style.maxHeight = `${this.contentHeight}px`
    this.contentTarget.classList.add("fade-out-opened")
    setTimeout(() => hide(this.gradientElement), this.transitionDurationValue * 1000)
    if (this.hasCloseElementTarget) {
      hide(this.openElementTarget)
      show(this.closeElementTarget)
    }
  }

  collapseContent() {
    this.contentTarget.style.maxHeight = `${this.initialHeightValue}px`
    this.contentTarget.classList.remove("fade-out-opened")
    show(this.gradientElement)
    if (this.hasCloseElementTarget) {
      show(this.openElementTarget)
      hide(this.closeElementTarget)
    }
  }

  addFadeOutStyles() {
    this.contentTarget.classList.add("relative")
    this.contentTarget.style.zIndex = "1"
    this.contentTarget.style.overflow = "hidden"
    this.contentTarget.style.transition = `height ${this.transitionDurationValue}s ease-in-out, max-height ${this.transitionDurationValue}s ease-in-out`
    this.contentTarget.style.maxHeight = `${this.initialHeightValue}px`
    this.contentTarget.style.paddingBottom = `${this.openElementTarget.offsetHeight * 2}px`

    this.gradientElement = document.createElement("div")
    this.gradientElement.classList.add("absolute")
    this.gradientElement.style.height = this.gradientHeightValue
    this.gradientElement.style.width = this.gradientWidthValue
    this.gradientElement.style.bottom = "0"
    this.gradientElement.style.left = "0"
    this.gradientElement.style.zIndex = "2"
    this.gradientElement.style.backgroundImage = `linear-gradient(to top, ${this.gradientColorValue}, transparent)`

    this.openElementTarget.classList.add("absolute")
    this.openElementTarget.style.bottom = "0"
    this.openElementTarget.style.left = "0"
    this.openElementTarget.style.zIndex = "3"

    if (this.hasCloseElementTarget) {
      this.closeElementTarget.classList.add("absolute")
      this.closeElementTarget.style.bottom = "0"
      this.closeElementTarget.style.left = "0"
      this.closeElementTarget.style.zIndex = "3"
    }

    this.contentTarget.appendChild(this.gradientElement)
  }

  removeFadeOutStyles() {
    this.contentTarget.classList.remove("relative")
    this.contentTarget.style.zIndex = null
    this.contentTarget.style.overflow = null
    this.contentTarget.style.transition = null
    this.contentTarget.style.maxHeight = null
    this.contentTarget.style.paddingBottom = null

    this.gradientElement.remove()

    this.openElementTarget.classList.remove("absolute")
    this.openElementTarget.style.bottom = null
    this.openElementTarget.style.left = null
    this.openElementTarget.style.zIndex = null

    if (this.hasCloseElementTarget) {
      this.closeElementTarget.classList.remove("absolute")
      this.closeElementTarget.style.bottom = null
      this.closeElementTarget.style.left = null
      this.closeElementTarget.style.zIndex = null
    }
  }
}
