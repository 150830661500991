import { Controller } from "@hotwired/stimulus"
import RequestTaskForm__TriggerFieldsController from "./trigger_fields_controller"

// Connects to data-controller="request-task-form--task-stage"
class RequestTaskForm__TaskStageController extends Controller {
  static targets = ["stageSelect"]
  stageSelectTarget: HTMLSelectElement

  static outlets = ["request-task-form--trigger-fields"]
  requestTaskFormTriggerFieldsOutlet: RequestTaskForm__TriggerFieldsController

  updateTriggers() {
    this.requestTaskFormTriggerFieldsOutlet.updateOptions()
  }
}

export default RequestTaskForm__TaskStageController
