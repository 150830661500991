import { Controller } from "@hotwired/stimulus"
import Sortable from "sortablejs"
import { patch } from "@rails/request.js"

export default class extends Controller {
  animationValue: number
  groupNameValue: string
  resourceNameValue: string
  paramNameValue: string
  responseKindValue: string
  contextValue: string
  sortables: Sortable[]
  handleValue: string
  // @ts-ignore
  element: HTMLElement

  static values = {
    resourceName: String,
    groupName: String,
    paramName: {
      type: String,
      default: "position",
    },
    responseKind: {
      type: String,
      default: "html",
    },
    context: String,
    animation: Number,
    handle: String,
  }

  initialize() {
    this.canOpenStep = false
    this.end = this.end.bind(this)
  }

  connect() {
    document.addEventListener("dragenter", this.dragEnter.bind(this))
    document.addEventListener("dragstart", this.dragStart.bind(this))

    this.sortables = []

    const sortableLists = document.querySelectorAll(".sortable-list")
    for (let i = 0; i < sortableLists.length; i++) {
      const sortable = new Sortable(sortableLists[i], {
        ...this.defaultOptions,
        ...this.options(sortableLists[i]),
      })

      this.sortables.push(sortable)
    }
  }

  disconnect() {
    document.removeEventListener("dragenter", this.dragEnter)
    document.removeEventListener("dragstart", this.dragStart)
    this.sortables.forEach((sortable) => sortable.destroy())
    this.sortables = []
  }

  dragEnter(event) {
    const toElement = event.toElement

    if (this.canOpenStep && toElement.classList.contains("step-container")) {
      toElement.dataset.collapseIsOpenValue = true
    }
  }

  dragStart(event) {
    this.canOpenStep = Boolean(event.target?.id?.includes(this.contextLabels().taskDivIdPrefix))
    this.showNoTasksContainer(event)
  }

  async end(event) {
    const { item, newIndex } = event

    if (!item.dataset.sortableUpdateUrl) return

    const param = this.resourceNameValue ? `${this.resourceNameValue}[${this.paramNameValue}]` : this.paramNameValue

    const data = new FormData()
    data.append(param, newIndex + 1)

    if (item.id.includes(this.contextLabels().taskDivIdPrefix)) {
      const stepContainer = this.getStepContainer(item)
      data.append(this.contextLabels().newStepParam, stepContainer.id.replace(this.contextLabels().stepDivIdPrefix, ""))

      this.hideNoTasksContainer(item)
    } else {
      const stageContainer = this.getStageContainer(item)
      data.append("request_stage", stageContainer.id)
    }

    await patch(item.dataset.sortableUpdateUrl, { body: data, responseKind: this.responseKindValue })
  }

  hideNoTasksContainer(item) {
    const noTasksContainer = item.parentElement.nextElementSibling
    const addTaskLink = noTasksContainer.nextElementSibling

    if (addTaskLink && noTasksContainer) {
      noTasksContainer.classList.add("hidden")
      addTaskLink.classList.remove("hidden")
    }
  }

  getStageContainer(item) {
    return item.parentElement.previousElementSibling
  }

  getStepContainer(item) {
    return item.parentElement.parentElement.parentElement
  }

  showNoTasksContainer(event) {
    const prevElement = event.srcElement.previousElementSibling
    const nextElement = event.srcElement.nextElementSibling
    const noTasksContainer = event.srcElement.parentElement.nextElementSibling
    const addTaskLink = noTasksContainer.nextElementSibling

    if (
      prevElement?.id.includes(this.contextLabels().taskDivIdPrefix) ||
      nextElement?.id.includes(this.contextLabels().taskDivIdPrefix)
    ) {
      return
    }

    if (addTaskLink && noTasksContainer?.classList.contains("hidden")) {
      noTasksContainer.classList.remove("hidden")
      addTaskLink.classList.add("hidden")
    }
  }

  options(sortableListElement): Sortable.Options {
    const groupName = sortableListElement.dataset.sortableGroupName

    return {
      draggable: ".draggable",
      filter: ".ignore-sortable",
      animation: this.animationValue || this.defaultOptions.animation || 150,
      handle: this.handleValue || this.defaultOptions.handle || undefined,
      onEnd: this.end,
      group: {
        name: groupName,
        pull: groupName,
        put: groupName,
      },
    }
  }

  contextLabels() {
    switch (this.contextValue) {
      case "workflow_steps":
        return {
          taskDivIdPrefix: "workflow-task",
          stepDivIdPrefix: "workflow-step-",
          newStepParam: "new_workflow_step_id",
        }
      case "request_steps":
        return {
          taskDivIdPrefix: "request-task",
          stepDivIdPrefix: "request-step-",
          newStepParam: "new_request_step_id",
        }
      default:
        return {}
    }
  }

  get defaultOptions(): Sortable.Options {
    return {}
  }
}
