import { Controller } from "@hotwired/stimulus"
import { parseMaskedFloat } from "../../utils/index"

export default class SumLineController extends Controller {
  static targets = [
    "alertIcon",
    "expenseAmount",
    "expenseLineItemSubTotal",
    "expenseSum",
    "itemLineItemSubTotal",
    "itemLineTotal",
    "itemQuantity",
    "itemRate",
    "itemSum",
    "mainTotal",
    "spendAlert",
    "total",
  ]

  static values = {
    requestAnticipatedSpend: Number,
    currencyCode: String,
  }

  connect(): void {
    this.calculateSums()
  }

  calculateSums(): void {
    this.calculateExpenseSum()
    this.calculateItemSum()
  }

  formatCurrency(amount: number): string {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: this.currencyCodeValue,
      minimumFractionDigits: 2,
    }).format(amount)
  }

  calculateExpenseSum(): void {
    if (this.hasExpenseAmountTarget === false) {
      return
    }
    if (this.hasExpenseLineItemSubTotalTarget === false) {
      return
    }

    const expenseInputs = this.expenseAmountTargets
    const sum = expenseInputs.reduce((acc, input) => acc + (parseMaskedFloat(input.value) || 0), 0)
    this.expenseSumTarget.textContent = this.formatCurrency(sum.toFixed(2))
    this.expenseLineItemSubTotalTarget.textContent = this.formatCurrency(sum.toFixed(2))

    this.updateTotal()
  }

  calculateItemSum(): void {
    let sum = 0

    if (this.hasItemRateTarget === false) {
      return
    }

    this.itemRateTargets.forEach((item: { value: string }, index: string | number) => {
      const quantity = parseFloat(this.itemQuantityTargets[index]?.value) || 0
      const itemValue = parseMaskedFloat(item.value) || 0

      const totalLineValue = quantity * itemValue
      sum += totalLineValue

      this.itemLineTotalTargets[index].textContent = this.formatCurrency(totalLineValue.toFixed(2))
    })

    this.itemSumTarget.textContent = this.formatCurrency(sum)
    this.itemLineItemSubTotalTarget.textContent = this.formatCurrency(sum)

    this.updateTotal()
  }

  updateTotal(): void {
    // This is to clear the total if there are no items or expenses
    if (!this.hasItemQuantityTarget && this.hasItemLineItemSubTotalTarget && this.hasItemSumTarget) {
      this.itemLineItemSubTotalTarget.textContent = 0
      this.itemSumTarget.textContent = 0
    }
    if (!this.hasExpenseAmountTarget && this.hasExpenseLineItemSubTotalTarget && this.hasExpenseSumTarget) {
      this.expenseLineItemSubTotalTarget.textContent = 0
      this.expenseSumTarget.textContent = 0
    }

    const expenseSum = this.hasExpenseSumTarget ? parseMaskedFloat(this.expenseSumTarget.textContent || 0) : 0
    const itemSum = this.hasItemSumTarget ? parseMaskedFloat(this.itemSumTarget.textContent || 0) : 0
    const total = (expenseSum + itemSum).toFixed(2)

    this.totalTargets.forEach((target: HTMLElement) => {
      target.textContent = this.formatCurrency(total)
    })

    // in a preview context we shouldn't show the alert since we don't have the request object available
    if (!this.requestAnticipatedSpendValue) return

    const isOverSpend = this.requestAnticipatedSpendValue < total

    this.spendAlertTarget.classList.toggle("hidden", !isOverSpend)
    this.mainTotalTarget.classList.toggle("text-red-600", isOverSpend)
    this.alertIconTarget.classList.toggle("hidden", !isOverSpend)
  }
}
