import { Chart } from "chart.js"
import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="bar-chart"
export default class BarChartController extends Controller {
  static values = {
    labels: String,
    datasets: String,
    yAxisTitle: String,
    yAxisCurrency: String,
    stacked: Boolean,
  }
  labelsValue: string
  datasetsValue: string
  yAxisTitleValue: String
  yAxisCurrencyValue: String
  stackedValue: boolean
  chart: Chart

  tootlipOptions() {
    return { enabled: false }
  }

  onClick() {}
  onHover() {}

  connect() {
    const labels = this.labelsValue ? JSON.parse(this.labelsValue) : []
    const datasets = this.datasetsValue ? JSON.parse(this.datasetsValue) : []
    const currency = this.yAxisCurrencyValue
    const stacked = this.stackedValue ? JSON.parse(this.stackedValue) : false

    const config = {
      type: "bar",
      data: {
        labels: labels,
        datasets: datasets,
      },
      options: {
        maintainAspectRatio: false,
        scales: {
          y: {
            stacked: stacked,
            beginAtZero: true,
            title: {
              display: true,
              text: this.yAxisTitleValue,
              color: "#6A778A",
              font: {
                size: 14,
              },
            },
            grid: {
              drawBorder: false,
            },
            ticks: {
              precision: 0,
              color: "#6A778A",
              font: {
                size: 14,
              },
              callback: function (value, index) {
                if (currency) {
                  return value.toLocaleString("en-US", {
                    style: "currency",
                    currency: currency,
                    maximumFractionDigits: 0,
                  })
                } else {
                  return value
                }
              },
            },
          },
          x: {
            stacked: stacked,
            grid: {
              drawBorder: false,
              drawOnChartArea: false,
              drawTicks: false,
            },
            ticks: {
              precision: 0,
              color: "#6A778A",
              font: {
                size: 14,
              },
            },
          },
        },
        plugins: {
          legend: { display: false },
          title: { display: false },
          tooltip: this.tootlipOptions(),
        },
        onClick: this.onClick,
        onHover: this.onHover,
      },
      plugins: [],
    }
    this.chart = new Chart(this.element, config)
  }
}
