import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="logo-upload"
export default class extends Controller {
  static targets = ["output", "input", "image"]
  outputTarget: HTMLParagraphElement
  inputTarget: HTMLInputElement
  imageTarget: HTMLImageElement

  imgFile: File | null = null

  loadNewImage(): void {
    const newSrc = URL.createObjectURL(this.imgFile)
    this.imageTarget.src = newSrc
    this.outputTarget.textContent = this.imgFile.name
  }

  change(): void {
    this.imgFile = this.inputTarget.files[0] // set logo
    this.loadNewImage()
  }
}
