import { TrixEditor } from "./trix_types"

export class TrixWordFinder {
  editor: TrixEditor

  static call = (editor: TrixEditor): WordInfo => new this(editor).call()

  private call = (): WordInfo => this.wordAtCaret()

  private caretPosition = (): number => this.editor.getSelectedRange()[0]

  private content = (): string => this.editor.getDocument().toString()

  private findNextSpace = (position: number, text: string): number => {
    if (this.isAtSpace(text.charAt(position))) return position

    const chopPosition = position + 1
    const substringSpacePosition = text.slice(chopPosition).split("").findIndex(this.isAtSpace)
    const isEndOfString = substringSpacePosition === -1

    return isEndOfString ? text.length : substringSpacePosition + chopPosition
  }

  private findPreviousSpace = (position: number, text: string): number => {
    const reverse = text.split("").reverse().join("")
    const reverseCaretPosition = text.length - position
    const previousSpace = this.findNextSpace(reverseCaretPosition, reverse)

    return text.length - previousSpace
  }

  private isAtSpace = (text) => text.trim() === ""

  private wordAtCaret = (): WordInfo => {
    const position = this.caretPosition()
    const content = this.content()
    const nextSpace = this.findNextSpace(position, content)
    const previousSpace = this.findPreviousSpace(position, content)

    return {
      word: content.substring(previousSpace, nextSpace),
      positionStart: previousSpace,
      positionEnd: nextSpace,
    }
  }

  private constructor(editor: TrixEditor) {
    this.editor = editor
  }
}

export type WordInfo = {
  word: string
  positionStart: number
  positionEnd: number
}
